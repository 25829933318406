import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import SvgConverter from "../../../../common/SvgConverter";
import { ReactComponent as OrangeRuppee } from "../../../../common/icons/profile/orange_ruppee_icon.svg";
import Separator from "../../../../common/ui/Separator";
import CustomButton from "../../../../common/ui/CustomButton";
import { convertAmount } from "../../../../common/utils";
import { PolicyAlternateCodes } from "../../../../common/types/PolicyTypes";

const UnAssignedPoliciesForBank = ({ data = {}, onAssignClick }: any) => {
  const theme = useTheme();
  const isAsset = data.alternateCode === PolicyAlternateCodes.ASSET;
  return (
    <Grid item>
      <Grid item>
        <Grid
          flexWrap={"nowrap"}
          container
          justifyContent={"space-between"}
          className="mb-15"
        >
          <Grid item>
            <Grid item>
              <Typography
                className="f-16 fw-600"
                color={theme.palette.common.black}
              >
                {data.planName}
              </Typography>
            </Grid>
            {isAsset ? (
              <>
                <Grid item>
                  <Typography
                    className="f-14"
                    color={theme.palette.common.black}
                  >
                    Vehicle Number
                  </Typography>
                  <Typography
                    className="f-16 fw-600"
                    color={theme.palette.common.black}
                  >
                    {data.vehicleNo}
                  </Typography>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item>
                    <Typography
                      className="f-14"
                      color={theme.palette.common.black}
                    >
                      IDV:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className="f-16 fw-600"
                      color={theme.palette.common.black}
                    >
                      <Grid container spacing={0.5} alignItems={"center"}>
                        <Grid item>
                          <SvgConverter Icon={OrangeRuppee} />
                        </Grid>
                        <Grid item>
                          <Typography
                            className="f-14 fw-600"
                            color={theme.palette.common.black}
                          >
                            {convertAmount(data?.idv)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item>
                <Typography className="f-14" color={theme.palette.common.black}>
                  Life Assured
                </Typography>
                <Typography
                  className="f-16 fw-600"
                  color={theme.palette.common.black}
                >
                  {data.customerName}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item className="mt-10">
            <img src={data.companyLogo} alt="logo" width={"51"} height={"32"} />
          </Grid>
        </Grid>
        <Grid container justifyContent={"space-between"} className="mt-n15">
          <Grid item sm={6}>
            <Grid item>
              <Typography className="f-14" color={theme.palette.common.black}>
                Sum Assured
              </Typography>
              <Grid container spacing={0.5} alignItems={"center"}>
                <Grid item>
                  <SvgConverter Icon={OrangeRuppee} />
                </Grid>
                <Grid item>
                  <Typography
                    className="f-14 fw-600"
                    color={theme.palette.common.black}
                  >
                    {convertAmount(data?.sumAssured)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6}>
            <Grid item className="">
              <Typography className="f-14" color={theme.palette.common.black}>
                Premium
              </Typography>
              <Grid container spacing={0.5} alignItems={"center"}>
                <Grid item>
                  <SvgConverter Icon={OrangeRuppee} className="mb-2" />
                </Grid>
                <Grid item>
                  <Typography
                    className="f-14 fw-600"
                    color={theme.palette.common.black}
                  >
                    {convertAmount(data?.premiumAmount)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="mt-5">
        <Separator color={theme.palette.grey[300]} />
      </Grid>
      <Grid item className="mb-10">
        <CustomButton
          text={"ASSIGN BANK"}
          fontSize={14}
          color={"primary"}
          variant={"text"}
          showIcon={false}
          onClick={onAssignClick}
        />
      </Grid>
    </Grid>
  );
};

export default UnAssignedPoliciesForBank;
