import React from 'react';

import Typography from '@mui/material/Typography';

import DashboardCardLayout from './common/DashboardCardLayout';
import PolicyCalendarCard from './common/PolicyCalendarCard';
import { Grid, useTheme } from '@mui/material';
import Spinner from '../../../common/ui/Spinner';

interface UserProps {
  calendarList?: any;
  calendarLoading?: any;
}
export const PoliciesByCalendar: React.FC<UserProps> = ({
  calendarList,
  calendarLoading,
}) => {
  const theme = useTheme();
  const calendarData = [
    {
      date: '23',
      month: 'Feb',
      amount: '100,000',
      policyType: 'life',
      policyName: 'Policy Name Policy Name...',
      isDue: true,
      dueText: 'Due on DDMMYY',
      isPaid: true,
      paidText: 'Paid on DDMMYY',
      annualPremium: 'Annual Premium',
    },
    {
      date: '23',
      month: 'Feb',
      amount: '100,000',
      policyType: 'life',
      policyName: 'Policy Name Policy Name...',
      isDue: true,
      dueText: 'Due on DDMMYY',
      isPaid: true,
      paidText: 'Paid on DDMMYY',
      annualPremium: 'Annual Premium',
    },
    {
      date: '23',
      month: 'Feb',
      amount: '100,000',
      policyType: 'life',
      policyName: 'Policy Name Policy Name...',
      isDue: false,
      dueText: 'Due on DDMMYY',
      isPaid: false,
      paidText: 'FINANCE MY PREMIUM',
      annualPremium: 'Annual Premium',
    },
    {
      date: '23',
      month: 'Feb',
      amount: '100,000',
      policyType: 'life',
      policyName: 'Policy Name Policy Name...',
      isDue: true,
      dueText: 'Due on DDMMYY',
      isPaid: false,
      paidText: 'FINANCE MY PREMIUM',
      annualPremium: 'Annual Premium',
    },
    {
      date: '23',
      month: 'Feb',
      amount: '100,000',
      policyType: 'life',
      policyName: 'Policy Name Policy Name...',
      isDue: false,
      dueText: 'Due on DDMMYY',
      annualPremium: 'Annual Premium',
    },
    {
      date: '23',
      month: 'Feb',
      amount: '100,000',
      policyType: 'life',
      policyName: 'Policy Name Policy Name...',
      isDue: true,
      dueText: 'Due on DDMMYY',
      annualPremium: 'Annual Premium',
    },
    {
      date: '23',
      month: 'Feb',
      amount: '100,000',
      policyType: 'life',
      policyName: 'Policy Name Policy Name...',
      isDue: false,
      dueText: 'Due on DDMMYY',
      annualPremium: 'Annual Premium',
    },
  ];
  const PolicyRenewalCalenderDetails = [
    {
      premiumDate: '12-APR-2023',
      insurerLogo: '//192.168.3.211/Image/hdfc.logo',
      policyName: 'Motor',
      premiumAmount: '18000',
      renewalDue: '16-APR-2023',
      anualPayout: '',
      maturityPayout: '',
    },
    {
      premiumDate: '22-APR-2022',
      insurerLogo: '//192.168.3.211/Image/hdfc.logo',
      policyName: 'Motor',
      premiumAmount: '18000',
      renewalDue: '16-APR-2023',
      anualPayout: '',
      maturityPayout: '',
    },
    {
      premiumDate: '21-JUN-2023',
      insurerLogo: '//192.168.3.211/Image/hdfc.logo',
      policyName: 'Motor',
      premiumAmount: '18000',
      renewalDue: '21-JUN-2023',
      anualPayout: '',
      maturityPayout: '',
    },
  ];
  console.log('calendarList', calendarList);
  return (
    <DashboardCardLayout>
      <div className='w-280px'>
        <Typography
          fontSize={16}
          color={'primary'}
          fontWeight={600}
          className={'mb-10'}
        >
          Year 2023 Calendar
        </Typography>
        {calendarLoading ? (
          <Grid item display={'flex'} justifyContent={'center'}>
            <Spinner />
          </Grid>
        ) : (
          <div
            className='policy-calender-container'
            style={{ border: `1px solid ${theme.palette.grey[400]}` }}
          >
            {calendarList &&
              calendarList.map((data: any, key: any) => (
                <PolicyCalendarCard
                  key={key}
                  data={data}
                  isLastItem={calendarData.length === key + 1}
                />
              ))}
          </div>
        )}
      </div>
    </DashboardCardLayout>
  );
};

export default PoliciesByCalendar;
