import React, { useState, useEffect } from 'react';
import CustomButton from '../../../../common/ui/CustomButton';
import { Grid, Typography } from '@mui/material';
import theme from '../../../../common/theme';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import FormCheckBox from '../../../../common/ui/Forms/FormInput/CheckBox/FormCheckBox';
import data from '../../../../common/constants/FormConstants.json';
import CustomSelectBox from '../../../../common/ui/Selectbox/CustomSelect';
import NomineeAddress from './NomineeAddress';
import { ReactComponent as Cancel } from '../../../../common/icons/Cancel-small.svg';
import { useSelector } from 'react-redux';
import '../Nominee.scss';
import {
  selectFamilyRelationShipList,
  selectIsFamilyRelationShipLoading,
} from '../../Profile/Profile.slice';
import Spinner from '../../../../common/ui/Spinner';
const NomineeForm = ({
  index,
  item,
  setValue,
  register,
  errors,
  onRemove,
  watch,
  submit,
  control,
}: {
  index: number;
  item?: any;
  setValue: any;
  register: any;
  errors: any;
  onRemove: () => void;
  watch?: any;
  submit: any;
  control: any;
}) => {
  console.log('items data', item);
  const [openList, setOpenList] = React.useState(false);
  const relationShipList = useSelector(selectFamilyRelationShipList);
  console.log('relationShipList data', relationShipList);
  const isRelationShipLoadingList = useSelector(
    selectIsFamilyRelationShipLoading
  );
  const relationName = watch(`nomineeData[${index}].relationshipCode`);
  useEffect(() => {
    const matchingRelation =
      relationShipList &&
      relationShipList.find((item: any) => item.relationCode === relationName);
    if (matchingRelation) {
      setValue(
        `nomineeData[${index}].relationshipName`,
        matchingRelation?.relationship
      );
    }
  }, [relationName, relationShipList, index, setValue]);

  const [selectedOption, setSelectedOption] = useState(null);

  console.log('selectedOption', selectedOption);
  const handleOption = (value: any) => {
    console.log('handle option', value);
    setSelectedOption(value);
    setValue(`nomineeData[${index}].relationshipCode`, value, {
      shouldValidate: true,
    });
  };
  const handleSelectChange = (value: string) => {
    // setSelectedOption(value);
  };
  const [isChecked, setIsChecked] = useState(
    item?.nomineeSentToInsurer === 'Y'
  );

  const handleChange = () => {
    // setIsChecked(event.target.checked);
    const newValue = !isChecked;
    setIsChecked(newValue);
    setValue('nomineeSentToInsurer', !isChecked ? 'Y' : 'N', {
      shouldValidate: true,
    });
    if (!isChecked) {
      console.log('checked true condition');
      setValue(`nomineeData.${index}.address1`, item.address1, {
        shouldValidate: true,
      });
      setValue(`nomineeData.${index}.address2`, item.address2, {
        shouldValidate: true,
      });
      setValue(`nomineeData.${index}.address3`, item.address3, {
        shouldValidate: true,
      });
      setValue(`nomineeData.${index}.city`, item.city, {
        shouldValidate: true,
      });
      setValue(`nomineeData.${index}.pincode`, item.pincode, {
        shouldValidate: true,
      });
      setValue(`nomineeData.${index}.state`, item.state, {
        shouldValidate: true,
      });
    } else {
      console.log('checked false condition');
      setValue(`nomineeData.${index}.address1`, item.address1);
      setValue(`nomineeData.${index}.address2`, item.address2);
      setValue(`nomineeData.${index}.address3`, item.address3);
      setValue(`nomineeData.${index}.city`, item.city);
      setValue(`nomineeData.${index}.pincode`, item.pincode);
      setValue(`nomineeData.${index}.state`, item.state);
    }
  };
  useEffect(() => {
    if (!item) return;
    setValue('nomineeFirstName', item.nomineeFirstName, {
      shouldValidate: true,
    });
    setValue('nomineePercentage', item.nomineePercentage, {
      shouldValidate: true,
    });
    setValue('date', item.date, { shouldValidate: true });
    setValue('month', item.month, { shouldValidate: true });
    setValue('year', item.year, { shouldValidate: true });
    setValue('relationshipName', item.relationshipName, {
      shouldValidate: true,
    });
    setValue('relationshipCode', item.relationshipCode, {
      shouldValidate: true,
    });
    setValue('nomineeSeqID', item.nomineeSeqID || '0', {
      shouldValidate: true,
    });
    setValue('address1', item.address1, { shouldValidate: true });
    setValue('address2', item.address2, { shouldValidate: true });
    setValue('address3', item.address3, { shouldValidate: true });
    setValue('city', item.city, { shouldValidate: true });
    setValue('state', item.state, { shouldValidate: true });
    setValue('pincode', item.pincode, { shouldValidate: true });
  }, [item]);

  const percentage = watch(`nomineeData[${index}].nomineePercentage`);

  const options1 = [
    {
      Code: '01',
      Description: 'Self',
      Category: 'Self',
    },
    {
      Code: '02',
      Description: 'Father',
      Category: 'Father',
    },
    {
      Code: '03',
      Description: 'Mother',
      Category: 'Mother',
    },
    {
      Code: '04',
      Description: 'Daughter',
      Category: 'Daughter',
    },
    {
      Code: '05',
      Description: 'Son',
      Category: 'Son',
    },
    {
      Code: '06',
      Description: 'Husband',
      Category: 'Husband',
    },
    {
      Code: '07',
      Description: 'Wife',
      Category: 'Wife',
    },
    {
      Code: '08',
      Description: 'Sister',
      Category: 'Sister',
    },
    {
      Code: '09',
      Description: 'Brother',
      Category: 'Brother',
    },
    {
      Code: '99',
      Description: 'Other',
      Category: 'Other',
    },
  ];

  const handleRemove = () => {
    onRemove();
  };
  const type = 'NOMINEE';
  return (
    <form onSubmit={submit}>
      <Grid item className='nominee-form' key={item.id}>
        <Grid item>
          <Grid container justifyContent='space-between' wrap='nowrap'>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item className=''>
                  <Typography color='black' fontSize={28}>
                    {index + 1}.
                  </Typography>
                </Grid>
                <Grid item className='mt-15'>
                  <Typography color={theme.palette.common.black} fontSize={14}>
                    Nominee
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className=' mt-15'>
              <Typography
                color={theme.palette.common.black}
                fontSize={14}
                className='ml-n32'
              >
                {percentage}%
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={'space-between'}>
            <Grid item sm={9}>
              <FormInput
                type='text'
                placeholder='Nominee Name'
                className='nominee-edit'
                invertBorder={true}
                name='nomineeFirstName'
                color={theme.palette.common.black}
                value={item?.nomineeFirstName}
                inputProps={{
                  className: 'px-10 ',
                  ...register(`nomineeData.${index}.nomineeFirstName`, {
                    required: {
                      value: true,
                      message: data.NOMINEENAME,
                    },
                  }),
                }}
                errors={errors}
              />
              {errors.nomineeData?.[index]?.nomineeFirstName ? (
                <Typography color={theme.palette.error.main} fontSize={12}>
                  {errors.nomineeData?.[index]?.nomineeFirstName?.message}
                </Typography>
              ) : null}
            </Grid>
            <Grid item sm={2}>
              <FormInput
                type='text'
                placeholder='XX'
                className='px-0'
                color={theme.palette.common.black}
                name='nomineePercentage'
                value={item?.nomineePercentage}
                inputProps={{
                  className: 'px-10',
                  ...register(`nomineeData.${index}.nomineePercentage`, {
                    required: {
                      value: true,
                      message: data.PERCENTAGE,
                    },
                  }),
                }}
                errors={errors}
              />
              {errors.nomineeData?.[index]?.nomineePercentage ? (
                <Typography color={theme.palette.error.main} fontSize={12}>
                  {errors.nomineeData?.[index]?.nomineePercentage?.message}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
          <Grid item className='mt-10'>
            <Typography fontSize={14} color={theme.palette.common.black}>
              Date of Birth
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              flexDirection={'row'}
              spacing={1}
              alignItems={'center'}
              flexWrap={'nowrap'}
            >
              <Grid item sm={2}>
                <FormInput
                  type='text'
                  placeholder='DD'
                  className='nominee-edit'
                  invertBorder={true}
                  color={theme.palette.common.black}
                  value={item?.date}
                  name='date'
                  inputProps={{
                    className: 'p-10',
                    ...register(`nomineeData.${index}.date`, {
                      required: {
                        value: true,
                        message: data.DATE,
                      },
                    }),
                  }}
                  errors={errors}
                />
                {errors.nomineeData?.[index]?.date ? (
                  <Typography color={theme.palette.error.main} fontSize={12}>
                    {errors.nomineeData?.[index]?.date?.message}
                  </Typography>
                ) : null}
              </Grid>

              <Grid item sm={2}>
                <FormInput
                  type='text'
                  placeholder='MM'
                  name='month'
                  invertBorder={true}
                  className='nominee-edit'
                  color={theme.palette.common.black}
                  value={item?.month}
                  inputProps={{
                    className: 'px-10',
                    ...register(`nomineeData.${index}.month`, {
                      required: {
                        value: true,
                        message: data.MONTH,
                      },
                    }),
                  }}
                  errors={errors}
                />
                {errors.nomineeData?.[index]?.month ? (
                  <Typography color={theme.palette.error.main} fontSize={12}>
                    {errors.nomineeData?.[index]?.month?.message}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item sm={3}>
                <FormInput
                  type='text'
                  placeholder='YY'
                  invertBorder={true}
                  name='year'
                  value={item?.year}
                  className='nominee-edit'
                  color={theme.palette.common.black}
                  inputProps={{
                    className: 'text-center',
                    ...register(`nomineeData.${index}.year`, {
                      required: {
                        value: true,
                        message: data.YEAR,
                      },
                    }),
                  }}
                  errors={errors}
                />
                {errors.nomineeData?.[index]?.year ? (
                  <Typography color={theme.palette.error.main} fontSize={12}>
                    {errors.nomineeData?.[index]?.year?.message}
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item className='mt-10'>
            <Typography className='f-14' color={theme.palette.common.black}>
              Relationship
            </Typography>
          </Grid>
          <Grid item sm={7}>
            {isRelationShipLoadingList ? (
              <Grid item>
                <Spinner />
              </Grid>
            ) : (
              <CustomSelectBox
                ClassName='w-100 relatioship-selectbox' // Correct the spelling of 'relationship-selectbox'
                options={
                  relationShipList?.map((row: any) => ({
                    Code: row.relationCode,
                    Description: row.relationship,
                    Category: row.relationship,
                  })) || []
                }
                inputProps={register(`nomineeData.${index}.relationshipCode`, {
                  required: {
                    value: true,
                    message: data.RELATIONSHIP,
                  },
                })}
                defaultValue={selectedOption || item.relationshipCode}
                onChange={(value) => {
                  console.log('onchange value', value);
                  handleOption(value);
                }}
                setSelectedOption={handleOption}
              />
            )}
            {errors.nomineeData?.[index]?.relationshipCode && (
              <Typography color={theme.palette.error.main} fontSize={12}>
                {errors.nomineeData?.[index]?.relationshipCode?.message}
              </Typography>
            )}
          </Grid>

          <Grid
            container
            spacing={1}
            justifyContent={'space-between'}
            flexWrap={'nowrap'}
            className='mt-2'
          >
            <Grid item>
              <Typography className='f-14' color={theme.palette.common.black}>
                Address
              </Typography>
            </Grid>
            <Grid item className='mt-n2 '>
              <FormCheckBox
                label={
                  <Typography
                    className='f-12'
                    color={theme.palette.common.black}
                  >
                    Address same as life Assured
                  </Typography>
                }
                checked={isChecked}
                onChange={handleChange}
                size={16}
                checkBoxPadding={1}
              />
            </Grid>
          </Grid>
          {!isChecked ? (
            <>
              <Grid item>
                <NomineeAddress
                  index={index}
                  item={item}
                  setValue={setValue}
                  register={register}
                  errors={errors.nomineeData?.[index]}
                  type={type}
                  submit={submit}
                />
              </Grid>
            </>
          ) : (
            <Grid item>
              <Typography fontSize={18} fontWeight={600}>
                Same as Life Assured
              </Typography>
            </Grid>
          )}

          <Grid item className='mt-20'>
            <CustomButton
              className=' f-14'
              text={'REMOVE THIS NOMINEE'}
              variant={'text'}
              color={'primary'}
              showIcon={false}
              startIcon={Cancel}
              onClick={handleRemove}
              fontWeight={400}
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default NomineeForm;
