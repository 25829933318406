import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import Separator from "../../../common/ui/Separator";

import MobilePolicyHeader from "./common/MobilePolicyHeader";

const UnassignedMobileNumber = ({
  mobileNoList = [],
  handleAssignPolicy,
  onRemove,
}: any) => {
  const theme = useTheme();

  return (
    <>
      <Grid item xs={12}>
        <Typography className="f-16  mt-10" color={theme.palette.error.main}>
          Unassigned Mobile Number
        </Typography>
      </Grid>
      <Grid item className="my-10">
        <Separator color={theme.palette.primary.main} borderWidth={2} />
      </Grid>
      <Grid container justifyContent={"space-between"} spacing={5}>
        {mobileNoList.map((data: any, index: number) => (
          <MobilePolicyHeader
            index={index}
            mobileNo={data?.mobileNo}
            key={data?.mobileNo}
            verified={data["mobileNoVerified"]}
            primary={data["primaryFlag"]}
            showAssignPolicyButton={true}
            handleAssignPolicy={() =>
              handleAssignPolicy({
                mobileNo: data?.mobileNo,
                mobileNoVerified: data["mobileNoVerified"],
              })
            }
            onRemove={() => onRemove(data?.mobileNo)}
          />
        ))}
      </Grid>
    </>
  );
};

export default UnassignedMobileNumber;
