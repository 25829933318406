import React from 'react'
import SvgConverter from '../../../common/SvgConverter';
import { Grid, Typography, useTheme } from '@mui/material';
import Separator from '../../../common/ui/Separator';
import { Link } from 'react-router-dom';

type LinkWithArrowProps = {
  to: any;
  text?: string;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
};
const LinkWithArrow = ({ to, text, Icon }: LinkWithArrowProps) => {
    const theme =useTheme();
  return (
    <>
      <Grid item className='mb-10'>
        <Separator color={theme.palette.grey[300]} />
      </Grid>

      <Grid item>
        <Link to={to} >
          <Grid container spacing={1} justifyContent={'flex-end'}>
            <Grid item>
              <Typography
                className='f-16 fw-600'
                color={theme.palette.primary.main}
              >
                {text}
              </Typography>
            </Grid>
            <Grid item >
              <SvgConverter
                Icon={Icon}
                width={22}
                height={22}
                className={'right-arrow d-flex'}
              />
            </Grid>
          </Grid>
        </Link>
      </Grid>

      <Grid item className='my-10'>
        <Separator color={theme.palette.grey[300]} />
      </Grid>
    </>
  );
};

export default LinkWithArrow