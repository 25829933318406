import { Grid, Typography } from '@mui/material';
import React from 'react';
import '../../Policy.scss';
import theme from '../../../../../common/theme';
import ActiveHealthCard from '../ActiveContent/ActiveHealthCard';

interface UserProps {
  headerType?: string;
  contentType?: string;
  premiumDue?: string;
  filteredHealth?: any;
}
export const ActiveHealth: React.FC<UserProps> = ({
  filteredHealth,
  headerType,
  premiumDue,
  contentType,
}) => {
  console.log('filteredHealth', filteredHealth);
  return (
    <Grid container justifyContent={'center'}>
      {filteredHealth.map((data: any, index: any) => (
        <Grid item container xs={4} justifyContent={'space-between'}>
          <Grid item key={index}>
            <Typography
              className='f-12 fw-600 py-4'
              color={theme.palette.primary.main}
            >
              {data.heading}
            </Typography>
            <ActiveHealthCard
              contentType={contentType}
              items={data}
              verificationStatus={'N'}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default ActiveHealth;
