import { Grid } from '@mui/material';
import React, { useState } from 'react';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as ProfilePic } from '../../../common/icons/profile/profile-icon.svg';
import { ReactComponent as ProfileCam } from '../../../common/icons/profile/profile-camera.svg';
import FileUploader from '../../../common/ui/Forms/FormInput/FileUploader/FileUploader';
import './profile.css'
const ProfileUploader = () => {
  const [image, setImage] = useState('');

  function handleFilesChange(newFiles: any) {
    var file = newFiles[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    var base64 = '';
    reader.onloadend = function () {
      if (reader.result) {
        base64 = (reader.result as string).replace(
          /^data:image\/\w+;base64,/,
          ''
        );
        setImage(base64);
      }
    };
  }
  return (
    <Grid item>
      <FileUploader accepts={['image/png']} onFilesChange={handleFilesChange}>
        <SvgConverter
          Icon={ProfilePic}
          width={'40px'}
          height={'40px'}
          className='profile-pic'
        />
        <SvgConverter Icon={ProfileCam} className='profile-cam' />
      </FileUploader>
    </Grid>
  );
};

export default ProfileUploader;
