import React, { useEffect, useState } from "react";
import { CommonModal } from "../../../common/ui/Modal/CommonModal";
import Typography from "@mui/material/Typography";
import theme from "../../../common/theme";
import { Grid } from "@mui/material";
import CustomButton from "../../../common/ui/CustomButton";
import { ReactComponent as Verify } from "../../../common/icons/update-mobile/verify_orange.svg";
import { ReactComponent as Cancel } from "../../../common/icons/update-mobile/cancel_orange.svg";
import OtpInput from "react-otp-input";
import config from "../../../common/config";

const OtpEntryModal = ({
  open,
  setOpen,
  onSubmit,
  value,
  isEmail,
  loading,
  resendLoading,
  onFinishDuration,
  duration,
  handleResendOtp,
}: any) => {
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (!open) {
      setOtp("");
    }
  }, [open, setOtp]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
        if (duration !== 0) {
          onFinishDuration();
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [onFinishDuration, seconds]);

  useEffect(() => {
    setSeconds(duration);
  }, [duration]);

  useEffect(() => {
    if (!otp) {
      return;
    }
    if (String(otp).length === config.otpCount) {
      setOtp(otp);
    }
  }, [otp]);

  useEffect(() => {
    if (!open) {
      setOtp("");
    }
  }, [open]);

  return (
    <CommonModal
      onClose={() => setOpen(false)}
      open={open}
      modalClassName="px-20 py-20"
      boxProps={{ width: 400 }}
    >
      <Grid container spacing={0.5} flexDirection={"column"}>
        <Grid item>
          <Typography textAlign={"center"} className={"f-14 px-20"}>
            Please enter 6 digit OTP sent to the {isEmail ? "Email" : "number"}
          </Typography>
          <Typography
            textAlign={"center"}
            color={theme.palette.secondary.main}
            lineHeight={"unset"}
          >
            {value}
          </Typography>
        </Grid>

        <Grid item className="my-30">
          <OtpInput
            numInputs={6}
            placeholder={"XXXXXX"}
            value={otp}
            onChange={setOtp}
            containerStyle={"otp-input-container"}
            isInputSecure={true}
            isInputNum={true}
          />
        </Grid>
        {seconds > 0 ? (
          <Grid
            item
            container
            justifyContent={"center"}
            spacing={0.6}
            flexWrap={"nowrap"}
          >
            <Grid item>
              <Typography
                className="f-14 fw-400"
                color={theme.palette.primary.main}
              >
                RESEND OTP
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className="f-13 fw-400"
                color={theme.palette.common.black}
              >
                in
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className="f-13 fw-400"
                color={theme.palette.secondary.main}
              >
                {seconds} sec
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            justifyContent={"center"}
            spacing={0.6}
            flexWrap={"nowrap"}
          >
            <Grid item>
              <CustomButton
                text={"RESEND OTP"}
                variant={"text"}
                color={"primary"}
                showIcon={false}
                // startIcon={Cancel}
                onClick={handleResendOtp}
                loading={resendLoading}
              />
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid container textAlign={"center"} className="mt-10">
        <Grid item xs={6}>
          <CustomButton
            text={"verify"}
            variant={"text"}
            color={"primary"}
            showIcon={false}
            startIcon={Verify}
            onClick={() => onSubmit(otp)}
            disabled={otp?.length !== 6}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomButton
            text={"CANCEL"}
            variant={"text"}
            color={"primary"}
            showIcon={false}
            startIcon={Cancel}
            onClick={() => setOpen(false)}
          />
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default OtpEntryModal;
