import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
// import { ReactComponent as rightArrow } from '../../../common/icons/right_arrow-icon.svg';
import CustomButton from '../../../../common/ui/CustomButton';
import Separator from '../../../../common/ui/Separator';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import { Link } from 'react-router-dom';
// import { ReactComponent as rightArrow } from '../../../common/icons/right_arrow-icon.svg';

const NomineeHeader = () => {
  const theme = useTheme();
  return (
    <Grid item xs={12}>
      <Grid container justifyContent={'space-between'} className='mb-10'>
        <Grid item>
          <Typography
            fontSize={16}
            fontWeight={600}
            color={theme.palette.primary.main}
          >
            Nominee
          </Typography>
        </Grid>
        <Grid item>
          <Link to={APP_ROUTES.DASHBOARD}>
            <CustomButton
              text={'DONE'}
              variant={'text'}
              color={'primary'}
              showIcon={true}
              fontSize={14}
              // endIcon={rightArrow}
            />
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Separator color={theme.palette.primary.main} />
      </Grid>
    </Grid>
  );
};

export default NomineeHeader;
