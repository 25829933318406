import { Grid, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as TickSuccessIcon } from './../../../common/icons/tick_green_icon.svg';
import '../SignUp/VerificationStatusScreen.css';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { fetchLoginRequest } from '../auth.slice';

const VerificationStatusScreen = () => {
  const [progress, setProgress] = React.useState(0);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          dispatch(
            fetchLoginRequest({ loginId: 'test-id', dateOfBirth: '02-02-2023' })
          );
          return oldProgress;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <>
      <Grid
        container
        flexDirection={'column'}
        flexWrap={'nowrap'}
        className={'py-40'}
        spacing={8}
      >
        <Grid item container>
          <Grid item className='mb-30'>
            <Typography className='f-14 fw-600'>Step 1/2</Typography>
          </Grid>
          <Grid item container>
            <Grid item>
              <Typography className='f-18 fw-600 mb-14' color={'primary'}>
                Verification Complete
              </Typography>
              <LabelWithIcon
                className='mb-8'
                justifyContent={'flex-start'}
                Icon={TickSuccessIcon}
                label={'Proof of Identity'}
              />
              <LabelWithIcon
                justifyContent={'flex-start'}
                Icon={TickSuccessIcon}
                label={'Proof of Address'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography className='f-18 fw-600' color={'primary'}>
            Your eIA number
          </Typography>
          <Typography className='f-18 fw-400'>ASDF1234567890</Typography>
        </Grid>
        <Grid item>
          <Typography className='f-18 fw-600 mb-17' color={'secondary'}>
            Setting up the app
          </Typography>
          <LinearProgress
            variant='determinate'
            color='success'
            value={progress}
            className={'verification-progress-bar'}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default VerificationStatusScreen;
