import { call, put, select, takeEvery } from 'typed-redux-saga';

import {
  fetchLoginRequest,
  fetchOtpRequest,
  fetchOtpVerify,
  initiateEkycError,
  initiateEkycRequest as initiateEkycRequestAction,
  initiateEkycSuccess,
  reportLoginRequestError,
  reportLoginRequestSuccess,
  reportOtpLoginError,
  reportOtpLoginSuccess,
  reportOtpVerifyError,
  reportOtpVerifySuccess,
  saveEkycError,
  saveEkycSuccess,
  selectLoginRequestInfo,
  setMpinError,
  setMpinRequest,
  setMpinSuccess,
  setUser,
  signUpError,
  signUpRequest,
  signUpSuccess,
  verifyMpinError,
  verifyMpinRequest,
  verifyMpinSuccess,
  saveEkycRequest as saveEkycRequestAction,
} from './auth.slice';
import {
  fetchOtpVerifyRequest,
  fetchResendOtpRequest,
  fetchSetMpinRequest,
  fetchSignUpRequest,
  fetchVerifyMpinRequest,
  initiateEkycRequest,
  saveEkycRequest,
  submitLoginRequestInfo,
} from './auth.service';
// import { AuthScreens } from '../../../common/routes/auth/auth.screens';
import {
  AuthUser,
  LOCAL_VARIABLES,
  generatePermanentAddressFromEkyc,
} from '../../common/utils';
import { QUICK_LOGIN_TYPES, SIGNUP_EIA_STATUS } from '../../common/Enums';
import { AUTH_ROUTES } from '../../common/constants/Routes';

function* handleLoginRequest({
  payload,
}: ReturnType<typeof fetchLoginRequest>) {
  const { navigate, ...requestPayload } = payload;
  try {
    const { data }: any = yield* call(submitLoginRequestInfo, requestPayload);
    // sessionStorage.setItem(
    //   LOCAL_VARIABLES.SESSION_ID,
    //   data?.Customer?.sessionId
    // );
    yield* put(reportLoginRequestSuccess(data));
    navigate(AUTH_ROUTES.LOGIN_OTP_VERIFY);
  } catch (e: any) {
    yield* put(reportLoginRequestError(e.message));
  }
}

function* handleOtpRequest({ payload }: ReturnType<typeof fetchOtpRequest>) {
  const { handleSuccess, ...requestPayload } = payload;
  console.log('payload', payload);
  try {
    const { data }: any = yield* call(fetchResendOtpRequest, requestPayload);
    yield* put(reportOtpLoginSuccess(data));
    if (handleSuccess) {
      handleSuccess();
    }
  } catch (e: any) {
    yield* put(reportOtpLoginError(e.message));
  }
}

function* handleOtpVerify({
  payload: { handleSuccess, isLoginFlow, ...payload },
}: ReturnType<typeof fetchOtpRequest>) {
  const { navigation, ...requestPayload } = payload;
  try {
    const { data }: any = yield* call(fetchOtpVerifyRequest, requestPayload);
    yield* put(reportOtpVerifySuccess(data));

    if (handleSuccess) {
      handleSuccess(data);
    }
  } catch (e: any) {
    yield* put(reportOtpVerifyError(e.message));
  }
}

function* setMpin({ payload }: ReturnType<typeof fetchOtpRequest>) {
  const { navigation, ...requestPayload } = payload;
  try {
    const { data }: any = yield* call(fetchSetMpinRequest, requestPayload);
    const authUserInfo = yield* select(selectLoginRequestInfo);
    // yield AsyncStorage.setItem(LOCAL_VARIABLES.MPIN_FLAG, '1');
    // yield AsyncStorage.setItem('QUICK_LOGIN_TYPES', QUICK_LOGIN_TYPES.MPIN);
    // yield AsyncStorage.setItem(
    //   LOCAL_VARIABLES.AUTH_USER_INFO,
    //   JSON.stringify(authUserInfo)
    // );
    yield* put(setMpinSuccess(data));
    yield* put(setUser(authUserInfo.Customer));
  } catch (e: any) {
    yield* put(setMpinError(e.message));
  }
}

function* verifyMpin({ payload }: ReturnType<typeof fetchOtpRequest>) {
  try {
    const { data }: any = yield* call(fetchVerifyMpinRequest, payload);
    yield* put(verifyMpinSuccess(data));
    const authUserInfo = yield* select(selectLoginRequestInfo);
    yield* put(setUser(authUserInfo.Customer));
  } catch (e: any) {
    yield* put(verifyMpinError(e.message));
  }
}

function* signUpRequestWatcher({
  payload,
}: ReturnType<typeof fetchOtpRequest>) {
  const { navigation, ...requestPayload } = payload;
  try {
    const { data }: any = yield* call(fetchSignUpRequest, requestPayload);
    yield* put(signUpSuccess(data));
    if (data.eiaStatus === SIGNUP_EIA_STATUS.EIA_NA) {
      // navigation.navigate(AuthScreens.EkycOtpScreen.routeName, {
      //   mobileNo: payload?.Customer?.mobileNo,
      // });
    } else if (data.eiaStatus === SIGNUP_EIA_STATUS.EIA_CAMS) {
      // navigation.navigate(AuthScreens.EkycInCamsOtpScreen.routeName, {
      //   mobileNo: payload?.Customer?.mobileNo,
      // });
    }
  } catch (e: any) {
    yield* put(signUpError(e.message));
  }
}

function* initiateEkycWatcher({ payload }: ReturnType<typeof fetchOtpRequest>) {
  const { navigation, ...requestPayload } = payload;
  try {
    const { data }: any = yield* call(initiateEkycRequest, requestPayload);
    yield* put(initiateEkycSuccess(data));
    // navigation.navigate(AuthScreens.ekycWeView.routeName, {
    //   // id: '3b25a08c-c355-4d36-82a9-3cbba26d0870',
    //   url: data.url,
    // });
  } catch (e: any) {
    yield* put(initiateEkycError(e.message));
  }
}

function* saveEkycWatcher({ payload }: ReturnType<typeof fetchOtpRequest>) {
  try {
    const { data }: any = yield* call(saveEkycRequest, payload);
    yield* put(
      saveEkycSuccess({
        formattedAddress: generatePermanentAddressFromEkyc(data),
        responseData: data,
      })
    );
  } catch (e: any) {
    yield* put(saveEkycError(e.message));
  }
}

function* watchAuth() {
  yield* takeEvery(fetchLoginRequest.type, handleLoginRequest);
  yield* takeEvery(fetchOtpRequest.type, handleOtpRequest);
  yield* takeEvery(fetchOtpVerify.type, handleOtpVerify);
  yield* takeEvery(setMpinRequest.type, setMpin);
  yield* takeEvery(verifyMpinRequest.type, verifyMpin);
  yield* takeEvery(signUpRequest.type, signUpRequestWatcher);
  yield* takeEvery(initiateEkycRequestAction.type, initiateEkycWatcher);
  yield* takeEvery(saveEkycRequestAction.type, saveEkycWatcher);
}

export { watchAuth };
