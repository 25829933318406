import * as React from 'react';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import Typography from '@mui/material/Typography';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import theme from '../../../../../common/theme';
import TimelineItem from '@mui/lab/TimelineItem';
import { ReactComponent as GreenTick } from '../../../../../common/icons/green_tick.svg';
import { ReactComponent as OrangeDot } from '../../../../../common/icons/orange_dot.svg';
import SvgConverter from '../../../../../common/SvgConverter';

interface UserProps {
  status?: string;
  title?: string;
  eta?: string;
  index?: any;
}
export const TrackerTransactionTimeline: React.FC<UserProps> = ({
  status,
  title,
  eta,
  index,
}) => {
  return (
    <TimelineItem>
      <TimelineSeparator>
        {status === 'completed' ? (
          <SvgConverter Icon={GreenTick} className='greentick' />
        ) : status === 'pending' ? (
          <SvgConverter Icon={OrangeDot} className='orangedot' />
        ) : (
          ''
        )}

        <RadioButtonUncheckedIcon
          color='primary'
          className={'timelineIcon'}
          fontSize='small'
        />

        {index + 1 == 5 ? <></> : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent className='content ml-10'>
        <Typography
          className='f-14 fw-600 mb-0'
          color={
            status === 'yet-to-start'
              ? theme.palette.common.black
              : theme.palette.primary.main
          }
        >
          {index + 1} .{title}
        </Typography>
        {status === 'completed' ? (
          <Typography className='f-12 ml-10' color={theme.palette.common.black}>
            Completed on {eta}
          </Typography>
        ) : status === 'pending' ? (
          <>
            <Typography className='f-10 ml-10' color={'#7f7f7f'}>
              ETA: as per actual
            </Typography>
            <Typography
              className='f-12 ml-10'
              color={theme.palette.common.black}
            >
              InProgress-Link shared
            </Typography>
            <Typography
              className='f-12 ml-10'
              color={theme.palette.primary.main}
            >
              SHARE LINK AGAIN
            </Typography>
            <Typography
              className='f-12 ml-10 '
              color={theme.palette.primary.main}
            >
              CALL
            </Typography>
          </>
        ) : (
          <Typography className='f-10 ml-10' color={'#7f7f7f'}>
            ETA: {eta} days
          </Typography>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};
