import React from 'react';
import './legal.scss';
import { Grid, Typography } from '@mui/material';

const PrivacyPolicyScreen = () => {
  return (
    <Grid>
      <Grid
        container
        spacing={2}
        justifyContent={'center'}
        className='mb-30 mt-15'
      >
        <Grid item xs={12} sm={5} className='content-merge'>
          <Grid item>
            <Typography
              fontSize={20}
              color={'black'}
              fontWeight={900}
              fontFamily={'inherit'}
              className='mb-5'
            >
              Privacy Policy
            </Typography>
          </Grid>
          <Grid item>
            <Typography fontSize={15} className='text-color'>
              CAMSRep reserves its right to correct any part of the said content
              at any time as and when required at its sole discretion. The
              content of this website shall not be displayed or printed in any
              form in part or whole without the prior written approval of
              CAMSRep.
            </Typography>
            <Typography fontSize={15} className='mt-10 text-color'>
              The information contents provided on this site can not be copied,
              modified, uploaded, downloaded, published or republished,
              transmitted or otherwise distributed for commercial purposes
              without prior and explicit permission from CAMSRep. Reproduction
              of any information or material provided on this website, with or
              without any modification, is prohibited unless, with prior
              permission of CAMSRep, and shall amount to violation of copyright
              and would be deemed an illegal act. You are requested to note that
              Insurance is a subject matter of solicitation and the information,
              tools and planners contained in the site, www.camsrepository.com
              are meant for your general information only and not to be used for
              deciding on your insurance requirements. You are specifically
              advised to contact one of our Agents for any of your particular
              insurance needs.
            </Typography>
            <Typography fontSize={15} className='mt-10 text-color'>
              The Privacy Policy governs the use of this website
              (www.camsrepository.com) of CAMS Insurance Repository Services Ltd
              (CAMSRep).
            </Typography>
            <Typography fontSize={15} className='mt-10 text-color'>
              CAMSRep reserve its right to select the user of this website and
              take decisions as to whether to permit or not to permit any person
              to use services provided on this website.
            </Typography>
            <Typography fontSize={15} className='mt-10 text-color'>
              CAMSRep is committed to protecting your privacy and working
              towards offering you a powerful, safe, online experience. To avail
              some of the services offered by us, you need to sign up by giving
              simple details that personally identify you. We need this minimal
              information to operate the online services and encourage you to
              update this information as and when there are any changes. When
              you do this through easily navigable clicks, you help us stay in
              touch with you always.
            </Typography>
            <Typography fontSize={15} className='mt-10 text-color'>
              www.camsrepository.com does not collect personal information about
              individuals except when such individuals specifically provide such
              information on a voluntary basis. This can be in several ways
              including for example, through registration for contests, the
              registration process for subscription sites or services and in
              connection with content submissions, community postings (e.g.,
              message boards), suggestions, and voting/polling activities.
              Personal information on individual users of www.camsrepository.com
              will not be sold or otherwise transferred to unaffiliated third
              parties unless otherwise stated at the time of collection or with
              the approval of the user as the case may be.
            </Typography>
            <Typography fontSize={15} className='mt-10 text-color'>
              CAMSRep treats your personal information or your use of the
              service as private and confidential and does not check, edit or
              reveal it to any third parties except where it believes in good
              faith, such action is necessary to comply with the applicable
              legal and regulatory processes or to protect and defend the rights
              of other users or to enforce the terms of service which are
              binding on all the users of www.camsrepository.com
            </Typography>
            <Typography fontSize={15} className='mt-10 text-color'>
              Except where specifically agreed or necessary for operational or
              regulatory reasons, CAMSRep will not send you any unsolicited
              information. If the user does not opt out, CAMSRep may use any
              e-mail addresses of users to send occasional e-mails pertaining to
              information on product and services. The user can nevertheless
              unsubscribe from receipt of such e-mails by following instructions
              therein or by communicating accordingly to www.camsrepository.com.
            </Typography>
            <Typography fontSize={15} className='mt-10 text-color'>
              CAMSRep may collect unnamed statistics, which do not personally
              identify you. CAMSRep reserves the right to perform statistical
              analyses of user behavior and characteristics in order to measure
              interest in and use of the various areas of the site and to inform
              advertisers of such information as well as the number of users
              that have been exposed to or clicked on their advertising banners.
              We will provide only aggregated data from these analyses to third
              parties.
            </Typography>
            <Typography fontSize={15} className='mt-10 text-color'>
              CAMSRep may provide specific services to users to post messages,
              e-mails, information, participate in chat sessions, news groups
              and other affinity group activities on the site and off the site.
              During such activities, you may provide your personal information
              beyond what has been given in your personal profile.
              www.camsrepository.com 's Privacy Policy excludes information
              transmitted through these activities. While CAMSRep encourages you
              to actively participate in such activities, it advises you to be
              careful in sharing your personal information and to respect the
              privacy of its other users.
            </Typography>
            <Typography fontSize={15} className='mt-10 text-color'>
              www.camsrepository.com offer links to other sites for which
              CAMSRep is not responsible either for their standards of public
              order and decency or for their personal data protection policy or
              the use that could be made of such data. When you access another
              website through a hypertext link, you are accepting that this
              access is at your own risk. Consequently, CAMSRep cannot accept
              responsibility for any direct or indirect damages that may result
              from your access to another site connected by a hypertext link. We
              would also draw your attention to the use of public forums where
              you may be asked to give your identity or e-mail address. This
              information then enters the public domain and may be used by a
              third party using the service. E-mail messages sent to CAMSRep
              over the Internet cannot be guaranteed to be completely secure.
              The integrity of such messages cannot be guaranteed on the
              Internet and CAMSRep will not be responsible for any damages
              incurred by users due to messages sent or received by them to and
              from CAMSRep. While every care has been taken in preparing the
              information and materials contained in this site, such information
              and materials are provided to you "as is" without warranty of any
              kind either express or implied. CAMSRep does not warranty the
              adequacy of the information made available. In particular, no
              warranty regarding non-infringement, security, accuracy, fitness
              for a particular purpose or freedom from computer virus is given
              in conjunction with such information and materials.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5} className='content-merge'>
          <Grid item>
            <Typography
              fontSize={20}
              color={'black'}
              fontWeight={900}
              fontFamily={'inherit'}
              className='mb-5'
            >
              Our Cookie Policy
            </Typography>
          </Grid>
          <Grid item>
            <Typography fontSize={15} className='text-color'>
              Cookies are small text files that are sent to and stored on your
              computer, smart-phone or other device for accessing the internet,
              whenever you visit a website. Cookies are useful because they
              allow a website to recognize a user's device. The cookies we use
              are completely safe. In fact, many of them are used purely to
              provide important security features such as protecting your data
              and your accounts.
            </Typography>
            <Typography fontSize={15} className='mt-10 text-color'>
              We use ‘analytics’ cookies to help us make our website better for
              those who visit it regularly. They help us work out what users
              like and don't like and how we can improve things for you. When we
              include links to other websites, please bear in mind they will
              have their own privacy and cookie policies that will govern the
              use of any information you submit. We recommend you read their
              policies as CAMS/CAMSREP is not responsible or liable for their
              privacy practices.
            </Typography>
            <Typography fontSize={15} className='mt-10 text-color'>
              We use cookies to give you the best possible experience on our
              website. Cookies allow you to do many things such as log on to do
              online transactions, request for account statements, portfolio
              valuations, IPR reports and a whole lot of customer friendly
              reports and services that CAMS/CAMSREP provides to its registered
              mail back users and general investors. This helps us 'remember'
              your log-in preferences, apply for products and services online
              and use the services available in our website efficiently.
            </Typography>
            <Typography
              className='mt-10'
              fontSize={18}
              color={'black'}
              fontWeight={500}
              fontFamily={'inherit'}
            >
              Personal Information Policy
            </Typography>
            <Typography fontSize={15} className='mt-10 text-color'>
              We collect your personal information through our website. We use
              this information including but not limited to:
            </Typography>
            <Typography fontSize={15} className='mt-10 text-color'>
              Personalize your experience and better our responses to match your
              requirements, wherever possible.
            </Typography>
            <Typography fontSize={15} className='mt-10 text-color'>
              Improve our website based on the information about visitor
              profiles, servicing patterns and what they are looking for etc.
            </Typography>
            <Typography fontSize={15} className='mt-10 text-color'>
              To improve customer service by your feedback which help us improve
              our services and offerings
            </Typography>
            <Typography fontSize={15} className='mt-10 text-color'>
              Use analytics to track the various website traffic patterns,
              sources of traffic, user preferences, referrals etc and use such
              an analytics to improve our services and website as a whole.
            </Typography>
            <Typography fontSize={15} className='mt-10 text-color'>
              To send periodic emails to email address you provide while making
              use of the services available in our website. These email IDs may
              be used to send you information and updates pertaining to your
              order or request, in addition to receiving periodic news / updates
              / promotions / related product or services provided by
              CAMS/CAMSREP and its group companies / subsidiaries and also
              informative and educative material(s) that we deem appropriate.
            </Typography>
            <Typography fontSize={15} className='mt-10 text-color'>
              It is our commitment and promise that your email IDs and other
              personal information you share are very confidential and safe with
              CAMS/CAMSREP.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PrivacyPolicyScreen;
