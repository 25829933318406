import React, { useEffect } from 'react';
import { Grid, useTheme } from '@mui/material';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import theme from '../../../../common/theme';
import data from '../../../../common/constants/FormConstants.json';
const AddFamilyAddress = ({
  setValue,
  register,
  errors,
  address1,
  address2,
  city,
  address3,
  state,
  pin,
  permanentEiaAddress,
  permanentAddress,
  type,
  data,
  index,
}: {
  setValue: any;
  register: any;
  errors?: any;
  address1?: any;
  address2?: any;
  city?: any;
  address3?: any;
  state?: any;
  pin?: any;
  permanentEiaAddress?: any;
  permanentAddress?: boolean;
  type?: any;
  data?: any;
  index?: number;
}) => {
  useEffect(() => {
    if (!permanentEiaAddress || !permanentAddress) return;
    setValue('address1', permanentEiaAddress.address1, {
      shouldValidate: true,
    });
    setValue('address2', permanentEiaAddress.address2, {
      shouldValidate: true,
    });
    setValue('address3', permanentEiaAddress.address3, {
      shouldValidate: true,
    });
    setValue('city', permanentEiaAddress.city, { shouldValidate: true });
    setValue('state', permanentEiaAddress.state, { shouldValidate: true });
    setValue('pin', permanentEiaAddress.pincode, { shouldValidate: true });
  }, [permanentAddress]);
  console.log('errors?.address1 && !address1 &&', !address1);
  return (
    <Grid item>
      {type === 'NOMINEE' ? (
        <Grid item>
          {/* <Grid item className='mb-5'>
            <FormInput
              type='text'
              placeholder='House #,Street #'
              value={data.address1}
              onChange={(value: any) => {
                setValue(`nomineeData.${index}.address1`, value, {
                  shouldValidate: true,
                });
              }}
              color={theme.palette.common.black}
              name='address1'
              inputProps={{
                className: 'px-10',
                ...register(`nomineeData.${index}.address1`, {
                  required: {
                    value: true,
                    message: data.STREET,
                  },
                }),
              }}
              errors={errors}
            />
          </Grid> */}

          <Grid item className='mb-5'>
            <FormInput
              type='text'
              placeholder='Locality,Area'
              name='address2'
              value={data.address2}
              color={theme.palette.common.black}
              inputProps={{
                className: 'px-10',
                ...register(`nomineeData.${index}.address2`, {
                  required: {
                    value: true,
                    message: data.AREA,
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>
          <Grid container spacing={3} className='mb-5'>
            <Grid item xs={6} sm={6}>
              <FormInput
                type='text'
                placeholder='City'
                value={data.city}
                name='city'
                color={theme.palette.common.black}
                inputProps={{
                  className: 'px-10',
                  ...register(`nomineeData.${index}.city`, {
                    required: {
                      value: true,
                      message: data.CITY,
                    },
                  }),
                }}
                errors={errors}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormInput
                type='text'
                placeholder='District'
                value={data.address3}
                name='address3'
                color={theme.palette.common.black}
                inputProps={{
                  className: 'px-10',
                  ...register(`nomineeData.${index}.address3`, {
                    required: {
                      value: true,
                      message: data.DISTRICT,
                    },
                  }),
                }}
                errors={errors}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className='mb-5'>
            <Grid item xs={6} sm={6}>
              <FormInput
                type='text'
                placeholder='State'
                value={data.state}
                name='state'
                color={theme.palette.common.black}
                inputProps={{
                  className: 'px-10',
                  ...register(`nomineeData.${index}.state`, {
                    required: {
                      value: true,
                      message: data.STATE,
                    },
                  }),
                }}
                errors={errors}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormInput
                type='text'
                placeholder='PIN'
                name='pincode'
                value={data.pincode}
                color={theme.palette.common.black}
                inputProps={{
                  className: 'px-10',
                  ...register(`nomineeData.${index}.pincode`, {
                    required: {
                      value: true,
                      message: data.PIN,
                    },
                  }),
                }}
                errors={errors}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item>
          <Grid item className='mb-5'>
            <FormInput
              type='text'
              placeholder='House #,Street #'
              color={theme.palette.common.black}
              name='address1'
              inputProps={{
                className: 'text-center',
                ...register('address1'),
              }}
              errors={errors}
            />
          </Grid>

          <Grid item className='mb-5'>
            <FormInput
              type='text'
              placeholder='Locality,Area'
              name='address2'
              inputProps={{
                className: 'text-center',
                ...register('address2'),
              }}
              errors={errors}
            />
          </Grid>
          <Grid container spacing={3} className='mb-5'>
            <Grid item xs={6} sm={6}>
              <FormInput
                type='text'
                placeholder='City'
                name='city'
                inputProps={{
                  className: 'text-center',
                  ...register('city'),
                }}
                errors={errors}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormInput
                type='text'
                placeholder='District'
                name='address3'
                inputProps={{
                  className: 'text-center',
                  ...register('address3'),
                }}
                errors={errors}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className='mb-5'>
            <Grid item xs={6} sm={6}>
              <FormInput
                type='text'
                placeholder='State'
                name='state'
                inputProps={{
                  className: 'text-center',
                  ...register('state'),
                }}
                errors={errors}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormInput
                type='text'
                placeholder='Pin'
                name='pin'
                inputProps={{
                  className: 'text-center',
                  ...register('pin'),
                }}
                errors={errors}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default AddFamilyAddress;
