import Grid from "@mui/material/Grid";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";
import React from "react";
import ProfileAvatar from "./ProfileAvatar";
import dayjs from "dayjs";
import Spinner from "./Spinner";

const UserProfileCard = ({
  // userName = 'Customer Name',

  // profilePercent = 70,
  profileUri,
  customerName,
  eiaNum,
  profileReadiness,
  dashboardLoading,
}: {
  customerName?: string | any;
  eiaNum?: string;
  profileReadiness?: string;
  lastUpdateDate: string | undefined;
  profileUri?: string;
  dashboardLoading?: any;
}) => {
  const theme = useTheme();

  return (
    <Grid container alignItems={"center"} spacing={1}>
      <Grid item>
        <ProfileAvatar
          profileUri={profileUri}
          profileReadiness={profileReadiness}
        />
      </Grid>
      {dashboardLoading ? (
        <Grid item>
          <Spinner />
        </Grid>
      ) : (
        <Grid item className="ml-10">
          <Typography
            textAlign={"left"}
            color={"primary"}
            fontSize={20}
            lineHeight={"20px"}
          >
            {customerName}
          </Typography>
          <Typography
            textAlign={"left"}
            color={theme.palette.grey.A700}
            fontSize={14}
            lineHeight={"16px"}
          >
            eIA#: {eiaNum}
          </Typography>
          <Typography
            textAlign={"left"}
            color={theme.palette.grey.A700}
            fontSize={14}
            whiteSpace={"nowrap"}
          >
            Profile Readiness:
            <Typography
              color={theme.palette.secondary.main}
              fontSize={14}
              component={"span"}
            >
              {profileReadiness}
            </Typography>
          </Typography>{" "}
          {/* <Typography
          textAlign={'left'}
          color={theme.palette.grey.A700}
          fontSize={14}
          whiteSpace={'nowrap'}
        >
          Last Updated on
          <Typography
            color={theme.palette.grey.A700}
            fontSize={14}
            component={'span'}
            className='ml-3'
          >
            {dayjs(lastUpdateDate).format('DD MM YYYY')}
          </Typography>
        </Typography> */}
        </Grid>
      )}
    </Grid>
  );
};

export default UserProfileCard;
