import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearBar from '../../../common/ui/ProgressBar/LinearBar';
import { ReactComponent as RefreshIcon } from '../../../common/icons/dashboard/circle_arrow-icon.svg';
import { Divider, Grid, IconButton, useTheme } from '@mui/material';
import DashboardCardLayout from './common/DashboardCardLayout';
import PolicyCardHeader from './common/PolicyCardHeader';
import PolicyCardCost from './common/PolicyCardCost';
import SvgConverter from '../../../common/SvgConverter';
import DoughnutChart from '../../../common/ui/Charts/DoughnutChart';
import Separator from '../../../common/ui/Separator';
import LifeProgressBarWithValues from '../../../common/ui/LifeProgressBarWithValues';

interface UserProps {
  lifeDetails?: any;
}
export const LifeScreen: React.FC<UserProps> = ({ lifeDetails }) => {
  // const [progressVal, setProgressVal] = useState(0);

  // useEffect(() => {
  //   setProgressVal((val) => (val === 0 ? 70 : 0));
  // }, []);

  const theme = useTheme();
  const total =
    parseInt(lifeDetails?.savings?.fundValueAmount) +
    parseInt(lifeDetails?.savings?.surrenderValueAmount);
  const progressVal = total / lifeDetails?.savings?.totalMaturityValueAmount;
  console.log('lefeeeeee', lifeDetails);
  return (
    <DashboardCardLayout>
      <div className='mb-30'>
        <PolicyCardHeader type={'life'} />
      </div>
      <Grid
        container
        flexDirection={'row'}
        spacing={3}
        justifyContent={'space-between'}
      >
        <Grid item className='mb-20'>
          <div className='w-280px'>
            <PolicyCardCost
              title='Current Total Life Cover'
              value={lifeDetails?.totalLifeCoverAmount}
              policyCounts={lifeDetails?.countLifeCover}
            />
          </div>
        </Grid>
        <Grid item className='mb-20'>
          <div className='w-280px'>
            <PolicyCardCost
              title='Current Total Accident Cover'
              value={lifeDetails?.totalAccidentCoverAmount}
              policyCounts={lifeDetails?.countAccidentCover}
            />
          </div>
        </Grid>
      </Grid>
      <Box className={'dashboard-policy-cost-container mb-20'}>
        <Typography
          fontWeight={600}
          className='pb-1'
          fontSize={14}
          color={theme.palette.primary.main}
        >
          Savings
        </Typography>
        <Box
          className={'header-border'}
          borderBottom={`2px solid ${theme.palette.secondary.main}`}
        />
      </Box>
      <Grid container flexDirection={'row'} spacing={5}>
        <Grid item lg={7} className='mb-20' display={'flex'}>
          <Divider
            orientation='vertical'
            sx={{
              height: '70px', // Set the height as per your requirement
              backgroundColor: 'black', // Optional: Set the background color
              width: '1px',
            }}
          />
          <div className='w-280px'>
            <PolicyCardCost.Normal
              title='Total Maturity Value'
              value={lifeDetails?.savings?.totalMaturityValueAmount}
              policyCounts={lifeDetails?.savings?.countMaturityValue}
            />
            <Grid container>
              <Grid item lg={12}>
                <LifeProgressBarWithValues
                  progressVal={progressVal}
                  total={total}
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              alignItems={'center'}
              spacing={2}
              justifyContent={'flex-end'}
            >
              {/* <Grid item sm={8}>
                <PolicyCardCost.Normal
                  title='Current Fund Value'
                  value={total}
                  policyCounts={3}
                  hideLastUpdate={false}
                  hidePolicyCounts={true}
                />
              </Grid>
              <Grid item>
                <IconButton className='p-0'>
                  <SvgConverter Icon={RefreshIcon} />
                </IconButton>
              </Grid> */}
            </Grid>
            <Grid
              item
              container
              alignItems={'center'}
              spacing={2}
              justifyContent={'flex-end'}
            >
              {/* <Grid item sm={8}>
                <PolicyCardCost.Normal
                  title='Current Surrender Value'
                  value={lifeDetails?.savings?.countMaturityValue}
                  policyCounts={3}
                  hideLastUpdate={false}
                  hidePolicyCounts={true}
                  updatedDate={lifeDetails?.savings?.lastUpdatedDate}
                />
              </Grid> */}
              {/* <Grid item>
                <IconButton className='p-0'>
                  <SvgConverter Icon={RefreshIcon} />
                </IconButton>
              </Grid> */}
            </Grid>
          </div>
        </Grid>
        <Grid item lg={4} className='mb-20'>
          <Grid container className='w-280px'>
            <Grid item container justifyContent={'center'} spacing={2}>
              <Grid item className={'text-center'}>
                <PolicyCardCost.Normal
                  title='Savings Risk Profile'
                  policyCounts={3}
                  hideLastUpdate={false}
                  hidePolicyCounts={true}
                  hideValue={true}
                  titleFontWeight={600}
                />
              </Grid>
              <Grid item className='lh-0'>
                <IconButton className='p-0'>
                  <SvgConverter Icon={RefreshIcon} className={'d-flex'} />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item className={'mt-18'}>
              <DoughnutChart riskprofile={lifeDetails?.savings?.riskprofile} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardCardLayout>
  );
};

export default LifeScreen;
