import React, { useState,useEffect } from 'react';
import { Grid } from '@mui/material';
import '../Nominee.scss';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Separator from '../../../../common/ui/Separator';
import theme from '../../../../common/theme';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRupee } from '../../../../common/icons/orange_rupee.svg';
import CustomButton from '../../../../common/ui/CustomButton';

import { ReactComponent as Correct } from '../../../../common/icons/tick-icon.svg';
import { ReactComponent as Cancel } from '../../../../common/icons/Cancel-small.svg';
import { ReactComponent as Edit } from '../../../../common/icons/edit.svg';
import { ReactComponent as Add } from '../../../../common/icons/plus-icon.svg';
import { CommonModal } from '../../../../common/ui/Modal/CommonModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFamilyNameListAction,
  selectUserFamilyNameList,
} from '../Nominee.slice';
import { fetchFamilyRelationShipList } from '../../Profile/Profile.slice';

const NomineeListModal = ({
  open,
  setOpen,
  onSubmit,
  loading,
  addNomineeCloseModal,
  addNomineeModal,
}: any) => {
  const dispatch = useDispatch();
  const nomineeNames = useSelector(selectUserFamilyNameList);
  useEffect(() => {
    dispatch(fetchFamilyNameListAction());
  }, []);

  return (
    <CommonModal
      onClose={() => setOpen(false)}
      open={open}
      // modalClassName='px-20 py-10'
      boxProps={{ width: 354, height: 449 }}
      modalClassName='nominee-list-modal'
    >
      <Grid
        container
        style={{
          maxHeight: '400px',
          overflowY: 'auto',
          backgroundColor: 'white',
        }}
      >
        {nomineeNames.map((item, index) => (
          <Grid item xs={12} key={index}>
            {/* <Typography
              className='f-16 fw-600 px-15 py-13'
              color={theme.palette.common.black}
            >
              {item.firstName}-{item.relationName}
            </Typography> */}
            <CustomButton
              text={`${item.firstName}-${item.relationName}`}
              variant='text'
              color='inherit'
              showIcon={false}
              className='f-16 fw-600 px-15 py-13'
              onClick={() => addNomineeCloseModal(item)}
            />

            {index !== nomineeNames.length - 1 && (
              <Separator color={theme.palette.primary.main} />
            )}
          </Grid>
        ))}
      </Grid>

      <Grid item xs={12}>
        <Separator color={theme.palette.primary.main} />
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
            marginBottom: '2rem',
          }}
          className='nominee-list-modal'
        >
          <Typography
            className='f-16 fw-600 '
            color={theme.palette.primary.main}
            style={{
              backgroundColor: 'white',
              borderRadius: '50%',
              width: 'fit-content',
              padding: '0.5rem',
              border: '1px solid blue',
            }}
          >
            OR
          </Typography>
        </Grid>
        <Typography
          className='f-16 fw-600 px-12 py-10'
          color={theme.palette.primary.main}
          textAlign={'center'}
          style={{
            backgroundColor: 'white',
            borderRadius: '7px',
            marginTop: '5%',
            border: '1px solid blue',
          }}
        >
          <CustomButton
            className='f-16 fw-600'
            text={'Add a Nominee'}
            variant={'text'}
            color={'primary'}
            showIcon={false}
            startIcon={Add}
            onClick={addNomineeModal}
          />
        </Typography>
      </Grid>
    </CommonModal>
  );
};

export default NomineeListModal;
