import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import { ReactComponent as HomeIcon } from "../../../../common/icons/profile/home_icon.svg";
import CustomButton from "../../../../common/ui/CustomButton";
import Separator from "../../../../common/ui/Separator";

const CorrespondenceAddressHeader = ({ toggleAddNumberModal }: any) => {
  const theme = useTheme();

  return (
    <Grid item>
      <Grid item className="mb-10">
        <Typography
          color={theme.palette.primary.main}
          fontSize={16}
          fontWeight={600}
        >
          Correspondence Addresses
        </Typography>
      </Grid>

      <Grid item>
        <CustomButton
          text={"ADD AN ADDRESS"}
          color={"primary"}
          startIcon={HomeIcon}
          variant={"text"}
          showIcon={false}
          onClick={toggleAddNumberModal}
          fontSize={16}
        />
      </Grid>
      <Grid item className="my-10">
        <Separator color={theme.palette.primary.main} borderWidth={2} />
      </Grid>
    </Grid>
  );
};

export default CorrespondenceAddressHeader;
