import dayjs from "dayjs";
import { store } from "./store";
import cryptoEncryptionDecryption, { generateUUID } from "./crypto";
import { getAccessToken } from "../features/Auth/auth.service";
import { setAlertInfo } from "../features/App/Common/Common.slice";

export const HandleAxiosRequest = async (config: any) => {
  try {
    const currentTimeStamp = dayjs(new Date()).valueOf();
    let access_token = sessionStorage.getItem("access_token");
    let token_expires_at: number | string | null =
      sessionStorage.getItem("token_expires_at");
    if (!access_token || currentTimeStamp >= Number(token_expires_at)) {
      const { data } = await getAccessToken();
      sessionStorage.setItem("access_token", data.access_token);
      sessionStorage.setItem(
        "token_expires_at",
        String(data.access_token * data.expires_in + currentTimeStamp)
      );
      access_token = data.access_token;
    }
    const timestamp = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS");
    config.data = {
      ...config.data,
      timestamp,
      txnId: generateUUID(),
      Source: {
        appType: "REACT",
        osVersion: "11",
        deviceId: "",
        deviceName: "DESKTOP",
        deviceOS: "WINDOWS",
        appVersion: "2.0",
        clientIp: "0.0.0.0",
        origin: "",
      },
    };
    console.log(`Payload---> ${config?.url} ---->`, config.data);
    config.headers["Content-Type"] = "text/plain";
    config.headers["Authorization"] = `Basic ${access_token}`;
    config.data = cryptoEncryptionDecryption.Encrypt(config.data);
    return config;
  } catch (error) {
    console.log(error);
  }
};

export const HandleAxiosResponse = async (response: any) => {
  try {
    response.data = JSON.parse(
      cryptoEncryptionDecryption.Decrypt(response.data)
    );
    console.log("Decrypted ->", response.data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const HandleAxiosResponseWithError = async (error: any) => {
  try {
    if (!error?.response || !error?.response?.data) {
      const alertInfo = {
        open: true,
        type: "ERROR",
        description: "Failed to connect with network,  Please try Again later.",
      };
      store.dispatch(setAlertInfo(alertInfo as any));
    } else {
      error.response.data = JSON.parse(
        cryptoEncryptionDecryption.Decrypt(error?.response?.data)
      );
      console.log(
        `Decrypt Data (Error)---> ${error?.response?.config?.url} ---->`,
        error?.response?.data
      );
      const alertInfo = {
        open: true,
        type: "ERROR",
        description: error?.response?.data?.errorDescription
          ? error?.response?.data?.errorDescription
          : "Problem with network,  Please try Again later.",
      };
      store.dispatch(setAlertInfo(alertInfo as any));
    }
    return Promise.reject(error);
  } catch (e) {
    console.log(e);
    return Promise.reject(error);
  }
};
