import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import SvgConverter from '../../../common/SvgConverter';
// import { ReactComponent as RupeeIcon } from '../../../../common/icons/LoanModule/rupee-icon.svg';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';

interface annualPayoutProps {
  payDetail: string | number;
  rupee: string | number;
}
const LoanPayoutDetails = ({ payDetail, rupee }: annualPayoutProps) => {
  const theme = useTheme();
  return (
    <Grid
      container
      justifyContent='space-between'
      alignItems={'center'}
      className='h-100'
    >
      <Grid item>
        <Typography className='f-14' color={theme.palette.common.black}>
          {payDetail}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={0.5}>
          <Grid item>
            <SvgConverter Icon={RupeeIcon} width={8} height={12} />
          </Grid>
          <Grid item>
            <Typography className='f-16 fw-600' color={theme.palette.primary.main}>
              {rupee}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoanPayoutDetails;
