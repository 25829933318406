import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import theme from '../../../../../common/theme';
import '../../Policy.scss';
import Separator from '../../../../../common/ui/Separator';
import PolicyFooter from '../PolicyFooter/PolicyFooter';
import HealthHeader from '../PolicyCard/HealthHeader';
import PremiumDue from '../PolicyCard/PremiumDue';
import PayNow from '../PolicyCard/PayNow';
interface UserProps {
  contentType?: string;
  items?: any;
  verificationStatus?: any;
}
const ActiveHealthCard: React.FC<UserProps> = ({
  contentType,
  items,
  verificationStatus,
}) => {
  console.log('footer');
  return (
    <Box className='policy-card'>
      <Grid container>
        <HealthHeader items={items} contentType={contentType} />
        <Separator color={theme.palette.primary.main} />
        <PremiumDue items={items} contentType={contentType} />

        {items.paymentFlag === 'Y' ||
          (items.verifiedFlag === 'Y' && (
            <>
              <PayNow items={items} contentType={contentType} />
            </>
          ))}
        <Separator color={theme.palette.primary.main} />
        <PolicyFooter footerType={contentType} items={items} />
      </Grid>
    </Box>
  );
};

export default ActiveHealthCard;
