import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';

interface labelProps {
  color?: string | undefined;
  fontWeight?: number | undefined;
}
interface fundAndcoverLoss {
  TotalNo?: string | number;
  rupee1?: string | number;
  rupee2?: string | number;
  Icon?: any;
  labelProps?: labelProps | undefined;
}
const TotalFundAndLossCoverDetails = ({
  TotalNo,
  rupee1,
  rupee2,
  Icon,
  labelProps,
}: fundAndcoverLoss) => {
  const theme = useTheme();
  return (
    <>
      <Grid container justifyContent={'space-between'} className='mb-3'>
        <Grid item>
          <Typography
            className='f-14 fw-600'
            color={theme.palette.common.black}
          >
            Total Funds Available
          </Typography>
          <Grid item>
            <Typography className='f-14 mt-n2'>{TotalNo}</Typography>
          </Grid>
        </Grid>
        <Grid item className='mt-4'>
          <Grid container spacing={0.5}>
            <Grid item>
              <SvgConverter
                Icon={RupeeIcon}
                width={5}
                height={9}
                className=' mb-4'
              />
            </Grid>
            <Grid item>
              <Typography
                className='f-12 fw-600'
                color={theme.palette.common.black}
              >
                {rupee1}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className='f-12' color={theme.palette.common.black}>
                (approx)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <Typography
            className='f-14 fw-600'
            color={theme.palette.common.black}
          >
            Loss of Cover
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={0.5}>
            <Grid item>
              <SvgConverter Icon={Icon} width={5} height={9} className='mb-4' />
            </Grid>
            <Grid item>
              <Typography
                className='f-12 fw-600'
                color={labelProps?.color || theme.palette.common.black}
                fontWeight={labelProps?.fontWeight}
              >
                {rupee2}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TotalFundAndLossCoverDetails;
