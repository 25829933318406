import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as bankdocs } from './../../../common/icons/bank-id-docs.svg';

const PersonalBankDocuments = ({
  profileIds,
  otherUri,
}: {
  otherUri: any;
  profileIds: any;
}) => {
  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography className='f-14 fw-600 mb-14' color={'primary'}>
          IDs & Docs
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography className='f-14 mb-14' color={'primary'}>
          UPLOAD MORE
        </Typography>
      </Grid>
      {profileIds &&
        profileIds.map((item: any, index: any) => (
          <Grid container className='py-20'>
            {item.imgData && item.imgType === 'M' ? (
              <Grid item xs={4}>
                <img src={`data:image/png;base64,${item.imgData}`} width={200} />
              </Grid>
            ) : item.imgData && item.imgType === 'L' ? (
              <Grid item xs={4}>
                <img src={`data:image/png;base64,${item.imgData}`} width={200} />
              </Grid>
            ) : otherUri && item.imgType === 'M' ? (
              <Grid item xs={4}>
                <img src={otherUri} />
              </Grid>
            ) : otherUri && item.imgType === 'L' ? (
              <Grid item xs={4}>
                <img src={otherUri} />
              </Grid>
            ) : (
              <Grid item xs={4}>
                <img src={otherUri} />
              </Grid>
            )}
          </Grid>
        ))}
    </Grid>
  );
};

export default PersonalBankDocuments;
