import React, { useMemo } from "react";
import { Grid } from "@mui/material";

import PermanentAddress from "./common/PermanentAddress";
import CorrespondenceAddressHeader from "./common/CorrespondenceAddressHeader";
import CorrespondenceAddress from "./common/CorrespondenceAddress";
import UnassignedAddress from "./common/UnassignedAddress";
import { groupBy, prop } from "ramda";
import { useSelector } from "react-redux";
import {
  selectGetAddressEkycUrlIsLoading,
  selectPoliciesForAddress,
} from "./Address.slice";
const ManageAddressHeader = ({
  handleOnChangeAddressClick,
  handleAssignPolicies,
  setRemoveUnAssignedAddress,
  toggleRemoveNumberModal,
  toggleAddNumberModal,
  handleChangePermanentAddress,
  isEkycPopupOpen,
}: any) => {
  const { permanentAddress, addressBasedPolicies, unassignedAddresses }: any =
    useSelector(selectPoliciesForAddress);
  const isGetEkycURLLoading = useSelector(selectGetAddressEkycUrlIsLoading);
  const groupPoliciesByAddress = groupBy(prop<any>("fullAddress"));
  const assignedPolicies: any = useMemo(() => {
    let data: any = groupPoliciesByAddress(addressBasedPolicies);
    return data;
  }, [addressBasedPolicies, groupPoliciesByAddress]);

  return (
    <>
      <Grid item className="ml-50 mr-50">
        <Grid item className="mb-10">
          <PermanentAddress
            addressDetails={permanentAddress}
            onChange={handleChangePermanentAddress}
            changeLoading={isGetEkycURLLoading || isEkycPopupOpen}
          />
        </Grid>
        <Grid item>
          <CorrespondenceAddressHeader
            toggleAddNumberModal={toggleAddNumberModal}
          />
        </Grid>
        <Grid item>
          <Grid container className="Manage-bank" spacing={5}>
            {Object.keys(assignedPolicies).map((fullAddress, index) => (
              <Grid item xs={12} sm={6} md={3} key={fullAddress}>
                <CorrespondenceAddress
                  addressDetails={assignedPolicies?.[fullAddress]?.[0]}
                  index={index}
                  policies={assignedPolicies?.[fullAddress]}
                  handleOnChangeAddressClick={handleOnChangeAddressClick}
                  handleAssignPolicies={handleAssignPolicies}
                  toggleRemoveNumberModal={toggleRemoveNumberModal}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item marginTop={5}>
          <UnassignedAddress
            addressList={unassignedAddresses}
            handleAssignPolicies={handleAssignPolicies}
            setRemoveUnAssignedAddress={setRemoveUnAssignedAddress}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ManageAddressHeader;
