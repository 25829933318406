import React, { FC } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface Props {
  label?: string | undefined;
  options: [] | any;
  value?: any | '';
  onChange: (value: string) => void;
  setSelectedOption?: any;
  size?: string;
  ClassName?: string | undefined;
  dropdownWidth?: string;
  inputProps?: any;
  defaultValue?: any;
}

const CustomSelectBox: React.FC<Props> = ({
  label,
  options,
  value,
  setSelectedOption,
  ClassName,
  size,
  dropdownWidth,
  inputProps,
  defaultValue,
}) => {
  const handleChange = (event: SelectChangeEvent<{ value: unknown }>) => {
    setSelectedOption(event.target.value);
  };

  return (
    <FormControl size='small' className={`${ClassName}`}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        defaultValue={defaultValue}
        inputProps={inputProps}
      >
        {options.map((option: any, index: any) => (
          <MenuItem
            key={index}
            value={option?.Code}
            sx={{ width: dropdownWidth }}
          >
            {option?.Description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelectBox;
