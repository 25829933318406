import React, { useState, useRef } from "react";
import { CommonModal } from "./CommonModal";
import { Grid, Typography, useTheme } from "@mui/material";
import CustomButton from "../CustomButton";
import Separator from "../Separator";
import WebCamModal from "./WebCamModal";
import { useDispatch } from "react-redux";
import { setAlertInfo } from "../../../features/App/Common/Common.slice";
import { convertToBase64, stripBase64Prefix } from "../../utils";

const ImagePickerModal = ({
  open,
  setOpen,
  handleImage,
  removePrefix = true,
}: any) => {
  const [webCamModal, setWebCamModal] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const fileInputRef = useRef<any>(null);

  const onCapture = (imageBase64: string) => {
    if (imageBase64) {
      setWebCamModal(false);
      if (removePrefix) {
        imageBase64 = stripBase64Prefix(imageBase64);
      }
      handleImage(imageBase64);
      setOpen(false);
    }
  };

  const handleChooseFileClick = () => {
    try {
      if (fileInputRef?.current) {
        fileInputRef.current.type = "text";
        fileInputRef.current.type = "file";
        fileInputRef?.current?.click();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleImagePicked = async (e: any) => {
    try {
      if (!String(e?.target?.files[0]["type"]).includes("image")) {
        throw "Invalid file type";
      }
      let base64Image: any = await convertToBase64(e?.target?.files[0]);
      if (removePrefix) {
        base64Image = stripBase64Prefix(base64Image);
      }
      handleImage(base64Image);
      setOpen(false);
    } catch (error) {
      console.log(error);
      dispatch(
        setAlertInfo({
          open: true,
          type: "FAILED",
          description: "Invalid file type",
        })
      );
    }
  };

  return (
    <>
      <CommonModal
        onClose={() => setOpen(false)}
        open={open}
        modalClassName="py-20"
        boxProps={{ width: 354 }}
      >
        <Grid container justifyContent={"center"}>
          <Grid>
            <Typography color={theme.palette.info.main} fontWeight={600}>
              Select Photo
            </Typography>
          </Grid>
        </Grid>
        <Separator color={theme.palette.info.main} className="my-10" />
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          flexWrap={"nowrap"}
        >
          <Grid>
            <CustomButton
              text={"Choose file"}
              variant={"text"}
              color={"primary"}
              showIcon={false}
              onClick={handleChooseFileClick}
              type="submit"
              fontWeight={600}
              fontSize={14}
            />
          </Grid>
          <Grid>
            <div
              className="mx-10"
              style={{
                width: 1,
                height: 30,
                borderRight: `1px solid ${theme.palette.info.main}`,
              }}
            ></div>
          </Grid>
          <Grid>
            <CustomButton
              text={"open webcam"}
              variant={"text"}
              color={"primary"}
              showIcon={false}
              onClick={() => setWebCamModal(true)}
              type="submit"
              fontWeight={600}
              fontSize={14}
            />
          </Grid>
        </Grid>
      </CommonModal>
      <WebCamModal
        open={webCamModal}
        setOpen={setWebCamModal}
        onCapture={onCapture}
      />
      <input
        ref={fileInputRef}
        className="display-none"
        type={"file"}
        accept="image/*"
        onChange={handleImagePicked}
      />
    </>
  );
};

export default ImagePickerModal;
