import React from 'react';
import { Divider, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import theme from '../../../../../common/theme';
import '../TrackTransaction.scss';
import HDFC from '../../../../../assets/images/HDFC.png';
import Separator from '../../../../../common/ui/Separator';
import LabelWithIcon from '../../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRupee } from '../../../../../common/icons/orange_rupee.svg';
import BoxHeader from './Box/BoxHeader';

import StepOne from './Box/StepOne';
import StepTwo from './Box/StepTwo';
import StepThree from './Box/StepThree';

interface UserProps {
  step?: string;
}

export const TransactionCard: React.FC<UserProps> = ({ step }) => {
  return (
    <>
      <Box className='tracker-card '>
        <Grid container>
          <Grid item xs={12}>
            <BoxHeader step={step} />
          </Grid>
          <Separator color={' #d7d7d7'} />
          {step === '1' ? (
            <StepOne />
          ) : step === '2' ? (
            <StepTwo />
          ) : (
            <StepThree />
          )}
        </Grid>
      </Box>
      <Typography
        className=' f-12 fw-600 ml-1 mt-3 mb-20'
        color={' #aaaaaa'}
        fontSize={14}
      >
        Terms & Conditions Apply
      </Typography>
    </>
  );
};

export default TransactionCard;
