import { Grid } from '@mui/material';
import React from 'react';
import '../../Policy.scss';
import ArchiveAssetsCard from '../ArchiveContent/ArchiveAssetsCard';

interface UserProps {
  contentType?: string;

  filteredAssets?: any;
}
export const ArchiveAssets: React.FC<UserProps> = ({ filteredAssets }) => {
  return (
    <Grid container justifyContent={'center'}>
      {filteredAssets.map((data: any, index: any) => (
        <Grid item xs={4} key={index}>
          <ArchiveAssetsCard contentType={'archive'} items={data} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ArchiveAssets;
