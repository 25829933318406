import {createSlice} from '@reduxjs/toolkit';
import { LoadingState } from '../../../common';
import { RootState } from '../../../common/store';
// import {LoadingState} from '../../common';
// import {RootState} from '../../common/store';

interface Notification {
  title: string;
  notification: string;
  createdDate: string;
}

interface NotificationsState {
  isLoading: LoadingState;
  notifications: Notification[];
  errors: any;
}

const initialState: NotificationsState = {
  isLoading: 'idle',
  notifications: [],
  errors: undefined,
};

const NotificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    fetchNotifications: state => {
      state.isLoading = 'loading';
      state.notifications = [];
      state.errors = undefined;
    },
    reportLoadNotification: (state, {payload}) => {
      state.isLoading = 'done';
      state.notifications = payload;
      state.errors = undefined;
    },
    reportLoadErrorNotification: (state, {payload}) => {
      state.isLoading = 'error';
      state.notifications = [];
      state.errors = payload;
    },
  },
});

export const selectIsNotificationLoading = (state: RootState) =>
  state.notifications.isLoading === 'loading';

export const selectLoadedNotifications = (state: RootState) =>
  state.notifications.notifications;

export const {
  fetchNotifications,
  reportLoadNotification,
  reportLoadErrorNotification,
} = NotificationSlice.actions;

export default NotificationSlice.reducer;
