import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import FormInput from "../../../../common/ui/Forms/FormInput/FormInput";
import { ReactComponent as Cancel } from "../../../../common/icons/cancel-icon.svg";
import { ReactComponent as Correct } from "../../../../common/icons/tick-icon.svg";
import CustomButton from "../../../../common/ui/CustomButton";
import { CommonModal } from "../../../../common/ui/Modal/CommonModal";
import { useForm } from "react-hook-form";
const AddressEntryModal = ({ show, setShow, onSubmit, handleCancel }: any) => {
  const theme = useTheme();
  const {
    formState: { errors },
    control,
    reset,
    handleSubmit,
  } = useForm();

  React.useEffect(() => {
    if (!show) {
      reset();
    }
  }, [show]);

  return (
    <CommonModal
      onClose={() => setShow(false)}
      open={show}
      modalClassName="px-30 py-30"
      boxProps={{ width: 354 }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item className="container-family" justifyContent={"center"}>
          <Grid item className=" ">
            <Typography
              className={"f-16 fw-600"}
              color={theme.palette.primary.main}
              textAlign={"center"}
            >
              Please enter correspondence address details.
            </Typography>
          </Grid>
          <Grid item className="mt-30 mb-25">
            <FormInput
              type="text"
              placeholder="House #,Street #"
              color={theme.palette.common.black}
              rules={{
                required: {
                  value: true,
                  message: "Address 1 is required",
                },
              }}
              control={control}
              name="address1"
              errors={errors}
            />
          </Grid>

          <Grid item className="mb-25">
            <FormInput
              type="text"
              placeholder="Locality,Area"
              rules={{
                required: {
                  value: true,
                  message: "Address 2 is required",
                },
              }}
              control={control}
              name="address2"
              errors={errors}
            />
          </Grid>
          <Grid container spacing={3} className="mb-25">
            <Grid item xs={6} sm={6}>
              <FormInput
                type="text"
                placeholder="City"
                rules={{
                  required: {
                    value: true,
                    message: "City is required",
                  },
                }}
                control={control}
                name="city"
                errors={errors}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormInput
                type="text"
                placeholder="District"
                rules={{
                  required: {
                    value: true,
                    message: "District is required",
                  },
                }}
                control={control}
                name="address3"
                errors={errors}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="mb-25">
            <Grid item xs={6} sm={6}>
              <FormInput
                type="text"
                placeholder="State"
                rules={{
                  required: {
                    value: true,
                    message: "State is required",
                  },
                }}
                control={control}
                name="state"
                errors={errors}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormInput
                type="text"
                placeholder="Pin"
                rules={{
                  required: {
                    value: true,
                    message: "Pin is required",
                  },
                }}
                control={control}
                name="pincode"
                errors={errors}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent={"space-around"}
            spacing={1}
            className="mt-40"
          >
            <Grid item>
              <CustomButton
                text={"add"}
                variant={"text"}
                color={"primary"}
                showIcon={false}
                startIcon={Correct}
                className="f-16 fw-600"
                type="submit"
              />
            </Grid>
            <Grid item>
              <CustomButton
                text={"cancel"}
                startIcon={Cancel}
                showIcon={false}
                color="primary"
                className="f-16 fw-400"
                variant="text"
                onClick={handleCancel}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </CommonModal>
  );
};

export default AddressEntryModal;
