import React from 'react';
import { Divider, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import '../../TrackTransaction.scss';
import HDFC from '../../../../../assets/images/HDFC.png';
import Separator from '../../../../../../common/ui/Separator';
import LabelWithIcon from '../../../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRupee } from '../../../../../../common/icons/orange_rupee.svg';
import theme from '../../../../../../common/theme';

const StepOne = () => {
  return (
    <Grid item xs={12}>
      <Grid container className=''>
        <Grid item xs={8}>
          <Grid container className='ml-10 mt-8'>
            <Grid item xs={6}>
              <Typography
                className='f-14 fw-600'
                color={theme.palette.common.black}
              >
                Payout of
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <LabelWithIcon
                svgClassName='mb-2 ml-2'
                justifyContent={'flex-start'}
                Icon={OrangeRupee}
                label={'XXXXXX'}
                labelProps={{
                  color: theme.palette.primary.main,
                  fontSize: 16,
                  fontWeight: 600,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                className='f-14'
                color={theme.palette.common.black}
                style={{ letterSpacing: '-0.7px' }}
              >
                to be received on DDMMYYYY
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Separator
            orientation='vertical'
            color={' #d7d7d7'}
            borderWidth={2}
          />
        </Grid>
        <Grid item xs={3} textAlign={'center'} className='mt-5'>
          <Typography
            className='fw-600 '
            color={theme.palette.secondary.main}
            fontSize={18}
          >
            5
          </Typography>
          <Typography className='fw-600 mb-10' color={'#aaaaaa'} fontSize={12}>
            days left
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StepOne;
