import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../../common';
import {
  FamilyProfile,
  FamilyRelationShips,
  UserProfile,
} from './types/Profile.model';
import { RootState } from '../../../common/store';

interface ProfileSlice {
  isLoading: LoadingState;
  userProfile: UserProfile | null;
  errors: any | null;
  familyList: {
    status: LoadingState;
    requestInfo: FamilyProfile[] | [];
    error: any;
  };
  familyRelationShip: FamilyRelationShips;
  addFamilyMember: {
    status: LoadingState;
    requestInfo: any;
    error: any;
  };
  uploadProfilePhoto: {
    status: LoadingState;
    uploadInfo: any;
    error: any;
  };
  uploadSignaturePhoto: {
    status: LoadingState;
    signInfo: any;
    error: any;
  };
  uploadOtherPhoto: {
    status: LoadingState;
    otherInfo: any;
    error: any;
  };
  generateOtpForMobile: { status: LoadingState; data: any; error: any };
  verifyMobileNo: { status: LoadingState; data: any; error: any };
  generateOtpForEmail: { status: LoadingState; data: any; error: any };
  verifyEmail: { status: LoadingState; data: any; error: any };
  familyVerification: { status: LoadingState; data: any; error?: any };
  familyEmailVerification: { status: LoadingState; data: any; error?: any };
  updateAR: { status: LoadingState; data: any; error: any };
}

const initialState: ProfileSlice = {
  isLoading: 'idle',
  userProfile: null,
  familyList: {
    status: 'idle',
    requestInfo: [],
    error: '',
  },
  familyRelationShip: {},
  errors: null,
  addFamilyMember: {
    status: 'idle',
    requestInfo: {},
    error: '',
  },
  uploadProfilePhoto: {
    status: 'idle',
    uploadInfo: null,
    error: null,
  },
  uploadSignaturePhoto: {
    status: 'idle',
    signInfo: null,
    error: null,
  },
  uploadOtherPhoto: {
    status: 'idle',
    otherInfo: null,
    error: null,
  },
  generateOtpForMobile: {
    status: 'idle',
    data: undefined,
    error: undefined,
  },
  verifyMobileNo: {
    status: 'idle',
    data: undefined,
    error: undefined,
  },
  generateOtpForEmail: {
    status: 'idle',
    data: undefined,
    error: undefined,
  },
  verifyEmail: {
    status: 'idle',
    data: undefined,
    error: undefined,
  },
  familyVerification: {
    status: 'idle',
    data: {},
    error: null,
  },
  familyEmailVerification: {
    status: 'idle',
    data: {},
    error: null,
  },
  updateAR: {
    status: 'idle',
    data: {},
    error: '',
  },
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    fetchUserProfile: (state) => ({
      ...state,
      isLoading: 'loading',
      errors: null,
      userProfile: null,
    }),
    loadUserProfile: (state, action) => ({
      ...state,
      userProfile: action.payload,
      errors: null,
      isLoading: 'idle',
    }),
    errorUserProfile: (state, action) => ({
      ...state,
      errors: action.payload,
      userProfile: null,
    }),
    fetchUserFamilyListAction: (state) => {
      state.familyList.status = 'loading';
      state.familyList.requestInfo = [];
      state.familyList.error = null;
    },
    loadUserFamilyList: (state, action) => {
      state.familyList.status = 'done';
      state.familyList.requestInfo = action.payload;
      state.familyList.error = null;
    },
    errorUserFamilyList: (state, action) => {
      state.familyList.status = 'done';
      state.familyList.requestInfo = [];
      state.familyList.error = action.payload;
    },
    fetchFamilyRelationShipList: (state) => ({
      ...state,
      isLoading: 'loading',
      familyRelationShip: {},
      errors: null,
    }),
    loadFamilyRelationShipList: (state, action) => ({
      ...state,
      isLoading: 'done',
      familyRelationShip: action.payload,
      errors: null,
    }),
    errorFamilyRelationShipList: (state, action) => ({
      ...state,
      isLoading: 'done',
      familyRelationShip: {},
      errors: action.payload,
    }),

    addFamilyMemberRequest: (state) => {
      state.addFamilyMember.status = 'loading';
      state.addFamilyMember.requestInfo = {};
      state.addFamilyMember.error = null;
    },
    addFamilyMemberSuccess: (state, action) => {
      state.addFamilyMember.status = 'done';
      state.addFamilyMember.requestInfo = action.payload;
      state.addFamilyMember.error = null;
    },
    addFamilyMemberError: (state, action) => {
      state.addFamilyMember.status = 'done';
      state.addFamilyMember.requestInfo = {};
      state.addFamilyMember.error = action.payload;
    },
    uploadProfilePhotoRequest: (state) => {
      state.uploadProfilePhoto.status = 'loading';
      state.uploadProfilePhoto.uploadInfo = {};
      state.uploadProfilePhoto.error = null;
    },
    uploadProfilePhotoSuccess: (state, action) => {
      state.uploadProfilePhoto.status = 'done';
      state.uploadProfilePhoto.uploadInfo = action.payload;
      state.uploadProfilePhoto.error = null;
    },
    uploadProfilePhotoError: (state, action) => {
      state.uploadProfilePhoto.status = 'done';
      state.uploadProfilePhoto.uploadInfo = {};
      state.uploadProfilePhoto.error = action.payload;
    },
    uploadSignaturePhotoRequest: (state) => {
      state.uploadSignaturePhoto.status = 'loading';
      state.uploadSignaturePhoto.signInfo = {};
      state.uploadSignaturePhoto.error = null;
    },
    uploadSignaturePhotoSuccess: (state, action) => {
      state.uploadSignaturePhoto.status = 'done';
      state.uploadSignaturePhoto.signInfo = action.payload;
      state.uploadSignaturePhoto.error = null;
    },
    uploadSignaturePhotoError: (state, action) => {
      state.uploadSignaturePhoto.status = 'done';
      state.uploadSignaturePhoto.signInfo = {};
      state.uploadSignaturePhoto.error = action.payload;
    },
    uploadOtherPhotoRequest: (state) => {
      state.uploadOtherPhoto.status = 'loading';
      state.uploadOtherPhoto.otherInfo = {};
      state.uploadOtherPhoto.error = null;
    },
    uploadOtherPhotoSuccess: (state, action) => {
      state.uploadOtherPhoto.status = 'done';
      state.uploadOtherPhoto.otherInfo = action.payload;
      state.uploadOtherPhoto.error = null;
    },
    uploadOtherPhotoError: (state, action) => {
      state.uploadOtherPhoto.status = 'done';
      state.uploadOtherPhoto.otherInfo = {};
      state.uploadOtherPhoto.error = action.payload;
    },
    verifyGenerateOtpForMobileRequest: (state) => {
      state.generateOtpForMobile.status = 'loading';
      state.generateOtpForMobile.data = undefined;
      state.generateOtpForMobile.error = undefined;
    },
    verifyGenerateOtpForMobileSuccess: (state, { payload }) => {
      state.generateOtpForMobile.status = 'done';
      state.generateOtpForMobile.data = payload;
      state.generateOtpForMobile.error = undefined;
    },
    verifyGenerateOtpForMobileError: (state, { payload }) => {
      state.generateOtpForMobile.status = 'error';
      state.generateOtpForMobile.data = undefined;
      state.generateOtpForMobile.error = payload;
    },
    verifyOtpMobileNoRequest: (state) => {
      state.verifyMobileNo.status = 'loading';
      state.verifyMobileNo.data = undefined;
      state.verifyMobileNo.error = undefined;
    },
    verifyOtpMobileNoSuccess: (state, { payload }) => {
      state.verifyMobileNo.status = 'done';
      state.verifyMobileNo.data = payload;
      state.verifyMobileNo.error = undefined;
    },
    verifyOtpMobileNoError: (state, { payload }) => {
      state.verifyMobileNo.status = 'error';
      state.verifyMobileNo.data = undefined;
      state.verifyMobileNo.error = payload;
    },
    verifyGenerateOtpForEmailRequest: (state) => {
      state.generateOtpForEmail.status = 'loading';
      state.generateOtpForEmail.data = undefined;
      state.generateOtpForEmail.error = undefined;
    },
    verifyGenerateOtpForEmailSuccess: (state, { payload }) => {
      state.generateOtpForEmail.status = 'done';
      state.generateOtpForEmail.data = payload;
      state.generateOtpForEmail.error = undefined;
    },
    verifyGenerateOtpForEmailError: (state, { payload }) => {
      state.generateOtpForEmail.status = 'error';
      state.generateOtpForEmail.data = undefined;
      state.generateOtpForEmail.error = payload;
    },
    verifyOtpEmailRequest: (state) => {
      state.verifyEmail.status = 'loading';
      state.verifyEmail.data = undefined;
      state.verifyEmail.error = undefined;
    },
    verifyOtpEmailSuccess: (state, { payload }) => {
      state.verifyEmail.status = 'done';
      state.verifyEmail.data = payload;
      state.verifyEmail.error = undefined;
    },
    verifyOtpEmailError: (state, { payload }) => {
      state.verifyEmail.status = 'error';
      state.verifyEmail.data = undefined;
      state.verifyEmail.error = payload;
    },
    saveFamilyVerificationDetails: (state) => {
      state.familyVerification.status = 'loading';
      state.familyVerification.data = {};
      state.familyVerification.error = undefined;
    },
    saveFamilyVerificationDetailsSuccess: (state, action) => {
      state.familyVerification.status = 'done';
      state.familyVerification.data = action.payload;
      state.familyVerification.error = undefined;
    },
    saveFamilyVerificationDetailsError: (state, action) => {
      state.familyVerification.status = 'error';
      state.familyVerification.data = {};
      state.familyVerification.error = action.payload;
    },
    saveFamilyEmailVerificationDetails: (state) => {
      state.familyEmailVerification.status = 'loading';
      state.familyEmailVerification.data = {};
      state.familyEmailVerification.error = undefined;
    },
    saveFamilyEmailVerificationDetailsSuccess: (state, action) => {
      state.familyEmailVerification.status = 'done';
      state.familyEmailVerification.data = action.payload;
      state.familyEmailVerification.error = undefined;
    },
    saveFamilyEmailVerificationDetailsError: (state, action) => {
      state.familyEmailVerification.status = 'error';
      state.familyEmailVerification.data = {};
      state.familyEmailVerification.error = action.payload;
    },
    updateArRequest: (state) => {
      state.updateAR.status = 'loading';
      state.updateAR.data = {};
      state.updateAR.error = null;
    },
    updateArSuccess: (state, action) => {
      state.updateAR.status = 'done';
      state.updateAR.data = action.payload;
      state.updateAR.error = null;
    },
    updateArError: (state, action) => {
      state.updateAR.status = 'done';
      state.updateAR.data = {};
      state.updateAR.error = action.payload;
    },
  },
});

export const selectIsLoading = (state: RootState) =>
  state.profile.isLoading === 'loading';

export const selectUserProfile = (state: RootState) =>
  state.profile.userProfile;

export const selectFamilyListIsLoading = (state: RootState) =>
  state.profile.familyList.status === 'loading';

export const selectUserFamilyList = (state: RootState) =>
  state.profile.familyList.requestInfo;

export const selectUserFamilyListError = (state: RootState) =>
  state.profile.errors;

export const AddFamilyMemberList = (state: RootState) =>
  state.profile.addFamilyMember;

export const selectIsAddFamilyMemberLoading = (state: RootState) =>
  state.profile.addFamilyMember.status === 'loading';

export const selectIsFamilyRelationShipLoading = (state: RootState) =>
  state.profile.isLoading === 'loading';

export const selectFamilyRelationShipList = (state: RootState) =>
  state.profile.familyRelationShip.Relations;

export const selectUploadImageIsLoading = (state: RootState) =>
  state.profile.uploadProfilePhoto.status === 'loading';

export const selectSignatureImageIsLoading = (state: RootState) =>
  state.profile.uploadSignaturePhoto.status === 'loading';

export const selectOthersImageIsLoading = (state: RootState) =>
  state.profile.uploadOtherPhoto.status === 'loading';

export const mobileGenerateOtpLoading = (state: RootState) =>
  state.profile.generateOtpForMobile.status === 'loading';

export const emailGenerateOtpLoading = (state: RootState) =>
  state.profile.generateOtpForEmail.status === 'loading';

export const mobileVerifyOtpLoading = (state: RootState) =>
  state.profile.verifyMobileNo.status === 'loading';

export const emailVerifyOtpLoading = (state: RootState) =>
  state.profile.verifyEmail.status === 'loading';

export const mobileNoOtpGenerate = (state: RootState) =>
  state.profile.generateOtpForMobile.data;

export const saveFamilyVerificationLoading = (state: RootState) =>
  state.profile.familyVerification.status === 'loading';

export const saveFamilyEmailVerificationLoading = (state: RootState) =>
  state.profile.familyEmailVerification.status === 'loading';

export const addArRequestLoading = (state: RootState) =>
  state.profile.updateAR.status === 'loading';

export const addArData = (state: RootState) => state.profile.updateAR.data;

export const {
  fetchUserProfile,
  loadUserProfile,
  errorUserProfile,
  fetchUserFamilyListAction,
  loadUserFamilyList,
  errorUserFamilyList,
  addFamilyMemberRequest,
  addFamilyMemberSuccess,
  addFamilyMemberError,
  fetchFamilyRelationShipList,
  loadFamilyRelationShipList,
  errorFamilyRelationShipList,
  uploadProfilePhotoRequest,
  uploadProfilePhotoSuccess,
  uploadProfilePhotoError,
  uploadSignaturePhotoRequest,
  uploadSignaturePhotoSuccess,
  uploadSignaturePhotoError,
  uploadOtherPhotoRequest,
  uploadOtherPhotoSuccess,
  uploadOtherPhotoError,
  verifyGenerateOtpForMobileRequest,
  verifyGenerateOtpForMobileSuccess,
  verifyGenerateOtpForMobileError,
  verifyOtpMobileNoRequest,
  verifyOtpMobileNoSuccess,
  verifyOtpMobileNoError,
  verifyGenerateOtpForEmailRequest,
  verifyGenerateOtpForEmailSuccess,
  verifyGenerateOtpForEmailError,
  verifyOtpEmailRequest,
  verifyOtpEmailSuccess,
  verifyOtpEmailError,
  saveFamilyVerificationDetails,
  saveFamilyVerificationDetailsSuccess,
  saveFamilyVerificationDetailsError,
  saveFamilyEmailVerificationDetails,
  saveFamilyEmailVerificationDetailsSuccess,
  saveFamilyEmailVerificationDetailsError,
  updateArRequest,
  updateArSuccess,
  updateArError,
} = profileSlice.actions;

export default profileSlice.reducer;
