import { Grid, makeStyles, Typography, useTheme } from '@mui/material';
import React from 'react';

const AddressDetails = (
  //   {
  //   houseNo,
  //   street,
  //   locality,
  //   area,
  //   city,
  //   district,
  //   state,
  //   pinCode,
  // }: {
  //   houseNo?: string | number;
  //   street?: string | number;
  //   locality?: string;
  //   area?: string;
  //   city?: string;
  //   district?: string;
  //   state?: string;
  //   pinCode?: string | number;
  // }
  {
    address,
  }: {
    address?: {
      address1: string;
      address2: string;
      address3: string;
      city: string;
      state: string;
      country: string;
      pincode: string;
    };
  }
) => {
  return (
    <>
      <Typography className='f-18' color='textPrimary'>
        {address?.address1} {address?.address2}
      </Typography>

      <Typography className='f-18' color='textPrimary'>
        {address?.address3}
      </Typography>

      <Typography className='f-18' color='textPrimary'>
        {address?.city}
      </Typography>

      <Typography className='f-18' color='textPrimary'>
        {address?.state}
      </Typography>

      <Typography className='f-18' color='textPrimary'>
        {address?.country}
      </Typography>

      <Typography className='f-18' color='textPrimary'>
        {address?.pincode}
      </Typography>
    </>
  );
};

export default AddressDetails;
