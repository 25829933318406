import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import SelectFinancierDetails from './SelectFinancierDetails';
import Checkbox from '@mui/material/Checkbox';
import HdfcIcon from '../../../assets/images/HDFC.png';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';

const FinancierBankContent = () => {
  return (
    <Grid
      container
      flexWrap={'nowrap'}
      alignItems={'center'}
      justifyContent={'space-between'}
      spacing={1}
      className='my-10'
    >
      <Grid item>
        <Grid container flexWrap={'nowrap'} spacing={1} alignItems={'center'}>
          <Grid item className='mt-n10'>
            <Checkbox
              sx={{
                color: 'primary.main',
                '&.Mui-checked': {
                  color: 'primary',
                },
              }}
            />
          </Grid>
          <Grid item>
            <img src={HdfcIcon} alt='logo' width={'42'} height={'27'} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item flexWrap={'nowrap'}>
        <SelectFinancierDetails
          product={'Financier Name'}
          lifeAssured={'Loan Amount'}
          charges={'Charges:'}
          rupeeOne={'XXXXXXXX'}
          rupeeTwo={'XX'}
          rupeeThree={'XX'}
          IconProps={{ Icon: RupeeIcon }}
        />
      </Grid>
    </Grid>
  );
};

export default FinancierBankContent;
