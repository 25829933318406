import {
  errorFamilyNameList,
  errorNomineeList,
  fetchFamilyNameListAction,
  fetchNomineeList,
  loadFamilyNameList,
  loadNomineeList,
  updateNomineeError,
  updateNomineeRequest,
  updateNomineeSuccess,
} from './Nominee.slice';
import { select, call, put, takeEvery } from 'typed-redux-saga';
import {
  editNomineeData,
  fetchFamiliesNameList,
  fetchNomineeDataList,
} from './Nominee.service';
// import {setAlertInfo} from '../alerts/alerts-slice';
// import {UserScreens} from '../../common/routes/user/user.screens';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';
import { setAlertInfo } from '../Common/Common.slice';

function* handleFetchNomineeInformation() {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      NomineeList: {
        eiaNo: user.eiaNo,
        policyNo: '',
        companyCode: '',
      },
    };
    const { data }: any = yield* call(fetchNomineeDataList, requestPayload);
    yield* put(loadNomineeList(data));
  } catch (e: any) {
    yield* put(errorNomineeList(e.message));
  }
}

function* handleEditNomineeInformation({
  payload,
}: ReturnType<typeof updateNomineeRequest>) {
  console.log('payload', payload);
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      UpdateNominee: {
        eiaNo: user.eiaNo,
        policyNo: payload.particularCardData.policyNo,
        companyName: payload.particularCardData.companyName,
        companyCode: payload.particularCardData.companyCode,
        planName: payload.particularCardData.planName,
        lifeAssuredName: payload.particularCardData.lifeAssuredName,
        sumAssured: payload.particularCardData.sumAssured,
        totalPremiumAmount: payload.particularCardData.totalPremiumAmount,
        companyLogo: payload.particularCardData.companyLogo,
        Nominees: [
          ...(payload.allFormData.addForm?.map((formData: any) => {
            return {
              action: formData.type,
              nomineeSeqID: formData.nomineeSeqID || '0',
              nomineeFirstName: formData.nomineeFirstName || '',
              nomineeMiddleName: '',
              nomineeLastName: '',
              nomineePercentage: formData.nomineePercentage || '',
              nomineeDOB: `${formData.date}/${formData.month}/${formData.year}`,
              relationshipCode: formData.relationshipCode || '',
              relationshipName: formData.relationshipName || '',
              address1: formData.address1 || '',
              address2: formData.address2 || '',
              address3: formData.address3 || '',
              city: formData.city || '',
              state: formData.state || '',
              country: '',
              pincode: formData.pincode || '',
              addressSameAsLifeAssured: formData.nomineeSentToInsurer || 'N',
            };
          }) || []),
          ...(payload.allFormData.changeForm?.map((formData: any) => {
            return {
              action: formData.type,
              nomineeSeqID: formData.nomineeSeqID || '0',
              nomineeFirstName: formData.nomineeFirstName || '',
              nomineeMiddleName: '',
              nomineeLastName: '',
              nomineePercentage: formData.nomineePercentage || '',
              nomineeDOB: `${formData.date}/${formData.month}/${formData.year}`,
              relationshipCode: formData.relationshipCode || '',
              relationshipName: formData.relationshipName || '',
              address1: formData.address1 || '',
              address2: formData.address2 || '',
              address3: formData.address3 || '',
              city: formData.city || '',
              state: formData.state || '',
              country: '',
              pincode: formData.pincode || '',
              addressSameAsLifeAssured: formData.nomineeSentToInsurer || 'N',
            };
          }) || []),
          ...(payload.allFormData.removeForm?.map((formData: any) => {
            return {
              action: formData.type,
              nomineeSeqID: formData.nomineeSeqID || '0',
              nomineeFirstName: formData.nomineeFirstName || '',
              nomineeMiddleName: '',
              nomineeLastName: '',
              nomineePercentage: formData.nomineePercentage || '',
              nomineeDOB: `${formData.date}/${formData.month}/${formData.year}`,
              relationshipCode: formData.relationshipCode || '',
              relationshipName: formData.relationshipName || '',
              address1: formData.address1 || '',
              address2: formData.address2 || '',
              address3: formData.address3 || '',
              city: formData.city || '',
              state: formData.state || '',
              country: '',
              pincode: formData.pincode || '',
              addressSameAsLifeAssured: formData.nomineeSentToInsurer || 'N',
              RemoveNomineeFlag: 'Y',
            };
          }) || []),
        ],
      },
    };
    const { data }: any = yield* call(editNomineeData, requestPayload);
    yield* put(updateNomineeSuccess(data));
    yield put(
      setAlertInfo({
        open: true,
        type: 'SUCCESS',
        description: data.displayMessage,
      })
    );
    // payload.allFormData.navigation.navigate(
    //   UserScreens.updateNominee.routeName
    // );
    yield* call(handleFetchNomineeInformation);
  } catch (e: any) {
    yield* put(updateNomineeError(e.message));
  }
}

function* handleFetchFamilyListInformation() {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
      },
    };
    const { data }: any = yield* call(fetchFamiliesNameList, requestPayload);
    const familyList = data.FamilyMembers ? data.FamilyMembers : [];
    yield* put(loadFamilyNameList(familyList));
  } catch (e: any) {
    yield* put(errorFamilyNameList(e.message));
  }
}

function* watchNominee() {
  yield* takeEvery(fetchNomineeList.type, handleFetchNomineeInformation);
  yield* takeEvery(updateNomineeRequest.type, handleEditNomineeInformation);
  yield* takeEvery(
    fetchFamilyNameListAction.type,
    handleFetchFamilyListInformation
  );
}

export { watchNominee };
