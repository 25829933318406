import { Grid, IconButton, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../common/ui/CustomButton";
import {
  fetchOtpRequest,
  fetchOtpVerify,
  selectIsLoginRequestLoading,
  selectIsOtpRequestLoading,
  selectLoginRequestInfo,
  setUser,
} from "../auth.slice";
import "../common/OtpScreen.css";
import { ReactComponent as ArrowLeftIcon } from "../../../common/icons/left_arrow-icon.svg";
import SvgConverter from "../../../common/SvgConverter";
import OtpInput from "react-otp-input";
import { OTP_PURPOSE } from "../../../common/Enums";
import { AuthUser } from "../../../common/utils";
import config from "../../../common/config";

const OtpScreen = () => {
  const [enterOtp, setEnterOtp] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [seconds, setSeconds] = useState(config.otpDuration);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isRequestLoading = useSelector(selectIsLoginRequestLoading);
  const loginInfo = useSelector(selectLoginRequestInfo);
  const isOtpRequestLoading = useSelector(selectIsOtpRequestLoading);

  const handleVerify = () => {
    const handleSuccess = () => {
      dispatch(setUser(loginInfo.Customer as AuthUser));
      sessionStorage.setItem("userInfo", JSON.stringify(loginInfo));
    };
    setEnterOtp(true);
    setIsValid(otp.length === 6 ? true : false);
    isValid &&
      dispatch(
        fetchOtpVerify({
          Customer: {
            eiaNo: loginInfo?.Customer?.eiaNo,
            value: loginInfo?.Customer?.mobileNo,
            otp: otp,
            otpPurpose: OTP_PURPOSE.SIGNIN,
            flag: "MOBILE",
          },
          handleSuccess,
        })
      );
  };

  const [otp, setOtp] = useState("");

  const handleOtpChange = (otpValue: string) => {
    setOtp(otpValue);
    setIsValid(otpValue.length === 6 ? true : false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    if (!loginInfo?.Customer?.eiaNo) {
      navigate("/", { replace: true });
    }
  }, [loginInfo]);

  const handleResendOtp = () => {
    const handleSuccess = () => {
      setSeconds(config.otpDuration);
    };
    dispatch(
      fetchOtpRequest({
        Customer: {
          eiaNo: loginInfo?.Customer?.eiaNo,
          value: loginInfo?.Customer?.mobileNo,
          otpPurpose: "SIGNIN",
          flag: "MOBILE",
        },
        handleSuccess,
      })
    );
  };

  return (
    <Grid
      container
      flexDirection={"column"}
      className={"h-100"}
      flexWrap={"nowrap"}
      alignItems={"center"}
    >
      <Grid
        item
        lg={3}
        alignItems={"center"}
        justifyContent={"center"}
        className="mt-25"
      >
        <Grid item className="mt-30 mb-5">
          <IconButton onClick={() => navigate(-1)} className={"p-0"}>
            <SvgConverter Icon={ArrowLeftIcon} className="go-back-icon" />
          </IconButton>
        </Grid>
        <Grid container item flex={1} alignItems={"center"} className="mt-20">
          <Grid item className={"w-100"}>
            <Grid
              container
              spacing={0.5}
              flexDirection={"column"}
              className="mt-15"
            >
              <Grid item>
                <Typography textAlign={"center"} className={"f-16 px-20"}>
                  Please enter 6 digit OTP sent
                </Typography>
                <Typography textAlign={"center"} className={"f-16 px-20"}>
                  to the registered number
                </Typography>
                <Typography
                  textAlign={"center"}
                  color={theme.palette.secondary.main}
                  lineHeight={"unset"}
                  fontSize={28}
                  fontWeight={600}
                  className="mt-10"
                >
                  91XXXXXX23
                </Typography>
              </Grid>

              <Grid item className="my-21">
                <OtpInput
                  numInputs={6}
                  placeholder={"XXXXXX"}
                  value={otp}
                  onChange={handleOtpChange}
                  containerStyle={"otp-input-container"}
                  isInputSecure={true}
                  isInputNum={true}
                />
              </Grid>
              {isValid === false && enterOtp === true && (
                <Typography color={"#d9001b"} className="">
                  Enter valid OTP
                </Typography>
              )}

              {seconds > 0 ? (
                <Grid
                  item
                  container
                  justifyContent={"center"}
                  spacing={0.6}
                  flexWrap={"nowrap"}
                >
                  <Grid item>
                    <Typography
                      className="f-14 fw-400"
                      color={theme.palette.primary.main}
                    >
                      RESEND OTP
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className="f-13 fw-400"
                      color={theme.palette.common.black}
                    >
                      in
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className="f-13 fw-400"
                      color={theme.palette.secondary.main}
                    >
                      {seconds} sec
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  container
                  justifyContent={"center"}
                  spacing={0.6}
                  flexWrap={"nowrap"}
                >
                  <Grid item>
                    <CustomButton
                      text={"RESEND OTP"}
                      variant={"text"}
                      color={"primary"}
                      showIcon={false}
                      // startIcon={Cancel}
                      onClick={handleResendOtp}
                      loading={isOtpRequestLoading}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid
                item
                container
                justifyContent={"center"}
                className={"mt-50"}
              >
                <CustomButton
                  onClick={handleVerify}
                  text={"PROCEED"}
                  variant={"text"}
                  color={"primary"}
                  className={"fw-500 f-20 mt-30"}
                  loading={isRequestLoading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OtpScreen;
