import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid/Grid";
import { useDispatch, useSelector } from "react-redux";

import MobileNumberHeader from "./MobileNumberHeader";
import MobileHeader from "./MobileHeader";
import {
  changeMobileNumber,
  fetchPoliciesForMobileNumber,
  generateOtpForMobileRequest,
  isGenerateOtpForMobileLoading,
  isUpdateMobileNumbersLoading,
  removeMobileNumber,
  selectIsMobileNoVerifyLoading,
  selectIsPolicyMobileNumbersLoading,
  selectMobileNoChangesList,
  selectPoliciesForMobileNumbers,
  setMobileNoChangesList,
  updateMobileNoRequest,
  verifyMobileNoRequest,
} from "./MobileNumber.slice";
import { useNavigate } from "react-router-dom";
import { setAlertInfo } from "../Common/Common.slice";
import config from "../../../common/config";
import {
  fetchOtpRequest,
  selectIsOtpRequestLoading,
  selectIsUserLoggedIn,
} from "../../Auth/auth.slice";
import { APP_ROUTES } from "../../../common/constants/Routes";
import { SELECT_POLICY_TYPES_FOR_MODULES } from "../../../common/Enums";
import MobileEntryModal from "../Common/MobileEntryModal";
import OtpEntryModal from "../Common/OtpEntryModal";
import { DoneModal } from "../Common/DoneModal";
import ChangeNumberModal from "./Modals/ChangeNumberModal";
import RemoveNumberModal from "./Modals/RemoveNumberModal";
import Spinner from "../../../common/ui/Spinner";
import ConfirmationModal from "../../../common/ui/Modal/ConfirmationModal";

const MobileNumberScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDoneModal, setShowDoneModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showRemoveNumberModal, setShowRemoveNumberModal] = useState(false);
  const [showChangeNumberModal, setShowChangeNumberModal] = useState(false);
  const [selectedPolicyDetails, setSelectedPolicyDetails] = useState({});
  const [selectedMobileNo, setSelectedMobileNo] = useState(false);
  const [newMobileNoData, setNewMobileNoData] = useState<any>({});
  const [verifyMobileDuration, setVerifyMobileDuration] = useState(0);
  const [isInstantVerifyRunning, setIsInstantVerifyRunning] = useState(false);
  const [removeUnAssignedMobileNo, setRemoveUnAssignedMobileNo] =
    useState(false);

  const [isPreSelectedPolicyType, setIsPreSelectedPolicyType] =
    useState<any>(false);

  const isUpdateMobileLoading = useSelector(isUpdateMobileNumbersLoading);
  const isMobileGenerateOtpLoading = useSelector(isGenerateOtpForMobileLoading);
  const isMobileVerifyLoading = useSelector(selectIsMobileNoVerifyLoading);
  const mobileChangesList = useSelector(selectMobileNoChangesList);

  const toggleAddNumberModal = (
    isPoliciesPreSelected: boolean,
    isChange: boolean
  ) => {
    if (isPoliciesPreSelected === true) {
      setIsPreSelectedPolicyType(isChange ? "CHANGE" : "REMOVE");
    } else {
      setIsPreSelectedPolicyType(false);
    }
    setShowAddModal(!showAddModal);
    setShowRemoveNumberModal(false);
    setShowChangeNumberModal(false);
  };
  const toggleDoneModal = () => {
    if (mobileChangesList?.length > 0) {
      setShowDoneModal(!showDoneModal);
    } else {
      navigate(-1);
    }
    return true;
  };

  const toggleRemoveNumberModal = (mobile: string) => {
    setSelectedMobileNo(mobile as any);
    setShowRemoveNumberModal(!showRemoveNumberModal);
  };

  const toggleChangeNumberModal = (data: any) => {
    setSelectedPolicyDetails(data);
    setShowChangeNumberModal(!showChangeNumberModal);
  };

  const closeAllModals = () => {
    setShowAddModal(false);
    setShowDoneModal(false);
    setShowOtpModal(false);
    setShowRemoveNumberModal(false);
    setShowChangeNumberModal(false);
  };

  const isPoliciesForMobileNumbersLoading = useSelector(
    selectIsPolicyMobileNumbersLoading
  );

  const policiesForMobileNumber = useSelector(selectPoliciesForMobileNumbers);

  useEffect(() => {
    if (mobileChangesList?.length === 0)
      dispatch(fetchPoliciesForMobileNumber());
  }, [dispatch, mobileChangesList]);

  const handleMobileNoChangesSubmit = () => {
    const handleSuccess: any = () => {
      setShowDoneModal(false);
      navigate(-1);
    };
    dispatch(updateMobileNoRequest({ handleSuccess } as any));
  };

  const handleOtpVerify = (otpCode: any) => {
    const { handleSuccess, ...newMobileNoDataLocal }: any = newMobileNoData;
    let data: any = { otpCode, mobileNo: newMobileNoDataLocal?.mobileNo };
    data.handleSuccess = () => {
      setShowAddModal(false);
      if (isPreSelectedPolicyType === "CHANGE") {
        setShowOtpModal(false);
        setIsPreSelectedPolicyType(false);
        dispatch(
          changeMobileNumber({
            selectedPolicy: selectedPolicyDetails,
            targetMobileNo: newMobileNoDataLocal?.mobileNo,
          })
        );
      } else if (isPreSelectedPolicyType === "REMOVE") {
        setShowOtpModal(false);
        dispatch(
          removeMobileNumber({
            newValue: newMobileNoDataLocal?.mobileNo,
            oldValue: selectedMobileNo,
          })
        );
      } else {
        setShowAddModal(false);
        if (isInstantVerifyRunning !== true) {
          navigate(APP_ROUTES.ASSIGN_POLICY, {
            state: {
              newMobileNoData: newMobileNoDataLocal,
              MODULE_TYPE: SELECT_POLICY_TYPES_FOR_MODULES.MOBILE,
            },
          });
          setIsInstantVerifyRunning(false);
        }
        setShowOtpModal(false);
      }
    };
    dispatch(verifyMobileNoRequest(data));
  };

  const handleNewMobileSubmit = (data: any) => {
    if (policiesForMobileNumber?.unAssignedMobiles?.length <= 5) {
      setNewMobileNoData(data);
      data.handleSuccess = () => {
        setShowAddModal(false);
        setShowOtpModal(true);
        setVerifyMobileDuration(config.otpDuration);
      };
      dispatch(generateOtpForMobileRequest(data));
    } else {
      setShowAddModal(false);
      dispatch(
        setAlertInfo({
          open: true,
          type: "FAILED",
          description: "Only 5 unassigned mobile numbers are allowed.",
        })
      );
    }
  };

  const handleInstantVerify = (mobileNo: string) => {
    setIsInstantVerifyRunning(true);
    closeAllModals();
    setNewMobileNoData({ mobileNo, mobileCountryCode: "+91" });
    setShowOtpModal(true);
    dispatch(
      generateOtpForMobileRequest({ mobileNo, mobileCountryCode: "+91" } as any)
    );
    setVerifyMobileDuration(config.otpDuration);
  };

  const handleCancel = () => {
    navigate(-1);
    dispatch(setMobileNoChangesList([]));
  };

  const loggedUser = useSelector(selectIsUserLoggedIn);
  const isResendOtpLoading = useSelector(selectIsOtpRequestLoading);

  const handleResendOtp = () => {
    const handleSuccess = () => {
      setVerifyMobileDuration(config.otpDuration);
    };
    let data: any = {
      eiaNo: loggedUser?.eiaNo,
      value: newMobileNoData?.mobileNo,
      otpPurpose: "UPDATE_MOBILE",
      flag: "MOBILE",
    };
    dispatch(
      fetchOtpRequest({
        Customer: data,
        handleSuccess,
      })
    );
  };

  const handleRemoveForUnAssignedMobiles = () => {
    const handleDone = () => {
      setRemoveUnAssignedMobileNo(false);
    };
    let payload = {
      oldValue: removeUnAssignedMobileNo,
      isUnAssignedMobile: true,
      handleDone,
    };
    dispatch(removeMobileNumber(payload));
  };

  const handleAssignPolicy = (data: any) => {
    navigate(APP_ROUTES.ASSIGN_POLICY, {
      state: {
        newMobileNoData: data,
        MODULE_TYPE: SELECT_POLICY_TYPES_FOR_MODULES.MOBILE,
        isOld: true,
      },
    });
  };
  return (
    <Grid item className="ml-50 mr-50 mb-50">
      <Grid item>
        <MobileHeader
          toggleAddNumberModal={toggleAddNumberModal}
          toggleDoneModal={toggleDoneModal}
        />
      </Grid>
      <Grid item>
        {isPoliciesForMobileNumbersLoading ? (
          <Grid container justifyContent={"center"}>
            <Grid item>
              <Spinner />
            </Grid>
          </Grid>
        ) : (
          <MobileNumberHeader
            toggleChangeNumberModal={toggleChangeNumberModal}
            toggleRemoveNumberModal={toggleRemoveNumberModal}
            handleAssignPolicy={handleAssignPolicy}
            handleRemoveForUnAssignedMobiles={handleRemoveForUnAssignedMobiles}
            handleInstantVerify={handleInstantVerify}
          />
        )}
      </Grid>
      <MobileEntryModal
        open={showAddModal}
        setOpen={setShowAddModal}
        onSubmit={handleNewMobileSubmit}
        handleCancel={closeAllModals}
        textLabel={"Mobile"}
        loading={isMobileGenerateOtpLoading}
      />
      <OtpEntryModal
        open={showOtpModal}
        setOpen={setShowOtpModal}
        onSubmit={handleOtpVerify}
        value={newMobileNoData?.mobileNo}
        isEmail={false}
        duration={verifyMobileDuration}
        onFinishDuration={() => setVerifyMobileDuration(0)}
        loading={isMobileVerifyLoading}
        resendLoading={isResendOtpLoading}
        handleResendOtp={handleResendOtp}
      />
      <DoneModal
        open={showDoneModal}
        setOpen={setShowDoneModal}
        onSubmit={handleMobileNoChangesSubmit}
        loading={isUpdateMobileLoading}
        onCancel={handleCancel}
        contentType={"MOBILE"}
      />
      <ChangeNumberModal
        open={showChangeNumberModal}
        setOpen={setShowChangeNumberModal}
        selectedPolicyDetails={selectedPolicyDetails}
        toggleAddNumberModal={toggleAddNumberModal}
      />
      <RemoveNumberModal
        open={showRemoveNumberModal}
        setOpen={setShowRemoveNumberModal}
        selectedMobileNo={selectedMobileNo}
        toggleAddNumberModal={toggleAddNumberModal}
      />
      <ConfirmationModal
        show={Boolean(removeUnAssignedMobileNo)}
        setShow={setRemoveUnAssignedMobileNo}
        description={`Are you sure to remove ${removeUnAssignedMobileNo} from unassigned mobiles?`}
        confirmText={"Remove"}
        cancelText={"Cancel"}
        onConfirm={handleRemoveForUnAssignedMobiles}
        onCancel={() => setRemoveUnAssignedMobileNo(false)}
      />
    </Grid>
  );
};

export default MobileNumberScreen;
