import { Grid, IconButton, Typography, Input } from '@mui/material';
import CustomButton from '../../../common/ui/CustomButton';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import { ReactComponent as MobileIcon } from './../../../common/icons/mobile-icon.svg';
import { ReactComponent as PanCardIcon } from './../../../common/icons/pan_card_icon.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/left_arrow-icon.svg';
import { ReactComponent as LabelCalendarIcon } from '../../../common/icons/login_calendar_label-icon.svg';
import React, { useState } from 'react';
import './SignUp.css';
import SvgConverter from '../../../common/SvgConverter';
import { Link, useNavigate } from 'react-router-dom';
import { AUTH_ROUTES } from '../../../common/constants/Routes';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import DatePickerModal from '../../../common/ui/DatePickerModal';
import { useForm } from 'react-hook-form';
import data from '../../../common/constants/FormConstants.json';
import FormCheckBox from '../../../common/ui/Forms/FormInput/CheckBox/FormCheckBox';
import theme from '../../../common/theme';
const SignUp = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    console.log(data);

    navigate('/signup/otp-verify');
  };
  const dobValue = watch('dob');
  console.log('dobValue', dobValue);
  const mobileNumberRegex = /^\d{10}$/;
  const handleDateChange = (date: any) => {
    setValue('dob', date, { shouldValidate: true });
  };
  const [isCheckedIndian, setIsCheckedIndian] = useState(false);
  const [isCheckedNRI, setIsCheckedNRI] = useState(false);
  const handleChangeIndian = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedIndian(event.target.checked);
    setIsCheckedNRI(false);
  };
  const handleChangeNRI = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedNRI(event.target.checked);
    setIsCheckedIndian(false);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        flexDirection={'column'}
        className={'h-100'}
        flexWrap={'nowrap'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Grid item lg={3} className='mt-30'>
          <Grid item className='mt-30 mb-5'>
            <IconButton onClick={() => navigate(-1)} className={'p-0'}>
              <SvgConverter Icon={ArrowLeftIcon} className='go-back-icon' />
            </IconButton>
          </Grid>
          <Grid container item flex={1} alignItems={'center'}>
            <Grid item container className={'w-100 mt-30'}>
              <Grid item xs={12} className={''}>
                <Typography className={'f-20 fw-600'}>
                  Enter details for Sign Up
                </Typography>
              </Grid>
              <Grid item xs={12} className={'py-10'}>
                <Grid container>
                  <Grid item xs={6}>
                    <FormCheckBox
                      label={
                        <Typography
                          className='f-14  '
                          color={theme.palette.common.black}
                        >
                          Resident Indian
                        </Typography>
                      }
                      checked={isCheckedIndian}
                      onChange={handleChangeIndian}
                      size={16}
                      checkBoxPadding={1}
                      CheckBoxClassName={'checkbox'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormCheckBox
                      label={
                        <Typography
                          className='f-14  '
                          color={theme.palette.common.black}
                        >
                          NRI
                        </Typography>
                      }
                      checked={isCheckedNRI}
                      onChange={handleChangeNRI}
                      size={16}
                      checkBoxPadding={1}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className='mb-15 w-100'>
                <LabelWithIcon
                  Icon={PanCardIcon}
                  label={'PAN Number'}
                  justifyContent={'flex-start'}
                  className={'mb-3 f-16'}
                />
                <FormInput
                  type='text'
                  className='pan-input'
                  placeholder='ABCDF2340X'
                  inputProps={{
                    ...register('panNumber', {
                      required: {
                        value: true,
                        message: data.PANCARD,
                      },
                    }),
                  }}
                  errors={errors}
                />
              </Grid>
              <Grid item className='mb-15 w-100'>
                <LabelWithIcon
                  Icon={MobileIcon}
                  label={'Mobile Number'}
                  justifyContent={'flex-start'}
                  className={'mb-3'}
                />
                <Grid container flexWrap={'nowrap'}>
                  <Grid item sm={3}>
                    <FormInput
                      inputProps={{
                        style: { textAlign: 'center' },
                      }}
                      type='number'
                      placeholder='+91'
                      hideCountButton={true}
                      className='mobile-pin'
                    />
                  </Grid>
                  <Grid item className='mr-46'>
                    <FormInput
                      type='number'
                      placeholder='1234567890'
                      hideCountButton={true}
                      className='  mobile-number'
                      inputProps={{
                        ...register('mobileNumber', {
                          required: data.MOBILENUMBER,
                          maxLength: {
                            value: 10,
                            message: data.MOBILEDIGIT,
                          },
                          pattern: {
                            value: mobileNumberRegex,
                            message: 'Invalid mobile number',
                          },
                        }),
                      }}
                      errors={errors}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {isCheckedNRI && (
                <Grid item className='mb-15 w-100'>
                  <LabelWithIcon
                    Icon={PanCardIcon}
                    label={'Email'}
                    justifyContent={'flex-start'}
                    className={'mb-3'}
                  />
                  <FormInput
                    type='text'
                    placeholder='email@website.com'
                    inputProps={{
                      ...register('email', {
                        required: {
                          value: true,
                          message: data.EMAIL,
                        },
                      }),
                    }}
                    errors={errors}
                  />
                </Grid>
              )}

              <Grid container item xs={12} flexDirection={'column'}>
                <Grid item className='my-3'>
                  <LabelWithIcon
                    Icon={LabelCalendarIcon}
                    label={'Date of Birth'}
                    iconProps={{ height: 33 }}
                    justifyContent={'flex-start'}
                  />
                </Grid>
                <Grid
                  item
                  container
                  flexDirection={'row'}
                  alignItems={'center'}
                  flexWrap={'nowrap'}
                >
                  <Grid item sm={2}>
                    <FormInput
                      inputProps={{
                        style: { textAlign: 'center' },
                        value: dobValue ? dobValue?.format('DD') : '',
                      }}
                      placeholder={'DD'}
                      className='date-month-input'
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <FormInput
                      inputProps={{
                        style: { textAlign: 'center' },
                        value: dobValue ? dobValue?.format('MM') : '',
                      }}
                      placeholder={'MM'}
                      className='date-month-input ml-15'
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <FormInput
                      inputProps={{
                        style: { textAlign: 'center' },
                        value: dobValue ? dobValue?.format('YYYY') : '',
                      }}
                      placeholder={'YYYY'}
                      className='signup-year-input'
                    />
                  </Grid>

                  <Grid item>
                    <DatePickerModal
                      iconType={'primary'}
                      onChange={handleDateChange}
                    />
                  </Grid>
                </Grid>
                <FormInput
                  hidden={true}
                  inputProps={{
                    ...register('dob', {
                      required: {
                        value: true,
                        message: data.DOB,
                      },
                    }),
                  }}
                  errors={errors}
                />
              </Grid>
              <Grid
                item
                container
                justifyContent={'center'}
                className={'my-50 mr-35'}
              >
                <CustomButton
                  text={'PROCEED'}
                  variant={'text'}
                  color={'primary'}
                  type={'submit'}
                  fontSize={20}
                  fontWeight={600}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default SignUp;
