import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { APP_ROUTES } from "../common/constants/Routes";
// import PageNotFound from '../common/ui/PageNotFound';
import DashboardScreen from "../features/App/DashboardScreen/DashboardScreen";
import AddPolicyScreen from "../features/App/PolicyScreen/AddPolicy/AddPolicyScreen";
import PolicyScreen from "../features/App/PolicyScreen/PolicyScreen";
import AddFamilyMember from "../features/App/Profile/AddFamilyMember/AddFamilyMember";
import AppLayout from "./AppLayout";
import WithBreadCrumpsScreen from "./components/WithBreadCrumpsScreen";
import ProfileScreen from "../features/App/Profile/ProfileScreen";
import NeedMoney from "../features/App/LoanModule/NeedMoney";
import MyOptionScreen from "../features/App/LoanModule/LoanOptionScreen";
import AddWitnessDetails from "../features/App/LoanModule/AddWitnessDetails";
import RecommendedCombinationSelected from "../features/App/LoanModule/RecommendedCombinationSelected";
import SubmitLoanApplication from "../features/App/LoanModule/SubmitLoanApplication";
import TrackTransactionScreen from "../features/App/Loan/TrackTransaction/TrackTransactionScreen";
import PaymentScreen from "../features/App/Payment/PaymentScreen";
import AssignPolicyScreen from "../features/App/Common/AssignPoilicy/AssignPolicyScreen";
import PolicyDetails from "../features/App/PolicyScreen/common/PolicyDetails/PolicyDetails";
import MobileNumberScreen from "../features/App/MobileNumber/MobileNumberScreen";
import ManageEmailScreen from "../features/App/Email/ManageEmailScreen";
import NomineeScreen from "../features/App/Nominee/NomineeScreen";
import PaymentFailed from "../features/App/Payment/PaymentFeedbacks/PaymentFailed";
import SecurityTips from "../features/App/Legal/SecurityTips";
import PrivacyPolicyScreen from "../features/App/Legal/PrivacyPolicyScreen";
import DisclaimerScreen from "../features/App/Legal/DisclaimerScreen";
import DataProtectionScreen from "../features/App/Legal/DataProtectionScreen";
import ManageBankScreen from "../features/App/BankModule/ManageBankScreen";
import ManageAddressScreen from "../features/App/AddressModule/ManageAddressScreen";
import EditNominee from "../features/App/Nominee/NomineeComponents/EditNominee";
import PaymentFeedbacks from "../features/App/Payment/PaymentFeedbacks/PaymentFeedbacks";
import AuthorizedRepresentativeScreen from "../features/Auth/SignUp/AuthorizedRepresentativeScreen";

const AppRoutes = ({ isLogged }: any) => {
  return (
    <Routes>
      {isLogged === true ? (
        <Route path={"/"} element={<AppLayout />}>
          <Route path={APP_ROUTES.DASHBOARD} element={<DashboardScreen />} />
          <Route
            path={APP_ROUTES.PROFILE}
            element={
              <WithBreadCrumpsScreen
                ScreenComponent={ProfileScreen}
                HeaderComponent={ProfileScreen.Header}
              />
            }
          />
          <Route
            path={APP_ROUTES.UPDATE_MOBILE}
            element={<MobileNumberScreen />}
          />
          <Route path={APP_ROUTES.POLICY} element={<PolicyScreen />} />
          <Route path={APP_ROUTES.ADDPOLICY} element={<AddPolicyScreen />} />
          <Route path={APP_ROUTES.POLICY_DETAILS} element={<PolicyDetails />} />
          <Route
            path={APP_ROUTES.ASSIGN_POLICY}
            element={<AssignPolicyScreen />}
          />
          <Route
            path={APP_ROUTES.TRACK_TRANSACTION}
            element={<TrackTransactionScreen />}
          />
          <Route
            path={APP_ROUTES.ADD_FAMILY_SCREEN}
            element={<AddFamilyMember />}
          />
          <Route path={APP_ROUTES.NEED_MONEY} element={<NeedMoney />} />
          <Route path={APP_ROUTES.MY_OPTIONS} element={<MyOptionScreen />} />
          <Route
            path={APP_ROUTES.ADD_WITNESS_DETAILS}
            element={<AddWitnessDetails />}
          />
          <Route
            path={APP_ROUTES.RECOMMENDED_SELECTED}
            element={<RecommendedCombinationSelected />}
          />
          <Route
            path={APP_ROUTES.SUBMIT_LOAN_APPLICATION}
            element={<SubmitLoanApplication />}
          />
          <Route
            path={APP_ROUTES.PREMIUM_PAYMENT}
            element={<PaymentScreen />}
          />

          <Route path={APP_ROUTES.EMAIL} element={<ManageEmailScreen />} />
          <Route
            path={APP_ROUTES.MANAGE_ADDRESS}
            element={<ManageAddressScreen />}
          />
          <Route path={APP_ROUTES.BANK} element={<ManageBankScreen />} />
          <Route path={APP_ROUTES.NOMINEE} element={<NomineeScreen />} />
          <Route path={APP_ROUTES.EDITNOMINEE} element={<EditNominee />} />
          <Route path={APP_ROUTES.PAYMENTPAGE} element={<PaymentFailed />} />
          {/* <Route path={APP_ROUTES.LEGAL} element={<LegalScreen />} /> */}
          <Route
            path={APP_ROUTES.PRIVACYPOLICY}
            element={<PrivacyPolicyScreen />}
          />
          <Route path={APP_ROUTES.DISCLAIMER} element={<DisclaimerScreen />} />
          <Route
            path={APP_ROUTES.DATAPROTECTIONPOLICY}
            element={<DataProtectionScreen />}
          />
          <Route path={APP_ROUTES.SECURITYTIPS} element={<SecurityTips />} />
          <Route
            path={"/payment-feedback/:paymentStatus"}
            element={<PaymentFeedbacks />}
          />
          <Route
            path={APP_ROUTES.AUTHORISED_REPRESENTATIVE}
            element={<AuthorizedRepresentativeScreen />}
          />
          <Route path={"*"} element={<Navigate to={APP_ROUTES.DASHBOARD} />} />
        </Route>
      ) : (
        <></>
      )}
    </Routes>
  );
};

export default AppRoutes;
