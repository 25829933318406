import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import CustomButton from "../../../../common/ui/CustomButton";

const BankDetailsHeader = ({
  data,
  index,
  onRemoveClick,
  isUnVerified = false,
  onVerifyNow,
  showAssignButton,
  handleAssignPolicy,
}: any) => {
  const theme = useTheme();

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        className="mb-10"
      >
        <Grid item>
          <Grid container spacing={1}>
            <Grid item className="ml-n20">
              <Typography color="black" fontSize={20} fontWeight={600}>
                {index + 1}.
              </Typography>
            </Grid>
            <Grid item className="mt-3">
              <Typography color="primary" fontSize={16} fontWeight={600}>
                {data.bankName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <CustomButton
            text={"REMOVE"}
            variant={"text"}
            fontSize={14}
            fontWeight={400}
            showIcon={false}
            onClick={onRemoveClick}
            color={"primary"}
          />
        </Grid>
      </Grid>
      <Grid
        flexWrap={"nowrap"}
        container
        justifyContent={"space-between"}
        className="mb-15"
      >
        <Grid item>
          <Grid item>
            <Typography className="f-16" color={theme.palette.common.black}>
              {data.accountHolderName}
            </Typography>

            <Typography className="f-14" color={theme.palette.common.black}>
              Acc# {data.bankAcNo}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="f-14" color={theme.palette.common.black}>
              {data.bankName}
            </Typography>
            <Typography className="f-14" color={theme.palette.common.black}>
              {data.bankBranch}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="f-14" color={theme.palette.common.black}>
              {data.bankIfscCode}
            </Typography>
          </Grid>
        </Grid>
        <Grid item className="mt-10">
          <img src={data?.bankLogo} alt="logo" width={"51"} height={"32"} />
        </Grid>
      </Grid>
      {isUnVerified === true ? (
        <CustomButton
          text={"Verify now"}
          variant={"text"}
          fontSize={14}
          fontWeight={400}
          showIcon={false}
          onClick={onVerifyNow}
          color={"primary"}
        />
      ) : (
        <Grid item className="mb-10">
          <Grid item className="">
            <img
              src={`data:image/png/jpg/jpeg;base64,${data?.chequeImage}`}
              alt="cheque"
              width={"51"}
              height={"32"}
            />
          </Grid>
        </Grid>
      )}
      {showAssignButton && (
        <CustomButton
          text={"ASSIGN POLICIES"}
          variant={"text"}
          fontSize={14}
          fontWeight={400}
          showIcon={false}
          onClick={handleAssignPolicy}
          color={"primary"}
        />
      )}
    </div>
  );
};

export default BankDetailsHeader;
