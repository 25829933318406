import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as checked } from '../../../common/icons/profile/checked.svg';
import { ReactComponent as questionmark } from '../../../common/icons/profile/question-mark.svg';

const VerifiedText = ({
  isVerified,
  label,
}: {
  isVerified: boolean;
  label: string;
}) => {
  const theme = useTheme();

  return (
    <Grid item container spacing={0.5} flexWrap={'nowrap'}>
      <Grid item>
        {isVerified && (
          <SvgConverter
            Icon={checked}
            width={'11px'}
            height={'8px'}
            className='mb-4'
          />
        )}
        {!isVerified && (
          <SvgConverter
            Icon={questionmark}
            width={'8px'}
            height={'12px'}
            className='mb-4'
          />
        )}
      </Grid>
      <Grid item>
        <Typography className='f-12 ' color={isVerified ? theme.palette.success.main : theme.palette.secondary.main}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default VerifiedText;
