import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as alert } from '../../../common/icons/profile/alert.svg';

const NotVerifiedEmail = () => {
  const theme = useTheme();
  return (
    <Grid item>
      <Grid item className='f-12 mt-2 ml-2' color={theme.palette.common.black}>
        <SvgConverter Icon={alert} />
      </Grid>
    </Grid>
  );
};

export default NotVerifiedEmail;
