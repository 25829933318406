import React, { useMemo } from "react";
import { Grid, Typography, useTheme } from "@mui/material";

import Separator from "../../../common/ui/Separator";
import CustomButton from "../../../common/ui/CustomButton";
import GeneratePolicyIcon from "../../../common/ui/GeneratePolicyIcon";
import { POLICY_TYPE_CODES, POLICY_TYPE_LABEL } from "../../../common/Enums";
import SvgConverter from "../../../common/SvgConverter";
import { ReactComponent as OrangeRuppee } from "../../../common/icons/profile/orange_ruppee_icon.svg";
import HdfcIcon from "../../../assets/images/HDFC.png";
import { convertAmount } from "../../../common/utils";

const PolicyCard = ({
  policyDetails = {},
  className,
  changeButtonText = "CHANGE ADDRESS",
  onChangeClick = () => false,
}: any) => {
  const theme = useTheme();
  const isAssetPolicy: boolean = useMemo(
    () =>
      String(policyDetails?.alternateCode).toUpperCase() ===
      POLICY_TYPE_CODES.ASSET,
    [policyDetails?.alternateCode]
  );

  return (
    <Grid item className={className}>
      <Grid container spacing={1.5} alignItems={"center"} className="mb-5">
        <Grid item>
          <GeneratePolicyIcon policyTypeCode={policyDetails?.alternateCode} />
        </Grid>
        <Grid item>
          <Typography color={theme.palette.primary.main} fontSize={14}>
            {POLICY_TYPE_LABEL[policyDetails?.alternateCode]}
          </Typography>
        </Grid>
      </Grid>
      <Grid item className="mb-10">
        <Separator color={theme.palette.primary.main} borderWidth={2} />
      </Grid>
      <Grid item>
        <Grid
          container
          flexWrap={"nowrap"}
          justifyContent={"space-between"}
          className="mb-15"
        >
          <Grid item>
            <Grid item>
              <Typography
                className="f-16 fw-600"
                color={theme.palette.common.black}
              >
                {policyDetails.planName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="f-14" color={theme.palette.common.black}>
                {isAssetPolicy ? "Vehicle Number" : "Life Assured"}
              </Typography>
              <Typography
                className="f-16 fw-600"
                color={theme.palette.common.black}
              >
                {policyDetails[isAssetPolicy ? "vehicleNo" : "customerName"]}
              </Typography>
            </Grid>
            {isAssetPolicy ? (
              <Grid item>
                <Typography className="f-14" color={theme.palette.common.black}>
                  IDV
                </Typography>
                <Typography
                  className="f-16 fw-600"
                  color={theme.palette.common.black}
                >
                  {convertAmount(policyDetails?.idv)}
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item className="mt-10">
            <img
              src={policyDetails?.companyLogo}
              alt="Logo"
              width={"100%"}
              height={50}
              style={{ objectFit: "contain" }}
            />
          </Grid>
        </Grid>
        <Grid container className="mt-n15">
          <Grid item sm={6}>
            <Grid item>
              <Typography className="f-14" color={theme.palette.common.black}>
                Sum Assured
              </Typography>
              <Grid container spacing={0.5} alignItems={"center"}>
                <Grid item>
                  <SvgConverter Icon={OrangeRuppee} className="mb-2" />
                </Grid>
                <Grid item>
                  <Typography
                    className="f-14 fw-600"
                    color={theme.palette.common.black}
                  >
                    {convertAmount(policyDetails?.sumAssured)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6}>
            <Grid item className="">
              <Typography className="f-14" color={theme.palette.common.black}>
                Premium
              </Typography>
              <Grid container spacing={0.5} alignItems={"center"}>
                <Grid item>
                  <SvgConverter Icon={OrangeRuppee} className="mb-2" />
                </Grid>
                <Grid item>
                  <Typography
                    className="f-14 fw-600"
                    color={theme.palette.common.black}
                  >
                    {convertAmount(policyDetails?.premiumAmount)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="mt-5">
        <CustomButton
          text={changeButtonText}
          variant={"text"}
          fontSize={14}
          fontWeight={400}
          showIcon={false}
          onClick={onChangeClick}
          color={"primary"}
        />
      </Grid>
    </Grid>
  );
};

export default PolicyCard;
