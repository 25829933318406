import { createSlice } from "@reduxjs/toolkit";
import { PolicyMobile } from "../Profile/types/Profile.model";
import * as R from "ramda";
import { LoadingState } from "../../../common";
import { MOBILE_EMAIL_CHANGE_ACTIONS } from "../../../common/Enums";
import {
  removeDuplicatedPoliciesForAssign,
  swapToTop,
} from "../../../common/utils";
import { RootState } from "../../../common/store";

interface MobileNumberState {
  policies: any;
  isLoading: LoadingState;
  errors: any | null;
  mobileNumbers: any[];
  isMobileNumbersLoading: LoadingState;
  errorMobileNumbers: any;
  removeMobileChanges: any;
  updateMobileNoChanges: { status: LoadingState; data: any; error: any };
  generateOtpForMobile: { status: LoadingState; data: any; error: any };
  verifyMobileNo: { status: LoadingState; data: any; error: any };
  mobileChangesList: any[];
}

const initialState: MobileNumberState = {
  isLoading: "idle",
  policies: { mobileBasedPolicies: [], unAssignedMobiles: [] },
  errors: null,
  isMobileNumbersLoading: "idle",
  mobileNumbers: [],
  errorMobileNumbers: null,
  removeMobileChanges: null,
  updateMobileNoChanges: {
    status: "idle",
    data: undefined,
    error: undefined,
  },
  generateOtpForMobile: {
    status: "idle",
    data: undefined,
    error: undefined,
  },
  verifyMobileNo: {
    status: "idle",
    data: undefined,
    error: undefined,
  },
  mobileChangesList: [],
};

const mobileNumberSlice = createSlice({
  name: "mobileNumber",
  initialState,
  reducers: {
    fetchPoliciesForMobileNumber: (state) => ({
      ...state,
      isLoading: "loading",
      errors: null,
      policies: { mobileBasedPolicies: [], unAssignedMobiles: [] },
    }),
    loadPoliciesForMobileNumber: (state, { payload }) => ({
      ...state,
      isLoading: "done",
      errors: null,
      policies: {
        mobileBasedPolicies: payload.MobileBasedPolicies,
        unAssignedMobiles: payload.UnassignedMobiles,
      },
    }),
    errorLoadingPoliciesForMobileNumber: (state, action) => ({
      ...state,
      isLoading: "done",
      errors: action.payload,
      policies: { mobileBasedPolicies: [], unAssignedMobiles: [] },
    }),
    fetchMobileNumbers: (state, _action) => ({
      ...state,
      isMobileNumbersLoading: "loading",
      mobileNumbers: [],
      errorMobileNumbers: null,
    }),
    loadMobileNumbers: (state, action) => ({
      ...state,
      isMobileNumbersLoading: "done",
      mobileNumbers: action.payload,
      errorMobileNumbers: null,
    }),
    errorLoadingMobileNumbers: (state, action) => ({
      ...state,
      isMobileNumbersLoading: "done",
      mobileNumbers: [],
      errorMobileNumbers: action.payload,
    }),

    removeMobileNumber: (state, action) => {
      const { newValue, oldValue, handleDone, isUnAssignedMobile } =
        action.payload;
      let changes: any = [];
      if (!isUnAssignedMobile) {
        const affectedPolicies = state.policies.mobileBasedPolicies.filter(
          ({ mobileNo }: any) => mobileNo === oldValue
        );
        const policies = assignPolicyToOtherNumber(
          state.policies.mobileBasedPolicies,
          newValue,
          oldValue
        );
        state.policies.mobileBasedPolicies = policies;
        changes = generateMobileChangesForAPI(
          affectedPolicies,
          newValue,
          oldValue,
          MOBILE_EMAIL_CHANGE_ACTIONS.REMOVE
        );
        // state.policies.unAssignedMobiles = [
        //   ...state.policies.unAssignedMobiles,
        //   {
        //     mobileCountryCode: '+91',
        //     mobileNo: oldValue,
        //     mobileNoVerified: 'Y',
        //   },
        // ];
      } else {
        changes = generateMobileChangesForAPI(
          [],
          newValue,
          oldValue,
          MOBILE_EMAIL_CHANGE_ACTIONS.REMOVE
        );
        state.policies.unAssignedMobiles = removeMobileFromUnAssignedMobiles(
          state.policies.unAssignedMobiles,
          oldValue
        );
      }

      state.mobileChangesList = [...state.mobileChangesList, changes];
      if (handleDone) {
        handleDone();
      }
    },

    changeMobileNumber: (state, action) => {
      const { selectedPolicy, targetMobileNo } = action.payload;
      const policies = changePolicyMobileNo(
        state.policies.mobileBasedPolicies,
        selectedPolicy,
        targetMobileNo
      );
      const { mobileNo } = selectedPolicy;
      const isMobileNoHavePolicy = policies.some(
        (policy) => policy.mobileNo === mobileNo
      );
      if (!isMobileNoHavePolicy && selectedPolicy?.mobileNo != "null") {
        state.policies.unAssignedMobiles = [
          ...state.policies.unAssignedMobiles,
          {
            mobileCountryCode: "+91",
            mobileNo,
            mobileNoVerified: "Y",
          },
        ];
      }
      state.policies.mobileBasedPolicies = policies;
      const changes = generateMobileChangesForAPI(
        [selectedPolicy],
        targetMobileNo,
        selectedPolicy.mobileNo,
        MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE
      );
      state.mobileChangesList = [...state.mobileChangesList, changes];
      state.policies.mobileBasedPolicies = swapToTop(
        state.policies.mobileBasedPolicies,
        changes.Policies.map(({ policyNo }) => policyNo),
        "policyNo"
      );
    },

    assignMobileNumber: (state, action) => {
      const { newMobileNo, selectedPolicies, handleDone, isOld } =
        action.payload;
      let policies = [];
      if (selectedPolicies?.length > 0) {
        policies = assignPolicyMobileNo(selectedPolicies, newMobileNo);
        const duplicateRemovedCurrentList = removeDuplicatedPoliciesForAssign(
          state.policies.mobileBasedPolicies,
          selectedPolicies
        );
        state.policies.mobileBasedPolicies = [
          ...duplicateRemovedCurrentList,
          ...policies,
        ];
      } else {
        if (!isOld) {
          state.policies.unAssignedMobiles = [
            ...state.policies.unAssignedMobiles,
            {
              mobileNo: newMobileNo,
              mobileNoVerified: "Y",
              mobileCountryCode: "+91",
            },
          ];
          state.policies.unAssignedMobiles = swapToTop(
            state.policies.unAssignedMobiles,
            [newMobileNo],
            "mobileNo"
          );
        } else {
          if (handleDone) {
            handleDone();
          }
          return state;
        }
      }

      const changes = generateMobileChangesForAPI(
        policies,
        newMobileNo,
        "null",
        MOBILE_EMAIL_CHANGE_ACTIONS.ADD
      );
      state.mobileChangesList = [...state.mobileChangesList, changes];
      state.policies.mobileBasedPolicies = swapToTop(
        state.policies.mobileBasedPolicies,
        changes.Policies.map(({ policyNo }) => policyNo),
        "policyNo"
      );
      if (handleDone) {
        handleDone();
      }
    },

    updateMobileNoRequest: (state) => {
      state.updateMobileNoChanges.status = "loading";
      state.updateMobileNoChanges.data = undefined;
      state.updateMobileNoChanges.error = undefined;
    },
    updateMobileNoSuccess: (state, { payload }) => {
      state.updateMobileNoChanges.status = "done";
      state.updateMobileNoChanges.data = payload;
      state.updateMobileNoChanges.error = undefined;
      state.mobileChangesList = [];
    },
    updateMobileNoError: (state, { payload }) => {
      state.updateMobileNoChanges.status = "error";
      state.updateMobileNoChanges.data = undefined;
      state.updateMobileNoChanges.error = payload;
    },

    generateOtpForMobileRequest: (state) => {
      state.generateOtpForMobile.status = "loading";
      state.generateOtpForMobile.data = undefined;
      state.generateOtpForMobile.error = undefined;
    },
    generateOtpForMobileSuccess: (state, { payload }) => {
      state.generateOtpForMobile.status = "done";
      state.generateOtpForMobile.data = payload;
      state.generateOtpForMobile.error = undefined;
    },
    generateOtpForMobileError: (state, { payload }) => {
      state.generateOtpForMobile.status = "error";
      state.generateOtpForMobile.data = undefined;
      state.generateOtpForMobile.error = payload;
    },

    verifyMobileNoRequest: (state) => {
      state.verifyMobileNo.status = "loading";
      state.verifyMobileNo.data = undefined;
      state.verifyMobileNo.error = undefined;
    },
    verifyMobileNoSuccess: (state, { payload }) => {
      state.verifyMobileNo.status = "done";
      state.verifyMobileNo.data = payload;
      state.verifyMobileNo.error = undefined;
    },
    verifyMobileNoError: (state, { payload }) => {
      state.verifyMobileNo.status = "error";
      state.verifyMobileNo.data = undefined;
      state.verifyMobileNo.error = payload;
    },
    setMobileNoChangesList: (state, { payload }) => {
      state.mobileChangesList = payload ? payload : [];
    },
  },
});

export const {
  fetchMobileNumbers,
  loadMobileNumbers,
  errorLoadingMobileNumbers,
  fetchPoliciesForMobileNumber,
  loadPoliciesForMobileNumber,
  errorLoadingPoliciesForMobileNumber,
  removeMobileNumber,
  changeMobileNumber,
  updateMobileNoRequest,
  updateMobileNoSuccess,
  updateMobileNoError,
  generateOtpForMobileRequest,
  generateOtpForMobileSuccess,
  generateOtpForMobileError,
  verifyMobileNoRequest,
  verifyMobileNoSuccess,
  verifyMobileNoError,
  assignMobileNumber,
  setMobileNoChangesList,
} = mobileNumberSlice.actions;

const mapAssignPolicy =
  (newValue: string, oldValue: string) => (policy: PolicyMobile) => {
    if (policy.mobileNo === oldValue) {
      policy.mobileNo = newValue;
    }
    return policy;
  };

const assignPolicyToOtherNumber = (
  policies: PolicyMobile[],
  newValue: string,
  oldValue: string
) => policies.map(mapAssignPolicy(newValue, oldValue));

const generateMobileChangesForAPI = (
  policies: PolicyMobile[],
  newValue: string,
  oldValue: string,
  action: string
) => {
  const policiesForPayload: any[] = policies.map(
    ({ companyCode, policyNo, alternateCode }: any) => ({
      insurerCode: companyCode,
      policyNo,
      alternateCode,
    })
  );

  return {
    action: oldValue == "null" ? MOBILE_EMAIL_CHANGE_ACTIONS.ADD : action, // For unsigned policies have to send as add action type
    mobileCountryCode: "+91",
    newValue: newValue ? newValue : "",
    oldValue: oldValue == "null" ? "" : oldValue,
    Policies: policiesForPayload,
    primaryFlag: policies?.[0]?.primaryFlag ? policies?.[0]?.primaryFlag : "",
  };
};

const changePolicyMobileNo = (
  policies: any[],
  selectedPolicy: any,
  targetMobileNo: string
) => {
  if (Array.isArray(policies)) {
    const selectedPolicyIndex = policies.findIndex(
      ({ mobileNo, policyNo }: any) =>
        mobileNo === selectedPolicy.mobileNo &&
        policyNo === selectedPolicy.policyNo
    );

    if (selectedPolicyIndex >= 0) {
      policies[selectedPolicyIndex].mobileNo = targetMobileNo;
      policies[selectedPolicyIndex].mobileNoVerified = "Y";
    }
  }
  return policies;
};

const assignPolicyMobileNo = (selectedPolicies: any[], newMobileNo: string) => {
  selectedPolicies = selectedPolicies.map((row: any) => ({
    ...row,
    mobileNo: newMobileNo,
    mobileNoVerified: "Y",
  }));
  return selectedPolicies;
};

const removeMobileFromUnAssignedMobiles = (
  policies: any[],
  targetMobileNo: string
) => R.reject(({ mobileNo }) => mobileNo === targetMobileNo, policies);

export const selectIsPolicyMobileNumbersLoading = (state: RootState) =>
  state.mobileNumber.isLoading === "loading";

export const selectPoliciesForMobileNumbers = (state: RootState) =>
  state.mobileNumber.policies;

export const selectErrorLoadingPolicies = (state: RootState) =>
  state.mobileNumber.errors;

export const selectIsMobileNumbersLoading = (state: RootState) =>
  state.mobileNumber.isMobileNumbersLoading === "loading";

export const selectMobileNumbers = (state: RootState) =>
  state.mobileNumber.mobileNumbers;

export const selectErrorLoadingMobileNumbers = (state: RootState) =>
  state.mobileNumber.errorMobileNumbers;

export const isUpdateMobileNumbersLoading = (state: RootState) =>
  state.mobileNumber.updateMobileNoChanges.status === "loading";

export const isGenerateOtpForMobileLoading = (state: RootState) =>
  state.mobileNumber.generateOtpForMobile.status === "loading";

export const selectIsMobileNoVerifyLoading = (state: RootState) =>
  state.mobileNumber.verifyMobileNo.status === "loading";

export const selectMobileNoChangesList = (state: RootState) =>
  state.mobileNumber.mobileChangesList;

export const selectUnAssignedMobileNumbers = (state: RootState) =>
  state.mobileNumber.policies.unAssignedMobiles;

export default mobileNumberSlice.reducer;

export const getMobileNoList = (
  selectedPolicyDetails: any,
  mobilePolicies: any
) => {
  let mobileNumbersLocal = [
    ...mobilePolicies.mobileBasedPolicies,
    ...mobilePolicies.unAssignedMobiles,
  ];
  mobileNumbersLocal = mobileNumbersLocal.map(({ mobileNo }) => mobileNo);
  mobileNumbersLocal = R.uniq(mobileNumbersLocal);
  mobileNumbersLocal = R.reject(
    (val) => val == "null" || val === selectedPolicyDetails?.mobileNo,
    mobileNumbersLocal
  );
  return mobileNumbersLocal;
};
