import { Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import SvgConverter from '../../../../common/SvgConverter';
import { ReactComponent as DividerIcon } from '../../../../common/icons/divider-icon.svg';
import AddFamilyMember from './AddFamilyMember';
import Separator from '../../../../common/ui/Separator';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFamilyRelationShipList,
  selectFamilyRelationShipList,
  selectIsFamilyRelationShipLoading,
} from '../Profile.slice';
import Spinner from '../../../../common/ui/Spinner';
import CustomButton from '../../../../common/ui/CustomButton';
import CustomPopOver from '../../../../common/ui/CustomPopOver';

const AddFamilyMemberContent = ({
  open,
  anchorEle,
  handleClosed,
}: // handleAddMember,
any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFamilyRelationShipList());
  }, []);
  const isRelationShipLoadingList = useSelector(
    selectIsFamilyRelationShipLoading
  );
  const relationShipList = useSelector(selectFamilyRelationShipList);
  const FamilyContent = [
    {
      title: 'Spouse',
      link: '/addfamilyscreen/',
    },
    {
      title: 'Son',
      link: '#',
    },
    {
      title: 'Daughter',
      link: '#',
    },
    {
      title: 'Father',
      link: '#',
    },
    {
      title: 'Mother',
      link: '#',
    },
  ];
  const Relations = [
    {
      relationCode: '01',
      relationship: 'Spouse',
    },
    {
      relationCode: '02',
      relationship: 'Daughter',
    },
  ];
  const handleAddMember = (data: any) => {
    navigate(APP_ROUTES.ADD_FAMILY_SCREEN, { state: { data } });
  };
  return (
    <CustomPopOver
      isOpen={open}
      anchorEl={anchorEle}
      onClose={handleClosed}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Grid className='modal'>
        {isRelationShipLoadingList ? (
          <Grid item>
            <Spinner />
          </Grid>
        ) : (
          relationShipList &&
          relationShipList.map((data, index) => (
            <Grid item key={index}>
              {/* <Link to={'/addfamilyscreen/' + `${data.relationship}`}> */}
              {/* <Link
                to={
                  {
                    pathname: APP_ROUTES.ADD_FAMILY_SCREEN,
                    state: { data: data },
                  } as any
                }
              >
                <Grid item className='px-15 py-12'>
                  <Typography
                    className='f-16 fw-600'
                    color={theme.palette.common.black}
                  >
                    {data.relationship}
                  </Typography>
                </Grid>
              </Link> */}
              <CustomButton
                text={`${data.relationship}`}
                variant={'text'}
                color='inherit'
                showIcon={false}
                className='f-16 fw-600 px-15 py-13'
                onClick={()=>handleAddMember(data)}
              />
              <Grid item className='w-100'>
                {index !== relationShipList.length - 1 && (
                  <Grid item className='w-100'>
                    <Separator color={theme.palette.primary.main} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          ))
        )}
      </Grid>
    </CustomPopOver>
  );
};

export default AddFamilyMemberContent;
