import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { APP_ROUTES, AUTH_ROUTES } from "../common/constants/Routes";
import { Login } from "../features/Auth/Login/Login";
import SignUp from "../features/Auth/SignUp/SignUp";
import OtpScreen from "../features/Auth/common/OtpScreen";
import AuthLayout from "./AuthLayout";
import VerificationStatusScreen from "../features/Auth/SignUp/VerificationStatusScreen";
import PageNotFound from "../common/ui/PageNotFound";
const AuthRoutes = ({ isLogged }: any) => {
  return (
    <Routes>
      {isLogged === false ? (
        <Route path={"/"} element={<AuthLayout />}>
          <Route path={AUTH_ROUTES.LOGIN} element={<Login />} />
          <Route path={AUTH_ROUTES.LOGIN_OTP_VERIFY} element={<OtpScreen />} />
          <Route path={AUTH_ROUTES.SIGNUP} element={<SignUp />} />
          <Route path={AUTH_ROUTES.SIGNUP_OTP_VERIFY} element={<OtpScreen />} />

          <Route
            path={AUTH_ROUTES.SIGNUP_VERIFICATION_STATUS}
            element={<VerificationStatusScreen />}
          />
          <Route path={"*"} element={<PageNotFound />} />
          {/* <Route path={"*"} element={<Navigate to={APP_ROUTES.DASHBOARD} />} /> */}
        </Route>
      ) : (
        <></>
      )}
    </Routes>
  );
};
export default AuthRoutes;
