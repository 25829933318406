import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginRequest, selectIsLoginRequestLoading } from '../auth.slice';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import { Grid, OutlinedInput, Typography, useTheme } from '@mui/material';
import CustomButton from '../../../common/ui/CustomButton';
import { ReactComponent as MobileIcon } from '../../../common/icons/login_mobile-icon.svg';
import { ReactComponent as PanIcon } from '../../../common/icons/login_PAN-icon.svg';
import { ReactComponent as EmailIcon } from '../../../common/icons/login_email-icon.svg';
import { ReactComponent as eIAIcon } from '../../../common/icons/login_EIA-icon.svg';
import { ReactComponent as LabelCalendarIcon } from '../../../common/icons/login_calendar_label-icon.svg';
import { Link, useNavigate } from 'react-router-dom';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import DatePickerModal from '../../../common/ui/DatePickerModal';
import { useForm } from 'react-hook-form';
import data from '../../../common/constants/FormConstants.json';
import config from '../../../common/config';
import './Login.scss';
export const LoginForm = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isRequestLoading = useSelector(selectIsLoginRequestLoading);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      userName: 'KIRPS0588A',
      dob: '01/06/1995',
      // userName: "9200000080507", // Don't use for development only for testing
      // dob: "24/07/1992",
    },
  });
  const navigate = useNavigate();

  const getLoginTypeByValue = (value: string) => {
    console.log(value);

    if (config.emailRegex.test(value)) {
      return 'EMAIL';
    } else if (config.PanRegex.test(value)) {
      return 'PAN';
    } else if (config.mobileRegex.test(value)) {
      return 'MOBILE';
    } else if (config.eiaRegex.test(value)) {
      return 'EIA';
    }
    return '';
  };

  const onSubmit = (data: any) => {
    const payload = {
      Customer: {
        value: data?.userName,
        dob: data?.dob,
        flag: getLoginTypeByValue(data?.userName),
      },
      navigate,
    };
    dispatch(fetchLoginRequest(payload));
  };

  const dobValue = String(watch('dob')).split('/');
  const handleDateChange = (date: any) => {
    setValue('dob', date?.format('DD/MM/YYYY'), { shouldValidate: true });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container justifyContent={'center'} flexDirection={'column'}>
        <Grid item container flexDirection={'column'}>
          <Grid
            item
            container
            spacing={1}
            alignItems={'end'}
            justifyContent={'center'}
          >
            <Grid item>
              <LabelWithIcon
                flexDirection={'column'}
                Icon={MobileIcon}
                label={'Mobile'}
                iconProps={{ height: 33 }}
                labelProps={{ color: theme.palette.common.white, fontSize: 16 }}
              />
            </Grid>
            <Grid item>
              <Typography color={theme.palette.divider} fontSize={14}>
                /
              </Typography>
            </Grid>
            <Grid item>
              <LabelWithIcon
                flexDirection={'column'}
                Icon={PanIcon}
                label={'PAN'}
                iconProps={{ height: 33 }}
                labelProps={{ color: theme.palette.common.white, fontSize: 16 }}
              />
            </Grid>
            <Grid item>
              <Typography color={theme.palette.divider} fontSize={14}>
                /
              </Typography>
            </Grid>
            <Grid item>
              <LabelWithIcon
                flexDirection={'column'}
                Icon={EmailIcon}
                label={'Email'}
                iconProps={{ height: 33 }}
                labelProps={{ color: theme.palette.common.white, fontSize: 16 }}
              />
            </Grid>
            <Grid item>
              <Typography color={theme.palette.divider} fontSize={14}>
                /
              </Typography>
            </Grid>
            <Grid item>
              <LabelWithIcon
                flexDirection={'column'}
                Icon={eIAIcon}
                label={'eIA#'}
                iconProps={{ height: 33 }}
                labelProps={{ color: theme.palette.common.white, fontSize: 16 }}
              />
            </Grid>
          </Grid>
          <Grid item className={'mt-9'}>
            <FormInput
              placeholder={'Enter Here...'}
              className='username-input common-login'
              invertBorder={true}
              backgroundColor={theme.palette.primary.main}
              inputProps={{
                className: 'text-center',
                ...register('userName', {
                  required: {
                    value: true,
                    message: data.USERNAME,
                  },
                  validate: (value) => {
                    if (
                      !config.emailRegex.test(value) &&
                      !config.mobileRegex.test(value) &&
                      !config.PanRegex.test(value) &&
                      !config.eiaRegex.test(value)
                    ) {
                      return data.INVALID_USER;
                    }
                    return true;
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <Grid item className=' mt-20 mb-5'>
            <LabelWithIcon
              Icon={LabelCalendarIcon}
              label={'Date of Birth'}
              iconProps={{ height: 33 }}
              labelProps={{ color: theme.palette.common.white, fontSize: 16 }}
              className={'dob-input'}
            />
          </Grid>
          <Grid
            item
            container
            flexDirection={'row'}
            spacing={2}
            alignItems={'center'}
            justifyContent={'space-between'}
            flexWrap={'nowrap'}
          >
            <Grid item sm={3}>
              <FormInput
                className='day-month-input common-login ml-35'
                invertBorder={true}
                backgroundColor={theme.palette.primary.main}
                inputProps={{
                  className: 'text-center',
                  value: dobValue?.[0],
                }}
                placeholder={'DD'}
                // disabled={true}
              />
            </Grid>
            <Grid item sm={3}>
              <FormInput
                className='day-month-input common-login ml-30'
                invertBorder={true}
                backgroundColor={theme.palette.primary.main}
                color={theme.palette.common.white}
                inputProps={{
                  className: 'text-center',
                  value: dobValue?.[1],
                }}
                placeholder={'MM'}
                // disabled={true}
              />
            </Grid>
            <Grid item sm={5}>
              <FormInput
                className='year-input common-login ml-28'
                invertBorder={true}
                backgroundColor={theme.palette.primary.main}
                inputProps={{
                  className: 'text-center',
                  value: dobValue?.[2],
                }}
                placeholder={'YYYY'}
                // disabled={true}
              />
            </Grid>
            <Grid item sm={1} style={{ marginLeft: '30px' }}>
              <DatePickerModal
                disableFuture={true}
                onChange={handleDateChange}
              />
            </Grid>
          </Grid>
          <FormInput
            hidden={true}
            inputProps={{
              ...register('dob', {
                required: {
                  value: true,
                  message: 'This field is mandatory!',
                },
              }),
            }}
            errors={errors}
          />{' '}
        </Grid>

        <Grid container justifyContent={'center'} className='mt-20 mb-38'>
          <Grid item>
            <CustomButton
              loading={isRequestLoading}
              text={'GET LOGIN OTP'}
              variant={'text'}
              iconClassName={'right-arrow'}
              type={'submit'}
              color={'secondary'}
              className={'f-20 mt-12 '}
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
