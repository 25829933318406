import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import BankDetailsHeader from "./common/BankDetailsHeader";
import { BANK_ADDRESS_EMAIL_MOBILE_REMOVE_TYPES } from "../../../common/Enums";

const UnverifiedBankScreen = ({
  bankList,
  handleRemoveBank,
  handleVerifyNow,
}: any) => {
  const theme = useTheme();

  return (
    <>
      <Grid item xs={12} className="my-10">
        <Grid item>
          <Typography
            className="f-16 fw-600 mb-10"
            color={theme.palette.error.main}
          >
            Unverified Bank Accounts
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={5} justifyContent={"space-between"}>
        {bankList.map((data: any, index: number) => (
          <Grid item key={index} xs={12} sm={6} md={3}>
            <BankDetailsHeader
              data={data}
              index={index}
              onRemoveClick={() =>
                handleRemoveBank(
                  data.accountNo,
                  BANK_ADDRESS_EMAIL_MOBILE_REMOVE_TYPES.UNVERIFIED
                )
              }
              isUnVerified={true}
              onVerifyNow={() =>
                handleVerifyNow(
                  data,
                  BANK_ADDRESS_EMAIL_MOBILE_REMOVE_TYPES.UNVERIFIED
                )
              }
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default UnverifiedBankScreen;
