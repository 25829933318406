import axios from "axios";
import { getIpAddress } from "../../../common/utils";

export const getRazorPayOrderId = async (data: any) => {
  const userIPaddress = await getIpAddress();
  return axios.post(
    `${process.env.REACT_APP_CAMSPAY_API_URL}/mobilepay/policystatus`,
    {
      userIPaddress,
      source: "mCAMSRep",
      serviceOption: "GenieReq-PAY-PREMIUM",
      username: process.env.REACT_APP_CAMSPAY_USER_NAME,
      password: process.env.REACT_APP_CAMSPAY_PASSWORD,
      ...data,
    }
  );
};

export const uploadPaymentDataEffect = async (data: any) => {
  const userIPaddress = await getIpAddress();
  return axios.post(
    `${process.env.REACT_APP_CAMSPAY_API_URL}/mobilepay/paymentdata`,
    {
      userIPaddress,
      source: "mCAMSRep",
      serviceOption: "GenieReq-PAY-PREMIUM",
      username: process.env.REACT_APP_CAMSPAY_USER_NAME,
      password: process.env.REACT_APP_CAMSPAY_PASSWORD,
      ...data,
    }
  );
};
