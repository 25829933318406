import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as ArrowIcon } from '../../../common/icons/LoanModule/arrow-icon.svg';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import Separator from '../../../common/ui/Separator';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { Link } from 'react-router-dom';

const LoanOptionHeader = () => {
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={1} className='mb-5'>
        <Grid item>
          {' '}
          <Link to={APP_ROUTES.NEED_MONEY}>
            <SvgConverter
              Icon={ArrowIcon}
              width={14}
              height={14}
              className='go-back-icon'
            />
          </Link>
        </Grid>
        <Grid item>
          <Typography className='f-16' color={theme.palette.primary.main}>
            Edit
          </Typography>
        </Grid>
      </Grid>
      <Grid item className='my-5 w-100px'>
        <Separator color={theme.palette.primary.main} />
      </Grid>
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <Grid item>
            <Typography className='f-14' color={theme.palette.common.black}>
              Total current fund value
            </Typography>
            <Typography className='f-10' color={theme.palette.grey.A700}>
              as per total value of the savings policies
            </Typography>
          </Grid>
          <Grid item className='mt-2' xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <SvgConverter
                  Icon={RupeeIcon}
                  width={8}
                  height={12}
                  className='mt-8'
                />
              </Grid>
              <Grid item>
                <Typography className='f-18' color={theme.palette.common.black}>
                  XXXXXXXX
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid item>
            <Typography className='f-14' color={theme.palette.common.black}>
              Total funds available without surrender
            </Typography>
            <Typography className='f-10' color={theme.palette.grey.A700}>
              as per partial withdrawal & loan eligibility criteria
            </Typography>
          </Grid>
          <Grid item className='mt-2' xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <SvgConverter
                  Icon={RupeeIcon}
                  width={8}
                  height={12}
                  className='mt-8'
                />
              </Grid>
              <Grid item>
                <Typography className='f-18' color={theme.palette.common.black}>
                  XXXXXXXX
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className='my-5'>
        <Typography className='f-16' color={theme.palette.primary.main}>
          My 0ptions
        </Typography>
      </Grid>
      <Grid item className='my-5 w-100px'>
        <Separator color={theme.palette.primary.main} />
      </Grid>
    </>
  );
};

export default LoanOptionHeader;
