import { createSlice } from "@reduxjs/toolkit";
import { PolicyEmail } from "../Profile/types/Profile.model";
import * as R from "ramda";
import { RootState } from "../../../common/store";
import { LoadingState } from "../../../common";
import { MOBILE_EMAIL_CHANGE_ACTIONS } from "../../../common/Enums";
import {
  removeDuplicatedPoliciesForAssign,
  swapToTop,
} from "../../../common/utils";

interface EmailsState {
  policies: any;
  isLoading: LoadingState;
  errors: any | null;
  isEmailsLoading: LoadingState;
  errorEmails: any;
  removeEmailChanges: any;
  generateOtpForEmail: { status: LoadingState; data: any; error: any };
  verifyEmail: { status: LoadingState; data: any; error: any };
  updateEmailChanges: { status: LoadingState; data: any; error: any };
  emailChangesList: any[];
}

const initialState: EmailsState = {
  isLoading: "idle",
  policies: { emailBasedPolicies: [], unAssignedEmails: [] },
  errors: null,
  isEmailsLoading: "idle",
  errorEmails: null,
  removeEmailChanges: null,
  generateOtpForEmail: {
    status: "idle",
    data: undefined,
    error: undefined,
  },
  verifyEmail: {
    status: "idle",
    data: undefined,
    error: undefined,
  },
  updateEmailChanges: {
    status: "idle",
    data: undefined,
    error: undefined,
  },
  emailChangesList: [],
};

const emailsSlice = createSlice({
  name: "emails",
  initialState,
  reducers: {
    fetchPoliciesForEmails: (state) => ({
      ...state,
      isLoading: "loading",
      errors: null,
      policies: { emailBasedPolicies: [], unAssignedEmails: [] },
    }),
    loadPoliciesForEmails: (state, { payload }) => ({
      ...state,
      isLoading: "done",
      errors: null,
      policies: {
        emailBasedPolicies: payload.EmailBasedPolicies,
        unAssignedEmails: payload.UnassignedEmails,
      },
    }),
    errorLoadingPoliciesForEmails: (state, action) => ({
      ...state,
      isLoading: "done",
      errors: action.payload,
      policies: { emailBasedPolicies: [], unAssignedEmails: [] },
    }),
    generateOtpForEmailRequest: (state) => {
      state.generateOtpForEmail.status = "loading";
      state.generateOtpForEmail.data = undefined;
      state.generateOtpForEmail.error = undefined;
    },
    generateOtpForEmailSuccess: (state, { payload }) => {
      state.generateOtpForEmail.status = "done";
      state.generateOtpForEmail.data = payload;
      state.generateOtpForEmail.error = undefined;
    },
    generateOtpForEmailError: (state, { payload }) => {
      state.generateOtpForEmail.status = "error";
      state.generateOtpForEmail.data = undefined;
      state.generateOtpForEmail.error = payload;
    },
    verifyEmailRequest: (state) => {
      state.verifyEmail.status = "loading";
      state.verifyEmail.data = undefined;
      state.verifyEmail.error = undefined;
    },
    verifyEmailSuccess: (state, { payload }) => {
      state.verifyEmail.status = "done";
      state.verifyEmail.data = payload;
      state.verifyEmail.error = undefined;
    },
    verifyEmailError: (state, { payload }) => {
      state.verifyEmail.status = "error";
      state.verifyEmail.data = undefined;
      state.verifyEmail.error = payload;
    },
    updateEmailChangesRequest: (state) => {
      state.updateEmailChanges.status = "loading";
      state.updateEmailChanges.data = undefined;
      state.updateEmailChanges.error = undefined;
    },
    updateEmailChangesSuccess: (state, { payload }) => {
      state.updateEmailChanges.status = "done";
      state.updateEmailChanges.data = payload;
      state.updateEmailChanges.error = undefined;
      state.emailChangesList = [];
    },
    updateEmailChangesError: (state, { payload }) => {
      state.updateEmailChanges.status = "error";
      state.updateEmailChanges.data = undefined;
      state.updateEmailChanges.error = payload;
    },
    changeEmail: (state, action) => {
      const { selectedPolicy, targetEmail } = action.payload;
      const policies = changePolicyEmail(
        state.policies.emailBasedPolicies,
        selectedPolicy,
        targetEmail
      );
      const { email } = selectedPolicy;
      const isEmailHavePolicy = policies.some(
        (policy) => policy.email === email
      );
      if (!isEmailHavePolicy && selectedPolicy?.email != "null") {
        state.policies.unAssignedEmails = [
          ...state.policies.unAssignedEmails,
          {
            email,
            emailVerified: "Y",
          },
        ];
      }
      state.policies.emailBasedPolicies = policies;
      const changes = generateEmailChangesForAPI(
        [selectedPolicy],
        targetEmail,
        selectedPolicy.email,
        MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE
      );

      state.emailChangesList = [...state.emailChangesList, changes];
      state.policies.emailBasedPolicies = swapToTop(
        state.policies.emailBasedPolicies,
        changes.Policies.map(({ policyNo }) => policyNo),
        "policyNo"
      );
      state.policies.unAssignedEmails = removeEmailFromUnAssignedEmails(
        state.policies.unAssignedEmails,
        targetEmail
      );
    },
    removeEmail: (state, action) => {
      const { newValue, oldValue, handleDone, isUnAssignedEmail } =
        action.payload;
      let changes: any = [];
      if (!isUnAssignedEmail) {
        const affectedPolicies = state.policies.emailBasedPolicies.filter(
          ({ email }: any) => email === oldValue
        );
        const policies = assignPolicyToOtherEmail(
          state.policies.emailBasedPolicies,
          newValue,
          oldValue
        );
        state.policies.emailBasedPolicies = policies;
        changes = generateEmailChangesForAPI(
          affectedPolicies,
          newValue,
          oldValue,
          MOBILE_EMAIL_CHANGE_ACTIONS.REMOVE
        );
      } else {
        changes = generateEmailChangesForAPI(
          [],
          newValue,
          oldValue,
          MOBILE_EMAIL_CHANGE_ACTIONS.REMOVE
        );
        state.policies.unAssignedEmails = removeEmailFromUnAssignedEmails(
          state.policies.unAssignedEmails,
          oldValue
        );
      }

      state.emailChangesList = [...state.emailChangesList, changes];
      if (handleDone) {
        handleDone();
      }
    },
    assignEmail: (state, action) => {
      const { newEmail, selectedPolicies, handleDone, isOld } = action.payload;
      let policies = [];
      if (selectedPolicies?.length > 0) {
        policies = assignPolicyEmail(selectedPolicies, newEmail);
        const duplicateRemovedCurrentList = removeDuplicatedPoliciesForAssign(
          state.policies.emailBasedPolicies,
          selectedPolicies
        );
        state.policies.emailBasedPolicies = [
          ...duplicateRemovedCurrentList,
          ...policies,
        ];
        state.policies.unAssignedEmails = removeEmailFromUnAssignedEmails(
          state.policies.unAssignedEmails,
          newEmail
        );
      } else {
        if (!isOld) {
          state.policies.unAssignedEmails = [
            ...state.policies.unAssignedEmails,
            {
              email: newEmail,
              emailVerified: "Y",
              mobileCountryCode: "+91",
            },
          ];
          state.policies.unAssignedEmails = swapToTop(
            state.policies.unAssignedEmails,
            [newEmail],
            "email"
          );
        } else {
          if (handleDone) {
            handleDone();
          }
          return state;
        }
      }

      const changes = generateEmailChangesForAPI(
        policies,
        newEmail,
        "null",
        MOBILE_EMAIL_CHANGE_ACTIONS.ADD
      );
      state.emailChangesList = [...state.emailChangesList, changes];
      state.policies.emailBasedPolicies = swapToTop(
        state.policies.emailBasedPolicies,
        changes.Policies.map(({ policyNo }) => policyNo),
        "policyNo"
      );
      if (handleDone) {
        handleDone();
      }
    },
    setEmailChangesList: (state, { payload }) => {
      state.emailChangesList = payload ? payload : [];
    },
  },
});

export const {
  fetchPoliciesForEmails,
  loadPoliciesForEmails,
  errorLoadingPoliciesForEmails,
  generateOtpForEmailRequest,
  generateOtpForEmailSuccess,
  generateOtpForEmailError,
  verifyEmailRequest,
  verifyEmailSuccess,
  verifyEmailError,
  updateEmailChangesRequest,
  updateEmailChangesSuccess,
  updateEmailChangesError,
  changeEmail,
  removeEmail,
  assignEmail,
  setEmailChangesList,
} = emailsSlice.actions;

const changePolicyEmail = (
  policies: PolicyEmail[],
  selectedPolicy: any,
  targetEmail: string
) => {
  const selectedPolicyIndex = policies.findIndex(
    ({ email, policyNo }: any) =>
      email === selectedPolicy.email && policyNo === selectedPolicy.policyNo
  );
  if (selectedPolicyIndex >= 0) {
    policies[selectedPolicyIndex].email = targetEmail;
    policies[selectedPolicyIndex].emailVerified = "Y";
  }
  return policies;
};

const mapAssignPolicy =
  (newValue: string, oldValue: string) => (policy: PolicyEmail) => {
    if (policy.email === oldValue) {
      policy.email = newValue;
    }
    return policy;
  };

const assignPolicyToOtherEmail = (
  policies: PolicyEmail[],
  newValue: string,
  oldValue: string
) => policies.map(mapAssignPolicy(newValue, oldValue));

const generateEmailChangesForAPI = (
  policies: PolicyEmail[],
  newValue: string,
  oldValue: string,
  action: string
) => {
  const policiesForPayload: any[] = policies.map(
    ({ companyCode, policyNo, alternateCode }: any) => ({
      insurerCode: companyCode,
      policyNo,
      alternateCode,
    })
  );

  return {
    action: oldValue == "null" ? MOBILE_EMAIL_CHANGE_ACTIONS.ADD : action, // For unsigned policies have to send as add action type
    newValue: newValue ? newValue : "",
    oldValue: oldValue == "null" ? "" : oldValue,
    Policies: policiesForPayload,
    primaryFlag: policies?.[0]?.primaryFlag ? policies?.[0]?.primaryFlag : "",
  };
};

const removeEmailFromUnAssignedEmails = (
  policies: any[],
  targetEmail: string
) => R.reject(({ email }) => email === targetEmail, policies);

const assignPolicyEmail = (selectedPolicies: any[], newEmail: string) => {
  selectedPolicies = selectedPolicies.map((row: any) => ({
    ...row,
    email: newEmail,
    emailVerified: "Y",
  }));
  return selectedPolicies;
};

export const selectIsPolicyEmailsLoading = (state: RootState) =>
  state.emails.isLoading === "loading";

export const selectPoliciesForEmails = (state: RootState) =>
  state.emails.policies;

export const selectErrorLoadingPolicies = (state: RootState) =>
  state.emails.errors;

export const selectIsEmailsLoading = (state: RootState) =>
  state.emails.isLoading === "loading";

export const selectErrorLoadingEmails = (state: RootState) =>
  state.emails.errorEmails;

export const isGenerateOtpForEmailLoading = (state: RootState) =>
  state.emails.generateOtpForEmail.status === "loading";

export const isUpdateEmailLoading = (state: RootState) =>
  state.emails.updateEmailChanges.status === "loading";

export const selectIsEmailVerifyLoading = (state: RootState) =>
  state.emails.verifyEmail.status === "loading";

export const selectEmailChangesList = (state: RootState) =>
  state.emails.emailChangesList;

export default emailsSlice.reducer;

export const getEmailList = (
  selectedPolicyDetails: any,
  emailBasedPolicies: any[],
  unAssignedEmails: any[]
) => {
  let data: any[] = R.reject(
    ({ email }: any) =>
      email === selectedPolicyDetails?.email || email == "null",
    emailBasedPolicies
  );
  data = [...data, ...(unAssignedEmails ? unAssignedEmails : [])];
  data = data ? data.map(({ email }) => email) : [];
  return R.uniq(data);
};
