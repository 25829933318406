import React from 'react';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import '../../TrackTransaction.scss';
import Separator from '../../../../../../common/ui/Separator';
import LabelWithIcon from '../../../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRupee } from '../../../../../../common/icons/orange_rupee.svg';
import theme from '../../../../../../common/theme';

const StepTwo = () => {
  return (
    <>
      <Grid container className='p-8 ' spacing={2}>
        <Grid item xs={5} textAlign={'right'}>
          <Typography color={theme.palette.primary.main} fontSize={14}>
            Maturity Value
          </Typography>
          <Typography color={theme.palette.primary.main} fontSize={14}>
            Fund Value
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign={'left'}>
          <LabelWithIcon
            svgClassName='mb-2'
            justifyContent={'flex-start'}
            Icon={OrangeRupee}
            label={'XXXXXX'}
            labelProps={{
              color: theme.palette.grey.A700,
              fontSize: 14,
              fontWeight: 600,
            }}
          />
          <LabelWithIcon
            svgClassName='mb-2'
            justifyContent={'flex-start'}
            Icon={OrangeRupee}
            label={'XXXXXX'}
            labelProps={{
              color: theme.palette.grey.A700,
              fontSize: 14,
              fontWeight: 600,
            }}
          />
        </Grid>
      </Grid>
      <Separator color={' #d7d7d7'} />
      <Grid item xs={12} textAlign={'center'}>
        <Typography color={theme.palette.common.black} fontSize={14}>
          Partial Withdrawal of{' '}
          <Typography
            component={'span'}
            fontSize={12}
            color={theme.palette.common.black}
          >
            [{' '}
            <Typography component={'span'} color={theme.palette.secondary.main}>
              xx
            </Typography>
            % of fund value]
          </Typography>
        </Typography>
      </Grid>
      <Grid container>
        <Grid item xs={4} className='ml-10' textAlign={'right'}>
          <LabelWithIcon
            svgClassName='mb-2 ml-2'
            justifyContent={'flex-start'}
            Icon={OrangeRupee}
            label={'XXXXXXX'}
            labelProps={{
              color: theme.palette.primary.main,
              fontSize: 16,
              fontWeight: 600,
            }}
          />
        </Grid>
        <Grid item xs={3} className='ml-5' textAlign={'left'}>
          <Typography
            color={theme.palette.common.black}
            fontSize={14}
            component={'span'}
          >
            initiated
          </Typography>
        </Grid>
      </Grid>
      <Grid container className='ml-10'>
        <Grid item xs={12}>
          <Typography
            color={theme.palette.common.black}
            fontSize={14}
            component={'span'}
          >
            Estimates processing Time
            <Typography
              component={'span'}
              color={theme.palette.secondary.main}
              className='ml-2'
            >
              6
            </Typography>
            <Typography
              component={'span'}
              className='ml-2'
              color={theme.palette.primary.main}
            >
              hours
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            color={theme.palette.common.black}
            fontSize={14}
            component={'span'}
            fontWeight={'bold'}
          >
            Time elapsed:
            <Typography
              component={'span'}
              color={theme.palette.secondary.main}
              className='ml-2'
            >
              0
            </Typography>
            <Typography
              component={'span'}
              className='ml-2'
              color={theme.palette.primary.main}
            >
              hours
            </Typography>
            <Typography
              component={'span'}
              color={theme.palette.secondary.main}
              className='ml-2'
            >
              1
            </Typography>
            <Typography
              component={'span'}
              className='ml-2'
              color={theme.palette.primary.main}
            >
              minute
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default StepTwo;
