import {
  ThunkAction,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { Action, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./root-saga";
import authReducer from "../features/Auth/auth.slice";
import policyReducer from "../features/App/PolicyScreen/Policies.slice";
import profileReducer from "../features/App/Profile/Profile.slice";
import mobileNumberReducer from "../features/App/MobileNumber/MobileNumber.slice";
import emailReducer from "../features/App/Email/Emails.slice";
import addressReducer from "../features/App/AddressModule/Address.slice";
import bankReducer from "../features/App/BankModule/Bank.slice";
import commonReducer from "../features/App/Common/Common.slice";
import nomineeReducer from "../features/App/Nominee/Nominee.slice";
import notificationReducer from "../features/App/Notifiactions/Notifications.slice";
import DashboardSlice from "../features/App/DashboardScreen/Dashboard.slice";
import paymentReducer from "../features/App/Payment/Payment.slice";

const rootReducer = combineReducers({
  auth: authReducer,
  policy: policyReducer,
  profile: profileReducer,
  mobileNumber: mobileNumberReducer,
  emails: emailReducer,
  address: addressReducer,
  banks: bankReducer,
  common: commonReducer,
  dashboard: DashboardSlice,
  nominee: nomineeReducer,
  notifications: notificationReducer,
  payment: paymentReducer,
});

const setup = () => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  });
  middlewares.push(sagaMiddleware);

  const store = configureStore({
    reducer: rootReducer,
    middleware: middlewares,
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

export const store = setup();

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export { rootReducer };
