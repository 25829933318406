import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import AddressSection from "../AddressSection";
import CustomButton from "../../../../common/ui/CustomButton";
import Separator from "../../../../common/ui/Separator";
import { fetchAddressFromPolicy } from "../Address.slice";

const UnassignedAddress = ({
  addressList,
  handleAssignPolicies,
  setRemoveUnAssignedAddress,
}: any) => {
  const theme = useTheme();

  return (
    <>
      <Grid item xs={12}>
        <Typography
          className="f-16 fw-600 my-10"
          color={theme.palette.error.main}
        >
          Unassigned Addresses
        </Typography>
      </Grid>
      <Grid item className="my-10">
        <Separator color={theme.palette.primary.main} borderWidth={2} />
      </Grid>
      <Grid container className="Manage-bank" spacing={5}>
        {addressList?.map((address: any, index: number) => (
          <Grid item xs={12} sm={6} md={3}>
            <Grid item className="Manage-bank">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                className="mb-10"
              >
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item className="ml-n20">
                      <Typography color="black" fontSize={20} fontWeight={600}>
                        {index + 1}.
                      </Typography>
                    </Grid>
                    <Grid item className="mt-3">
                      <Typography
                        color="primary"
                        fontSize={16}
                        fontWeight={600}
                      >
                        {address?.city} Address
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <CustomButton
                    text={"REMOVE"}
                    variant="text"
                    color="primary"
                    fontSize={14}
                    showIcon={false}
                    fontWeight={400}
                    onClick={() =>
                      setRemoveUnAssignedAddress({
                        ...address,
                        isUnaAssigned: true,
                      })
                    }
                  />
                </Grid>
              </Grid>
              <Grid item>
                <AddressSection data={address} />
              </Grid>
              <Grid item className="mt-3">
                <CustomButton
                  text={"ASSIGN POLICIES"}
                  variant="text"
                  color="primary"
                  fontSize={14}
                  showIcon={false}
                  fontWeight={400}
                  onClick={() =>
                    handleAssignPolicies({
                      ...fetchAddressFromPolicy(address),
                      isUnaAssigned: true,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default UnassignedAddress;
