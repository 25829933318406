import React, { useState, useEffect } from 'react';
import { Grid, useTheme, SelectChangeEvent } from '@mui/material';
import Typography from '@mui/material/Typography';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import '../SignUp/CaseScreens.scss';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
import CustomButton from '../../../common/ui/CustomButton';
import { useForm } from 'react-hook-form';
import {
  fetchFamilyRelationShipList,
  selectFamilyRelationShipList,
  selectIsFamilyRelationShipLoading,
  updateArRequest,
} from '../../App/Profile/Profile.slice';
import { useDispatch, useSelector } from 'react-redux';
import data from '../../../common/constants/FormConstants.json';
import Spinner from '../../../common/ui/Spinner';
import { selectIsUserLoggedIn } from '../auth.slice';

const AuthorizedRepresentativeScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFamilyRelationShipList());
  }, []);
  const relationShipList = useSelector(selectFamilyRelationShipList);
  const isRelationShipLoadingList = useSelector(
    selectIsFamilyRelationShipLoading
  );
  const loginInfo = useSelector(selectIsUserLoggedIn);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      countryCode: '91',
      firstName: '',
      mobileCountryCode: '',
      mobileNo: '',
      email: '',
      relationCode: '',
      address1: '',
      address2: '',
      address3: '',
      city: '',
      state: '',
      pincode: '',
      date: '',
      month: '',
      year: '',
    },
  });

  const theme = useTheme();

  const [selectedOption, setSelectedOption] = useState(null);
  const options = [
    'Select',
    'Son',
    'Daughter',
    'Spouse',
    'Father',
    'Mother',
    'Brother',
    'Friend',
    'Other',
  ];
  // const handleSelectChange = (value: string) => {
  //   setSelectedOption(value);
  // };
  const relationName = watch('relationCode');
  useEffect(() => {
    const matchingRelation =
      relationShipList &&
      relationShipList.find((item: any) => item.relationCode === relationName);
    if (matchingRelation) {
      setValue('relationCode', matchingRelation?.relationship);
    }
  }, [relationName, relationShipList, setValue]);
  console.log('selectedOption', selectedOption);
  const handleOption = (value: any) => {
    console.log('handle option', value);
    setSelectedOption(value);
    setValue('relationCode', value, {
      shouldValidate: true,
    });
  };
  const navigate = useNavigate();

  const handleConfirm = (data: any) => {
    console.log('onsubmit data', data);
    let payload = {
      Customer: {
        eiaNo: loginInfo?.eiaNo,
        firstName: data?.firstName,
        middleName: '',
        lastName: '',
        mobileCountryCode: data?.countryCode,
        mobileNo: data?.mobileNo,
        email: data?.email,
        dob: `${data?.date}/${data?.month}/${data?.year}`,
        relationCode: setSelectedOption,
        address1: data?.address1,
        address2: '',
        address3: '',
        city: data?.city,
        state: data?.state,
        countryCode: '',
        pincode: data?.pincode,
        investHorizon: '',
      },
      navigate,
    };
    dispatch(updateArRequest(payload as any));
  };
  return (
    <form>
      <Grid container justifyContent={'center'}>
        <Grid item>
          <Grid item className='case1'>
            <Grid item className={'mb-10'}>
              <Typography
                className={'f-20 fw-600'}
                color={theme.palette.primary.main}
              >
                Please assign an Authorized Representative.
              </Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Typography
                    className={'f-14 '}
                    color={theme.palette.common.black}
                  >
                    Who is an
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={'f-14 fw-600'}
                    color={theme.palette.common.black}
                  >
                    "Authorized Representative"?
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  className='f-14   mb-10'
                  color={theme.palette.common.black}
                >
                  A policy holder shall appoint an Authorized Representative
                  (AR) who shall be entitled to access the account in the event
                  of demise of the policy holder or in his incapacity to operate
                  the e Insurance Account. The AR is entitled only to access the
                  eIA to know the portfolio of insurance policies and the
                  nominees of the respective policies held under that account.
                </Typography>
              </Grid>
            </Grid>
            <Grid container className='h-100 mt-15' justifyContent={'center'}>
              <Grid item className='add-family'>
                <Grid item>
                  <Typography
                    className='f-14 fw-600'
                    color={theme.palette.secondary.main}
                  >
                    AR Name (Mandatory)
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormInput
                    type='text'
                    placeholder='Type here..'
                    name='firstName'
                    inputProps={{
                      className: 'px-10',
                      ...register('firstName', {
                        required: {
                          value: true,
                          message: 'Ar is mandatory!',
                        },
                      }),
                    }}
                    errors={errors}
                  />
                </Grid>

                <Grid
                  container
                  spacing={1}
                  justifyContent={'space-between'}
                  className='mt-2 mb-14'
                >
                  <Grid item xs={6}>
                    <Grid item>
                      <Typography
                        className='f-14 fw-600'
                        color={theme.palette.secondary.main}
                      >
                        Mobile (Mandatory)
                      </Typography>

                      <Grid
                        container
                        flexWrap={'nowrap'}
                        spacing={0.5}
                        className='mb-10'
                      >
                        <Grid item sm={3}>
                          <FormInput
                            inputProps={{
                              className: 'px-5',
                              ...register('countryCode'),
                            }}
                            type='number'
                            placeholder='+91'
                            hideCountButton={true}
                          />
                        </Grid>
                        <Grid item sm={9}>
                          <FormInput
                            type='number'
                            name='mobileNo'
                            inputProps={{
                              className: 'px-10',
                              ...register('mobileNo', {
                                required: {
                                  value: true,
                                  message: data.MOBILENUMBER,
                                },
                              }),
                            }}
                            placeholder='1234567890'
                            hideCountButton={true}
                            errors={errors}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item>
                      <Typography className='f-14 fw-600' color={'primary'}>
                        Email
                      </Typography>

                      <FormInput
                        type='email'
                        placeholder='Type here..'
                        name='email'
                        inputProps={{
                          className: 'px-10',
                          ...register('email', {
                            required: {
                              value: true,
                              message: data.EMAIL,
                            },
                          }),
                        }}
                        errors={errors}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  justifyContent={'space-between'}
                  className='mt-2 mb-14'
                >
                  <Grid item xs={6}>
                    <Grid item>
                      <Typography className='f-14 fw-600' color={'primary'}>
                        Date Of Birth
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      container
                      flexDirection={'row'}
                      // spacing={0.4}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      flexWrap={'nowrap'}
                    >
                      <Grid item sm={3}>
                        <FormInput
                          placeholder={'DD'}
                          name='date'
                          inputProps={{
                            className: 'px-10',
                            ...register('date', {
                              required: {
                                value: true,
                                message: data.DATE,
                              },
                            }),
                          }}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item sm={3}>
                        <FormInput
                          placeholder={'MM'}
                          name='month'
                          inputProps={{
                            className: 'px-10',
                            ...register('month', {
                              required: {
                                value: true,
                                message: data.MONTH,
                              },
                            }),
                          }}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item sm={5}>
                        <FormInput
                          placeholder={'YYYY'}
                          name='year'
                          inputProps={{
                            className: 'px-10',
                            ...register('year', {
                              required: {
                                value: true,
                                message: data.YEAR,
                              },
                            }),
                          }}
                          errors={errors}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item>
                      <Typography className='f-14 fw-600' color={'primary'}>
                        Relationship
                      </Typography>
                      {isRelationShipLoadingList ? (
                        <Grid item>
                          <Spinner />
                        </Grid>
                      ) : (
                        <CustomSelectBox
                          ClassName='w-100 relationship-selectbox'
                          options={
                            relationShipList?.map((row: any) => ({
                              Code: row.relationCode,
                              Description: row.relationship,
                              Category: row.relationship,
                            })) || []
                          }
                          inputProps={register('relationCode', {
                            required: {
                              value: true,
                              message: data.RELATIONSHIP,
                            },
                          })}
                          defaultValue={selectedOption}
                          onChange={(value) => {
                            console.log('onchange value', value);
                            handleOption(value);
                          }}
                          setSelectedOption={handleOption}
                        />
                      )}
                      {errors.relationCode ? (
                        <Typography
                          color={theme.palette.error.main}
                          fontSize={12}
                        >
                          {errors.relationCode?.message}
                        </Typography>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Typography className='f-14 fw-600' color={'primary'}>
                    Address
                  </Typography>
                </Grid>
                <Grid item className='mb-5'>
                  <FormInput
                    type='text'
                    placeholder='House #,Street #'
                    color={theme.palette.common.black}
                    name='address1'
                    inputProps={{
                      className: 'px-10',
                      ...register('address1', {
                        required: {
                          value: true,
                          message: data.STREET,
                        },
                      }),
                    }}
                    errors={errors}
                  />
                </Grid>

                <Grid container spacing={3} className='mb-5'>
                  <Grid item xs={6} sm={6}>
                    <FormInput
                      type='text'
                      placeholder='City'
                      name='city'
                      inputProps={{
                        className: 'px-10',
                        ...register('city', {
                          required: {
                            value: true,
                            message: data.CITY,
                          },
                        }),
                      }}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <FormInput
                      type='text'
                      placeholder='District'
                      name='address3'
                      inputProps={{
                        className: 'px-10',
                        ...register('address3', {
                          required: {
                            value: true,
                            message: data.DISTRICT,
                          },
                        }),
                      }}
                      errors={errors}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} className='mb-5'>
                  <Grid item xs={6} sm={6}>
                    <FormInput
                      type='text'
                      placeholder='State'
                      name='state'
                      inputProps={{
                        className: 'px-10',
                        ...register('state', {
                          required: {
                            value: true,
                            message: data.STATE,
                          },
                        }),
                      }}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <FormInput
                      type='text'
                      placeholder='Pin'
                      name='pincode'
                      inputProps={{
                        className: 'px-10',
                        ...register('pincode', {
                          required: {
                            value: true,
                            message: data.PIN,
                          },
                        }),
                      }}
                      errors={errors}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container justifyContent={'center'} className={'mt-30'}>
              <CustomButton
                text={'SUBMIT'}
                variant={'text'}
                color={'primary'}
                className={'fw-600 f-20'}
                onClick={handleSubmit(handleConfirm)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AuthorizedRepresentativeScreen;
