import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface CheckboxProps {
  checked: boolean;
  label: string | any;
  CheckBoxClassName?: string | undefined;
  onClick?: any;

  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: number;
  checkBoxPadding?: number;
}

const FormCheckBox: React.FC<CheckboxProps> = ({
  checked,
  label,
  onChange,
  onClick,
  CheckBoxClassName,
  size = 24,
  checkBoxPadding,
}) => {
  const checkboxStyle = {
    transform: `scale(${size / 24})`,
    padding: checkBoxPadding,
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          onClick={onClick}
          className={`${CheckBoxClassName}`}
          style={checkboxStyle}
        />
      }
      label={label}
    />
  );
};

export default FormCheckBox;
