import { useTheme } from "@mui/material";
import React, { Fragment } from "react";

const SvgConverter = ({
  Icon = false,
  className = "",
  color = "black",
  disabled = false,
  width = 0,
  height = 0,

  ...rest
}: {
  Icon?: any;
  className?: string;
  color?: string;
  disabled?: boolean;
  width?: number | string;
  height?: number | string;
}) => {
  const theme = useTheme();
  // console.log(theme);

  return (
    <>
      {Icon ? (
        <Icon
          style={{ display: "flex" }}
          fill={color}
          className={`icon-converter ${className} color-${
            !disabled ? color : "muted"
          }`}
          {...(height ? { height } : {})}
          {...(width ? { width } : {})}
          {...rest}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SvgConverter;
