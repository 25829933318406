import React, { useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
import './Policy.scss';
import { ReactComponent as Group_certified } from '../../../common/icons/dashboard/group_certified-icon.svg';
import { ReactComponent as Health } from '../../../common/icons/dashboard/health-icon.svg';
import { ReactComponent as Assets } from '../../../common/icons/dashboard/assets-icon.svg';
import { CustomAccordion } from '../../../common/ui/Accordion/CustomAccordion';
import ActiveLife from './common/AccordionContent/ActiveLife';
import ActiveHealth from './common/AccordionContent/ActiveHealth';
import ActiveAssets from './common/AccordionContent/ActiveAssets';
import { useDispatch, useSelector } from 'react-redux';
import { fetchActivePolicy, selectActivePolicies } from './Policies.slice';
import Separator from '../../../common/ui/Separator';
import theme from '../../../common/theme';
import { groupBy, prop } from 'ramda';
import Spinner from '../../../common/ui/Spinner';
import { useLocation } from 'react-router-dom';

interface UserProps {
  prevPath?: any;
  activeLoading?: any;
}
export const ActiveScreen: React.FC<UserProps> = ({
  prevPath,
  activeLoading,
}) => {
  const dispatch = useDispatch();

  const activePolicies = useSelector(selectActivePolicies);

  const policyList = useMemo(() => {
    const groupedPolicies = groupBy(prop<string>('alternateCode'))(
      activePolicies
    );
    return {
      lifeList: groupedPolicies.L || [],
      healthList: groupedPolicies.H || [],
      assetList: groupedPolicies.G || [],
    };
  }, [activePolicies]);
  console.log('assetList', policyList?.assetList);
  useEffect(() => {
    dispatch(fetchActivePolicy());
  }, []);
  const location = useLocation();
  const AccordionState = location.state;
  console.log('active screen accordion type', AccordionState);
  return (
    <Grid container>
      {activeLoading ? (
        <Grid display={'flex'} justifyContent={'center'}>
          <Spinner />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <CustomAccordion
            accordionIcon={Group_certified}
            accordionLabel={'Life'}
            expanded={AccordionState === 'life'}
          >
            <ActiveLife
              filteredLife={policyList?.lifeList}
              contentType={'active'}
            />
          </CustomAccordion>

          <Separator color={theme.palette.grey[300]} />
          <CustomAccordion
            accordionIcon={Health}
            accordionLabel={'Health'}
            expanded={AccordionState === 'health'}
          >
            <ActiveHealth
              filteredHealth={policyList?.healthList}
              contentType={'active'}
            />
          </CustomAccordion>
          <Separator color={theme.palette.grey[300]} />
          <CustomAccordion
            accordionIcon={Assets}
            accordionLabel={'Assets'}
            expanded={AccordionState === 'assets'}
          >
            <ActiveAssets
              filteredAssets={policyList?.assetList}
              contentType={'active'}
            />
          </CustomAccordion>
          <Separator color={theme.palette.grey[300]} />
        </Grid>
      )}
    </Grid>
  );
};

export default ActiveScreen;
