import { FormHelperText, IconButton, Popover } from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React from 'react';
import SvgConverter from '../SvgConverter';
import { ReactComponent as CalendarIcon } from '../icons/login_calendar_input-icon.svg';
import { ReactComponent as CalendarIconPrimary } from '../icons/normal_u119.svg';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import theme from '../theme';

const Icons = {
  primary: CalendarIconPrimary,
  secondary: CalendarIcon,
};

const DatePickerModal = ({
  iconType = 'secondary',
  disableFuture = false,
  onChange = () => {},
}: {
  iconType?: 'primary' | 'secondary' | undefined;
  disableFuture?: boolean | undefined;
  onChange?: any;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  return (
    <>
      <IconButton
        sx={{ padding: 0 }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <SvgConverter Icon={Icons[iconType]} />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            defaultValue={dayjs(new Date())}
            disableFuture={disableFuture}
            onChange={onChange}
            onAccept={(date) => {
              setAnchorEl(null);
              onChange(date);
            }}
            onClose={() => setAnchorEl(null)}
          />
        </LocalizationProvider>
      </Popover>
    </>
  );
};

export default DatePickerModal;
