import React, { useEffect } from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import FormInput from "../../../../common/ui/Forms/FormInput/FormInput";
import { ReactComponent as cancel } from "../../../../common/icons/cancel-icon.svg";
import { ReactComponent as correct } from "../../../../common/icons/tick-icon.svg";
import { CommonModal } from "../../../../common/ui/Modal/CommonModal";
import CustomButton from "../../../../common/ui/CustomButton";
import { useForm } from "react-hook-form";

const BankEntryModal = ({ open, setOpen, onSubmit, loading }: any) => {
  const theme = useTheme();

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      accountNumber: "1113155000015020",
      retypeAccountNo: "1113155000015020",
      accountHolderName: "ESWARAN A",
      ifscCode: "KVBL0001113",
    },
  });

  const validateRetypeAccountNo = (value: any) => {
    const accountNo = watch("accountNumber");
    return value === accountNo || "Account numbers do not match";
  };

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  return (
    <CommonModal
      onClose={() => setOpen(false)}
      open={open}
      modalClassName="px-20 py-20"
      boxProps={{ width: 354 }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent={"center"}>
          <Grid item className="assign-policy">
            <Grid item className="mb-25">
              <Typography
                className={"f-16 fw-600"}
                color={theme.palette.primary.main}
                textAlign={"center"}
              >
                Please enter bank account details.
              </Typography>
            </Grid>
            <Grid item className="mt-10 mb-5">
              <Typography className={"f-14"} color={theme.palette.common.black}>
                Account Holder Name
              </Typography>
            </Grid>
            <Grid item className="mb-15">
              <FormInput
                type="text"
                placeholder="Account Holder Name"
                color={theme.palette.common.black}
                rules={{
                  required: {
                    value: true,
                    message: " Account Holder Name is required",
                  },
                }}
                errors={errors}
                control={control}
                name={"accountHolderName"}
              />
            </Grid>

            <Grid item className="mt-10 mb-5">
              <Typography className={"f-14"} color={theme.palette.common.black}>
                Account Number
              </Typography>
            </Grid>
            <Grid item className="mb-15">
              <FormInput
                type="text"
                placeholder="Account Number"
                color={theme.palette.common.black}
                rules={{
                  required: {
                    value: true,
                    message: "Account Number is required",
                  },
                }}
                errors={errors}
                control={control}
                name={"accountNumber"}
              />
            </Grid>
            <Grid item className="mt-10 mb-5">
              <Typography className={"f-14"} color={theme.palette.common.black}>
                Re-enter Account Number
              </Typography>
            </Grid>
            <Grid item className="mb-15">
              <FormInput
                type="text"
                placeholder=" Retype-Account Number"
                color={theme.palette.common.black}
                rules={{
                  required: {
                    value: true,
                    message: "Re-enter Account Number is required",
                  },
                  validate: validateRetypeAccountNo,
                }}
                errors={errors}
                control={control}
                name={"retypeAccountNo"}
              />
            </Grid>
            <Grid item className="mt-10 mb-5">
              <Typography className={"f-14"} color={theme.palette.common.black}>
                IFSC Code
              </Typography>
            </Grid>
            <Grid item className="mb-15">
              <FormInput
                type="text"
                placeholder="IFSC Code"
                color={theme.palette.common.black}
                rules={{
                  required: {
                    value: true,
                    message: "IFSC code is required",
                  },
                }}
                errors={errors}
                control={control}
                name={"ifscCode"}
              />
            </Grid>
            <Grid
              container
              justifyContent={"space-around"}
              spacing={1}
              className="mt-30"
            >
              <Grid item>
                <CustomButton
                  type="submit"
                  className="f-14"
                  startIcon={correct}
                  showIcon={false}
                  text={"SEARCH "}
                  color="primary"
                  variant={"text"}
                  fontSize={16}
                  fontWeight={600}
                  loading={loading}
                />
              </Grid>
              <Grid item>
                <CustomButton
                  text={"CANCEL"}
                  variant={"text"}
                  color={"primary"}
                  startIcon={cancel}
                  showIcon={false}
                  fontWeight={400}
                  fontSize={14}
                  onClick={() => setOpen(false)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </CommonModal>
  );
};
export default BankEntryModal;
