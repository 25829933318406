import { call, put, select, takeEvery } from "typed-redux-saga";
// import { UserScreens } from '../../common/routes/user/user.screens';
// import { setShowAlert } from '../alerts/alerts-slice';
import { selectIsUserLoggedIn } from "../../Auth/auth.slice";
// import { handleUploadImage } from '../Common/Upload/Upload.saga';
import {
  ProfileUpdateArDetails,
  SaveFamilyVerification,
  addFamilyMemberRequest,
  fetchFamilyRelationShipRequest,
  fetchUserFamilyList,
  fetchUserProfileData,
  otpGenerateForMobileEmail,
  otpVerifyForMobileEmail,
} from "./Profile.service";
import {
  addFamilyMemberError,
  addFamilyMemberRequest as addFamilyMemberRequestAction,
  addFamilyMemberSuccess,
  errorFamilyRelationShipList,
  errorUserFamilyList,
  errorUserProfile,
  fetchFamilyRelationShipList,
  fetchUserFamilyListAction,
  fetchUserProfile,
  loadFamilyRelationShipList,
  loadUserFamilyList,
  loadUserProfile,
  saveFamilyEmailVerificationDetails,
  saveFamilyEmailVerificationDetailsError,
  saveFamilyEmailVerificationDetailsSuccess,
  saveFamilyVerificationDetails,
  saveFamilyVerificationDetailsError,
  saveFamilyVerificationDetailsSuccess,
  updateArError,
  updateArRequest,
  updateArSuccess,
  uploadOtherPhotoError,
  uploadOtherPhotoRequest,
  uploadOtherPhotoSuccess,
  uploadProfilePhotoError,
  uploadProfilePhotoRequest,
  uploadProfilePhotoSuccess,
  uploadSignaturePhotoError,
  uploadSignaturePhotoRequest,
  uploadSignaturePhotoSuccess,
  verifyGenerateOtpForEmailError,
  verifyGenerateOtpForEmailRequest,
  verifyGenerateOtpForEmailSuccess,
  verifyGenerateOtpForMobileError,
  verifyGenerateOtpForMobileRequest,
  verifyGenerateOtpForMobileSuccess,
  verifyOtpEmailError,
  verifyOtpEmailRequest,
  verifyOtpEmailSuccess,
  verifyOtpMobileNoError,
  verifyOtpMobileNoRequest,
  verifyOtpMobileNoSuccess,
} from "./Profile.slice";
import { setAlertInfo } from "../Common/Common.slice";
import { handleUploadImage } from "../../../common/ui/Selectbox/Upload.saga";

function* handleFetchUserProfileInformation() {
  // alert();
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
        // eiaNo: 'eiaNo',
      },
    };
    const { data }: any = yield* call(fetchUserProfileData, requestPayload);
    yield* put(loadUserProfile(data));
  } catch (e: any) {
    yield* put(errorUserProfile(e.message));
  }
}

function* handleFetchUserFamilyListInformation() {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
      },
    };
    const { data }: any = yield* call(fetchUserFamilyList, requestPayload);
    const familyList = data.FamilyMembers ? data.FamilyMembers : [];
    yield* put(loadUserFamilyList(familyList));
  } catch (e: any) {
    yield* put(errorUserFamilyList(e.message));
  }
}

function* handleAddFamilyMember({ payload: { navigation, ...payload } }: any) {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
      },
      ...payload,
    };
    const { data }: any = yield* call(addFamilyMemberRequest, requestPayload);
    yield* put(addFamilyMemberSuccess(data));
    yield* put(
      setAlertInfo({
        open: true,
        type: "SUCCESS",
        description: data?.displayMessage,
      })
    );
    // navigation.navigate(UserScreens.profile.routeName);
    yield* put(fetchUserFamilyListAction());
  } catch (e: any) {
    yield* put(addFamilyMemberError(e.message));
  }
}

function* handleFetchFamilyRelationShip() {
  try {
    const { data }: any = yield* call(fetchFamilyRelationShipRequest);
    yield* put(loadFamilyRelationShipList(data));
  } catch (e: any) {
    yield* put(errorFamilyRelationShipList(e.message));
  }
}

function* handleProfileImageUpload(payload: any) {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
      },
      ...payload,
    };
    // const { data }: any = yield* call(handleUploadImage, requestPayload);
    // yield* put(uploadProfilePhotoSuccess(data));
  } catch (e: any) {
    yield* put(uploadProfilePhotoError(e.message));
  }
}

function* handleSignatureImageUpload(payload: any) {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
      },
      ...payload,
    };
    const { data }: any = yield* call(handleUploadImage, requestPayload);
    yield* put(uploadSignaturePhotoSuccess(data));
  } catch (e: any) {
    yield* put(uploadSignaturePhotoError(e.message));
  }
}

function* handleOthersImageUpload(payload: any) {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
      },
      ...payload,
    };
    const { data }: any = yield* call(handleUploadImage, requestPayload);
    yield* put(uploadOtherPhotoSuccess(data));
  } catch (e: any) {
    yield* put(uploadOtherPhotoError(e.message));
  }
}

function* handleOtpGenerateForMobile({
  payload: { handleSuccess, ...payload },
}: any) {
  console.log("mbl payload family", payload, handleSuccess);
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        value: payload?.mobileNo,
        flag: "MOBILE",
        otpPurpose: "UPDATE_MOBILE",
        eiaNo: user.eiaNo,
      },
    };
    const { data }: any = yield* call(
      otpGenerateForMobileEmail,
      requestPayload
    );
    yield* put(verifyGenerateOtpForMobileSuccess(data));
    if (handleSuccess) {
      handleSuccess(data);
    }
  } catch (e: any) {
    yield* put(verifyGenerateOtpForMobileError(e.message));
  }
}

function* handleOtpGenerateForEmail({
  payload: { handleSuccess, ...payload },
}: any) {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        value: payload.email,
        flag: "EMAIL",
        otpPurpose: "UPDATE_EMAIL",
        eiaNo: user.eiaNo,
      },
    };
    const { data }: any = yield* call(
      otpGenerateForMobileEmail,
      requestPayload
    );
    yield* put(verifyGenerateOtpForEmailSuccess(data));
    if (handleSuccess) {
      handleSuccess(data);
    }
  } catch (e: any) {
    yield* put(verifyGenerateOtpForEmailError(e.message));
  }
}
function* handleOtpVerifyForMobile({
  payload: { handleSuccess, ...payload },
}: any) {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        value: payload.mobileNo,
        otp: payload.otpCode,
        flag: "MOBILE",
        otpPurpose: "FAMILY_MEMBER",
        eiaNo: user.eiaNo,
      },
    };
    const { data }: any = yield* call(otpVerifyForMobileEmail, requestPayload);
    yield* put(verifyOtpMobileNoSuccess(data));
    if (handleSuccess) {
      handleSuccess(data);
    }
  } catch (e: any) {
    yield* put(verifyOtpMobileNoError(e.message));
  }
}
function* handleOtpVerifyForEmail({
  payload: { handleSuccess, ...payload },
}: any) {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        value: payload.email,
        otp: payload.otpCode,
        flag: "EMAIL",
        otpPurpose: "UPDATE_EMAIL",
        eiaNo: user.eiaNo,
      },
    };
    const { data }: any = yield* call(otpVerifyForMobileEmail, requestPayload);
    yield* put(verifyOtpEmailSuccess(data));
    if (handleSuccess) {
      handleSuccess(data);
    }
    yield call(handleFetchUserFamilyListInformation);
  } catch (e: any) {
    yield* put(verifyOtpEmailError(e.message));
  }
}
function* handleSaveFamilyVerificationStatus({ payload }: any) {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
        seqId: payload?.seqId ? payload?.seqId : "0",
        value: payload?.mobileNo,
        flag: "MOBILE",
        nominee: payload?.mblNominee,
      },
    };
    const { data }: any = yield* call(SaveFamilyVerification, requestPayload);
    yield* put(saveFamilyVerificationDetailsSuccess(data));
    yield* put(
      setAlertInfo({
        open: true,
        type: "SUCCESS",
        description: data?.displayMessage,
      })
    );
    yield call(handleFetchUserFamilyListInformation);
  } catch (e: any) {
    yield* put(saveFamilyVerificationDetailsError(e.message));
  }
}

function* handleSaveFamilyEmailVerificationStatus({ payload }: any) {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
        seqId: payload?.seqId ? payload?.seqId : "0",
        value: payload?.email,
        flag: "EMAIL",
        nominee: payload?.emailNominee,
      },
    };
    const { data }: any = yield* call(SaveFamilyVerification, requestPayload);
    yield* put(saveFamilyEmailVerificationDetailsSuccess(data));
    yield* put(
      setAlertInfo({
        open: true,
        type: "SUCCESS",
        description: data?.displayMessage,
      })
    );
    yield call(handleFetchUserFamilyListInformation);
  } catch (e: any) {
    yield* put(saveFamilyEmailVerificationDetailsError(e.message));
  }
}

function* handleProfileUpdateAr({ payload: { navigation, ...payload } }: any) {
  try {
    const { data }: any = yield* call(ProfileUpdateArDetails, payload);
    yield* put(updateArSuccess(data));
    yield* put(
      setAlertInfo({
        open: true,
        type: "SUCCESS",
        description: data?.displayMessage,
      })
    );
    navigation.goBack();
    yield call(handleFetchUserProfileInformation);
  } catch (e: any) {
    yield* put(updateArError(e.message));
  }
}
function* watchProfile() {
  yield* takeEvery(fetchUserProfile.type, handleFetchUserProfileInformation);
  yield* takeEvery(
    fetchUserFamilyListAction.type,
    handleFetchUserFamilyListInformation
  );
  yield* takeEvery(addFamilyMemberRequestAction.type, handleAddFamilyMember);
  yield* takeEvery(
    fetchFamilyRelationShipList.type,
    handleFetchFamilyRelationShip
  );
  yield* takeEvery(uploadProfilePhotoRequest.type, handleProfileImageUpload);
  yield* takeEvery(
    uploadSignaturePhotoRequest.type,
    handleSignatureImageUpload
  );
  yield* takeEvery(uploadOtherPhotoRequest.type, handleOthersImageUpload);
  yield* takeEvery(
    verifyGenerateOtpForMobileRequest.type,
    handleOtpGenerateForMobile
  );
  yield* takeEvery(
    verifyGenerateOtpForEmailRequest.type,
    handleOtpGenerateForEmail
  );
  yield* takeEvery(verifyOtpMobileNoRequest.type, handleOtpVerifyForMobile);
  yield* takeEvery(verifyOtpEmailRequest.type, handleOtpVerifyForEmail);
  yield* takeEvery(
    saveFamilyVerificationDetails.type,
    handleSaveFamilyVerificationStatus
  );
  yield* takeEvery(
    saveFamilyEmailVerificationDetails.type,
    handleSaveFamilyEmailVerificationStatus
  );
  yield* takeEvery(updateArRequest.type, handleProfileUpdateAr);
}

export {
  watchProfile,
  handleFetchUserProfileInformation,
  handleFetchUserFamilyListInformation,
};
