import { call, put, select, takeEvery } from 'typed-redux-saga';
import { fetchDashboardPoliciesList } from './Dashboard.service';
import {
  dashboardListError,
  dashboardListRequest,
  dashboardListSuccess,
} from './Dashboard.slice';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';

function* handleFetchDashboardList() {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
      },
    };
    const { data }: any = yield* call(
      fetchDashboardPoliciesList,
      requestPayload
    );
    yield* put(dashboardListSuccess(data));
  } catch (e: any) {
    yield* put(dashboardListError(e.message));
  }
}

function* watchDashboard() {
  yield* takeEvery(dashboardListRequest.type, handleFetchDashboardList);
}

export { watchDashboard };
