import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import defaultAvatar from '../icons/default_avatar.svg';
import FileUploader from './Forms/FormInput/FileUploader/FileUploader';
import { ReactComponent as ProfileCam } from '../../common/icons/profile/profile-camera.svg';
import SvgConverter from '../SvgConverter';
const ProfileAvatar = ({
  profileUri,
  profileReadiness,
}: {
  profileReadiness: any;
  profileUri: any;
}) => {
  const [image, setImage] = useState('');
  const [photo, setPhoto] = useState('');
  function handleFilesChange(newFiles: any) {
    console.log('newfiles', newFiles[0]?.preview?.url);
    var file = newFiles[0];
    var reader = new FileReader();
    var dataa = newFiles[0]?.preview?.url;
    setPhoto(dataa);
    console.log('dataadataadataa', dataa);
    console.log('readerreaderreaderreader', reader);
    var base64 = '';
    reader.onloadend = function () {
      if (reader.result) {
        base64 = (reader.result as string).replace(
          /^data:image\/\w+;base64,/,
          ''
        );
        setImage(base64);
      }
    };
  }
  const profileReadinessValue = parseInt(profileReadiness, 10);
  console.log('profileReadiness', profileReadinessValue);
  return (
    <div className='user-avatar-container m-5'>
      <FileUploader accepts={['image/png']} onFilesChange={handleFilesChange}>
        <Avatar
          alt='user profile avatar'
          src={profileUri ? profileUri : defaultAvatar}
          sx={{ width: 52, height: 52 }}
        />
        <CircularProgress
          variant='determinate'
          value={profileReadinessValue}
          size={58}
          className={'avatar-progress-bar'}
          color={
            profileReadinessValue <= 40
              ? 'error'
              : profileReadinessValue <= 70
              ? 'secondary'
              : 'success'
          }
        />
        {/* <SvgConverter Icon={ProfileCam} className='profile-cam-personal ' /> */}
      </FileUploader>
    </div>
  );
};

export default ProfileAvatar;
