import {call, put, select, takeEvery} from 'typed-redux-saga';
import {uploadFamilyService, uploadService} from './Upload.service';
// import {setShowAlert} from '../../alerts/alerts-slice';
import {
  handleFetchUserFamilyListInformation,
  handleFetchUserProfileInformation,
} from '../../../features/App/Profile/Profile.saga';
import { selectIsUserLoggedIn } from '../../../features/Auth/auth.slice';
import { setAlertInfo } from '../../../features/App/Common/Common.slice';

enum UploadType {
  PROFILE = 'PROFILE_PHOTO',
  SIGNATURE = 'SIGNATURE',
  OTHER = 'OTHER',
}

const uploadFileToServer = (uploadInfo: any) => ({
  type: uploadFileToServer.type,
  payload: uploadInfo,
});
uploadFileToServer.type = 'file/upload';

function* handleUploadImage({payload}: ReturnType<typeof uploadFileToServer>) {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
        ...payload,
      },
    };
    const {data}: any = yield* call(uploadService, requestPayload);
    yield call(handleFetchUserProfileInformation);
    yield put(
        setAlertInfo({
        open: true,
        type: 'SUCCESS',
        description: data?.displayMessage,
      })
    );
  } catch (e: any) {
    yield * put(setAlertInfo(e.message));
  }
}

const FileUploadToServer = (uploadData: any) => ({
  type: FileUploadToServer.type,
  payload: uploadData,
});
FileUploadToServer.type = 'upload/file';

function* handleImageUpload({payload}: ReturnType<typeof FileUploadToServer>) {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
      },
      Person: {
        seqId: payload.seqId,
        dob: payload.dob,
        relationCode: payload.relationCode,
        imageBase64: payload.imageBase64,
        fileName: payload.fileName,
      },
    };
    const {data}: any = yield* call(uploadFamilyService, requestPayload);
    yield call(handleFetchUserFamilyListInformation);
    yield put(
      setAlertInfo({
        open: true,
        type: 'SUCCESS',
        description: data?.displayMessage,
      })
    );
  } catch (e: any) {
    yield * put(setAlertInfo(e.errorDescription));
  }
}

function* watchUpload() {
  yield* takeEvery(uploadFileToServer.type, handleUploadImage);
  yield* takeEvery(FileUploadToServer.type, handleImageUpload);
}

export {
  watchUpload,
  uploadFileToServer,
  FileUploadToServer,
  handleUploadImage,
  UploadType,
};
