import React, { useEffect, useMemo } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  reportLoginRequestSuccess,
  selectIsUserLoggedIn,
  setUser,
} from "../features/Auth/auth.slice";
import AuthRoutes from "../Layout/AuthRoutes";
import AppRoutes from "../Layout/AppRoutes";
import { useTheme } from "@mui/system";
import { changeCssColors } from "../common/theme";
import "./colors.css";
import "./App.scss";
import { AuthUser } from "../common/utils";
import ApiModal from "../common/ui/Modal/ApiModal";

function App() {
  const userInfo = useSelector(selectIsUserLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const access_token = sessionStorage.getItem("access_token");
        let userInfo: any = sessionStorage.getItem("userInfo");
        userInfo = JSON.parse(userInfo as string);

        if (access_token && userInfo?.Customer?.eiaNo) {
          dispatch(setUser(userInfo.Customer as AuthUser));
        }
      } catch (error) {}
    })();
  }, []);

  const theme = useTheme();
  useEffect(() => {
    changeCssColors(theme);
  }, [theme]);

  const isLogged = useMemo(
    () => (userInfo?.eiaNo ? true : false),
    [userInfo?.eiaNo]
  );

  return (
    <>
      <Router>
        <AuthRoutes isLogged={isLogged} />
        <AppRoutes isLogged={isLogged} />
      </Router>
      <ApiModal />
    </>
  );
}

export default App;
