import { call, put, takeEvery } from "redux-saga/effects";
import {
  getRazorPayOrderId,
  uploadPaymentDataEffect,
} from "./Payment.services";
import {
  fetchPaymentDetailsRequest,
  paymentDetailsError,
  paymentDetailsSuccess,
  uploadPaymentDetailsError,
  uploadPaymentDetailsRequest,
  uploadPaymentDetailsSuccess,
} from "./Payment.slice";
import { setAlertInfo } from "../Common/Common.slice";

function* paymentDetailsWatcher({ payload }: any) {
  try {
    const { data } = yield call(getRazorPayOrderId, payload);
    if (data.policyStatus !== "ACCEPT") {
      yield put(
        setAlertInfo({
          open: true,
          type: "FAILED",
          description: data?.ErrorMsg ? data?.ErrorMsg : "Unknown Error!",
        })
      );
      throw new Error(data);
    }
    yield put(paymentDetailsSuccess(data));
  } catch (e: any) {
    console.log(e);

    yield put(paymentDetailsError(e?.ErrorMsg));
  }
}

function* uploadPaymentDataWatcher({ payload }: any) {
  try {
    const { data } = yield call(uploadPaymentDataEffect, payload);
    yield put(uploadPaymentDetailsSuccess(data));
  } catch (e: any) {
    yield put(uploadPaymentDetailsError(e?.ErrorMsg));
  }
}

function* watchPayments() {
  yield takeEvery(fetchPaymentDetailsRequest.type, paymentDetailsWatcher);
  yield takeEvery(uploadPaymentDetailsRequest.type, uploadPaymentDataWatcher);
}

export { watchPayments };
