const ROUTES = {
  accessTokenRequest: {
    url: '/oauth/token',
  },
  loginRequest: {
    url: 'LoginValidation',
  },
  resendOtpRequest: {
    url: 'ResendOTP',
  },
  verifyOtpRequest: {
    url: 'OTPValidation',
  },
  setMpinRequest: {
    url: 'SaveMPIN',
  },
  verifyMpinRequest: {
    url: 'MPINValidation',
  },
  signUp: {
    url: 'SignUp',
  },
  initiateEkyc: {
    url: 'EkycInititate',
  },
  saveEkycData: {
    url: 'SaveKYCData',
  },
  //policies
  policy: {
    url: 'ListPolicy',
  },
  policyDocument: {
    url: 'DocumentDownload',
  },
  listInsurer: {
    url: 'ListInsurer',
  },
  addPolicy: {
    url: 'AddPolicy',
  },
  viewPolicy: {
    url: 'ViewPolicyDetails',
  },
};

const ProfileRoutes = {
  userProfile: {
    url: 'ProfileData',
  },
  familyList: {
    url: 'ListFamilyMembers',
  },
  addFamilyMember: {
    url: 'AddFamilyMember',
  },
  familyRelationShip: {
    url: 'ListRelationships',
  },
  generateOtpForMobileEmail: {
    url: 'GenerateOTP',
  },
  verifyOtpForMobileEmail: {
    url: 'OTPValidation',
  },
  saveFamily: {
    url: 'SaveVerificationStatus',
  },
  updateAr: {
    url: 'UpdateARdetails',
  },
};

const MobileNumberRoutes = {
  listAllMobileNumber: {
    url: 'ListMobileNo_Email',
  },
  listAllPolicies: {
    url: 'ListPoliciesForMobile',
  },
  updateMobileNumbers: {
    url: 'UpdatePolicyMobileEmail',
  },
  generateOtpForMobile: {
    url: 'GenerateOTP',
  },
  verifyOtpForMobile: {
    url: 'OTPValidation',
  },
};

const EmailRoutes = {
  listAllPoliciesForEmail: {
    url: 'ListPoliciesForEmail',
  },
  generateOtpForEmail: {
    url: 'GenerateOTP',
  },
  verifyOtpForEmail: {
    url: 'OTPValidation',
  },
  updateEmail: {
    url: 'UpdatePolicyMobileEmail',
  },
};

const AddressRoutes = {
  listAllAddress: {
    url: 'ListofAddress',
  },
  listAllPoliciesForAddress: {
    url: 'ListPoliciesForAddress',
  },
  finalSubmitForAddress: {
    url: 'UpdatePolicyAddress',
  },
};

const BankRoutes = {
  listAllBanks: {
    url: 'ListBank',
  },
  listAllPoliciesForBanks: {
    url: 'ListPoliciesForBank',
  },
  generateOtpForBank: {
    url: 'GenerateOTP',
  },
  verifyOtpForBank: {
    url: 'AddMobileEmailAddressBankInWorkflow',
  },
  updateForBank: {
    url: 'UpdatePolicyBank',
  },
  uploadCanceledCheque: {
    url: 'UploadCheque',
  },
};

const utils = {
  upload: {
    url: 'UploadImage',
  },
  uploadFamily: {
    url: 'UploadFamilyImage',
  },
};

const DashboardRoutes = {
  policiesList: {
    url: 'Dashboard',
  },
};

const NomineeRoutes = {
  nominesLists: {
    url: 'NomineeList',
  },
  editNominee: {
    url: 'UpdateNomineeList',
  },
  familiesList: {
    url: 'ListFamilyMembers',
  },
};

const NotificationsRoutes = {
  notificationsList: {
    url: 'ListNotifications',
  },
};
export {
  DashboardRoutes,
  ProfileRoutes,
  MobileNumberRoutes,
  EmailRoutes,
  AddressRoutes,
  BankRoutes,
  utils,
  NomineeRoutes,
  NotificationsRoutes,
};

export default ROUTES;
