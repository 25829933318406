import React from 'react';
import { Divider, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import '../../TrackTransaction.scss';
import LIC from '../../../../../../common/icons/LIC.png';
import Separator from '../../../../../../common/ui/Separator';
import LabelWithIcon from '../../../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRupee } from '../../../../../../common/icons/orange_rupee.svg';
import theme from '../../../../../../common/theme';

const StepThree = () => {
  return (
    <Grid container spacing={2} className='py-10 px-10'>
      <Grid item xs={3}>
        <Box width={50} height={50} className='image-box mt-5'>
          <img src={LIC} width={42} height={27} className='mt-10' />
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Typography
          className='fw-600'
          color={theme.palette.primary.main}
          fontSize={14}
        >
          Financier Name
        </Typography>
        <Typography color={theme.palette.primary.main} fontSize={12}>
          Loan Amount
        </Typography>
        <Grid container>
          <Grid item xs={5}>
            <LabelWithIcon
              svgClassName='mb-2'
              justifyContent={'flex-start'}
              Icon={OrangeRupee}
              label={'XXXXXX'}
              labelProps={{
                color: theme.palette.grey.A700,
                fontSize: 14,
                fontWeight: 600,
              }}
            />
          </Grid>
          <Grid item xs={7} textAlign={'left'}>
            <Typography component={'span'}>
              @
              <Typography
                component={'span'}
                className='ml-5 '
                fontWeight={'bold'}
              >
                xx
              </Typography>
              % interest
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3}>
            <Typography color={theme.palette.primary.main} fontSize={12}>
              Charges:
            </Typography>
          </Grid>
          <Grid item xs={6} className='ml-5'>
            <LabelWithIcon
              svgClassName='mb-2'
              justifyContent={'flex-start'}
              Icon={OrangeRupee}
              label={'XXXXXX'}
              labelProps={{
                color: theme.palette.grey.A700,
                fontSize: 14,
                fontWeight: 600,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className='divider'>
        <Separator color={' #d7d7d7'} />
      </Grid>
      <Grid item xs={12} className='mb-10'>
        <Typography
          className='fw-600'
          color={theme.palette.primary.main}
          fontSize={14}
        >
          Repayment option selected
        </Typography>
        <Typography
          className='fw-600'
          color={theme.palette.common.black}
          fontSize={14}
        >
          Decide Later
        </Typography>
      </Grid>
    </Grid>
  );
};

export default StepThree;
