import React, { useState, useEffect } from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import UploadGif from "../../../../common/icons/update-mobile/upload_gif.gif";
import { CommonModal } from "../../../../common/ui/Modal/CommonModal";

const UploadModal = ({ open }: any) => {
  const theme = useTheme();

  return (
    <CommonModal
      onClose={() => false}
      open={open}
      modalClassName="px-20 py-20"
      boxProps={{ width: 354 }}
    >
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "60vh",
        }}
      >
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item alignItems={"center"} justifyContent={"center"}>
            <Grid item className="">
              <img src={UploadGif} height={82} width={82} />
            </Grid>
            <Grid item className="mt-10">
              <Typography
                className="f-16 fw-600"
                color={theme.palette.primary.main}
              >
                Uploading...
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default UploadModal;
