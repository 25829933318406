import React from "react";
import MuiAppBar from "@mui/material/AppBar";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ReactComponent as ArrowLeftIcon } from "../../common/icons/left_arrow-icon.svg";
import SvgConverter from "../../common/SvgConverter";
import { useLocation, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

const WithBreadCrumpsScreen = ({
  ScreenComponent,
  HeaderComponent = React.Fragment,
  hideBackButton = false,
}: {
  ScreenComponent: React.FC;
  HeaderComponent?: React.FC;
  hideBackButton?: boolean | undefined;
}) => {
  const location = useLocation();
  const appBarRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const [contentHeight, setContentHeight] = React.useState(0);

  React.useEffect(() => {
    if (appBarRef.current) {
      setContentHeight(appBarRef?.current?.offsetHeight);
    }
  }, [appBarRef, location?.pathname]);

  return (
    <>
      <MuiAppBar
        ref={appBarRef}
        position="fixed"
        sx={{ backgroundColor: theme.palette.common.white }}
        elevation={0}
        className={"px-50 py-20"}
      >
        <Grid container alignItems={"center"}>
          <Grid item>
            <Grid container alignItems={"center"}>
              {hideBackButton || (
                <Grid item className="mr-13">
                  <IconButton onClick={() => navigate(-1)} className={"p-0"}>
                    <SvgConverter
                      Icon={ArrowLeftIcon}
                      className={"go-back-icon d-flex"}
                    />
                  </IconButton>
                </Grid>
              )}

              <Grid item>
                <Typography fontSize={16} color={"primary"} fontWeight={600}>
                  Profile
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item flex={1}>
            <HeaderComponent />
          </Grid>
        </Grid>
      </MuiAppBar>
      <Box
        sx={{
          paddingTop: `${contentHeight - 35}px`,
        }}
      >
        <ScreenComponent />
      </Box>
    </>
  );
};

export default WithBreadCrumpsScreen;
