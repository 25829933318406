import React, { useState } from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import AddressSection from "../AddressSection";
import { APP_ROUTES } from "../../../../common/constants/Routes";
import CustomButton from "../../../../common/ui/CustomButton";
import AddressLineGenerator from "../../../../common/ui/AddressLineGenerator";
import PolicyCard from "../../Common/PolicyCard";
import { fetchAddressFromPolicy } from "../Address.slice";
import { filter, propEq } from "ramda";
import { PolicyAlternateCodes } from "../../../../common/types/PolicyTypes";

const CorrespondenceAddress = ({
  addressDetails,
  index,
  policies,
  handleOnChangeAddressClick,
  handleAssignPolicies,
  toggleRemoveNumberModal,
}: any) => {
  const lifePolicies = filter(
    propEq(PolicyAlternateCodes.LIFE, "alternateCode")
  );
  const healthPolicies: any = filter(
    propEq(PolicyAlternateCodes.HEALTH, "alternateCode")
  );
  const assetPolicies: any = filter(
    propEq(PolicyAlternateCodes.ASSET, "alternateCode")
  );

  return (
    <Grid item className="Manage-bank">
      <Grid
        container
        justifyContent="space-between"
        flexWrap="nowrap"
        className="mb-10"
        spacing={1}
      >
        <Grid item>
          <Grid container spacing={1} flexWrap={"nowrap"}>
            <Grid item className="ml-n20">
              <Typography color="black" fontSize={20} fontWeight={600}>
                {index + 1}.
              </Typography>
            </Grid>
            <Grid item className="mt-3">
              <Typography color="primary" fontSize={16} fontWeight={600}>
                {addressDetails.city}

                <Typography component={"span"} color="black" marginLeft={1}>
                  Address
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <CustomButton
            text={"REMOVE"}
            variant="text"
            color="primary"
            fontSize={14}
            showIcon={false}
            fontWeight={400}
            onClick={() =>
              toggleRemoveNumberModal({
                ...addressDetails,
              })
            }
          />
        </Grid>
      </Grid>
      <Grid item>
        <AddressSection data={addressDetails} />
      </Grid>
      {/* <Grid item className="mt-3">
        <CustomButton
          text={"ASSIGN POLICIES"}
          variant="text"
          color="primary"
          fontSize={14}
          showIcon={false}
          fontWeight={400}
          onClick={() =>
            handleAssignPolicies(fetchAddressFromPolicy(addressDetails))
          }
        />
      </Grid> */}
      {policies?.length > 0 ? (
        <Grid item className="Policies-screen p-20 mt-10">
          {lifePolicies(policies).map((policy: any, index: number) => (
            <PolicyCard
              className={index < policies?.length - 1 ? "mb-20" : ""}
              key={policy.policyNo}
              policyDetails={policy}
              changeButtonText={"CHANGE ADDRESS"}
              onChangeClick={() => handleOnChangeAddressClick(policy)}
            />
          ))}
          {healthPolicies(policies).map((policy: any, index: number) => (
            <PolicyCard
              className={index < policies?.length - 1 ? "mb-20" : ""}
              key={policy.policyNo}
              policyDetails={policy}
              changeButtonText={"CHANGE ADDRESS"}
              onChangeClick={() => handleOnChangeAddressClick(policy)}
            />
          ))}
          {assetPolicies(policies).map((policy: any, index: number) => (
            <PolicyCard
              className={index < policies?.length - 1 ? "mb-20" : ""}
              key={policy.policyNo}
              policyDetails={policy}
              changeButtonText={"CHANGE ADDRESS"}
              onChangeClick={() => handleOnChangeAddressClick(policy)}
            />
          ))}
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default CorrespondenceAddress;
