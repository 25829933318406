import { Grid } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import './Policy.scss';
import { ReactComponent as Group_certified } from '../../../common/icons/dashboard/group_certified-icon.svg';
import { ReactComponent as Health } from '../../../common/icons/dashboard/health-icon.svg';
import { ReactComponent as Assets } from '../../../common/icons/dashboard/assets-icon.svg';
import { CustomAccordion } from '../../../common/ui/Accordion/CustomAccordion';
import ArchiveLife from './common/AccordionContent/ArchiveLife';
import ArchiveHealth from './common/AccordionContent/ArchiveHealth';
import ArchiveAssets from './common/AccordionContent/ArchiveAssets';
import { useDispatch, useSelector } from 'react-redux';
import { fetchArchivePolicy, selectArchivePolicies } from './Policies.slice';
import Separator from '../../../common/ui/Separator';
import theme from '../../../common/theme';
import { groupBy, prop } from 'ramda';
import { useLocation } from 'react-router';
import Spinner from '../../../common/ui/Spinner';
interface ArchiveProps {
  archiveLoading?: any;
}
const ArchiveScreen: React.FC<ArchiveProps> = ({ archiveLoading }) => {
  const dispatch = useDispatch();

  const archivePolicies = useSelector(selectArchivePolicies);

  const policyList = useMemo(() => {
    const groupedPolicies = groupBy(prop<string>('alternateCode'))(
      archivePolicies
    );
    return {
      lifeList: groupedPolicies.L || [],
      healthList: groupedPolicies.H || [],
      assetList: groupedPolicies.G || [],
    };
  }, [archivePolicies]);
  useEffect(() => {
    dispatch(fetchArchivePolicy());
  }, []);
  console.log('archivr-lifeList', policyList.lifeList);
  return (
    <Grid container>
      {archiveLoading ? (
        <Grid item display={'flex'} justifyContent={'center'}>
          <Spinner />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <CustomAccordion
            accordionIcon={Group_certified}
            accordionLabel={'Life'}
          >
            <ArchiveLife filteredLife={policyList.lifeList} />
          </CustomAccordion>
          <Separator color={theme.palette.grey[300]} />
          <CustomAccordion accordionIcon={Health} accordionLabel={'Health'}>
            <ArchiveHealth filteredHealth={policyList.healthList} />
          </CustomAccordion>
          <Separator color={theme.palette.grey[300]} />
          <CustomAccordion accordionIcon={Assets} accordionLabel={'Assets'}>
            <ArchiveAssets filteredAssets={policyList.assetList} />
          </CustomAccordion>
          <Separator color={theme.palette.grey[300]} />
        </Grid>
      )}
    </Grid>
  );
};

export default ArchiveScreen;
