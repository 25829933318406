import React from 'react';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import { Grid, Typography, useTheme } from '@mui/material';
import SvgConverter from '../../../common/SvgConverter';

const MaturityAndFundValue = () => {
  const theme = useTheme();

  return (
    <Grid item>
      <Grid container spacing={0.5} justifyContent={'space-between'}>
        <Grid item xs={5}>
          <Typography className='f-14' color={theme.palette.common.black}>
            Maturity Value
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Grid container spacing={0.5}>
            <Grid item>
              <SvgConverter Icon={RupeeIcon} />
            </Grid>
            <Grid item>
              <Typography className='f-14 ' color={theme.palette.primary.main}>
                XXXXXXXX
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={0.5} justifyContent={'space-between'}>
        <Grid item xs={5}>
          <Typography className='f-14' color={theme.palette.common.black}>
            Fund Value
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Grid container spacing={0.5}>
            <Grid item>
              <SvgConverter Icon={RupeeIcon} />
            </Grid>
            <Grid item>
              <Typography className='f-14 ' color={theme.palette.primary.main}>
                XXXXXXXX
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MaturityAndFundValue;
