import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {
  HandleAxiosRequest,
  HandleAxiosResponse,
  HandleAxiosResponseWithError,
} from "./interceptors";

//TODO: add headers
const axiosAuthConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_AUTH_API_URL,
  headers: {
    Authorization: `Basic ${process.env.REACT_APP_OAUTH_BEARER_TOKEN}`,
    "Content-Type": "application/x-www-form-urlencoded",
  },
  method: "POST",
};

const axiosConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  method: "POST",
  headers: {
    "Access-Control-Allow-Origin": "*",
    mode: "no-cors",
  },
};

const axiosAuthInstance: AxiosInstance = axios.create(axiosAuthConfig);
const axiosInstance: AxiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.request.use(HandleAxiosRequest, function (error) {
  // Do something with request error
  return Promise.reject(error);
});
axiosInstance.interceptors.response.use(
  HandleAxiosResponse,
  HandleAxiosResponseWithError
);

export { axiosAuthInstance, axiosInstance };
