import { Grid } from '@mui/material';
import React, { useState } from 'react';
import '../Policy.scss';
import Typography from '@mui/material/Typography';
import theme from '../../../../common/theme';
import AddPolicyForm from './AddPolicyForm';
import AddPolicyList from './AddpolicyList';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import CustomButton from '../../../../common/ui/CustomButton';
import { ReactComponent as cancel } from '../../../../common/icons/cancel-icon.svg';
const AddPolicyScreen = () => {
  const [insurercode, setInsurerCode] = useState('');
  const [policyNo, setPolicyNo] = useState('');
  return (
    <Grid container className='px-20'>
      <Grid item xs={12} justifyContent={'space-between'} className='px-15'>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Typography
              className='f-16 fw-600'
              color={theme.palette.primary.main}
            >
              Add Policies
            </Typography>
          </Grid>
          <Grid item>
            <Link to={APP_ROUTES.POLICY}>
              <CustomButton
                className='f-14  '
                text={'DONE'}
                variant={'text'}
                color={'primary'}
                showIcon={false}
                startIcon={cancel}
                type='submit'
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={3}>
          <AddPolicyForm
            policyNo={policyNo}
            setPolicyNo={setPolicyNo}
            insurercode={insurercode}
            setInsurerCode={setInsurerCode}
          />
        </Grid>
        <Grid item xs={8}>
          <AddPolicyList
            policyNo={policyNo}
            setPolicyNo={setPolicyNo}
            insurercode={insurercode}
            setInsurerCode={setInsurerCode}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddPolicyScreen;
