import { Grid } from '@mui/material';
import React from 'react';

import '../Policy.scss';
import Typography from '@mui/material/Typography';

import Separator from '../../../../common/ui/Separator';
import theme from '../../../../common/theme';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../../common/constants/Routes';
const PolicyServices = () => {
  const policyServicesData = [
    'Surrender Policy',
    'Update Address',
    'Update Mobile',
    'Update email',
    'Update Bank Details',
    'Add/Remove Nominees',
    'Fund Switch/Allocation [if applicable]',
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          className='f-16 fw-600 px-12 py-13'
          color={theme.palette.common.black}
        >
          Surrender Policy
        </Typography>
        <Separator color={theme.palette.primary.main} />
        <Link to={APP_ROUTES.MANAGE_ADDRESS}>
          <Typography
            className='f-16 fw-600 px-12 py-13'
            color={theme.palette.common.black}
          >
            Update Address
          </Typography>
        </Link>
        <Separator color={theme.palette.primary.main} />
        <Link to={APP_ROUTES.UPDATE_MOBILE}>
          <Typography
            className='f-16 fw-600 px-12 py-13'
            color={theme.palette.common.black}
          >
            Update Mobile
          </Typography>
        </Link>
        <Separator color={theme.palette.primary.main} />
        <Link to={APP_ROUTES.EMAIL}>
          <Typography
            className='f-16 fw-600 px-12 py-13'
            color={theme.palette.common.black}
          >
            Update email
          </Typography>
        </Link>
        <Separator color={theme.palette.primary.main} />
        <Link to={APP_ROUTES.BANK}>
          <Typography
            className='f-16 fw-600 px-12 py-13'
            color={theme.palette.common.black}
          >
            Update Bank Details
          </Typography>
        </Link>
        <Separator color={theme.palette.primary.main} />
        <Link to={APP_ROUTES.NOMINEE}>
          {' '}
          <Typography
            className='f-16 fw-600 px-12 py-13'
            color={theme.palette.common.black}
          >
            Add/Remove Nominees
          </Typography>{' '}
        </Link>

        <Separator color={theme.palette.primary.main} />
        <Typography
          className='f-16 fw-600 px-12 py-13'
          color={theme.palette.common.black}
        >
          Fund Switch/Allocation [if applicable]
        </Typography>
        <Separator color={theme.palette.primary.main} />
        <Typography
          className='f-16 fw-600 px-5 py-10'
          color={theme.palette.common.black}
        >
          Loan against the policy [if applicable]
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PolicyServices;
