import { useState } from 'react';
import { TabList } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import './Policy.scss';
import Box from '@mui/material/Box/Box';
import Tab from '@mui/material/Tab/Tab';
import Grid from '@mui/material/Grid';
import ActiveScreen from './ActiveScreen';
import ArchiveScreen from './ArchiveScreen';
import Separator from '../../../common/ui/Separator';
import theme from '../../../common/theme';
import { useSelector } from 'react-redux';
import { selectIsPolicyLoading } from './Policies.slice';

interface UserProps {
  prevPath?: any;
}
export const PolicyTab: React.FC<UserProps> = ({ prevPath }) => {
  const [value, setValue] = useState('Active');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const policyLoading = useSelector(selectIsPolicyLoading);

  return (
    <Box>
      <TabContext value={value}>
        <Box>
          <TabList
            onChange={handleChange}
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
            textColor='inherit'
          >
            <Tab
              label='Active'
              value='Active'
              sx={{
                fontWeight: value === 'Active' ? 600 : 600,
              }}
              className=' mt-5 mr-0'
            />

            <Grid item xs={1}>
              <Separator
                orientation='vertical'
                color={theme.palette.secondary.main}
                borderWidth={3}
              />
            </Grid>

            <Tab
              label='Archive'
              value='Archive'
              sx={{
                fontWeight: value === 'Archive' ? 600 : 500,
              }}
              className='mt-5 ml-20'
            />
          </TabList>
        </Box>
        <Grid item className='py-10'>
          <Separator color={theme.palette.primary.main} />
        </Grid>
        <TabPanel value='Active'>
          <ActiveScreen prevPath={prevPath} activeLoading={policyLoading} />
        </TabPanel>
        <TabPanel value='Archive'>
          <ArchiveScreen archiveLoading={policyLoading} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default PolicyTab;
