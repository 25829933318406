import { call, put, select, takeEvery } from "typed-redux-saga";
import {
  fetchPoliciesForEmail,
  generateOtpEmail,
  updateForEmail,
  verifyOtpEmail,
} from "./Emails.service";
import {
  errorLoadingPoliciesForEmails,
  loadPoliciesForEmails,
  fetchPoliciesForEmails as fetchPoliciesForEmailsAction,
  generateOtpForEmailRequest,
  generateOtpForEmailSuccess,
  generateOtpForEmailError,
  verifyEmailRequest,
  verifyEmailSuccess,
  verifyEmailError,
  updateEmailChangesSuccess,
  updateEmailChangesError,
  updateEmailChangesRequest,
  selectEmailChangesList,
} from "./Emails.slice";
import { selectIsUserLoggedIn } from "../../Auth/auth.slice";
import { RequestTypes } from "../../../common/utils";
import { setAlertInfo } from "../Common/Common.slice";

function* handleFetchPoliciesForEmails() {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
      },
    };
    let {
      data: { EmailBasedPolicies, UnassignedEmails },
    }: any = yield* call(fetchPoliciesForEmail, requestPayload);
    EmailBasedPolicies = EmailBasedPolicies?.map((row: any) => ({
      ...row,
      email: String(row.email).trim() === "" ? "null" : row.email,
    }));
    yield* put(loadPoliciesForEmails({ EmailBasedPolicies, UnassignedEmails }));
  } catch (e: any) {
    yield* put(errorLoadingPoliciesForEmails(e.message));
  }
}

function* handleUpdateEmail({ payload: { handleSuccess } }: any) {
  try {
    const Actions = yield* select(selectEmailChangesList);
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Actions,
      Customer: {
        eiaNo: user.eiaNo,
      },
      flag: RequestTypes.EMAIL,
    };
    const { data }: any = yield* call(updateForEmail, requestPayload);
    yield* put(updateEmailChangesSuccess(data || []));
    if (handleSuccess) {
      handleSuccess();
    }
    yield put(
      setAlertInfo({
        open: true,
        type: "SUCCESS",
        description: "Successfully Updated Email policies.",
      })
    );
  } catch (e: any) {
    yield* put(updateEmailChangesError(e.message));
  }
}

function* handleGenerateOtpForEmail({
  payload: { handleSuccess, ...payload },
}: any) {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        value: payload.email,
        flag: "EMAIL",
        otpPurpose: "UPDATE_EMAIL",
        eiaNo: user.eiaNo,
      },
    };
    const { data }: any = yield* call(generateOtpEmail, requestPayload);
    yield* put(generateOtpForEmailSuccess(data));
    if (handleSuccess) {
      handleSuccess(data);
    }
  } catch (e: any) {
    yield* put(generateOtpForEmailError(e.message));
  }
}

function* handleVerifyEmailOtp({
  payload: { handleSuccess, ...payload },
}: any) {
  try {
    const requestPayload = {
      Customer: {
        value: payload.email,
        otp: payload.otpCode,
        flag: "EMAIL",
        otpPurpose: "UPDATE_EMAIL",
      },
    };
    const { data }: any = yield* call(verifyOtpEmail, requestPayload);
    yield* put(verifyEmailSuccess(data));
    if (handleSuccess) {
      handleSuccess(data);
    }
  } catch (e: any) {
    yield* put(verifyEmailError(e.message));
  }
}

function* watchEmails() {
  yield* takeEvery(
    fetchPoliciesForEmailsAction.type,
    handleFetchPoliciesForEmails
  );
  yield* takeEvery(generateOtpForEmailRequest.type, handleGenerateOtpForEmail);
  yield* takeEvery(verifyEmailRequest.type, handleVerifyEmailOtp);
  yield* takeEvery(updateEmailChangesRequest.type, handleUpdateEmail);
}

export { watchEmails };
