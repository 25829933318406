import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as alert } from '../../../common/icons/profile/alert.svg';

const NotVerifiedMobilenumber = () => {
  const theme = useTheme();
  return (
    <Grid item>
      {/* <Grid container spacing={1} className='mt-20'> */}
        {/* <Grid item>
          <Typography className='f-14 fw-600' color={'primary'}>
            Mobile Number
          </Typography>
        </Grid> */}
        <Grid item className='f-12' color={theme.palette.common.black}>
          <SvgConverter Icon={alert} />
        </Grid>
      {/* </Grid> */}
    </Grid>
  );
};

export default NotVerifiedMobilenumber;
