import { axiosAuthInstance, axiosInstance } from "../../common/axiosInstance";
import ROUTES from "../../common/routes";

const axios = axiosInstance;
const axiosAuth = axiosAuthInstance;

const {
  loginRequest,
  accessTokenRequest,
  resendOtpRequest,
  verifyOtpRequest,
  setMpinRequest,
  verifyMpinRequest,
  signUp,
  initiateEkyc,
  saveEkycData,
} = ROUTES;

//TODO: uncomment when working on api
const getAccessToken = () =>
  axiosAuth.request({
    ...accessTokenRequest,
    data: {
      username: "RI_USER",
      password: "ri1$1234",
      grant_type: "password",
    },
  });

const submitLoginRequestInfo = (data: any) =>
  axios.request({ ...loginRequest, data });

const fetchResendOtpRequest = (data: any) =>
  axios.request({ ...resendOtpRequest, data });

const fetchOtpVerifyRequest = (data: any) =>
  axios.request({ ...verifyOtpRequest, data });

const fetchSetMpinRequest = (data: any) =>
  axios.request({ ...setMpinRequest, data });

const fetchVerifyMpinRequest = (data: any) =>
  axios.request({ ...verifyMpinRequest, data });

const fetchSignUpRequest = (data: any) => axios.request({ ...signUp, data });
const initiateEkycRequest = (data: any) =>
  axios.request({ ...initiateEkyc, data });

const saveEkycRequest = (data: any) => axios.request({ ...saveEkycData, data });

// const getEkycDetailsService = (ekycId: any) =>
//   axiosMain({
//     method: 'get',
//     url: `${config.camsEkycUrl}/api/v1/client/ekyc-requests/view/${ekycId}`,
//     headers: {
//       Authorization: `Bearer ${config.camsEkycBearerToken}`,
//     },
//   });

export {
  getAccessToken,
  submitLoginRequestInfo,
  fetchResendOtpRequest,
  fetchOtpVerifyRequest,
  fetchSetMpinRequest,
  fetchVerifyMpinRequest,
  fetchSignUpRequest,
  initiateEkycRequest,
  // getEkycDetailsService,
  saveEkycRequest,
};
