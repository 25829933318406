import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import { Controller, useForm } from 'react-hook-form';
import data from '../../../../common/constants/FormConstants.json';

const NomineeAddress = ({
  index,
  item,
  type,
  setValue,
  register,
  errors,
  address1,
  submit,
}: {
  index: number;
  item: any;
  type: any;
  setValue: any;
  register: any;
  errors: any;
  address1?: any;
  submit:any;
}) => {
  const theme = useTheme();

  return (
    <form onSubmit={submit}>
      <Grid item key={item.id}>
        <Grid item className='mb-5'>
          <FormInput
            type='text'
            placeholder='House #,Street #'
            value={item.address1}
            onChange={(value: any) => {
              setValue(`nomineeData.${index}.address1`, value, {
                shouldValidate: true,
              });
            }}
            color={theme.palette.common.black}
            name='address1'
            inputProps={{
              className: 'px-10',
              ...register(`nomineeData.${index}.address1`, {
                required: {
                  value: true,
                  message: data.STREET,
                },
              }),
            }}
            errors={errors}
          />
        </Grid>

        <Grid item className='mb-5'>
          <FormInput
            type='text'
            placeholder='Locality,Area'
            name='address2'
            value={item.address2}
            color={theme.palette.common.black}
            inputProps={{
              className: 'px-10',
              ...register(`nomineeData.${index}.address2`, {
                required: {
                  value: true,
                  message: data.AREA,
                },
              }),
            }}
            errors={errors}
          />
        </Grid>
        <Grid container spacing={3} className='mb-5'>
          <Grid item xs={6} sm={6}>
            <FormInput
              type='text'
              placeholder='City'
              value={item.city}
              name='city'
              color={theme.palette.common.black}
              inputProps={{
                className: 'px-10',
                ...register(`nomineeData.${index}.city`, {
                  required: {
                    value: true,
                    message: data.CITY,
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormInput
              type='text'
              placeholder='District'
              value={item.address3}
              name='address3'
              color={theme.palette.common.black}
              inputProps={{
                className: 'px-10',
                ...register(`nomineeData.${index}.address3`, {
                  required: {
                    value: true,
                    message: data.DISTRICT,
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className='mb-5'>
          <Grid item xs={6} sm={6}>
            <FormInput
              type='text'
              placeholder='State'
              value={item.state}
              name='state'
              color={theme.palette.common.black}
              inputProps={{
                className: 'px-10',
                ...register(`nomineeData.${index}.state`, {
                  required: {
                    value: true,
                    message: data.STATE,
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormInput
              type='text'
              placeholder='PIN'
              name='pincode'
              value={item.pincode}
              color={theme.palette.common.black}
              inputProps={{
                className: 'px-10',
                ...register(`nomineeData.${index}.pincode`, {
                  required: {
                    value: true,
                    message: data.PIN,
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default NomineeAddress;
