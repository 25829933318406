import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import { ReactComponent as cancel } from "../../../../common/icons/cancel-icon.svg";
import SvgConverter from "../../../../common/SvgConverter";
import HdfcIcon from "../../../../common/icons/update-mobile/hdfc_bank_icon.png";
import { ReactComponent as MobileIcon } from "../../../../common/icons/update-mobile/camera_icon.svg";
import { ReactComponent as BackSlashIcon } from "../../../../common/icons/update-mobile/backSlash_icon.svg";
import { ReactComponent as UploaderIcon } from "../../../../common/icons/update-mobile/Uploader_icon.svg";
import { ReactComponent as SkipIcon } from "../../../../common/icons/update-mobile/skip_icon.svg";
import { CommonModal } from "../../../../common/ui/Modal/CommonModal";
import CustomButton from "../../../../common/ui/CustomButton";
import ImagePickerModal from "../../../../common/ui/Modal/ImagePickerModal";
import { appendBase64PrefixForImage } from "../../../../common/utils";

const UploadImageBankModal = ({
  open,
  setOpen,
  bankDetails,
  accountDetails,
  handleUploadModal,
  uploadChequeIsDone,
  uploadChequeData,
  handleSkip,
  imagePickerModalVisible,
  setImagePickerModalVisible,
  handleAssignPolicies,
}: any) => {
  const theme = useTheme();

  return (
    <>
      <CommonModal
        onClose={() => false}
        open={open}
        modalClassName="px-20 py-20"
        boxProps={{ width: 354 }}
      >
        <Grid container justifyContent={"center"}>
          <Grid item className="assign-policy">
            <Grid item className="  mb-10" textAlign={"center"}>
              <img src={HdfcIcon} alt="logo" width={"73"} height={"77"} />
            </Grid>
            <Grid item textAlign={"center"}>
              <Grid item>
                <Grid item>
                  <Typography
                    className="f-16 fw-600"
                    color={theme.palette.primary.main}
                  >
                    {bankDetails?.BANK}
                  </Typography>
                  <Typography
                    className="f-16 fw-600"
                    color={theme.palette.common.black}
                  >
                    {accountDetails?.accountHolderName}
                  </Typography>

                  <Typography
                    className="f-16"
                    color={theme.palette.common.black}
                  >
                    Acc# {accountDetails?.accountNumber}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className="f-16"
                    color={theme.palette.common.black}
                  >
                    {bankDetails?.BANK}
                  </Typography>
                  <Typography
                    className="f-16"
                    color={theme.palette.common.black}
                  >
                    {bankDetails?.BRANCH}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className="f-16"
                    color={theme.palette.common.black}
                  >
                    {bankDetails?.IFSC}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {uploadChequeIsDone ? (
              <>
                <Grid>
                  <Grid>
                    <img
                      src={appendBase64PrefixForImage(
                        uploadChequeData?.chequeImage
                      )}
                      alt="cheque"
                      style={{
                        width: "100%",
                        height: 150,
                        objectFit: "contain",
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid item className="mt-10">
                  <Typography
                    className="f-16 fw-600"
                    color={theme.palette.primary.main}
                    textAlign={"center"}
                  >
                    Please upload image of cancelled cheque.
                  </Typography>

                  <Typography
                    className="f-12 my-10"
                    color={theme.palette.grey[400]}
                    textAlign={"center"}
                  >
                    As per govt. regulations, to assign a new bank account or
                    change an existing assigned bank account, a copy of
                    cancelled cheque is mandatory.
                  </Typography>
                </Grid>
              </>
            )}

            <Grid item className="mt-20">
              <Grid
                container
                spacing={0.5}
                alignItems={"center"}
                direction={"column"}
              >
                {uploadChequeIsDone ? (
                  <>
                    <Grid item className="">
                      <CustomButton
                        text={"ASSIGN POLICIES"}
                        variant={"text"}
                        color={"primary"}
                        startIcon={cancel}
                        showIcon={false}
                        onClick={handleAssignPolicies}
                        type="submit"
                        fontWeight={600}
                        fontSize={14}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item className="">
                      <Grid container flexWrap={"nowrap"}>
                        <Grid item>
                          <SvgConverter
                            Icon={MobileIcon}
                            width={"22"}
                            height={"20"}
                          />
                        </Grid>
                        <Grid item>
                          <SvgConverter
                            Icon={BackSlashIcon}
                            width={"12"}
                            height={"24"}
                            className="image-upload-modal ml-7"
                          />
                        </Grid>
                        <Grid item>
                          <SvgConverter
                            Icon={UploaderIcon}
                            width={"22"}
                            height={"22"}
                            className="ml-n2"
                          />
                        </Grid>
                        <Grid item className="">
                          <CustomButton
                            text={"TAKE A PIC / BROWSE"}
                            variant={"text"}
                            color={"primary"}
                            showIcon={false}
                            onClick={() => setImagePickerModalVisible(true)}
                            type="submit"
                            fontWeight={600}
                            fontSize={14}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid item className="">
                  <CustomButton
                    text={"SKIP FOR NOW"}
                    variant={"text"}
                    color={"primary"}
                    startIcon={SkipIcon}
                    showIcon={false}
                    onClick={() => handleSkip(uploadChequeIsDone)}
                    type="submit"
                    fontWeight={600}
                    fontSize={14}
                  />
                </Grid>
                <Grid item className="">
                  <CustomButton
                    text={"CANCEL"}
                    variant={"text"}
                    color={"primary"}
                    startIcon={cancel}
                    showIcon={false}
                    onClick={() => setOpen(false)}
                    type="submit"
                    fontWeight={400}
                    fontSize={14}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CommonModal>
      <ImagePickerModal
        open={imagePickerModalVisible}
        setOpen={setImagePickerModalVisible}
        handleImage={handleUploadModal}
      />
    </>
  );
};
export default UploadImageBankModal;
