import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import "../LoanModule/LoanModule.scss";
import SvgConverter from "../../../common/SvgConverter";
import { ReactComponent as RupeeIcon } from "../../../common/icons/LoanModule/rupee-icon.svg";
import RecommendedCombination from "./RecommendedCombination";
import MaxedOutCombination from "./MaxedOutCombination";
import LoanOptionHeader from "./LoanOptionHeader";
import NeedMoneyHeader from "./NeedMoneyHeader";
// import MaxLoanCombination from './MaxLoanCombination';

// import MaxLoanCombination from './MaxLoanCombination';

const LoanOptionScreen = () => {
  const theme = useTheme();
  return (
    <>
      <Grid item>
        <NeedMoneyHeader />
      </Grid>
      <Grid item className="my-8">
        <Grid container flexWrap={"nowrap"} spacing={1}>
          <Grid item>
            <Typography
              className="f-14 fw-600 mt-3"
              color={theme.palette.primary.main}
            >
              Need
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={0.5}>
              <Grid item>
                <SvgConverter
                  Icon={RupeeIcon}
                  width={8}
                  height={12}
                  className="mt-8"
                />
              </Grid>
              <Grid item>
                <Typography className="f-18" color={theme.palette.common.black}>
                  XXXXXXXX
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Typography
              className="f-14 fw-600 mt-3"
              color={theme.palette.primary.main}
            >
              By
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              className="f-18 fw-600"
              color={theme.palette.common.black}
            >
              DDMMYYYY
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <LoanOptionHeader />
      </Grid>

      <Grid container className="mt-15" justifyContent={"space-between"}>
        <Grid item>
          <RecommendedCombination />
        </Grid>
        <Grid item>{/* <MaxLoanCombination /> */}</Grid>
        <Grid item>
          <MaxedOutCombination />
        </Grid>
      </Grid>
    </>
  );
};

export default LoanOptionScreen;
