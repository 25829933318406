import React from 'react';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import theme from '../../../../../common/theme';
import '../TrackTransaction.scss';
import { ReactComponent as OrangeRupee } from '../../../../../common/icons/orange_rupee.svg';
import LabelWithIcon from '../../../../../common/ui/LabelWithIcon';
const TrackTransactionFooter = () => {
  return (
    <Box className='footer-card py-15'>
      <Grid container rowSpacing={2}>
        <Grid item xs={6}>
          <Typography className='f-12' color={theme.palette.common.black}>
            Total Charges
          </Typography>
          <Typography className='f-12' color={theme.palette.primary.main}>
            [SEE DETAILS]
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <LabelWithIcon
            svgClassName='mb-2 ml-8'
            justifyContent={'flex-start'}
            Icon={OrangeRupee}
            label={'XXX (-)'}
            labelProps={{
              color: theme.palette.grey.A700,
              fontSize: 14,
              fontWeight: 600,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            className='f-14 fw-600'
            color={theme.palette.common.black}
          >
            Total Funds Availed
          </Typography>
          <Typography
            className='f-14 fw-600'
            color={theme.palette.common.black}
          >
            (1+2+3)
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <LabelWithIcon
            svgClassName='mb-2 ml-10'
            justifyContent={'flex-start'}
            Icon={OrangeRupee}
            label={'XXXXXX'}
            labelProps={{
              color: theme.palette.common.black,
              fontSize: 14,
              fontWeight: 600,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            className='f-14 fw-600'
            color={theme.palette.common.black}
          >
            Loss of cover
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            className='f-14 fw-600 ml-12'
            color={theme.palette.common.black}
          >
            Nil
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TrackTransactionFooter;
