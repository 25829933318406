import React from 'react';

import Typography from '@mui/material/Typography';
import DashboardCardLayout from './common/DashboardCardLayout';
import { Grid } from '@mui/material';
import PolicyCardHeader from './common/PolicyCardHeader';
import PolicyCardCost from './common/PolicyCardCost';

interface UserProps {
  healthDetails?: any;
}
export const HealthScreen: React.FC<UserProps> = ({ healthDetails }) => {
  return (
    <DashboardCardLayout>
      <div className='mb-30'>
        <PolicyCardHeader type={'health'} />
      </div>

      <Grid
        container
        flexDirection={'row'}
        spacing={3}
        justifyContent={'space-between'}
      >
        <Grid item className='mb-20'>
          <div className='w-280px'>
            <PolicyCardCost
              title='Current Total Hospitalization Cover'
              value={healthDetails?.totalHospitalizationCoverAmount}
              policyCounts={2}
            />
            <div className='mt-16'>
              <Typography fontSize={28} fontWeight={300} color={'secondary'}>
                {healthDetails?.countHospitalizationCover}
              </Typography>
              <Typography fontSize={14} color={'primary'}>
                network hospitals in Chennai area
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item className='mb-20'>
          <div className='w-280px'>
            <PolicyCardCost
              title='Current Critical Care Cover'
              value={healthDetails?.totalCriticalCareCoverAmount}
              policyCounts={healthDetails?.countCriticalCareCover}
            />
            <div className='mt-16'>
              <Typography fontSize={28} fontWeight={300} color={'secondary'}>
                {healthDetails?.countIllnessesCovered}
              </Typography>
              <Typography fontSize={14} color={'primary'}>
                illnesses covered
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </DashboardCardLayout>
  );
};

export default HealthScreen;
