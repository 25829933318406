import React, { ReactElement } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import SvgConverter from '../../../common/SvgConverter';
// import { ReactComponent as RupeeIcon } from '../../../../common/icons/LoanModule/rupee-icon.svg';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
interface iconInterface {
  Icon?: any;
  width?: number | undefined;
  height?: number | undefined;
  className?: string;
}
interface ProductProps {
  product?: string;
  lifeAssured?: string;
  selfName?: string;
  charges?: string;
  rupeeOne?: string | number;
  rupeeTwo?: string | number;
  rupeeThree?: string | number;
  IconProps?: iconInterface | undefined;
  repaymentOptions?: string;
  decideLater?: string;
}

const SelectFinancierDetails = ({
  product,
  lifeAssured,
  selfName,
  charges,
  rupeeOne,
  rupeeTwo,
  rupeeThree,
  IconProps,
  repaymentOptions,
  decideLater,
}: ProductProps) => {
  const theme = useTheme();
  return (
    <Grid container spacing={0.2}>
      <Grid item xs={12}>
        <Typography className='f-14 fw-600' color={'primary'}>
          {product}
        </Typography>
      </Grid>
      <Grid item xs={12} color={theme.palette.primary.main}>
        <Typography className='f-12'>{lifeAssured}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className='f-12 fw-600'>{selfName}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={0.5}>
          <Grid item>
            <SvgConverter
              Icon={IconProps?.Icon}
              width={IconProps?.width || 5}
              height={IconProps?.height || 9}
              className={IconProps?.className || 'mb-5'}
            />
          </Grid>
          <Grid item>
            <Typography
              className='f-12 fw-600'
              color={theme.palette.common.black}
            >
              {rupeeOne}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className='f-12' color={theme.palette.grey.A700}>
              @
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              className='f-12 fw-600'
              color={theme.palette.common.black}
            >
              {rupeeTwo}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className='f-12' color={theme.palette.grey.A700}>
              % of interest
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className='mt-n4'>
        <Grid container spacing={0.5} flexWrap={'nowrap'}>
          <Grid item>
            <Typography className='f-12' color={theme.palette.primary.main}>
              {charges}
            </Typography>
          </Grid>
          <Grid item>
            <SvgConverter
              Icon={IconProps?.Icon}
              width={IconProps?.width || 5}
              height={IconProps?.height || 9}
              className={IconProps?.className || 'mb-5'}
            />
          </Grid>
          <Grid item>
            <Typography
              className='f-12 fw-600'
              color={theme.palette.common.black}
            >
              {rupeeThree}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography className='f-12' color={theme.palette.primary.main}>
          {repaymentOptions}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className='f-12 fw-600'>{decideLater}</Typography>
      </Grid>
    </Grid>
  );
};

export default SelectFinancierDetails;
