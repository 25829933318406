import React, { useRef } from 'react';
import LinearBar from './ProgressBar/LinearBar';
import Separator from './Separator';
import { ReactComponent as RefreshIcon } from '../../common/icons/dashboard/circle_arrow-icon.svg';
import { ReactComponent as OrangeArrow } from '../../common/icons/orange-arrow.svg';
import PolicyCardCost from '../../features/App/DashboardScreen/common/PolicyCardCost';
import SvgConverter from '../SvgConverter';
import { Divider, Grid, IconButton, useTheme } from '@mui/material';
const LifeProgressBarWithValues = ({ progressVal, total }: any) => {
  const firstCardRef = useRef<any>(null);
  const clampProgress = Math.max(0, Math.min(1, progressVal));
  console.log('progressVal', progressVal);
  return (
    <div className='' style={{ position: 'relative' }}>
      <div style={{ transform: 'rotateY(180deg)' }}>
        <LinearBar progressVal={progressVal} total={total} />
      </div>
      <Grid
        container
        flexWrap={'nowrap'}
        className='h-100 w-0'
        style={{
          position: 'absolute',
          right: `${clampProgress * 100}%`,
          marginRight: firstCardRef?.current?.clientWidth + 5,
          transition: 'all .6s',
        }}
        justifyContent={'start'}
        // flexDirection={progressVal > 50 ? 'row-reverse' : 'row'}
        alignItems={'stretch'}
      >
        {clampProgress * 100 > 50 ? (
          <>
            {' '}
            <Grid item ref={firstCardRef}>
              <IconButton className='p-0'>
                <SvgConverter Icon={RefreshIcon} />
              </IconButton>
            </Grid>
            <Grid
              container
              alignItems='center'
              className='mx-5'
              justifyContent={'space-around'}
            >
              <Grid item>
                <SvgConverter Icon={OrangeArrow} />
              </Grid>
              <Grid item>
                <Divider
                  orientation='vertical'
                  sx={{
                    height: '90px', // Set the height as per your requirement
                    backgroundColor: 'black', // Optional: Set the background color
                    width: '1px',
                  }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <PolicyCardCost.Normal
                title='Current Fund Value'
                value={total}
                policyCounts={3}
                hideLastUpdate={false}
                hidePolicyCounts={true}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              <PolicyCardCost.Normal
                title='Current Fund Value'
                value={total}
                policyCounts={3}
                hideLastUpdate={false}
                hidePolicyCounts={true}
              />
            </Grid>
            <Grid
              container
              alignItems='center'
              className='mx-5'
              justifyContent={'space-around'}
            >
              <Grid item>
                <SvgConverter Icon={OrangeArrow} />
              </Grid>
              <Grid item>
                <Divider
                  orientation='vertical'
                  sx={{
                    height: '90px', // Set the height as per your requirement
                    backgroundColor: 'black', // Optional: Set the background color
                    width: '1px',
                  }}
                />
              </Grid>
            </Grid>
            <Grid item ref={firstCardRef}>
              <IconButton className='p-0'>
                <SvgConverter Icon={RefreshIcon} />
              </IconButton>
            </Grid>
          </>
        )}

        <Grid item></Grid>
      </Grid>
    </div>
  );
};

export default LifeProgressBarWithValues;
