import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import Separator from "../../../common/ui/Separator";
import PolicyCard from "../Common/PolicyCard";

const MobileUnassignedPolicies = ({
  policies = [],
  toggleChangeNumberModal,
}: any) => {
  const theme = useTheme();

  return (
    <>
      <Grid item xs={12}>
        <Typography className="f-16  mt-10" color={theme.palette.error.main}>
          Unassigned Policies
        </Typography>
      </Grid>
      <Grid item className="my-10">
        <Separator color={theme.palette.primary.main} borderWidth={2} />
      </Grid>

      <Grid item className="mobile-Policy-screen p-20">
        {policies.map((policy: any) => (
          <PolicyCard
            policyDetails={policy}
            key={policy?.policyNo}
            changeButtonText={"ASSIGN NUMBER"}
            onChangeClick={() => toggleChangeNumberModal(policy)}
          />
        ))}
      </Grid>
    </>
  );
};

export default MobileUnassignedPolicies;
