import { Grid, Typography } from '@mui/material';
import React from 'react';
import './legal.scss';

const DataProtectionScreen = () => {
  return (
    <Grid
      container
      spacing={3}
      justifyContent={'center'}
      className='mb-30 mt-15'
    >
      <Grid item xs={12} sm={10}>
        <Grid item>
          <Typography
            fontSize={18}
            color={'black'}
            fontWeight={800}
            fontFamily={'sans-serif'}
            className='mb-5'
          >
            Data Protection Policy
          </Typography>
        </Grid>
        <Grid item>
          <Typography fontSize={14}>
            CAMSRep collects, records, stores and use investor personal data
            either received directly from the policy holder or received by
            virtue of contractual business arrangements from the insurer. The
            information collected includes sensitive information as defined in
            the Information Technology (Reasonable security Practices and
            Procedures and Sensitive Personal Information) Rules, 2011 as
            follows
          </Typography>
        </Grid>
      </Grid>
      <Grid container xs={12} sm={10}>
        <ul>
          <li>
            <Typography>
              Investor Bank Account details provided either as part of the
              e-Insurance application submitted by the policy holder or based on
              written request such as change of bank account registered as part
              of e-insurance account.
            </Typography>
          </li>
          <li>
            <Typography className='mt-10'>
              Payment instrument details provided as part of e-insurance account
              or received towards Premium both for existing and new policies or
              as proof for carrying out change of bank account or any other
              Details of debit card obtained in this regard.
            </Typography>
          </li>
          <li>
            <Typography className='mt-10'>
              Generation and storing Password in encrypted form based on the
              request from the investor.
            </Typography>
          </li>
          <li>
            <Typography className='mt-10'>
              Biometric information obtained, if any while opening e-Insurance
              account.
            </Typography>
          </li>
          <li>
            <Typography className='mt-10'>
              Physical, physiological and mental health condition, Sexual
              orientation, Medical Records received as part of information
              provided in the policy documents or received from the insurer as
              part of business arrangement for record and storage.
            </Typography>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Grid container className='content-box p-35'>
          <Grid item>
            <Typography fontSize={14} fontFamily={'sans-serif'}>
              The above information shall be received from the policy holders or
              through their authorized representative or approved persons or
              Insurer for processing, storage and maintenance under lawful
              contract or otherwise.
            </Typography>
            <Typography
              fontSize={14}
              fontFamily={'sans-serif'}
              className='mt-10 text-color'
              fontWeight={800}
            >
              The Information collected is stored in a secure manner in
              accordance with the ISO 27001 standards
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Grid container>
          <Grid item>
            <Typography
              fontSize={18}
              color={'black'}
              fontWeight={800}
              fontFamily={'sans-serif'}
              className='mb-5 mt-10'
            >
              We aspire to adhere to certain generally accepted principles of
              data protection, to the extent these are in our control as
              insurance repository
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              fontSize={14}
              color={'black'}
              fontWeight={600}
              fontFamily={'sans-serif'}
              className='mb-5 mt-10'
            >
              These general principles state that personal data must be:
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12} sm={10}>
        <ul>
          <li>
            <Typography>
              Fairly and lawfully collected and processed.
            </Typography>
          </li>
          <li>
            <Typography className='mt-10'>
              Processed for limited purposes and not in any other way which
              would be incompatible with those purposes.
            </Typography>
          </li>
          <li>
            <Typography className='mt-10'>
              Accurate and kept up to date.
            </Typography>
          </li>
          <li>
            <Typography className='mt-10'>
              Not kept for longer than is necessary.
            </Typography>
          </li>
          <li>
            <Typography className='mt-10'>Kept secure.</Typography>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Grid container>
          <Grid item>
            <Typography
              fontSize={18}
              color={'black'}
              fontWeight={800}
              fontFamily={'sans-serif'}
            >
              In order to meet the requirements of the principles, following
              process and controls are adhered:
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              fontSize={14}
              fontFamily={'sans-serif'}
              className='mt-20'
            >
              We have a responsible data security policy and implemented
              technology and policies with the objective of protecting your
              privacy from unauthorised access and improper use.
            </Typography>
            <Typography
              fontSize={14}
              fontFamily={'sans-serif'}
              className='mt-10'
            >
              We provide details of the policy holders only based on consent
              from the policy holder to , authorized representative, insurer,
              banks and similar representatives to fulfill business obligation.
            </Typography>
            <Typography
              fontSize={14}
              fontFamily={'sans-serif'}
              className='mt-10'
            >
              We may be required from time to time to disclose your personal
              information to governmental or judicial bodies or agencies or our
              regulators based on their requirement.
            </Typography>
            <Typography
              fontSize={14}
              fontFamily={'sans-serif'}
              className='mt-10'
            >
              We will permit only authorized employees who are trained in the
              proper handling of customer information, to have access to that
              information.
            </Typography>
          </Grid>
        </Grid>
        <Grid container className='content-box p-20 mt-10'>
          <Grid item>
            <Typography
              fontSize={16}
              fontWeight={800}
              fontFamily={'sans-serif'}
            >
              Grievance Officer{' '}
              <span className='info'>– Information Security</span>
            </Typography>
            <Typography
              fontSize={14}
              fontFamily={'sans-serif'}
              className='mt-10'
            >
              R. Sanjay Jain
            </Typography>
            <Typography
              fontSize={14}
              fontFamily={'sans-serif'}
              className='mt-5'
            >
              No. 158, Rayala Towers, Anna Salai, Chennai – 600 002
            </Typography>
            <Typography
              fontSize={14}
              fontFamily={'sans-serif'}
              className='mt-5'
            >
              Ph.044-30212555
            </Typography>
            <Typography
              fontSize={14}
              fontFamily={'sans-serif'}
              className='text-alignment mt-5 email-text-res'
            >
              Email:
              <Typography
                fontSize={14}
                fontWeight={500}
                fontFamily={'sans-serif'}
                className='ml-2 email-text-color'
              >
                ITgrievance@camsrepository.com
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Typography fontSize={15} className='mt-10 text-color'>
          The users of the computer resources including website of CAMSRep or
          any victim who has suffered due to access or usage of the sensitive
          information shall notify the complaints, if any to the above officer
          either through email or written compliant and such compliant shall be
          redressed within in one month from the date of receipt of the
          complaint.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DataProtectionScreen;
