import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import SelectedRecommendProductDetails from './SelectedRecommendProductDetails';
import MaturityAndFundValue from './MaturityAndFundValue';
import { ReactComponent as RoundBlackicon } from '../../../common/icons/LoanModule/round_black_icon.svg';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import HdfcIcon from '../../../assets/images/HDFC.png';
import SvgConverter from '../../../common/SvgConverter';
import RadioButton from './RadioButtonDetails';
import FormInputWithRupee from './FormInputWithRupee';
import { ReactComponent as divider } from '../../../common/icons/LoanModule/divider.svg';
import Separator from '../../../common/ui/Separator';
import FinancierBankContent from './FinancierBankContent';

const FinancierNameAndLoanAmountContent = () => {
  const theme = useTheme();
  const options = [
    { label: 'EMI of XXXX per month', value: 'emi' },
    { label: 'Annual XXXXX along with Premium', value: 'annual' },
    { label: 'Adjust from a future payout', value: 'adjust' },
    { label: 'Decide Later', value: 'decide' },
  ];
  const optionTwo = [
    { label: 'EMI of XXXX per month', value: 'emi' },
    { label: 'Annual XXXXX along with Premium', value: 'annual' },
  ];
  const optionOne = [{ label: 'EMI of XXXX per month', value: 'emi' }];

  return (
    <>
      <Grid item className='recommend-select'>
        <Grid item>
          <SelectedRecommendProductDetails
            Icon={RoundBlackicon}
            logoSrc={HdfcIcon}
            product={'Savings Product'}
            selfName={'[Self Name]'}
          />
        </Grid>
        <Typography
          component={'span'}
          className='recommend-icon-number  three f-16'
          color={theme.palette.common.black}
        >
          3
        </Typography>
        <MaturityAndFundValue />
        <Grid item bgcolor={'#f2f2f2'} className='p-10'>
          <Grid item>
            <Typography
              className='f-14 fw-600'
              color={theme.palette.primary.main}
            >
              Loan against fund value
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1} className='mt-4'>
              <Grid item className='ml-18'>
                <Typography
                  className='f-12 mt-2'
                  color={theme.palette.common.black}
                  justifyContent={'center'}
                >
                  [xx % of fund value]
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid className='mt-4'>
            <FormInputWithRupee />
          </Grid>
        </Grid>
        <Grid item>
          <Grid item className='mt-3 ml-15'>
            <Typography
              className='f-14 fw-600'
              color={theme.palette.primary.main}
            >
              Select a Financier
            </Typography>
          </Grid>
          <FinancierBankContent />
          <Grid item className='w-280 ml-50 mt-n30'>
            <SvgConverter Icon={divider} />
          </Grid>
          <Grid item>
            <Typography
              className='f-12 pl-48'
              color={theme.palette.primary.main}
            >
              Select Repayment Options
            </Typography>
          </Grid>
          <Grid item className='mt-n20 pl-10'>
            <RadioButton options={options} />
          </Grid>
        </Grid>
        <Grid item>
          <FinancierBankContent />
          <Grid item className='mt-n30 ml-50'>
            <SvgConverter Icon={divider} />
          </Grid>
          <Grid item>
            <Typography
              className='f-12 pl-48'
              color={theme.palette.primary.main}
            >
              Select Repayment Options
            </Typography>
          </Grid>
          <Grid item className='mt-n20 pl-10'>
            <RadioButton options={optionTwo} />
          </Grid>
        </Grid>
        <Grid item>
          <FinancierBankContent />
          <Grid item className='mt-n30 ml-50'>
            <SvgConverter Icon={divider} />
          </Grid>
          <Grid item>
            <Typography
              className='f-12 pl-48'
              color={theme.palette.primary.main}
            >
              Select Repayment Options
            </Typography>
          </Grid>
          <Grid item className='mt-n20 pl-10'>
            <RadioButton options={optionOne} />
          </Grid>
        </Grid>
        <Grid item>
          <FinancierBankContent />
          <Grid item className='mt-n30 ml-50'>
            <SvgConverter Icon={divider} />
          </Grid>
          <Grid item>
            <Typography
              className='f-12 pl-48'
              color={theme.palette.primary.main}
            >
              Select Repayment Options
            </Typography>
          </Grid>
          <Grid item className='mt-n20 pl-10'>
            <RadioButton options={optionOne} />
          </Grid>
        </Grid>
        <Grid item>
          <FinancierBankContent />
          <Grid item className='mt-n30 ml-50'>
            <SvgConverter Icon={divider} />
          </Grid>
          <Grid item>
            <Typography
              className='f-12 pl-48'
              color={theme.palette.primary.main}
            >
              Select Repayment Options
            </Typography>
          </Grid>
          <Grid item className='mt-n20 pl-10'>
            <RadioButton options={optionOne} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className='my-10'>
        <Separator color={theme.palette.grey[300]} />
      </Grid>
      <Grid item className='my-10'>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Typography className='f-12' color={theme.palette.common.black}>
              Total Charges
            </Typography>
            <Grid item>
              <Typography className='f-12' color={theme.palette.primary.main}>
                [SEE DETAILS]
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={0.5}>
              <Grid item>
                <SvgConverter
                  Icon={RupeeIcon}
                  width={5}
                  height={9}
                  className=' mb-4'
                />
              </Grid>
              <Grid item>
                <Typography
                  className='f-12 '
                  color={theme.palette.common.black}
                >
                  XXX (-)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FinancierNameAndLoanAmountContent;
