import { Box, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import SvgConverter from '../../common/SvgConverter';
import { ReactComponent as CorporateIcon } from '../../common/icons/corporate.svg';

const AppFooter = () => {
  const theme = useTheme();

  return (
    <Box
      className={'text-center py-14'}
      sx={{ backgroundColor: theme.palette.primary.main }}
    >
      <Typography color={theme.palette.grey.A400} fontSize={12}>
        Powered by CAMS Insurance Repository
      </Typography>
      <Grid container alignItems={'center'} justifyContent={'center'}>
        <Typography color={theme.palette.grey.A400} fontSize={12}>
          <SvgConverter Icon={CorporateIcon} className={'d-flex mb-3 mr-6'} />
        </Typography>
        <Typography color={theme.palette.grey.A400} fontSize={12}>
          CAMS Insurance Repository
        </Typography>
      </Grid>
    </Box>
  );
};

export default AppFooter;
