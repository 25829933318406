import React from "react";
import { Grid } from "@mui/material";
import BankPolicies from "./BankPolicies";
import UnassignedBankAccountScreen from "./UnassignedBankAccountScreen";
import UnassignedPoliciesScreen from "./UnassignedPoliciesScreen";
import UnverifiedBankScreen from "./UnverifiedBankScreen";
import { useSelector } from "react-redux";
import {
  selectGroupedPoliciesByBanks,
  selectTotalPolices,
  selectUnVerifiedBanks,
  selectUnassignedBanks,
  selectUnassignedPolicies,
} from "./Bank.slice";

const ManageBankHeader = ({
  handleChangeBank,
  handleRemoveBank,
  handleVerifyNow,
  handleAssignPolicies,
}: any) => {
  const policiesByAccountNumber = useSelector(selectGroupedPoliciesByBanks);
  const totalPolicies = useSelector(selectTotalPolices);
  const unAssignedBanks = useSelector(selectUnassignedBanks);
  const unassignedPolicies = useSelector(selectUnassignedPolicies);
  const unVerifiedBanks = useSelector(selectUnVerifiedBanks);

  return (
    <>
      <Grid item>
        <BankPolicies
          policiesObj={policiesByAccountNumber}
          totalPolicies={totalPolicies}
          unAssignedBanks={unAssignedBanks}
          handleChangeBank={handleChangeBank}
          handleRemoveBank={handleRemoveBank}
        />
      </Grid>
      {unAssignedBanks?.length > 0 && (
        <Grid item marginTop={5}>
          <UnassignedBankAccountScreen
            banks={unAssignedBanks}
            handleRemoveBank={handleRemoveBank}
            handleAssignPolicies={handleAssignPolicies}
          />
        </Grid>
      )}
      {unassignedPolicies?.length > 0 && (
        <Grid item marginTop={5}>
          <UnassignedPoliciesScreen
            policies={unassignedPolicies}
            handleChangeBank={handleChangeBank}
          />
        </Grid>
      )}

      {unVerifiedBanks?.length > 0 && (
        <Grid item marginTop={5}>
          <UnverifiedBankScreen
            bankList={unVerifiedBanks}
            handleVerifyNow={handleVerifyNow}
            handleRemoveBank={handleRemoveBank}
          />
        </Grid>
      )}
    </>
  );
};

export default ManageBankHeader;
