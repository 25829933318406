import React, { useState, useMemo, useEffect } from "react";
import { Grid } from "@mui/material";
import "./Mobile.scss";

import { filter, findIndex, groupBy, prop, propEq, reject } from "ramda";
import { useSelector } from "react-redux";
import { selectPoliciesForMobileNumbers } from "./MobileNumber.slice";
import { PolicyAlternateCodes } from "../../../common/types/PolicyTypes";
import MobilePolicyHeader from "./common/MobilePolicyHeader";
import PolicyCard from "../Common/PolicyCard";
import UnassignedMobileNumber from "./UnassignedMobileNumber";
import MobileUnassignedPolicies from "./MobileUnassignedPolicies";
const MobileNumberHeader = ({
  toggleChangeNumberModal,
  toggleRemoveNumberModal,
  handleAssignPolicy,
  handleRemoveForUnAssignedMobiles,
  handleInstantVerify,
}: any) => {
  const [count, setCount] = useState(0);
  const groupPoliciesByNumber = groupBy(prop<any>("mobileNo"));
  const policiesForMobileNumber = useSelector(selectPoliciesForMobileNumbers);
  const { mobileNumbersListPolicies, unAssignedMobileNumbers } = useMemo(
    () => ({
      mobileNumbersListPolicies: policiesForMobileNumber?.mobileBasedPolicies,
      unAssignedMobileNumbers: policiesForMobileNumber?.unAssignedMobiles,
    }),
    [policiesForMobileNumber]
  );
  const policies: any = useMemo(
    () =>
      groupPoliciesByNumber(
        mobileNumbersListPolicies ? mobileNumbersListPolicies : []
      ),
    [mobileNumbersListPolicies, groupPoliciesByNumber]
  );

  const { assignedPoliciesObj, unAssignedPolicies } = useMemo(() => {
    if (policies) {
      let assignedPoliciesObjLocal = { ...policies };
      let unAssignedPoliciesLocal = assignedPoliciesObjLocal.null
        ? assignedPoliciesObjLocal.null
        : [];
      delete assignedPoliciesObjLocal.null;
      return {
        assignedPoliciesObj: assignedPoliciesObjLocal,
        unAssignedPolicies: unAssignedPoliciesLocal,
      };
    } else {
      return { assignedPoliciesObj: {}, unAssignedPolicies: [] };
    }
  }, [policies]);

  const lifePolicies = filter(
    propEq(PolicyAlternateCodes.LIFE, "alternateCode")
  );
  const healthPolicies: any = filter(
    propEq(PolicyAlternateCodes.HEALTH, "alternateCode")
  );
  const assetPolicies: any = filter(
    propEq(PolicyAlternateCodes.ASSET, "alternateCode")
  );

  useEffect(() => {
    setCount(count + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unAssignedMobileNumbersLocal = useMemo(() => {
    if (unAssignedMobileNumbers && mobileNumbersListPolicies) {
      return reject(
        ({ mobileNo }: any) =>
          findIndex(propEq(mobileNo, "mobileNo"))(mobileNumbersListPolicies) >=
          0,
        unAssignedMobileNumbers
      );
    } else {
      return [];
    }
  }, [unAssignedMobileNumbers, mobileNumbersListPolicies]);

  return (
    <>
      <Grid container spacing={5} justifyContent={"space-between"}>
        {Object.keys(assignedPoliciesObj).map((mobileNo, index) => (
          <Grid item key={mobileNo} xs={12} sm={6} md={3}>
            <MobilePolicyHeader
              index={index}
              mobileNo={mobileNo}
              verified={assignedPoliciesObj[mobileNo][0]["mobileNoVerified"]}
              primary={assignedPoliciesObj[mobileNo][0]["primaryFlag"]}
              onRemove={() => toggleRemoveNumberModal(mobileNo)}
              handleAssignPolicy={handleAssignPolicy}
              showAssignPolicyButton={false}
              onVerify={() => handleInstantVerify(mobileNo)}
            />
            <Grid
              item
              className="mobile-Policy-screen policy-list-container p-20"
            >
              {lifePolicies(assignedPoliciesObj[mobileNo]).map(
                (policy: any) => (
                  <PolicyCard
                    policyDetails={policy}
                    key={policy?.policyNo}
                    changeButtonText={"CHANGE NUMBER"}
                    onChangeClick={() => toggleChangeNumberModal(policy)}
                  />
                )
              )}
              {healthPolicies(assignedPoliciesObj[mobileNo]).map(
                (policy: any) => (
                  <PolicyCard
                    policyDetails={policy}
                    key={policy?.policyNo}
                    changeButtonText={"CHANGE NUMBER"}
                    onChangeClick={() => toggleChangeNumberModal(policy)}
                  />
                )
              )}
              {assetPolicies(assignedPoliciesObj[mobileNo]).map(
                (policy: any) => (
                  <PolicyCard
                    policyDetails={policy}
                    key={policy?.policyNo}
                    changeButtonText={"CHANGE NUMBER"}
                    onChangeClick={() => toggleChangeNumberModal(policy)}
                  />
                )
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
      {unAssignedMobileNumbersLocal.length > 0 ? (
        <UnassignedMobileNumber
          mobileNoList={unAssignedMobileNumbersLocal}
          handleAssignPolicy={handleAssignPolicy}
          onRemove={handleRemoveForUnAssignedMobiles}
        />
      ) : (
        <></>
      )}

      {unAssignedPolicies.length > 0 ? (
        <MobileUnassignedPolicies
          policies={unAssignedPolicies}
          toggleChangeNumberModal={toggleChangeNumberModal}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default MobileNumberHeader;
