import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ReactComponent as cancel } from '../../../common/icons/cancel-icon.svg';
import SvgConverter from '../../../common/SvgConverter';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';

const NeedMoneyHeader = () => {
  return (
    <Grid container justifyContent={'space-between'} alignItems={'center'}>
      <Grid item>
        <Typography className='f-16 fw-600 mt-n10' color={'primary'}>
          Need Money
        </Typography>
      </Grid>
      <Grid item>
        <Link to={APP_ROUTES.DASHBOARD}>
          <Grid container spacing={0.5}>
            <Grid item className='mt-n2'>
              <Typography className='f-14' color={'primary'}>
                Done
              </Typography>
            </Grid>
            <Grid item>
              <SvgConverter Icon={cancel} width={23} height={23} />
            </Grid>
          </Grid>
        </Link>
      </Grid>
    </Grid>
  );
};

export default NeedMoneyHeader;
