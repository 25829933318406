import React from 'react'
import { Grid, Typography, useTheme } from '@mui/material';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import SvgConverter from '../../../common/SvgConverter';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';

const FormInputWithRupee = () => {
    const theme = useTheme();

  return (
      <Grid container alignItems={'center'} spacing={1}>
        <Grid item className='mt-n5'>
          <SvgConverter Icon={RupeeIcon} />
        </Grid>

        <Grid item>
          <FormInput type='text' placeholder='XXXXXX' />
          <Typography className='f-10' color={theme.palette.grey.A700}>
            [Max % allowed: XX% of fund value]
          </Typography>
        </Grid>
      </Grid>
  );
}

export default FormInputWithRupee