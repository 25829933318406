import React from 'react';
import { Grid } from '@mui/material';
import '../Nominee.scss';
import Typography from '@mui/material/Typography';
import HDFC from '../../../../assets/images/HDFC.png';
import { Link } from 'react-router-dom';
import Separator from '../../../../common/ui/Separator';
import theme from '../../../../common/theme';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRupee } from '../../../../common/icons/orange_rupee.svg';
import SvgConverter from '../../../../common/SvgConverter';
const NomineeCardHeader = ({
  productName,
  selfName,
  sumAssured,
  premium,
  bankImage,
}: {
  productName: string;
  selfName: string;
  sumAssured: string | number;
  premium: string | number;
  bankImage: any;
}) => {
  return (
    <Grid item className='nominee-form '>
      <Grid container justifyContent={'space-between'} className='p-20'>
        <Grid item xs={8}>
          <Typography
            fontSize={16}
            fontWeight={600}
            color={theme.palette.primary.main}
            className='mb-5'
          >
            {productName}
          </Typography>
          <Typography fontSize={14} color={theme.palette.primary.main}>
            Life Assured
          </Typography>
            <Typography
              fontSize={16}
              fontWeight={600}
              color={theme.palette.common.black}
            >
              {selfName}
            </Typography>
        </Grid>
        <Grid item xs={3} className='mt-10'>
          <img src={bankImage} width={'58'} height={'58'} />
        </Grid>
      </Grid>

      <Grid item xs={12} className='mt-n25 p-20'>
        <Grid container>
          <Grid item>
            <Typography fontSize={14} color={theme.palette.primary.main}>
              Sum Assured
            </Typography>{' '}
            <Grid container spacing={0.5}>
              <Grid item className='mt-3'>
                <SvgConverter Icon={OrangeRupee} width={'7'} height={'12'} />
              </Grid>
              <Grid item>
                <Typography
                  className='f-28 fw-600'
                  color={theme.palette.common.black}
                >
                  {sumAssured}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className='px-10 '>
            <Separator
              orientation='vertical'
              color={theme.palette.primary.main}
            />
          </Grid>
          <Grid item className=''>
            <Typography fontSize={14} color={theme.palette.primary.main}>
              Premium
            </Typography>
            <Grid container spacing={0.5}>
              <Grid item className='mt-3'>
                <SvgConverter Icon={OrangeRupee} width={'7'} height={'12'} />
              </Grid>
              <Grid item>
                <Typography
                  className='f-28 fw-600'
                  color={theme.palette.common.black}
                >
                  {premium}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NomineeCardHeader;
