import { Grid, Typography } from '@mui/material';
import React from 'react';

const DisclaimerScreen = () => {
  return (
    <Grid
      container
      spacing={2}
      justifyContent={'center'}
      className='mb-30 mt-15'
    >
      <Grid item xs={12} sm={10} className='content-merge'>
        <Grid item>
          <Typography
            fontSize={18}
            color={'black'}
            fontWeight={800}
            fontFamily={'sans-serif'}
            className='mb-5'
          >
            Disclaimer
          </Typography>
        </Grid>
        <Grid item>
          <Typography fontSize={14}>
            The content on the CAMSRepository.com is provided for information
            purpose only. CAMSRep maintains this website only to enhance access
            to the information about eInsurance account and about
            CAMSRepository.com.
          </Typography>
          <Typography className='mt-10' fontSize={14}>
            The content displayed as part of this website or webpage comes from
            authenticated sources believed to be accurate, but may contain
            inaccuracies or typographical errors. CAMSRepository.com makes no
            representations about the results to be obtained from using the
            CAMSRepository.com Site or the content.
          </Typography>
          <Typography className='mt-10' fontSize={14}>
            While we try to keep the information as current and accurate as
            possible, we make no warranty of any kind, implied or express, as to
            its accuracy, completeness or appropriateness for any purpose. We
            will make an effort to correct the errors brought to our attention
            from time to time. In the event of conflict, if any, between the
            information contained in this website and those contained in the
            policy document or the premium certificate issued by the insurer,
            the information by the insurer shall prevail.
          </Typography>
          <Typography className='mt-10' fontSize={14}>
            This Website may contain links to other websites, web pages and
            services and use of each such website, web page and services on any
            other website or web service is subject to the terms and conditions,
            if any, of the respective website or web service only.
          </Typography>
          <Typography className='mt-10' fontSize={14}>
            Information submitted or provided as part of CAMSRepository.com will
            be transmitted over local Exchange, Interexchange and Internet
            Backbone Carrier Lines and through routers switches and other
            devices owned, maintained and serviced by third party service
            providers, long distant carriers, utilities, internet service
            providers and others, all of which are beyond the control and
            jurisdiction of CAMSRepository.com.
          </Typography>
          <Typography className='mt-10' fontSize={14}>
            Accordingly, CAMSRepository.com assumes no liability for or relating
            to the delay, failure, interruption or corruption of any data or
            other information transmitted in connection therewith.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DisclaimerScreen;
