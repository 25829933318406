import { call, put, select, takeEvery } from "typed-redux-saga";
import {
  fetchMobileNumbers,
  fetchPoliciesForMobileNumber,
  generateOtpForMobile,
  updateMobileNumbers,
  verifyOtpForMobile,
} from "./MobileNumber.service";
import {
  errorLoadingPoliciesForMobileNumber,
  loadPoliciesForMobileNumber,
  fetchPoliciesForMobileNumber as fetchPoliciesForMobileNumberAction,
  fetchMobileNumbers as fetchMobileNumbersAction,
  loadMobileNumbers,
  errorLoadingMobileNumbers,
  updateMobileNoRequest,
  updateMobileNoSuccess,
  updateMobileNoError,
  generateOtpForMobileRequest,
  generateOtpForMobileSuccess,
  generateOtpForMobileError,
  verifyMobileNoRequest,
  verifyMobileNoSuccess,
  verifyMobileNoError,
  selectMobileNoChangesList,
} from "./MobileNumber.slice";
import { selectIsUserLoggedIn } from "../../Auth/auth.slice";
import { RequestTypes } from "../../../common/utils";
import { setAlertInfo } from "../Common/Common.slice";

function* handleFetchPoliciesForMobileNumber() {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
      },
    };
    let { data }: any = yield* call(
      fetchPoliciesForMobileNumber,
      requestPayload
    );
    data.MobileBasedPolicies = data.MobileBasedPolicies?.map((row: any) => ({
      ...row,
      mobileNo: String(row.mobileNo).trim() === "" ? "null" : row.mobileNo,
    }));
    yield* put(loadPoliciesForMobileNumber(data ? data : {}));
  } catch (e: any) {
    yield* put(errorLoadingPoliciesForMobileNumber(e.message));
  }
}

function* handleFetchMobileNumbers({
  payload,
}: ReturnType<typeof fetchMobileNumbersAction>) {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
        value: payload,
        flag: RequestTypes.MOBILE,
      },
    };
    const { data }: any = yield* call(fetchMobileNumbers, requestPayload);
    yield* put(loadMobileNumbers(data.mobileNoList || []));
  } catch (e: any) {
    yield* put(errorLoadingMobileNumbers(e.message));
  }
}

function* handleUpdateMobileNumbers({
  payload: { handleSuccess },
}: ReturnType<typeof fetchMobileNumbersAction>) {
  try {
    const Actions = yield* select(selectMobileNoChangesList);
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
      },
      flag: RequestTypes.MOBILE,
      Actions,
    };
    const { data }: any = yield* call(updateMobileNumbers, requestPayload);
    yield* put(updateMobileNoSuccess(data.mobileNoList || []));
    if (handleSuccess) {
      handleSuccess();
    }
    yield put(
      setAlertInfo({
        open: true,
        type: "SUCCESS",
        description: "Successfully Updated mobile policies.",
      })
    );
  } catch (e: any) {
    yield* put(updateMobileNoError(e.message));
  }
}

function* handleGenerateOtpForMobile({
  payload: { handleSuccess, ...payload },
}: ReturnType<typeof fetchMobileNumbersAction>) {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        value: payload.mobileNo,
        flag: "MOBILE",
        otpPurpose: "UPDATE_MOBILE",
        eiaNo: user.eiaNo,
      },
    };
    const { data }: any = yield* call(generateOtpForMobile, requestPayload);
    yield* put(generateOtpForMobileSuccess(data));
    if (handleSuccess) {
      handleSuccess(data);
    }
  } catch (e: any) {
    yield* put(generateOtpForMobileError(e.message));
  }
}

function* handleVerifyOtp({
  payload: { handleSuccess, ...payload },
}: ReturnType<typeof fetchMobileNumbersAction>) {
  try {
    const requestPayload = {
      Customer: {
        value: payload.mobileNo,
        otp: payload.otpCode,
        flag: "MOBILE",
        otpPurpose: "UPDATE_MOBILE",
      },
    };
    const { data }: any = yield* call(verifyOtpForMobile, requestPayload);
    yield* put(verifyMobileNoSuccess(data));
    if (handleSuccess) {
      handleSuccess(data);
    }
  } catch (e: any) {
    yield* put(verifyMobileNoError(e.message));
  }
}

function* watchMobileNumbers() {
  yield* takeEvery(
    fetchPoliciesForMobileNumberAction.type,
    handleFetchPoliciesForMobileNumber
  );
  yield* takeEvery(fetchMobileNumbersAction.type, handleFetchMobileNumbers);
  yield* takeEvery(updateMobileNoRequest.type, handleUpdateMobileNumbers);
  yield* takeEvery(
    generateOtpForMobileRequest.type,
    handleGenerateOtpForMobile
  );
  yield* takeEvery(verifyMobileNoRequest.type, handleVerifyOtp);
}

export { watchMobileNumbers };
