import React from "react";
import { Grid } from "@mui/material";
import AddressLineGenerator from "../../../common/ui/AddressLineGenerator";

const AddressSection = ({ data }: any) => {
  return (
    <Grid>
      {data ? (
        <Grid item>
          <AddressLineGenerator value={`${data.address1}, ${data.address2}`} />
          <AddressLineGenerator value={data.address3} />
          <AddressLineGenerator value={data.city} />
          <AddressLineGenerator
            value={data.state}
            showComma={Boolean(data.country)}
          />
          <AddressLineGenerator value={data.country} />
          <AddressLineGenerator value={data.pincode} showComma={false} />
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default AddressSection;
