import { createSlice } from "@reduxjs/toolkit";
import { PolicyAddress } from "../Profile/types/Profile.model";
import * as R from "ramda";
import { LoadingState } from "../../../common";
import {
  convertToString,
  generateFullAddress,
  removeDuplicatedPoliciesForAssign,
  swapToTop,
} from "../../../common/utils";
import { MOBILE_EMAIL_CHANGE_ACTIONS } from "../../../common/Enums";
import { RootState } from "../../../common/store";

interface AddressState {
  policies: any;
  isLoading: LoadingState;
  errors: any | null;
  address: any[];
  isAddressLoading: LoadingState;
  errorAddress: any;
  generateOtpForAddress: { status: LoadingState; data: any; error: any };
  verifyAddress: { status: LoadingState; data: any; error: any };
  updateAddressChanges: { status: LoadingState; data: any; error: any };
  addressChangesList: any[];
  getAddressEkycUrl: { status: LoadingState; data: any; error: any };
  saveAddressEkyc: { status: LoadingState; data: any; error: any };
}

const initialState: AddressState = {
  isLoading: "idle",
  policies: {
    permanentAddress: {},
    addressBasedPolicies: [],
    unassignedAddresses: [],
  },
  errors: null,
  isAddressLoading: "idle",
  address: [],
  errorAddress: null,
  generateOtpForAddress: {
    status: "idle",
    data: undefined,
    error: undefined,
  },
  verifyAddress: {
    status: "idle",
    data: undefined,
    error: undefined,
  },
  updateAddressChanges: {
    status: "idle",
    data: undefined,
    error: undefined,
  },
  addressChangesList: [],
  getAddressEkycUrl: {
    status: "idle",
    data: undefined,
    error: undefined,
  },
  saveAddressEkyc: {
    status: "idle",
    data: undefined,
    error: undefined,
  },
};

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    fetchPoliciesForAddress: (state) => ({
      ...state,
      isLoading: "loading",
      errors: null,
      policies: {
        permanentAddress: {},
        addressBasedPolicies: [],
        unassignedAddresses: [],
      },
    }),
    loadPoliciesForAddress: (state, action) => ({
      ...state,
      isLoading: "done",
      errors: null,
      policies: action.payload,
    }),
    errorLoadingPoliciesForAddress: (state, action) => ({
      ...state,
      isLoading: "done",
      errors: action.payload,
      policies: {
        permanentAddress: {},
        addressBasedPolicies: [],
        unassignedAddresses: [],
      },
    }),
    fetchAddress: (state, _action) => ({
      ...state,
      isAddressLoading: "loading",
      address: [],
      errorAddress: null,
    }),
    loadAddress: (state, action) => ({
      ...state,
      isAddressLoading: "done",
      address: action.payload,
      errorAddress: null,
    }),
    errorLoadingAddress: (state, action) => ({
      ...state,
      isAddressLoading: "done",
      address: [],
      errorAddress: action.payload,
    }),

    generateOtpForAddressRequest: (state) => {
      state.generateOtpForAddress.status = "loading";
      state.generateOtpForAddress.data = undefined;
      state.generateOtpForAddress.error = undefined;
    },
    generateOtpForAddressSuccess: (state, { payload }) => {
      state.generateOtpForAddress.status = "done";
      state.generateOtpForAddress.data = payload;
      state.generateOtpForAddress.error = undefined;
    },
    generateOtpForAddressError: (state, { payload }) => {
      state.generateOtpForAddress.status = "error";
      state.generateOtpForAddress.data = undefined;
      state.generateOtpForAddress.error = payload;
    },
    verifyAddressRequest: (state) => {
      state.verifyAddress.status = "loading";
      state.verifyAddress.data = undefined;
      state.verifyAddress.error = undefined;
    },
    verifyAddressSuccess: (state, { payload }) => {
      state.verifyAddress.status = "done";
      state.verifyAddress.data = payload;
      state.verifyAddress.error = undefined;
    },
    verifyAddressError: (state, { payload }) => {
      state.verifyAddress.status = "error";
      state.verifyAddress.data = undefined;
      state.verifyAddress.error = payload;
    },
    updateAddressChangesRequest: (state) => {
      state.updateAddressChanges.status = "loading";
      state.updateAddressChanges.data = undefined;
      state.updateAddressChanges.error = undefined;
    },
    updateAddressChangesSuccess: (state, { payload }) => {
      state.updateAddressChanges.status = "done";
      state.updateAddressChanges.data = payload;
      state.updateAddressChanges.error = undefined;
      state.addressChangesList = [];
    },
    updateAddressChangesError: (state, { payload }) => {
      state.updateAddressChanges.status = "error";
      state.updateAddressChanges.data = undefined;
      state.updateAddressChanges.error = payload;
    },
    getAddressEkycUrlRequest: (state, _) => {
      state.getAddressEkycUrl.status = "loading";
      state.getAddressEkycUrl.data = undefined;
      state.getAddressEkycUrl.error = undefined;
    },
    getAddressEkycUrlSuccess: (state, { payload }) => {
      state.getAddressEkycUrl.status = "done";
      state.getAddressEkycUrl.data = payload;
      state.getAddressEkycUrl.error = undefined;
    },
    getAddressEkycUrlError: (state, { payload }) => {
      state.getAddressEkycUrl.status = "error";
      state.getAddressEkycUrl.data = undefined;
      state.getAddressEkycUrl.error = payload;
    },

    saveAddressEkycRequest: (state) => {
      state.saveAddressEkyc.status = "loading";
      state.saveAddressEkyc.data = undefined;
      state.saveAddressEkyc.error = undefined;
    },
    saveAddressEkycSuccess: (state, { payload }) => {
      state.saveAddressEkyc.status = "done";
      state.saveAddressEkyc.data = payload;
      state.saveAddressEkyc.error = undefined;
      state.policies.permanentAddress = {
        ...state.policies.permanentAddress,
        ...(payload?.permanentAddress ? payload?.permanentAddress : {}),
      };
    },
    saveAddressEkycError: (state, { payload }) => {
      state.saveAddressEkyc.status = "error";
      state.saveAddressEkyc.data = undefined;
      state.saveAddressEkyc.error = payload;
    },

    assignAddress: (state, action) => {
      const { selectedPolicies, newAddress, handleDone, isUnaAssigned } =
        action.payload;
      let policies = handleAddNewAddress(selectedPolicies, newAddress);
      if (selectedPolicies?.length > 0) {
        policies = handleAddNewAddress(selectedPolicies, newAddress);
        const duplicateRemovedCurrentList = removeDuplicatedPoliciesForAssign(
          state.policies.addressBasedPolicies,
          selectedPolicies
        );
        state.policies.addressBasedPolicies = [
          ...policies,
          ...duplicateRemovedCurrentList,
        ];
      } else {
        const fullAddress = generateFullAddress(newAddress);
        state.policies.unassignedAddresses = [
          ...state.policies.unassignedAddresses,
          { ...newAddress, fullAddress },
        ];
        state.policies.unassignedAddresses = swapToTop(
          state.policies.unassignedAddresses,
          [fullAddress],
          "fullAddress"
        );
      }
      if (isUnaAssigned) {
        state.policies.unassignedAddresses = R.reject(
          ({ fullAddress }: any) => {
            return fullAddress === newAddress?.fullAddress;
          },
          state.policies.unassignedAddresses
        );
      }
      const changes = generateAddressChangesForAPI(
        policies,
        newAddress,
        "null",
        MOBILE_EMAIL_CHANGE_ACTIONS.ADD
      );
      state.addressChangesList = [...state.addressChangesList, changes];
      state.policies.addressBasedPolicies = swapToTop(
        state.policies.addressBasedPolicies,
        changes.Policies.map(({ policyNo }: any) => policyNo),
        "policyNo"
      );
      if (handleDone) {
        handleDone();
      }
    },
    changeAddress: (state, action) => {
      const { selectedPolicy, newAddress, handleDone } = action.payload;
      const policies = changePolicyAddress(
        state.policies.addressBasedPolicies,
        selectedPolicy,
        newAddress
      );
      const { fullAddress } = selectedPolicy;
      const isAddressHavePolicy = policies.some(
        (policy) => policy.fullAddress === fullAddress
      );
      if (!isAddressHavePolicy && selectedPolicy?.mobileNo != "null") {
        state.policies.unassignedAddresses = [
          ...state.policies.unassignedAddresses,
          {
            fullAddress,
            address1: selectedPolicy?.address1,
            address2: selectedPolicy?.address2,
            address3: selectedPolicy?.address3,
            addressVerified: selectedPolicy?.addressVerified,
            city: selectedPolicy?.city,
            country: selectedPolicy?.country,
            investHorizon: selectedPolicy?.investHorizon,
            pincode: selectedPolicy?.pincode,
            state: selectedPolicy?.state,
          },
        ];
      }
      state.policies.addressBasedPolicies = policies;
      const changes = generateAddressChangesForAPI(
        [selectedPolicy],
        newAddress,
        selectedPolicy,
        MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE
      );
      state.addressChangesList = [...state.addressChangesList, changes];
      state.policies.addressBasedPolicies = swapToTop(
        state.policies.addressBasedPolicies,
        changes.Policies.map(({ policyNo }: any) => policyNo),
        "policyNo"
      );
      if (handleDone) {
        handleDone();
      }
    },
    removeAddress: (state, action) => {
      const { newAddress, oldAddress, isUnAssignedAddress, handleDone } =
        action.payload;
      let changes: any = [];
      if (!isUnAssignedAddress) {
        const affectedPolicies = state.policies.addressBasedPolicies.filter(
          ({ fullAddress }: any) => fullAddress === oldAddress?.fullAddress
        );
        const policies = assignPolicyToOtherAddress(
          state.policies.addressBasedPolicies,
          newAddress,
          oldAddress
        );
        state.policies.addressBasedPolicies = policies;
        changes = generateAddressChangesForAPI(
          affectedPolicies,
          newAddress,
          oldAddress,
          MOBILE_EMAIL_CHANGE_ACTIONS.REMOVE
        );
      } else {
        changes = generateAddressChangesForAPI(
          [],
          "null",
          oldAddress,
          MOBILE_EMAIL_CHANGE_ACTIONS.REMOVE
        );
        state.policies.unassignedAddresses =
          removeAddressFromUnAssignedAddresses(
            state.policies.unassignedAddresses,
            oldAddress
          );
      }
      state.addressChangesList = [...state.addressChangesList, changes];
      if (handleDone) {
        handleDone();
      }
    },
    setAddressChangesList: (state, { payload }) => {
      state.addressChangesList = payload;
    },
  },
});

export const {
  fetchAddress,
  loadAddress,
  errorLoadingAddress,
  fetchPoliciesForAddress,
  loadPoliciesForAddress,
  errorLoadingPoliciesForAddress,
  removeAddress,
  changeAddress,
  generateOtpForAddressRequest,
  generateOtpForAddressSuccess,
  generateOtpForAddressError,
  verifyAddressSuccess,
  verifyAddressRequest,
  verifyAddressError,
  updateAddressChangesRequest,
  updateAddressChangesSuccess,
  updateAddressChangesError,
  assignAddress,
  setAddressChangesList,
  getAddressEkycUrlRequest,
  getAddressEkycUrlSuccess,
  getAddressEkycUrlError,
  saveAddressEkycRequest,
  saveAddressEkycSuccess,
  saveAddressEkycError,
} = addressSlice.actions;

export const selectIsPolicyAddressLoading = (state: RootState) =>
  state.address.isLoading === "loading";

export const selectPoliciesForAddress = (state: RootState) =>
  state.address.policies;

export const selectErrorLoadingPolicies = (state: RootState) =>
  state.address.errors;

export const selectIsAddressLoading = (state: RootState) =>
  state.address.isLoading === "loading";

export const selectAddress = (state: RootState) => state.address.address;

export const selectErrorLoadingAddress = (state: RootState) =>
  state.address.errorAddress;

export const selectIsUpdateAddressLoading = (state: RootState) =>
  state.address.updateAddressChanges.status === "loading";

export const selectAddressChangesList = (state: RootState) =>
  state.address.addressChangesList;

export const selectGetAddressEkycUrl = (state: RootState) =>
  state.address?.getAddressEkycUrl?.data
    ? state.address?.getAddressEkycUrl?.data
    : {};

export const selectGetAddressEkycUrlIsLoading = (state: RootState) =>
  state.address?.getAddressEkycUrl.status === "loading";

export default addressSlice.reducer;

//---------------------------------------------------------------------------------------

const removeAddressFromUnAssignedAddresses = (
  policies: any[],
  targetAddress: any
) =>
  R.reject(
    ({ fullAddress }: any) => fullAddress === targetAddress.fullAddress,
    policies
  );

const generateAddressChangesForAPI = (
  policies: any[],
  newAddress: any,
  oldAddress: any,
  action: string
) => {
  const policiesForPayload: any[] = policies.map(
    ({ companyCode, policyNo, alternateCode }: any) => ({
      insurerCode: companyCode,
      policyNo,
      alternateCode,
    })
  );

  let newValues: any = {
    newAddress1: "",
    newAddress2: "",
    newAddress3: "",
    newCity: "",
    newState: "",
    newCountry: "",
    newPincode: "",
    newInvestHorizon: "",
  };

  if (newAddress || newAddress != "null") {
    newValues = {
      newAddress1: convertToString(newAddress?.address1),
      newAddress2: convertToString(newAddress?.address2),
      newAddress3: convertToString(newAddress?.address3),
      newCity: convertToString(newAddress?.city),
      newState: convertToString(newAddress?.state),
      // newCountry: convertToString(newAddress?.country),
      newPincode: convertToString(newAddress?.pincode),
      newInvestHorizon: convertToString(newAddress?.investHorizon),
    };
  }

  let oldValues: any = {
    oldAddress1: "",
    oldAddress2: "",
    oldAddress3: "",
    oldCity: "",
    oldState: "",
    oldCountry: "",
    oldPincode: "",
    oldInvestHorizon: "",
  };

  if (oldAddress || oldAddress != "null") {
    oldValues = {
      oldAddress1: convertToString(oldAddress?.address1),
      oldAddress2: convertToString(oldAddress?.address2),
      oldAddress3: convertToString(oldAddress?.address3),
      oldCity: convertToString(oldAddress?.city),
      oldState: convertToString(oldAddress?.state),
      // oldCountry: convertToString(oldAddress?.country),
      oldPincode: convertToString(oldAddress?.pincode),
      oldInvestHorizon: convertToString(oldAddress?.investHorizon),
    };
  }

  return {
    ...newValues,
    ...oldValues,
    action: oldAddress == "null" ? MOBILE_EMAIL_CHANGE_ACTIONS.ADD : action, // For unsigned policies have to send as add action type
    Policies: policiesForPayload,
    permanentAddressFlag: "",
    addressVerified: "",
  };
};
const changePolicyAddress = (
  policies: PolicyAddress[],
  selectedPolicy: any,
  newAddress: any
) => {
  const selectedPolicyIndex = policies.findIndex(
    ({ fullAddress, policyNo }: any) =>
      fullAddress === selectedPolicy.fullAddress &&
      policyNo === selectedPolicy.policyNo
  );
  if (selectedPolicyIndex >= 0) {
    policies[selectedPolicyIndex].fullAddress = newAddress.fullAddress;
    policies[selectedPolicyIndex].address1 = newAddress.address1;
    policies[selectedPolicyIndex].address2 = newAddress.address2;
    policies[selectedPolicyIndex].address3 = newAddress.address3;
    policies[selectedPolicyIndex].district = newAddress.district;
    policies[selectedPolicyIndex].addressVerified = newAddress.addressVerified;
    policies[selectedPolicyIndex].city = newAddress.city;
    policies[selectedPolicyIndex].country = newAddress.country;
    policies[selectedPolicyIndex].investHorizon = newAddress.investHorizon;
    policies[selectedPolicyIndex].pincode = newAddress.pincode;
    policies[selectedPolicyIndex].state = newAddress.state;
  }
  return policies;
};

const mapAssignPolicy =
  (newAddress: any, oldAddress: any) => (policy: PolicyAddress) => {
    if (policy.fullAddress === oldAddress.fullAddress) {
      policy.fullAddress = newAddress.fullAddress;
      policy.address1 = newAddress.address1;
      policy.address2 = newAddress.address2;
      policy.address3 = newAddress.address3;
      policy.city = newAddress.city;
      policy.state = newAddress.state;
      policy.country = newAddress.country;
      policy.pincode = newAddress.pincode;
      policy.investHorizon = newAddress.investHorizon;
    }
    return policy;
  };

const assignPolicyToOtherAddress = (
  policies: PolicyAddress[],
  newAddress: string,
  oldAddress: string
) => policies.map(mapAssignPolicy(newAddress, oldAddress));

const handleAddNewAddress = (policies: any[], newAddress: any) => {
  const fullAddress = generateFullAddress(newAddress);
  policies = policies.map((data: any) => ({
    ...data,
    fullAddress,
    ...newAddress,
  }));
  return policies;
};

export const getAddressLists = (
  addressBasedPolicies: any[],
  unassignedAddresses: any[],
  selectedPolicyDetails: any
) => {
  let data: any[] = R.reject(
    ({ fullAddress }) =>
      fullAddress === selectedPolicyDetails?.fullAddress ||
      fullAddress == "null",
    addressBasedPolicies
  );
  data = [...data, ...(unassignedAddresses ? unassignedAddresses : [])];
  data = data ? data.map(({ fullAddress }) => fullAddress) : [];
  return R.uniq(data);
};

export const fetchAddressFromPolicy = (policy: any) => ({
  address1: policy?.address1,
  address2: policy?.address2,
  address3: policy?.address3,
  city: policy?.city,
  state: policy?.state,
  country: policy?.country,
  pincode: policy?.pincode,
  fullAddress: policy?.fullAddress,
});
