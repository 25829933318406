import React, { useState, useMemo, useEffect } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import theme from "../../../../common/theme";
import AssignPolicyCard from "./AssignPolicyCard";
import LabelWithIcon from "../../../../common/ui/LabelWithIcon";
import { ReactComponent as LifeIcon } from "../../../../common/icons/group-certified-small.svg";
import { ReactComponent as HealthIcon } from "../../../../common/icons/small-health-icon.svg";
import { ReactComponent as AssetsIcon } from "../../../../common/icons/small-assets-icon.svg";
import { ReactComponent as Verify } from "../../../../common/icons/update-mobile/verify_orange.svg";
import { ReactComponent as Cancel } from "../../../../common/icons/update-mobile/cancel_orange.svg";
import CustomButton from "../../../../common/ui/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { find, groupBy, prop, propEq } from "ramda";
import {
  fetchActivePolicy,
  selectActivePolicies,
  selectIsPolicyLoading,
} from "../../PolicyScreen/Policies.slice";
import {
  assignBank,
  assignPoliciesForUnassignedBank,
  selectUploadCanceledChequePayloadDetails,
  skipBank,
} from "../../BankModule/Bank.slice";
import { PolicyAlternateCodes } from "../../../../common/types/PolicyTypes";
import { SELECT_POLICY_TYPES_FOR_MODULES } from "../../../../common/Enums";
import { assignEmail } from "../../Email/Emails.slice";
import Spinner from "../../../../common/ui/Spinner";
import { assignAddress } from "../../AddressModule/Address.slice";
import { assignMobileNumber } from "../../MobileNumber/MobileNumber.slice";

const AssignPolicyScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const activePolicies = useSelector(selectActivePolicies);
  const isPolicyLoading = useSelector(selectIsPolicyLoading);
  const uploadChequePayload = useSelector(
    selectUploadCanceledChequePayloadDetails
  );
  const [selectedPolicies, setSelectedPolicies] = useState<any[]>([]);
  const groupPoliciesByNumber = groupBy(prop<any>("alternateCode"));

  const policies: any = useMemo(() => {
    const verifiedPolicies =
      activePolicies?.length > 0
        ? activePolicies?.filter(
            ({ verifiedFlag }: any) =>
              String(verifiedFlag).toUpperCase() === "Y"
          )
        : [];
    return groupPoliciesByNumber(verifiedPolicies);
  }, [activePolicies, groupPoliciesByNumber]);
  useEffect(() => {
    dispatch(fetchActivePolicy());
  }, []);

  const newMobileNo = useMemo(
    () => location?.state?.newMobileNoData?.mobileNo,
    [location?.state]
  );
  const newAddress = useMemo(
    () => location?.state?.newAddress,
    [location?.state]
  );
  const bankOCRDetails = useMemo(
    () => location?.state?.bankOCRDetails,
    [location?.state]
  );

  const handleCheckBox = (data: any) => (e: any) => {
    const checked: boolean = Boolean(e?.target?.checked);
    if (checked) {
      const newArray: any[] = [...selectedPolicies, data];
      setSelectedPolicies(newArray);
    } else {
      const newArray: any[] = selectedPolicies.filter(
        (selectedPolicy: { policyNo: any }) =>
          selectedPolicy.policyNo !== data.policyNo
      );
      setSelectedPolicies(newArray);
    }
  };

  const assignPolicy = (isSkip: any) => {
    let policies = isSkip ? [] : selectedPolicies;
    if (
      location?.state?.MODULE_TYPE === SELECT_POLICY_TYPES_FOR_MODULES.MOBILE
    ) {
      const handleDone = () => navigate(-1);
      let payload = {
        selectedPolicies: policies,
        newMobileNo,
        handleDone,
        isOld: location?.state?.isOld,
      };
      dispatch(assignMobileNumber(payload));
    } else if (
      location?.state?.MODULE_TYPE === SELECT_POLICY_TYPES_FOR_MODULES.EMAIL
    ) {
      const handleDone = () => navigate(-1);
      let payload = {
        selectedPolicies: policies,
        newEmail: location?.state?.newEmailData?.email,
        handleDone,
        isOld: location?.state?.isOld,
      };
      dispatch(assignEmail(payload));
    } else if (
      location?.state?.MODULE_TYPE === SELECT_POLICY_TYPES_FOR_MODULES.ADDRESS
    ) {
      const handleDone = () => navigate(-1);
      let payload = { selectedPolicies: policies, newAddress, handleDone };
      dispatch(assignAddress(payload));
    } else if (
      location?.state?.MODULE_TYPE === SELECT_POLICY_TYPES_FOR_MODULES.BANK
    ) {
      const handleDone = () => navigate(-1);
      if (location?.state?.isUnassignedBank) {
        if (policies?.length === 0) {
          handleDone();
        } else {
          let payload = {
            selectedPolicies: policies,
            unAssignedBankDetails: location?.state?.bankDetails,
            handleDone,
          };
          dispatch(assignPoliciesForUnassignedBank(payload));
        }
      } else {
        if (policies?.length === 0) {
          let payload: any = {
            isVerified: true,
            uploadChequePayload,
            bankOCRDetails,
            handleDone,
          };
          dispatch(skipBank(payload));
        } else {
          let payload = {
            selectedPolicies: policies,
            newOCRDetails: bankOCRDetails,
            handleDone,
          };
          dispatch(assignBank(payload));
        }
      }
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          className="f-16 py-10 fw-600"
          color={theme.palette.primary.main}
        >
          Select Policies to Assign
        </Typography>
      </Grid>
      <Grid item xs={11} justifyContent="center">
        <Grid container justifyContent="space-between" className="py-10">
          <CustomButton
            text={"Skip"}
            variant={"text"}
            color={"primary"}
            showIcon={false}
            startIcon={Cancel}
            className="mb-15"
            onClick={() => assignPolicy(true)}
          />
          <CustomButton
            className="mr-25 fw-600"
            text={"Assign"}
            variant={"text"}
            color={"primary"}
            showIcon={false}
            startIcon={Verify}
            type="submit"
            disabled={selectedPolicies?.length === 0}
            onClick={() => assignPolicy(false)}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} justifyContent={"space-between"}></Grid>
      {isPolicyLoading ? (
        <Grid item xs={12} container justifyContent={"center"}>
          <Grid item>
            <Spinner />
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid item xs={4}>
            <LabelWithIcon
              svgClassName=""
              justifyContent={"flex-start"}
              Icon={LifeIcon}
              label={"Life"}
              labelProps={{
                color: theme.palette.primary.main,
                fontSize: 14,
              }}
            />
            {policies[PolicyAlternateCodes.LIFE] &&
              policies[PolicyAlternateCodes.LIFE].map((data: any) => (
                <AssignPolicyCard
                  policyDetails={data}
                  handleCheck={handleCheckBox(data)}
                  isChecked={Boolean(
                    find(propEq(data?.policyNo, "policyNo"))(selectedPolicies)
                  )}
                />
              ))}
          </Grid>
          <Grid item xs={4}>
            <LabelWithIcon
              svgClassName=""
              justifyContent={"flex-start"}
              Icon={HealthIcon}
              label={"Health"}
              labelProps={{
                color: theme.palette.primary.main,
                fontSize: 14,
              }}
            />
            {policies[PolicyAlternateCodes.HEALTH] &&
              policies[PolicyAlternateCodes.HEALTH].map((data: any) => (
                <AssignPolicyCard
                  policyDetails={data}
                  handleCheck={handleCheckBox(data)}
                  isChecked={Boolean(
                    find(propEq(data?.policyNo, "policyNo"))(selectedPolicies)
                  )}
                />
              ))}
          </Grid>
          <Grid item xs={4}>
            <LabelWithIcon
              svgClassName=""
              justifyContent={"flex-start"}
              Icon={AssetsIcon}
              label={"Assets-Motor"}
              labelProps={{
                color: theme.palette.primary.main,
                fontSize: 14,
              }}
            />
            {policies[PolicyAlternateCodes.ASSET] &&
              policies[PolicyAlternateCodes.ASSET].map((data: any) => (
                <AssignPolicyCard
                  policyDetails={data}
                  handleCheck={handleCheckBox(data)}
                  isChecked={Boolean(
                    find(propEq(data?.policyNo, "policyNo"))(selectedPolicies)
                  )}
                />
              ))}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default AssignPolicyScreen;
