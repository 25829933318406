import crypto from "../../crypto-custom";
import config from "./config";

var algorithm = "aes-256-cbc";

const encrypt = (input, key, iven) =>
  new Promise(async (resolve, reject) => {
    try {
      var hash = crypto.createHash("sha256").update(key).digest();
      var hashstring = hash.toString("hex");
      hashstring = hashstring.substring(0, 32);

      var cipher = crypto.createCipheriv(algorithm, hashstring, iven);
      var encrypted =
        cipher.update(input, "utf8", "base64") + cipher.final("base64");

      encrypted = encrypted.split("+").join("-");
      encrypted = encrypted.split("/").join("_");
      console.log("encrypted-->", encrypted);
      resolve(encrypted);
    } catch (e) {
      reject(e);
    }
  });

const decrypt = async (encryptedInput, key, iven) =>
  new Promise(async (resolve, reject) => {
    try {
      var hash = crypto.createHash("sha256").update(key).digest();
      var hashstring = hash.toString("hex");
      hashstring = hashstring.substring(0, 32);
      var decryptedInput = encryptedInput.split("-").join("+");
      decryptedInput = decryptedInput.split("_").join("/");
      var decipher = crypto.createDecipheriv(algorithm, hashstring, iven);
      var decrypted =
        decipher.update(JSON.stringify(encryptedInput), "base64", "utf8") +
        decipher.final("utf8");
      console.log("decrypted-->", decrypted);
      resolve(decrypted);
    } catch (e) {
      reject(e);
    }
  });

const generateChecksum = async (input, key) =>
  new Promise(async (resolve, reject) => {
    try {
      resolve(crypto.createHmac("sha256", key).update(input).digest("hex"));
    } catch (e) {
      reject(e);
    }
  });

// export {encryptRequest, decryptResponse, generateRandomUUID};

export const EncryptCamsPayRequest = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      // console.log("Cams Payload -->", data);
      const idPayload = {
        merchantid: config.camsMerchantId,
        subbillerid: config.camsSupplierId,
        paytype: "ALL",
      };
      const id = await encrypt(
        JSON.stringify(idPayload),
        config.camsIdKey,
        config.camsIdIv
      );
      const req = await encrypt(
        JSON.stringify(data),
        config.camsReqKey,
        config.camsReqIv
      );
      const checksum = await generateChecksum(
        JSON.stringify(data),
        config.camsChecksumKey
      );
      const dec = await decrypt(
        "6k-008aeXXKNovAtOcNmmCEQq9ctdl5bCwBjC8Sei48_GRkHrfcmcpnv0juELAqFpoR0W2pnCD0Z4BJ0obVo4R8gp_zKgemL8qfppocJ2r4mH-75Zw4ZmtsVCd7OzWKZfUl6NzhVuNzZCUb7eKfZ9GaJJtRQsoIB8in4-RG1FuLfc9Tlfv_V06uUjCEAGokYzxPeQ4fE-cEHgjSz_chyZerNYYiYf8Yfv1gX05Mjf73IaY7AJj1rSwIYD4ZCtDZL8F2377FHJSIZU_SsbilnqEWGiVDz-e1Vd79j1WJUreBFB8wNYhaBjCHs2fA1Myg81Rxgkidg-9d8PdsXwKFjrrnv2wchfy730ndeiQv3elosmezfFkB8cS53foveEplRtU4RSR6thEaRsFnRiaaRWB0HiIUlip-bXrqJEyCAhtvh4v0Fywt06cA7T9biGlc6CSAPdtmXIRQVQ_2kPFE4v9W6jN_IWh1qwdMdMs-CJ2Vrr1OiOxwUpdp4yuuX4C2XOwGkdaycZYTtMZz8q01lenSuU5LWHDAWY2a75ochc4c90_xqUTw33sa6CT84gE4_6NFUqq52h0VjjqCtknZGd1mKQJ4rNJlMaS24Ay-1d36fqRe_sPobY9a8Nwpl0XaeiTwvX0n9sjE71iPOa-FjB8xBz2mPhSU_RfWFS3XtwqbgWnrJcgiCJNprzHAjM075LEolBA4BwOkigXe6t9xSVVF1duTLO_0Tq9t-GO3iUXM-hpatqb85EOepuhqQb1eyIN-faZgsYSjIpI2w6ZmGay10J3gu2BQcDxJFj-NAb0WSD57gdhPriHf7PWSt3fvxVMp43Ra2vICjGPL4RR8WlV2SUWgfBlKYYZ1bH-rxICFu7a7QjQGD9p02cnwpKWa1HfmQWm0PMU11IVdELms0J-4ECX3yrHbmmqXq3uzKMkEwyqg7KHW8Zkz0yei15uCF",
        config.camsDecKey,
        config.camsReqIv
      );
      // console.log("dec==>", JSON.parse(dec));
      resolve({ id, req, checksum });
    } catch (error) {
      console.log(error);
      reject(false);
    }
  });

export const DecryptCamsPayRequest = (encryptedText) =>
  new Promise(async (resolve, reject) => {
    try {
      const decryptedText = await decrypt(
        encryptedText,
        config.camsDecKey,
        config.camsReqIv
      );
      // console.log("dec==>", JSON.parse(decryptedText));
      resolve(JSON.parse(decryptedText));
    } catch (error) {
      console.log(error);
      reject(false);
    }
  });
