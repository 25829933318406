import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import theme from '../../../../../common/theme';
import '../../Policy.scss';
import LabelWithIcon from '../../../../../common/ui/LabelWithIcon';
import ICICI from '../../../../../assets/images/ICICI.png';
import { ReactComponent as OrangeDot } from '../../../../../common/icons/orange-bullet.svg';
import { ReactComponent as OrangeRupee } from '../../../../../common/icons/small-orange-rupee.svg';
import { convertAmount } from '../../../../../common/utils';
import VerificationStatus from './VerificationStatus';
interface UserProps {
  contentType?: string;
  items?: any;
}
const AssetsHeader: React.FC<UserProps> = ({ contentType, items }) => {
  return (
    <Grid container>
      <Grid container className='px-10 py-15' spacing={2}>
        <Grid item xs={3}>
          <img
            src={items.companyLogo}
            width={'60px'}
            height={'60px'}
            className='image-assets'
          />
          <VerificationStatus status={items.verifiedFlag} />
        </Grid>
        <Grid item xs={9}>
          <Typography
            className='f-14 fw-600 '
            color={theme.palette.primary.main}
          >
            {items.planName}
          </Typography>
          <Typography
            className='f-14 fw-600 '
            color={theme.palette.primary.main}
          ></Typography>{' '}
          <Typography className='f-14 ' color={theme.palette.primary.main}>
            Cover Type
          </Typography>
          <Typography
            className='f-16 fw-600'
            color={
              contentType === 'active'
                ? theme.palette.common.black
                : theme.palette.grey.A700
            }
          >
            {items.companyName}
          </Typography>
          <Typography
            className='f-12'
            color={
              contentType === 'active'
                ? theme.palette.common.black
                : theme.palette.grey.A700
            }
          >
            {items.PlanName}
          </Typography>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography
                className='f-14 mt-8'
                color={theme.palette.primary.main}
              >
                IDV
              </Typography>
              <LabelWithIcon
                className=' f-16'
                svgClassName='mb-2'
                justifyContent={'flex-start'}
                Icon={OrangeRupee}
                label={convertAmount(items?.idv)}
                labelProps={{
                  color:
                    contentType === 'active'
                      ? theme.palette.common.black
                      : theme.palette.grey.A700,

                  fontSize: 14,
                  fontWeight: 600,
                }}
              />
            </Grid>{' '}
            <Grid item xs={6}>
              <Typography
                className='f-14 mt-8'
                color={theme.palette.primary.main}
              >
                Depreciation
              </Typography>
              <Typography
                className='f-16'
                color={theme.palette.common.black}
                fontWeight={600}
              >
                @ {items?.depreciation}%
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Typography
              className='f-14 fw-600 '
              color={theme.palette.primary.main}
            >
              Covers
            </Typography>
            {/* <LabelWithIcon
              className=' f-16'
              svgClassName='mb-2'
              justifyContent={'flex-start'}
              Icon={OrangeDot}
              label={'Own Damage'}
              labelProps={{
                color:
                  contentType === 'active'
                    ? theme.palette.common.black
                    : theme.palette.grey.A700,

                fontSize: 14,
              }}
            />
            <LabelWithIcon
              className=' f-16'
              svgClassName='mb-2'
              justifyContent={'flex-start'}
              Icon={OrangeDot}
              label={'Third Party'}
              labelProps={{
                color:
                  contentType === 'active'
                    ? theme.palette.common.black
                    : theme.palette.grey.A700,

                fontSize: 14,
              }}
            // /> */}
            {/* data is not provided in api response */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AssetsHeader;
