import { Grid, Typography, useTheme } from "@mui/material";

import React from "react";
import { ReactComponent as need_money } from "../../../common/icons/dashboard/need_money.svg";
import { ReactComponent as rightArrow } from "../../../common/icons/right_arrow-icon.svg";
import { ReactComponent as pay } from "../../../common/icons/dashboard/pay_premiums-icon.svg";
import { ReactComponent as home } from "../../../common/icons/dashboard/home-icon.svg";
import { ReactComponent as bank } from "../../../common/icons/dashboard/bank-icon.svg";
import { ReactComponent as group } from "../../../common/icons/dashboard/group-icon.svg";
import { ReactComponent as email } from "../../../common/icons/email-small.svg";
import { ReactComponent as mobile } from "../../../common/icons/mobile-small.svg";
import SvgConverter from "../../../common/SvgConverter";
import DashboardCardLayout from "./common/DashboardCardLayout";
import Separator from "../../../common/ui/Separator";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../common/constants/Routes";

const HelpComponent = () => {
  const theme = useTheme();
  const HealthContent = [
    {
      icon: need_money,
      title: "I am in need of money?",
      link: "/needmoney",
    },
    {
      icon: pay,
      title: "Pay Premiums",
      link: "#",
    },
    {
      icon: home,
      title: "Update Address",
      link: APP_ROUTES.MANAGE_ADDRESS,
    },
    {
      icon: mobile,
      title: "Update Mobile Number",
      link: APP_ROUTES.UPDATE_MOBILE,
    },
    {
      icon: email,
      title: "Update Email",
      link: APP_ROUTES.EMAIL,
    },
    {
      icon: bank,
      title: "Update Bank Details",
      link: APP_ROUTES.BANK,
    },
    {
      icon: group,
      title: "See/Update nominees",
      link: APP_ROUTES.NOMINEE,
    },
  ];
  return (
    <DashboardCardLayout>
      <div className="w-280px">
        <Typography
          fontSize={16}
          color={"primary"}
          className={"mb-25"}
          fontWeight={600}
        >
          How may we help?
        </Typography>
        <div className="mb-10">
          <Separator color={theme.palette.grey[300]} />
        </div>
        {HealthContent.map((data, key) => (
          <div key={key}>
            <Link to={data.link ? data.link : "#"}>
              <Grid container alignItems={"center"}>
                <Grid item sx={{ width: 40 }}>
                  <Grid justifyContent={"center"} alignItems={"center"}>
                    <Grid item>
                      <SvgConverter Icon={data.icon} className={"d-flex"} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item flex={1}>
                  <Grid container spacing={1} justifyContent={"space-between"}>
                    <Grid item>
                      <Typography color={"primary"} fontSize={16}>
                        {data.title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <SvgConverter
                        Icon={rightArrow}
                        className={"right-arrow d-flex"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Link>
            <div className="my-10">
              <Separator color={theme.palette.grey[300]} />
            </div>
          </div>
        ))}
      </div>
    </DashboardCardLayout>
  );
};

export default HelpComponent;
