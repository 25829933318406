import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import "./Bank.scss";
import BankHeader from "./BankHeader";
import ManageBankHeader from "./ManageBankHeader";
import {
  changeBank,
  clearBankEntries,
  fetchPoliciesForBanks,
  getBankDetailsByIfscRequest,
  handleInstantBankFlow,
  removeBank,
  selectBankChangesList,
  selectGetBankDetails,
  selectIsPolicyBanksLoading,
  selectUploadCanceledChequeDetails,
  selectUploadCanceledChequeLoading,
  selectUploadCanceledChequePayloadDetails,
  uploadCanceledChequeRequest,
  removeBank as removeBankPolicies,
  updateBankChangesRequest,
  setBankChangesList,
  skipBank,
  selectUpdateBankChangesLoading,
  selectGetBankDetailsLoading,
} from "./Bank.slice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SELECT_POLICY_TYPES_FOR_MODULES } from "../../../common/Enums";
import { APP_ROUTES } from "../../../common/constants/Routes";
import Spinner from "../../../common/ui/Spinner";
import ChangeBankModalDetails from "./BankModals/ChangeBankModalDetails";
import RemoveBankModalDetails from "./BankModals/RemoveBankModalDetails";
import BankEntryModal from "./BankModals/BankEntryModal";
import UploadImageBankModal from "./BankModals/UploadImageBankModal";
import UploadModal from "./BankModals/UploadModal";
import { DoneModal } from "../Common/DoneModal";

const ManageBankScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [doneModal, setDoneModal] = useState(false);
  const [removeBankModal, setRemoveBankModal] = useState(false);
  const [changeBankModal, setChangeBankModal] = useState(false);
  const [addAccount, setAddAccount] = useState(false);
  const [verifyNow, setVerifyNow] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [newAccountData, setNewAccountData] = useState<any>(null);
  const [imagePickerModalVisible, setImagePickerModalVisible] = useState(false);
  const [oldBankData, setOldBankData] = useState(false);
  const [instantAddAccountData, setInstantAddAccountData] = useState(false);

  const isPoliciesForBanksLoading = useSelector(selectIsPolicyBanksLoading);
  const isUploadCheckLoadingLoading = useSelector(
    selectUploadCanceledChequeLoading
  );
  const bankDetailsByIfsc = useSelector(selectGetBankDetails);
  const bankChangesList = useSelector(selectBankChangesList);
  const bankOCRDetails: any = useSelector(selectUploadCanceledChequeDetails);
  const uploadChequePayload = useSelector(
    selectUploadCanceledChequePayloadDetails
  );
  const isGetBankDetailsLoading = useSelector(selectGetBankDetailsLoading);
  useEffect(() => {
    if (bankChangesList?.length === 0) dispatch(fetchPoliciesForBanks());
  }, [dispatch, bankChangesList]);

  const handleRemoveBank = (acNo: string, type: any) => {
    if (type) {
      dispatch(removeBank({ targetBankAccountNo: acNo, type }));
    } else {
      setSelectedAccount(acNo);
      setRemoveBankModal(true);
    }
  };

  const handleUploadModal = (chequeImage: any) => {
    let payload: any = {
      payeeAccNo: newAccountData?.accountNumber,
      payeeAccName: newAccountData?.accountHolderName,
      payeeIfscCode: bankDetailsByIfsc?.IFSC,
      chequeImage,
    };

    payload.handleSuccess = () => {
      if (instantAddAccountData) {
        setVerifyNow(false);
        dispatch(handleInstantBankFlow(instantAddAccountData));
      }
      setImagePickerModalVisible(false);
    };
    dispatch(uploadCanceledChequeRequest(payload));
  };

  const handleChangeBank = (acNo: string, policy: any) => () => {
    setSelectedAccount(acNo);
    setSelectedPolicy(policy);
    setChangeBankModal(true);
  };
  const verifyModal = () => {
    setVerifyNow(true);
  };

  const addBankAccount = (primaryValue: string, isChange: any) => {
    setInstantAddAccountData(false);
    if (primaryValue) {
      setInstantAddAccountData({
        [isChange ? "selectedPolicyNo" : "selectedBankAcNo"]: primaryValue,
        isChange: Boolean(isChange),
      } as any);
    }

    dispatch(clearBankEntries);
    setAddAccount(true);
    setRemoveBankModal(false);
    setChangeBankModal(false);
  };
  const handleSearch = (data: any) => {
    let payload: any = { ifsc: data?.ifscCode };
    payload.handleSuccess = () => {
      setNewAccountData(data);
      setAddAccount(false);
      setVerifyNow(true);
    };
    dispatch(getBankDetailsByIfscRequest(payload));
  };

  const onChangePolicy = (targetBank: any) => {
    dispatch(changeBank({ selectedPolicy, targetBank }));
    setChangeBankModal(false);
    setSelectedAccount("");
    setSelectedPolicy(null);
  };

  const onRemovePolicy = (selectedValue: any) => {
    dispatch(
      removeBankPolicies({
        targetBankAccountNo: selectedValue,
        oldBankAccNo: selectedAccount,
      })
    );
    setRemoveBankModal(false);
    setSelectedAccount("");
    setSelectedPolicy(null);
  };

  const handleBankChangesSubmit = () => {
    const handleDone: any = () => {
      setDoneModal(false);
      navigate(-1);
    };
    dispatch(updateBankChangesRequest({ handleDone } as any));
  };

  const handleCancel = () => {
    navigate(-1);
    dispatch(setBankChangesList([]));
  };

  const isUpdateBankLoading = useSelector(selectUpdateBankChangesLoading);

  const handleOnclickDone = () => {
    if (bankChangesList?.length > 0) {
      setDoneModal(true);
    } else {
      navigate(-1);
    }
    return true;
  };

  const handleAssignPolicies = () => {
    setVerifyNow(false);
    navigate(APP_ROUTES.ASSIGN_POLICY, {
      state: {
        bankOCRDetails: {
          OCR: bankOCRDetails?.OCR,
          PennyDrop: bankOCRDetails?.PennyDrop,
          NameMatch: bankOCRDetails?.NameMatch,
        },
        MODULE_TYPE: SELECT_POLICY_TYPES_FOR_MODULES.BANK,
      },
    });
  };

  const handleSkip = (isVerified: boolean) => {
    let payload: any = {
      isVerified,
      bankDetailsByIfsc,
      uploadChequePayload,
      bankOCRDetails,
      newAccountData,
    };
    payload.handleDone = () => {
      setVerifyNow(false);
    };
    dispatch(skipBank(payload));
  };

  const handleVerifyNow = (bankInfo: any, type: any) => {
    let payload: any = { ifsc: bankInfo?.ifscCode };
    payload.handleSuccess = () => {
      setNewAccountData({
        accountHolderName: bankInfo?.accountHolderName,
        accountNumber: bankInfo?.accountNo,
        ifscCode: bankInfo?.ifscCode,
        retypeAccountNo: bankInfo?.accountNo,
      } as any);
      setAddAccount(false);
      setOldBankData({ bankInfo, type } as any);
    };
    dispatch(getBankDetailsByIfscRequest(payload));
    setVerifyNow(true);
  };

  const handleAssignPoliciesForUnassignedBanks = (bank: any) => {
    navigate(APP_ROUTES.ASSIGN_POLICY, {
      state: {
        bankDetails: bank,
        isUnassignedBank: true,
        MODULE_TYPE: SELECT_POLICY_TYPES_FOR_MODULES.BANK,
      },
    });
  };

  return (
    <>
      <Grid item className="ml-50 mr-50">
        <Grid item xs={12}>
          <BankHeader
            onAddBankClick={addBankAccount}
            onClickDone={handleOnclickDone}
          />
        </Grid>
        {isPoliciesForBanksLoading ? (
          <Grid container justifyContent={"center"}>
            <Grid item>
              <Spinner />
            </Grid>
          </Grid>
        ) : (
          <ManageBankHeader
            handleChangeBank={handleChangeBank}
            handleRemoveBank={handleRemoveBank}
            handleVerifyNow={handleVerifyNow}
            handleAssignPolicies={handleAssignPoliciesForUnassignedBanks}
          />
        )}
      </Grid>
      <ChangeBankModalDetails
        open={changeBankModal}
        setOpen={setChangeBankModal}
        onSubmit={onChangePolicy}
        addBankAccount={addBankAccount}
        selectedAccount={selectedAccount}
        selectedPolicy={selectedPolicy}
      />
      <RemoveBankModalDetails
        open={removeBankModal}
        setOpen={setRemoveBankModal}
        onSubmit={onRemovePolicy}
        addBankAccount={addBankAccount}
        selectedAccount={selectedAccount}
      />
      <BankEntryModal
        open={addAccount}
        setOpen={setAddAccount}
        onSubmit={handleSearch}
        loading={isGetBankDetailsLoading}
      />
      <UploadImageBankModal
        open={verifyNow}
        setOpen={setVerifyNow}
        bankDetails={bankDetailsByIfsc}
        accountDetails={newAccountData}
        handleUploadModal={handleUploadModal}
        uploadChequeIsDone={Boolean(uploadChequePayload?.chequeImage)}
        uploadChequeData={uploadChequePayload}
        handleSkip={handleSkip}
        imagePickerModalVisible={imagePickerModalVisible}
        setImagePickerModalVisible={setImagePickerModalVisible}
        handleAssignPolicies={handleAssignPolicies}
      />
      <UploadModal open={isUploadCheckLoadingLoading} />
      <DoneModal
        open={doneModal}
        setOpen={setDoneModal}
        onSubmit={handleBankChangesSubmit}
        loading={isUpdateBankLoading}
        onCancel={handleCancel}
        contentType={"BANK"}
      />
    </>
  );
};

export default ManageBankScreen;
