import React from 'react';
import { Grid } from '@mui/material';
import '../Nominee.scss';
import Typography from '@mui/material/Typography';
import HDFC from '../../../../assets/images/HDFC.png';
import { Link } from 'react-router-dom';
import Separator from '../../../../common/ui/Separator';
import theme from '../../../../common/theme';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRupee } from '../../../../common/icons/orange_rupee.svg';
import CustomButton from '../../../../common/ui/CustomButton';
import { ReactComponent as Add } from '../../../../common/icons/plus-icon.svg';
import { ReactComponent as Correct } from '../../../../common/icons/tick-icon.svg';
import { ReactComponent as Cancel } from '../../../../common/icons/Cancel-small.svg';
import { ReactComponent as Edit } from '../../../../common/icons/edit.svg';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import { CommonModal } from '../../../../common/ui/Modal/CommonModal';

const NomineeModal = ({
  open,
  setOpen,
  onSubmit,
  loading,
  FinalSubmitModal,
}: any) => {
  return (
    <CommonModal
      onClose={() => setOpen(false)}
      open={open}
      modalClassName='nominee-modal px-20 py-20'
      boxProps={{ width: 354, height: 400 }}
    >
      <Grid container className=''>
        <Grid item xs={12} textAlign={'center'} className='py-20'>
          <Typography
            fontSize={18}
            fontWeight={600}
            color={theme.palette.primary.main}
            className='mb-2'
          >
            You have made changes in the nominees.
          </Typography>
          <Typography
            fontSize={18}
            fontWeight={600}
            color={theme.palette.primary.main}
            className='mt-15 '
            textAlign={'center'}
          >
            The changes will be forwarded to the insurance company and the
            official nominee details will be updated.
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign={'center'} className='mt-20'>
          <Link to={APP_ROUTES.NOMINEE}>
            <CustomButton
              className='f-16 fw-600'
              text={'Submit changes'}
              variant={'text'}
              color={'primary'}
              showIcon={false}
              startIcon={Correct}
              onClick={FinalSubmitModal}
            />
          </Link>
        </Grid>
        <Grid item xs={12} textAlign={'center'} className='mt-15'>
          <CustomButton
            className='f-16 fw-400'
            text={'Edit More'}
            variant={'text'}
            color={'primary'}
            showIcon={false}
            startIcon={Edit}
            onClick={() => setOpen(false)}
          />
        </Grid>
        <Grid item xs={12} textAlign={'center'} className='mt-16'>
          <Link to={APP_ROUTES.NOMINEE}>
            <CustomButton
              className='f-16 fw-400'
              text={'cancel & Exit'}
              variant={'text'}
              color={'primary'}
              showIcon={false}
              startIcon={Cancel}
            />
          </Link>
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default NomineeModal;
