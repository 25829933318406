import React, { useState } from 'react';
import { Grid } from '@mui/material';
import '../Nominee.scss';
import Typography from '@mui/material/Typography';
import HDFC from '../../../../assets/images/HDFC.png';
import { Link, useLocation, useParams } from 'react-router-dom';
import Separator from '../../../../common/ui/Separator';
import theme from '../../../../common/theme';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import { ReactComponent as Cancel } from '../../../../common/icons/Cancel-small.svg';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import { useFieldArray, useForm } from 'react-hook-form';
import CustomButton from '../../../../common/ui/CustomButton';
import NomineeCardHeader from './NomineeCardHeader';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import { ReactComponent as Add } from '../../../../common/icons/plus-icon.svg';
import { ReactComponent as Correct } from '../../../../common/icons/tick-icon.svg';
import NomineeModal from './NomineeModal';
import NomineeListModal from './NomineeListModal';
import NomineeAddress from './NomineeAddress';
import { useDispatch, useSelector } from 'react-redux';
import { updateNomineeRequest } from '../Nominee.slice';
import data from '../../../../common/constants/FormConstants.json';
import NomineeForm from './NomineeForm';
import { setAlertInfo } from '../../Common/Common.slice';

const EditNominee = () => {
  const dispatch = useDispatch();
  const [addFormData, setAddFormData] = useState([]);
  const [submitModalVisible, setsubmitModalVisible] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const handleAddModal = () => {
    setAddModalVisible(true);
  };

  const location = useLocation();
  const cardData = location.state?.cardData || {
    planName: '',
    lifeAssuredName: '',
    sumAssured: '',
    basicPremiumAmount: '',
    companyLogo: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    watch,
    control,
  } = useForm({
    defaultValues: {
      nomineeData:
        cardData.Nominees &&
        cardData.Nominees.map((item: any) => {
          const [Date, Month, Year] = item.nomineeDOB.split('-');
          return {
            nomineeSeqID: item.nomineeSeqID || '0',
            nomineeSentToInsurer: item.nomineeSentToInsurer,
            nomineeFirstName: item.nomineeFirstName,
            nomineePercentage: item.nomineePercentage,
            nomineeAge: item.nomineeAge,
            date: Date,
            month: Month,
            year: Year,
            relationshipName: item.relationshipName,
            relationshipCode: item.relationshipCode,
            address1: item.address1,
            address2: item.address2,
            address3: item.address3,
            city: item.city,
            state: item.state,
            country: item.country,
            pincode: item.pincode,
            fullAddress: item.fullAddress,
          };
        }),
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'nomineeData',
  });
  // const dobValue = watch('dob');

  const type = 'NOMINEE';
  const [list, setList] = useState(false);

  const handleList = () => {
    setList(true);
  };
  const [removeFormData, setRemoveFormData] = useState<
    { id: string; type: string }[]
  >([]);

  const percentage = watch();
  const percentageCalc =
    percentage.nomineeData &&
    percentage.nomineeData
      .map((item: any) => parseInt(item.nomineePercentage, 10))
      .filter((percentage: number) => !isNaN(percentage));
  const sumOfPercentages =
    percentageCalc &&
    percentageCalc.reduce(
      (sum: number, percentage: number) => sum + percentage,
      0
    );

  const removeNominee = (index: number) => {
    const removedItem = {
      type: 'REMOVE',
      ...fields[index],
    };
    remove(index);
    setRemoveFormData((prev) => [...prev, removedItem]);
  };

  const addNomineeModal = () => {
    setAddModalVisible(false);
    const nomineeData = {
      type: 'ADD',
      nomineeSeqID: '0',
      nomineeSentToInsurer: '',
      nomineeFirstName: '',
      nomineePercentage: '',
      nomineeAge: '',
      date: '',
      month: '',
      year: '',
      relationshipName: '',
      relationshipCode: '',
      address1: '',
      address2: '',
      address3: '',
      city: '',
      country: '',
      pincode: '',
      state: '',
    };
    append(nomineeData);
  };

  const addNomineeCloseModal = (data: any) => {
    setAddModalVisible(false);
    const [Date, Month, Year] = data.dob.split('-');
    const nomineeData = {
      type: 'ADD',
      nomineeSeqID: '0',
      nomineeSentToInsurer: data.addressVerified,
      nomineeFirstName: data.firstName,
      nomineePercentage: '',
      nomineeAge: data.nomineeAge,
      date: Date,
      month: Month,
      year: Year,
      relationshipName: data.relationName,
      relationshipCode: data.relationCode,
      address1: data.address1,
      address2: data.address2,
      address3: data.address3,
      city: data.city,
      country: data.country,
      pincode: data.pincode,
      state: data.state,
    };
    const nomineeExists =
      cardData.Nominees &&
      cardData.Nominees.some((nominee: any) => {
        const [dobDate, dobMonth, dobYear] = nominee.nomineeDOB.split('-');
        return (
          nominee.nomineeFirstName === nomineeData.nomineeFirstName &&
          nominee.relationshipName === nomineeData.relationshipName &&
          nominee.relationshipCode === nomineeData.relationshipCode &&
          dobDate === nomineeData.date &&
          dobMonth === nomineeData.month &&
          dobYear === nomineeData.year
        );
      });
    if (!nomineeExists) {
      append(nomineeData);
    } else {
      const alertMessage = `${nomineeData.nomineeFirstName} already exists as a nominee. Please select another person.`;
      dispatch(
        setAlertInfo({
          type: 'FAILED',
          open: true,
          description: alertMessage,
        })
      );
    }
  };

  const FinalSubmitModal = () => {
    const nomineeData = getValues();
    const filteredNomineeData = nomineeData.nomineeData.filter(
      (formData: any) => formData.type !== 'ADD'
    );
    const uniqueNomineeData: any[] = [];

    const changeForm = filteredNomineeData.map((formData: any) => {
      if (
        !uniqueNomineeData.some(
          (data) => data.nomineeFirstName === formData.nomineeFirstName
        )
      ) {
        uniqueNomineeData.push(formData);
      }
      return {
        ...formData,
        type: 'CHANGE',
      };
    });

    const formData = {
      addForm: addFormData,
      changeForm: changeForm,
      removeForm: removeFormData,
      // navigation: navigation,
    };
    dispatch(
      updateNomineeRequest({
        particularCardData: cardData,
        allFormData: formData,
      })
    );
    setsubmitModalVisible(false);
  };
  const onSubmit = (data: any) => {
    console.log('data submit data', data);
    const filteredData = data.nomineeData.filter(
      (formData: any) => formData.type === 'ADD'
    );
    setAddFormData(filteredData);
    if (!isValid) {
      return false;
    } else {
      setsubmitModalVisible(true);
    }
  };
  return (
    <Grid container justifyContent={'center'}>
      <Grid item xs={12}>
        <Typography
          fontSize={16}
          fontWeight={600}
          color={theme.palette.primary.main}
          className='mb-5'
        >
          Edit Nominee
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Grid container className='nominee-form'>
          <Grid item xs={12}>
            <Grid container className='ml-10 py-10'>
              <Grid item xs={6}>
                <Link to={APP_ROUTES.NOMINEE}>
                  <CustomButton
                    className=' fw-400'
                    text={'cancel'}
                    variant={'text'}
                    color={'primary'}
                    showIcon={false}
                    startIcon={Cancel}
                  />
                </Link>
              </Grid>
              <Grid item xs={6}>
                <CustomButton
                  className=' fw-600 '
                  text={'submit'}
                  variant={'text'}
                  color={'primary'}
                  showIcon={false}
                  startIcon={Correct}
                  disabled={sumOfPercentages !== 100}
                  onClick={handleSubmit(onSubmit)}
                />
                <NomineeModal
                  open={submitModalVisible}
                  setOpen={setsubmitModalVisible}
                  FinalSubmitModal={FinalSubmitModal}
                />
              </Grid>
            </Grid>

            <Grid item>
              <NomineeCardHeader
                productName={cardData.planName}
                selfName={cardData.lifeAssuredName}
                sumAssured={cardData.sumAssured}
                premium={cardData.basicPremiumAmount}
                bankImage={cardData.companyLogo}
              />
            </Grid>
            <Grid item className='my-10'>
              <Separator color={theme.palette.primary.main} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container className=''>
              <Grid
                container
                justifyContent={'space-around'}
                flexWrap={'nowrap'}
              >
                <Grid item>
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    color={theme.palette.primary.main}
                  >
                    Nominee
                  </Typography>
                </Grid>
                <Grid item flexWrap={'nowrap'}>
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    color={theme.palette.error.main}
                  >
                    The total should be 100%
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    color={
                      sumOfPercentages != 100
                        ? theme.palette.error.main
                        : theme.palette.primary.main
                    }
                  >
                    {sumOfPercentages}%
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} className='ml-10 py-10'>
                <CustomButton
                  className=' f-14'
                  text={'Add a Nominee'}
                  variant={'text'}
                  color={'primary'}
                  showIcon={false}
                  startIcon={Add}
                  onClick={handleAddModal}
                />
                <NomineeListModal
                  open={addModalVisible}
                  setOpen={setAddModalVisible}
                  addNomineeCloseModal={addNomineeCloseModal}
                  addNomineeModal={addNomineeModal}
                />
              </Grid>
              <Grid item>
                {fields.map((item: any, index: number) => (
                  <Grid item key={index}>
                    <NomineeForm
                      item={item}
                      index={index}
                      key={item.id}
                      setValue={setValue}
                      register={register}
                      errors={errors}
                      watch={watch}
                      control={control}
                      submit={handleSubmit(onSubmit)}
                      onRemove={() => removeNominee(index)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditNominee;
