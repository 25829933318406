import { Grid, Typography } from '@mui/material';
import React from 'react';
import '../../Policy.scss';

import theme from '../../../../../common/theme';
import ActiveLifeCard from '../ActiveContent/ActiveLifeCard';

interface UserProps {
  contentType?: string;
  filteredLife?: any;
}
export const ActiveLife: React.FC<UserProps> = ({
  contentType,
  filteredLife,
}) => {
  console.log('filteredLife', filteredLife);
  return (
    <Grid container justifyContent={'center'}>
      {filteredLife.map((data: any, index: any) => (
        <Grid container item xs={4} justifyContent={'space-between'}>
          <Grid xs={12} item key={index}>
            <Typography
              className='f-12 fw-600 py-4'
              color={theme.palette.primary.main}
            >
              {data?.heading}
            </Typography>

            <ActiveLifeCard
              contentType={contentType}
              items={data}
              verificationStatus={'Y'}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default ActiveLife;
