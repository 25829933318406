import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import LabelWithIcon from "../../../common/ui/LabelWithIcon";
import { ReactComponent as checked } from "../../../common/icons/profile/checked.svg";
import SvgConverter from "../../../common/SvgConverter";

const VerifiedText = () => {
  const theme = useTheme();
  return (
    <Grid container spacing={0.5} alignItems={"center"} flexWrap={"nowrap"}>
      <Grid item>
        <SvgConverter Icon={checked} width={12} height={9} />
      </Grid>
      <Grid item>
        <Typography className=" f-14 " color={theme.palette.success.main}>
          verified
        </Typography>
      </Grid>
    </Grid>
  );
};

export default VerifiedText;
