import React from 'react';
import Files from 'react-files';

interface FileDropzoneProps {
  onFilesChange: (files: any) => void;
  onFilesError?: (error: any, file: any) => void;
  accepts?: string[];
  multiple?: boolean;
  maxFileSize?: number;
  minFileSize?: number;
  clickable?: boolean;
  children?: React.ReactNode;
}

const FileUploader: React.FC<FileDropzoneProps> = ({
  onFilesChange,
  onFilesError,
  accepts,
  multiple,
  maxFileSize,
  minFileSize,
  clickable,
  children,
}) => {
  const handleChange = (files: any) => {
    onFilesChange(files);
  };

  const handleError = (error: any, file: any) => {
    if (onFilesError) {
      onFilesError(error, file);
    } else {
      console.log('error code ' + error.code + ': ' + error.message);
    }
  };

  return (
    <Files
      onChange={handleChange}
      onError={handleError}
      accepts={accepts}
      multiple={multiple}
      maxFileSize={maxFileSize}
      minFileSize={minFileSize}
      clickable={clickable}
    >
      {children}
    </Files>
  );
};

export default FileUploader;
