import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../../common';
import { RootState } from '../../../common/store';
import { DashboardDetails } from './types/Dashboard.model';

interface DashboardSlice {
  isLoading: LoadingState;
  dashboardDetails: {
    status: LoadingState;
    requestInfo: DashboardDetails[] | any;
    error: any;
  };
  errors: any | null;
  dashboard: any | null;
}

const initialState: DashboardSlice = {
  isLoading: 'idle',
  dashboardDetails: {
    status: 'idle',
    requestInfo: [],
    error: '',
  },
  errors: null,
  dashboard: null,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    dashboardListRequest: (state) => {
      state.dashboardDetails.status = 'loading';
      state.dashboardDetails.requestInfo = [];
      state.dashboardDetails.error = null;
    },
    dashboardListSuccess: (state, action) => {
      state.dashboardDetails.status = 'done';
      state.dashboardDetails.requestInfo = action.payload;
      state.dashboardDetails.error = null;
    },
    dashboardListError: (state, action) => {
      state.dashboardDetails.status = 'done';
      state.dashboardDetails.requestInfo = [];
      state.dashboardDetails.error = action.payload;
    },
  },
});

export const selectIsLoading = (state: RootState) =>
  state.dashboard.isLoading === 'loading';

export const selectDashboardListError = (state: RootState) =>
  state.dashboard.errors;

export const selectIsDashboardLoading = (state: RootState) =>
  state.dashboard.dashboardDetails.status === 'loading';

export const DashboardList = (state: RootState) =>
  state.dashboard.dashboardDetails.requestInfo;

export const {
  dashboardListRequest,
  dashboardListSuccess,
  dashboardListError,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
