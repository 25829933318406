import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import DatePickerModal from '../../../common/ui/DatePickerModal';
import '../LoanModule/LoanModule.scss';
import { Link, useNavigate } from 'react-router-dom';
import NeedMoneyHeader from './NeedMoneyHeader';
import { useForm } from 'react-hook-form';
import CustomButton from '../../../common/ui/CustomButton';
import data from '../../../common/constants/FormConstants.json';
const NeedMoney = () => {
  const theme = useTheme();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const navigate = useNavigate();
  const handleDateChange = (date: any) => {
    setValue('dob', date, { shouldValidate: true });
  };
  const onSubmit = (data: any) => {
    console.log('data', data);
    // /    e.preventDefault();
    // dispatch(
    //   fetchLoginRequest({ loginId: "test-id", dateOfBirth: "02-02-2023" })
    // );

    navigate('/myoptions');
    console.log(data);
  };
  const dobValue = watch('dob');
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid item>
        <NeedMoneyHeader />
      </Grid>

      <Grid
        container
        // className=' mt-30'
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Grid item className='need-money'>
          <Grid item className=' mb-30'>
            <NeedMoneyHeader />
          </Grid>

          <Grid item className='mb-5'>
            <Typography className='f-18' color={theme.palette.common.black}>
              How much money do you need?
            </Typography>
          </Grid>
          <Grid item className='mb-12'>
            <FormInput
              type='text'
              placeholder='type here...'
              inputProps={{
                className: 'text-center',
                ...register('money', {
                  required: {
                    value: true,
                    message: data.COMMON,
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>
          <Grid container item flexDirection={'column'}>
            <Grid item className='my-13'>
              <Typography className='f-18' color={theme.palette.common.black}>
                By when do you need the money [Date]?
              </Typography>
            </Grid>
            <Grid
              item
              container
              flexDirection={'row'}
              spacing={2}
              alignItems={'center'}
              justifyContent={'space-between'}
              flexWrap={'nowrap'}
            >
              <Grid item sm={3}>
                <FormInput
                  inputProps={{
                    style: { textAlign: 'center' },
                    value: dobValue ? dobValue.format('DD') : '',
                  }}
                  placeholder={'DD'}
                />
              </Grid>
              <Grid item sm={3}>
                <FormInput
                  inputProps={{
                    style: { textAlign: 'center' },
                    value: dobValue ? dobValue.format('MM') : '',
                  }}
                  placeholder={'MM'}
                />
              </Grid>
              <Grid item sm={4}>
                <FormInput
                  inputProps={{
                    style: { textAlign: 'center' },
                    value: dobValue ? dobValue.format('YYYY') : '',
                  }}
                  placeholder={'YYYY'}
                />
              </Grid>
              <Grid item>
                <DatePickerModal
                  iconType={'primary'}
                  onChange={handleDateChange}
                />
              </Grid>
            </Grid>
            <FormInput
              hidden={true}
              inputProps={{
                ...register('dob', {
                  required: {
                    value: true,
                    message: data.DOB,
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item className='mt-25'>
            {/* <Link to={APP_ROUTES.MY_OPTIONS}> */}

            <CustomButton
              className='f-14 fw-600 '
              text={'  See My Options'}
              variant={'text'}
              color={'primary'}
              type='submit'
            />

            {/* </Link> */}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default NeedMoney;
