import { axiosInstance } from "../../../common/axiosInstance";
import { AddressRoutes } from "../../../common/routes";

const axios = axiosInstance;

const fetchAddressList = (data: any) =>
  axios.request({ ...AddressRoutes.listAllAddress, data });

const fetchPoliciesForAddress = (data: any) =>
  axios.request({ ...AddressRoutes.listAllPoliciesForAddress, data });

const finalSubmitAddress = (data: any) =>
  axios.request({ ...AddressRoutes.finalSubmitForAddress, data });

export { fetchAddressList, fetchPoliciesForAddress, finalSubmitAddress };
