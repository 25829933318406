import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import './Nominee.scss';
import Typography from '@mui/material/Typography';
import { ReactComponent as Edit } from '../../../common/icons/edit.svg';
import { ReactComponent as Cancel } from '../../../common/icons/cancel-icon.svg';
import { ReactComponent as Correct } from '../../../common/icons/tick-icon.svg';
import { Link } from 'react-router-dom';
import Separator from '../../../common/ui/Separator';
import theme from '../../../common/theme';
import NomineeCardHeader from './NomineeComponents/NomineeCardHeader';
import NomineeCardList from './NomineeComponents/NomineeCardList';
import CustomButton from '../../../common/ui/CustomButton';
import NomineeForm from './NomineeComponents/EditNominee';
import { CommonModal } from '../../../common/ui/Modal/CommonModal';
import NomineeModal from './NomineeComponents/NomineeModal';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { ReactComponent as rightArrow } from '../../../common/icons/right_arrow-icon.svg';
import NomineeHeader from './NomineeComponents/NomineeHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchNomineeList,
  selectNomineeIsLoading,
  selectNomineeList,
  selectUpdateNomineeIsLoading,
  selectUserFamilyNameList,
} from './Nominee.slice';
import { fetchFamilyRelationShipList } from '../Profile/Profile.slice';
import { fetchNomineeDataList } from './Nominee.service';
import { indexBy } from 'ramda';
import NomineeCard from './NomineeCard';
import Spinner from '../../../common/ui/Spinner';

const NomineeScreen = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const List = [{ sno: '1' }, { sno: '2' }, { sno: '3' }];
  const isLoading = useSelector(selectNomineeIsLoading);
  const NomineesList = useSelector(selectNomineeList);
  console.log('nomineelist', NomineesList);

  useEffect(() => {
    dispatch(fetchFamilyRelationShipList());
  }, []);

  useEffect(() => {
    dispatch(fetchNomineeList());
  }, []);

  return (
    <>
      <Grid item className='ml-50 mr-50'>
        <Grid item xs={12} className='mb-15'>
          <NomineeHeader />
        </Grid>
        {isLoading ? (
          <Grid container justifyContent={'center'}>
            <Grid item>
              <Spinner />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={5} className='mb-15'>
            {NomineesList?.Policies &&
              NomineesList?.Policies.map((item, index) => {
                let overallPercentage = 0;
                if (item.Nominees && Array.isArray(item.Nominees)) {
                  item.Nominees.forEach((nominee) => {
                    const percentage = parseInt(nominee.nomineePercentage);
                    if (!isNaN(percentage)) {
                      overallPercentage += percentage;
                    }
                  });
                }
                return (
                  <Grid
                    item
                    key={index}
                    xs={12}
                    sm={6}
                    md={3}
                    className='mb-20'
                  >
                    <NomineeCard
                      productName={item.planName}
                      selfName={item.lifeAssuredName}
                      sumAssured={item.sumAssured}
                      premium={item.basicPremiumAmount}
                      companyLogo={item.companyLogo}
                      nominees={item.Nominees}
                      cardData={item}
                      isVerified={true}
                      overallPercentage={overallPercentage}
                    />
                  </Grid>
                );
              })}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default NomineeScreen;
