import { Grid, Typography } from '@mui/material';
import React from 'react';
import './legal.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const SecurityTips = () => {
  return (
    <Grid container justifyContent={'center'}>
      <Grid container justifyContent={'center'} className='mb-30 mt-15'>
        <Grid item xs={12} sm={5} className='content-merge'>
          <Grid item>
            <Typography
              fontSize={18}
              fontWeight={800}
              fontFamily={'inherit'}
              className='mb-5 header-text-color'
            >
              Footer Security Tips
            </Typography>
          </Grid>
          <Grid>
            <ul>
              <li>
                <Typography fontSize={15} className='text-color'>
                  Investor Bank Account details provided either as part of the
                  e-Insurance application submitted by the policy holder or
                  based on written request such as change of bank account
                  registered as part of e-insurance account.
                </Typography>
              </li>
              <li>
                <Typography fontSize={15} className='mt-10 text-color'>
                  Payment instrument details provided as part of e-insurance
                  account or received towards Premium both for existing and new
                  policies or as proof for carrying out change of bank account
                  or any other Details of debit card obtained in this regard.
                </Typography>
              </li>
              <li>
                <Typography fontSize={15} className='mt-10 text-color'>
                  Generation and storing Password in encrypted form based on the
                  request from the investor.
                </Typography>
              </li>
              <li>
                <Typography fontSize={15} className='mt-10 text-color'>
                  Biometric information obtained, if any while opening
                  e-Insurance account.
                </Typography>
              </li>
              <li>
                <Typography fontSize={15} className='mt-10 text-color'>
                  Physical, physiological and mental health condition, Sexual
                  orientation, Medical Records received as part of information
                  provided in the policy documents or received from the insurer
                  as part of business arrangement for record and storage.
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5} className='content-merge'>
          <Grid container className='content-box p-20 mt-50'>
            <Grid item>
              <Typography fontSize={15} className='tips-box-text'>
                CAMS Repository or any of its authorized representatives will
                never send email/SMS or will call you over phone either landline
                or mobile to seek your personal information or password. Such
                e-mail/SMS or phone call is an attempt to fraudulently seek your
                policy information. Never respond to such email/SMS or phone
                call. Please report immediately on{' '}
                <span className='email-text-color'>
                  Alert-phishing@camsrepository.com{' '}
                </span>
                on any such incident.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className='mb-10' xs={12} sm={10}>
        <Grid container spacing={2}>
          <Grid item>
            <Typography
              fontSize={20}
              fontWeight={600}
              fontFamily={'inherit'}
              className='mb-5 header-text-color'
            >
              About Phishing:
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography
            fontSize={18}
            fontWeight={500}
            fontFamily={'inherit'}
            className='mb-5 footer'
          >
            IPhishing' is a form of Internet piracy to steal user personal
            information like Bank account details, passwords and may use the
            information to cause financial loss or identity theft to the victim.
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            fontSize={18}
            fontWeight={500}
            fontFamily={'inherit'}
            className='mb-5 mt-10'
          >
            As an online user, users should be aware about the phishing attacks
            and advice to follows do’s and don’ts.
          </Typography>
        </Grid>
        <Grid container>
          <ul>
            <li>
              <Typography fontSize={15} className='text-color'>
                Phishing attacks are meant for stealing the policyholder data
                including policy details.
              </Typography>
            </li>
            <li>
              <Typography fontSize={15} className='mt-10 text-color'>
                Policy holder receives an e-mail which is fraudulent however it
                seems to be received from legitimate Internet address.
              </Typography>
            </li>
            <li>
              <Typography fontSize={15} className='mt-10 text-color'>
                The email may contain message to invite policyholder to click on
                a hyperlink provided as part of the content.
              </Typography>
            </li>
            <li>
              <Typography fontSize={15} className='mt-10 text-color'>
                The policy holder will be directed to a fake web site which may
                look similar to www.camsrepository.com
              </Typography>
            </li>
            <li>
              <Typography fontSize={15} className='mt-10 text-color'>
                The policy holder may be required to provide password, bank
                account numbers, policy details etc.
              </Typography>
            </li>
            <li>
              <Typography fontSize={15} className='mt-10 text-color'>
                By providing the above details, the policy holder may fall prey
                to the phishing attempt.
              </Typography>
            </li>
          </ul>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Grid container className='box-line'>
          <Grid item xs={10} sm={5} className='content-merge space'>
            <Grid container>
              <Grid item alignItems={'center'} className='text-alignment'>
                <CheckCircleIcon color='success' />
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  fontFamily={'inherit'}
                  className='mb-5 header-text-color ml-5'
                >
                  Do's
                </Typography>
              </Grid>
              <Grid item>
                <ul>
                  <li>
                    <Typography fontSize={15} className='text-color'>
                      Always logon to a site by typing the proper URL in the
                      address bar.
                    </Typography>
                  </li>
                  <li>
                    <Typography fontSize={15} className='mt-10 text-color'>
                      Provide your user id and password only at the
                      authenticated login page which is
                      https://www.camsrepository.com
                    </Typography>
                  </li>
                  <li>
                    <Typography fontSize={15} className='mt-10 text-color'>
                      Please ensure that the URL starts with the text ‘https://’
                      only and not ‘http:// ‘.The https: stands for 'secured'
                      and indicates Web page uses encryption.
                    </Typography>
                  </li>
                  <li>
                    <Typography fontSize={15} className='mt-10 text-color'>
                      Always look for the lock sign at the top right of the
                      browser and the GeoTrust DV SSL CA certificate.
                    </Typography>
                  </li>
                  <li>
                    <Typography fontSize={15} className='mt-10 text-color'>
                      CAMS Repository or any of its authorized representatives
                      will never send email/SMS or will call you over phone
                      either landline or mobile to seek your personal
                      information or password.
                    </Typography>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10} sm={5} className='content-merge bod'>
            <Grid className='space-left'>
              <Grid item alignItems={'center'} className='text-alignment'>
                <CancelIcon color='error' />
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  fontFamily={'inherit'}
                  className='mb-5 header-text-color ml-5'
                >
                  Dont's
                </Typography>
              </Grid>
              <Grid item>
                <ul>
                  <li>
                    <Typography fontSize={15} className='text-color'>
                      Never click on any hyperlink provided as part of e-mail
                      from an unkown source or sender. The mail may contain
                      malicious code or could be an attempt to 'Phish'.
                    </Typography>
                  </li>
                  <li>
                    <Typography fontSize={15} className='mt-10 text-color'>
                      Don’t provide any personal information on a page coming as
                      a pop-up window.
                    </Typography>
                  </li>
                  <li>
                    <Typography fontSize={15} className='mt-10 text-color'>
                      Password or personal details should never be provided over
                      the phone or in response to solicit or unsolicited request
                      over e-mail.
                    </Typography>
                  </li>
                  <li>
                    <Typography fontSize={15} className='mt-10 text-color'>
                      Password is strictly confidential and is not known even to
                      Camsrepository employees/ approved persons of the
                      repository. Please don’t reveal to anyone.
                    </Typography>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Grid item xs={12} sm={5}>
          <Grid container>
            <Grid item>
              <Typography
                fontSize={20}
                fontWeight={600}
                fontFamily={'inherit'}
                className='mt-10'
              >
                If you are a victim of phishing, please do the following:
              </Typography>
            </Grid>
            <Grid item>
              <ul>
                <li>
                  <Typography fontSize={15} className='text-color'>
                    Immediately Change your password.
                  </Typography>
                </li>
                <li>
                  <Typography fontSize={15} className='mt-10 text-color'>
                    Report Phishing.
                  </Typography>
                </li>
                <li>
                  <Typography fontSize={15} className='mt-10 text-color'>
                    Check your e-policy statement and ensure that it reflects
                    correctly.
                  </Typography>
                </li>
                <li>
                  <Typography fontSize={15} className='mt-10 text-color'>
                    Report any inconsistencies to both insurer and repository.
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SecurityTips;
