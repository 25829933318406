import React, { useState } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import NomineeHeader from './NomineeComponents/NomineeHeader';
import CustomButton from '../../../common/ui/CustomButton';
import NomineeCardHeader from './NomineeComponents/NomineeCardHeader';
import Separator from '../../../common/ui/Separator';
import NomineeCardList from './NomineeComponents/NomineeCardList';
import theme from '../../../common/theme';
import { Link, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { ReactComponent as Edit } from '../../../common/icons/edit.svg';
import { ReactComponent as Cancel } from '../../../common/icons/cancel-icon.svg';
import { ReactComponent as Correct } from '../../../common/icons/tick-icon.svg';

interface Props {
  productName: string;
  selfName: string;
  sumAssured: string;
  premium: string;
    isVerified: boolean;
  companyLogo: string;
  nominees: any;
  //   navigation: any;
  cardData: any;
  overallPercentage: any;
}
const NomineeCard = ({
  productName,
  selfName,
  sumAssured,
  premium,
    isVerified,
  companyLogo,
  nominees,
  //   navigation,
  cardData,
  overallPercentage,
}: Props) => {
  const navigate = useNavigate();

  const handleEditNominee = () => {
    navigate(APP_ROUTES.EDITNOMINEE, { state: { cardData } });
  };
  return (
    <Grid item>
      <Grid item>
        <Grid container className='nominee-box'>
          <Grid item xs={12}>
            <Grid item>
              <NomineeCardHeader
                productName={productName}
                selfName={selfName}
                sumAssured={sumAssured}
                premium={premium}
                bankImage={companyLogo}
              />
            </Grid>

            <Separator color={theme.palette.primary.main} />
          </Grid>

          <Grid item>
            <NomineeCardList
              nominees={nominees}
              overallPercentage={overallPercentage}
              isVerified={true}
            />
          </Grid>

          <Separator color={theme.palette.primary.main} borderWidth={2} />
          <Grid item xs={12} className='ml-10 py-10 '>
            {/* <Link to={APP_ROUTES.EDITNOMINEE}> */}
            <CustomButton
              className='f-16 fw-600'
              text={'Edit Nominees'}
              variant={'text'}
              color={'primary'}
              showIcon={false}
              startIcon={Edit}
              onClick={handleEditNominee}
            />
            {/* </Link> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NomineeCard;
