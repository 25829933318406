import React from 'react';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import '../../TrackTransaction.scss';
import theme from '../../../../../../common/theme';
import LabelWithIcon from '../../../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRupee } from '../../../../../../common/icons/orange_rupee.svg';
import HDFC from '../../../../../../assets/images/HDFC.png';
import SvgConverter from '../../../../../../common/SvgConverter';
import { ReactComponent as Circle } from '../../../../../../common/icons/number-circle.svg';

interface UserProps {
  step?: string;
}

export const BoxHeader: React.FC<UserProps> = ({ step }) => {
  return (
    <Grid container>
      <Grid item xs={3} className='p-10 '>
        <Box width={50} height={50} className='image-box mt-5'>
          <img src={HDFC} width={42} height={27} className='mt-10' />
        </Box>
      </Grid>
      <Grid item xs={6} className='mt-9'>
        <Typography
          className='fw-600'
          color={theme.palette.primary.main}
          fontSize={14}
        >
          Income Product
        </Typography>
        <Typography color={theme.palette.primary.main} fontSize={12}>
          [Life Assured]
        </Typography>
        <Typography
          className='fw-600'
          color={theme.palette.common.black}
          fontSize={12}
        >
          [self Name]
        </Typography>
      </Grid>

      <Grid item xs={3} textAlign={'center'} className='py-17'>
        <Grid item position={'relative'}>
          <Typography
            component={'span'}
            className='fw-600 ml-15 mt-8'
            color={theme.palette.common.black}
            fontSize={16}
            position={'absolute'}
          >
            {step}
          </Typography>

          <SvgConverter Icon={Circle} />
        </Grid>
      </Grid>
      {step === '3' && (
        <Grid container className='p-10'>
          <Grid item xs={6}>
            <Typography color={theme.palette.primary.main} fontSize={14}>
              Maturity Value
            </Typography>
            <LabelWithIcon
              svgClassName='mb-2'
              justifyContent={'flex-start'}
              Icon={OrangeRupee}
              label={'XXXX'}
              labelProps={{
                color: theme.palette.grey.A700,
                fontSize: 14,
                fontWeight: 600,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography color={theme.palette.primary.main} fontSize={14}>
              Fund Value
            </Typography>
            <LabelWithIcon
              svgClassName='mb-2'
              justifyContent={'flex-start'}
              Icon={OrangeRupee}
              label={'XXXXXX'}
              labelProps={{
                color: theme.palette.grey.A700,
                fontSize: 14,
                fontWeight: 600,
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default BoxHeader;
