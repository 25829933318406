// import { LoadingState } from "../../common";
// import { NomineeFamilyList, NomineeList } from "./types/Nominee.model";
import { createSlice } from '@reduxjs/toolkit';
// import { RootState } from "../../common/store";
import { LoadingState } from '../../../common';
import { RootState } from '../../../common/store';
import { NomineeFamilyList, NomineeList } from './types/Nominee.model';

interface NomineeSlice {
  isLoading: LoadingState;
  nomineeList: NomineeList | null;
  editNominee: { status: LoadingState; data: any; error: any };
  errors: any | null;
  nomineeNameList: NomineeFamilyList[] | [];
}

const initialState: NomineeSlice = {
  isLoading: 'idle',
  nomineeList: null,
  errors: null,
  nomineeNameList: [],
  editNominee: {
    status: 'idle',
    data: undefined,
    error: undefined,
  },
};

const nomineeSlice = createSlice({
  name: 'nominee',
  initialState,
  reducers: {
    fetchNomineeList: (state) => ({
      ...state,
      isLoading: 'loading',
      errors: null,
      nomineeList: null,
    }),
    loadNomineeList: (state, action) => ({
      ...state,
      nomineeList: action.payload,
      errors: null,
      isLoading: 'idle',
    }),
    errorNomineeList: (state, action) => ({
      ...state,
      errors: action.payload,
      nomineeList: null,
    }),
    fetchFamilyNameListAction: (state) => ({
      ...state,
      isLoading: 'loading',
      nomineeNameList: [],
      errors: null,
    }),
    loadFamilyNameList: (state, action) => ({
      ...state,
      isLoading: 'done',
      nomineeNameList: action.payload,
      errors: null,
    }),
    errorFamilyNameList: (state, action) => ({
      ...state,
      isLoading: 'done',
      nomineeNameList: [],
      errors: action.payload,
    }),
    updateNomineeRequest: (state, { payload }) => {
      state.editNominee.status = 'loading';
      state.editNominee.data = undefined;
      state.editNominee.error = undefined;
    },
    updateNomineeSuccess: (state, { payload }) => {
      state.editNominee.status = 'done';
      state.editNominee.data = payload;
      state.editNominee.error = undefined;
    },
    updateNomineeError: (state, { payload }) => {
      state.editNominee.status = 'error';
      state.editNominee.data = undefined;
      state.editNominee.error = payload;
    },
  },
});

export const selectNomineeIsLoading = (state: RootState) =>
  state.nominee.isLoading === 'loading';

export const selectNomineeList = (state: RootState) =>
  state.nominee.nomineeList;

export const selectNomineeListError = (state: RootState) =>
  state.nominee.errors;

export const selectUserFamilyNameList = (state: RootState) =>
  state.nominee.nomineeNameList;

export const selectUpdateNomineeIsLoading = (state: RootState) =>
  state.nominee.editNominee.status === 'loading';

export const {
  fetchNomineeList,
  loadNomineeList,
  errorNomineeList,
  updateNomineeRequest,
  updateNomineeSuccess,
  updateNomineeError,
  fetchFamilyNameListAction,
  loadFamilyNameList,
  errorFamilyNameList,
} = nomineeSlice.actions;

export default nomineeSlice.reducer;
