import React from "react";
import { Grid, useTheme } from "@mui/material";
import "../payment.scss";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { ReactComponent as OrangeRupee } from "../../../../common/icons/orange_rupee.svg";
import { ReactComponent as CancelBig } from "../../../../common/icons/cancel-red-big.svg";
import { ReactComponent as Cancel } from "../../../../common/icons/update-mobile/cancel_orange.svg";
import { ReactComponent as Circle } from "../../../../common/icons/background-circle.svg";
import CustomButton from "../../../../common/ui/CustomButton";
import SvgConverter from "../../../../common/SvgConverter";
import LabelWithIcon from "../../../../common/ui/LabelWithIcon";
import { convertAmount } from "../../../../common/utils";

const PaymentFailed = ({ amount, transactionId }: any) => {
  const theme = useTheme();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={3} width={279}>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <Grid container justifyContent={"space-between"}>
              <Typography
                fontSize={16}
                fontWeight={600}
                color={theme.palette.primary.main}
              >
                Premium Payment
              </Typography>
              <CustomButton
                className=" fw-600"
                text={"cancel"}
                variant={"text"}
                color={"primary"}
                showIcon={false}
                startIcon={Cancel}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} className="payment-card-success mt-30">
            <Grid container justifyContent={"center"}>
              <Grid item>
                <SvgConverter Icon={CancelBig} className="green-tick" />
                <SvgConverter Icon={Circle} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          textAlign={"center"}
          className="mt-10"
        >
          <Grid item>
            <LabelWithIcon
              svgClassName="mb-2 ml-20"
              justifyContent={"center"}
              Icon={OrangeRupee}
              label={convertAmount(amount)}
              labelProps={{
                color: theme.palette.common.black,
                fontSize: 20,
                fontWeight: 600,
              }}
            />
            <Typography fontSize={16} fontWeight={600} color={"  #d9001b"}>
              Premium Payment Failed
            </Typography>
            <Typography
              fontSize={12}
              color={theme.palette.common.black}
              className="mt-15"
            >
              Transaction ID{" "}
            </Typography>{" "}
            <Typography
              fontSize={12}
              fontWeight={600}
              color={theme.palette.common.black}
              className="mb-20"
            >
              {transactionId}
            </Typography>
            <Typography
              fontSize={20}
              color={theme.palette.common.black}
              lineHeight={1}
              className={"mb-20"}
            >
              There seems to be some issue with the transaction.
            </Typography>
            <Typography
              fontSize={20}
              color={theme.palette.common.black}
              lineHeight={1}
              className={"mb-20"}
            >
              Please try again after some time
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent={"right"} textAlign={"right"}>
          <Grid item justifyContent={"right"} textAlign={"right"}>
            <Link to={"/"} replace>
              <CustomButton
                className=" fw-600"
                text={"Click to go back to previous page"}
                variant={"text"}
                color={"primary"}
                showIcon={false}
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default PaymentFailed;
