import axiosMain from "axios";
import { axiosInstance } from "../../../common/axiosInstance";
import { BankRoutes } from "../../../common/routes";
import config from "../../../common/config";
import { generateUUID } from "../../../common/crypto";

const axios = axiosInstance;

const fetchBanks = (data: any) =>
  axios.request({ ...BankRoutes.listAllBanks, data });

const fetchPoliciesForBanks = (data: any) =>
  axios.request({ ...BankRoutes.listAllPoliciesForBanks, data });

const generateOtpBank = (data: any) =>
  axios.request({ ...BankRoutes.generateOtpForBank, data });

const verifyOtpBank = (data: any) =>
  axios.request({ ...BankRoutes.verifyOtpForBank, data });

const updateForBank = (data: any) =>
  axios.request({ ...BankRoutes.updateForBank, data });

const getBankDetailsByIfscService = (data: any) =>
  axiosMain.post(
    `${config.vahanaCloudDomain}/vahanahub/execution/executeService`,
    data,
    {
      headers: {
        "content-type": "application/json",
        securitylevel: "X",
        "security-version": 2,
        loginId: config.vahanacloudCred.loginId,
        orgid: config.vahanacloudCred.orgid,
        appid: config.vahanacloudCred.appid,
        clientsecret: config.vahanacloudCred.clientsecret,
        servicename: config.vahanacloudCred.servicename,
        requestid: generateUUID(),
      },
    }
  );

const uploadCanceledChequeService = (data: any) =>
  axios.request({ ...BankRoutes.uploadCanceledCheque, data });

export {
  fetchBanks,
  fetchPoliciesForBanks,
  generateOtpBank,
  verifyOtpBank,
  updateForBank,
  getBankDetailsByIfscService,
  uploadCanceledChequeService,
};
