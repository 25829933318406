import dayjs from "dayjs";
import Cookies from "universal-cookie";
import { DATE_FORMAT_ENUMS } from "./Enums";
import { reject } from "ramda";
import axios from "axios";

export const setOAuthTokenData = (data: any) => {
  const cookies = new Cookies();
  const expires_in = data.expires_in * 1000;
  cookies.set("access_token", data.access_token);
  cookies.set("refresh_token", data.refresh_token);
  cookies.set("token_expires_at", dayjs(new Date()).valueOf() + expires_in);
};

export const dummyDelay = (delay: number | undefined = 1000) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, delay);
  });

export enum RequestTypes {
  MOBILE = "MOBILE",
  EMAIL = "EMAIL",
}

export enum LOCAL_VARIABLES {
  SESSION_ID = "sessionId",
  MPIN_NEVER_ASK = "hasMPINNeverAsk",
  MPIN_FLAG = "hasMPINFlag",
  AUTH_USER_INFO = "authUserInfo",
  FINGER_PRINT = "FINGER_PRINT_ENABLED",
}

export interface AuthUser {
  eiaNo: string;
  panNo: string;
  mobileNo: string;
  email: string;
}

export const isArray = (obj: any) => obj instanceof Array;

export const isDev = () => process.env.REACT_APP_MODE === "dev";

export const numericOnly = (text: string): string => {
  return text.replace(/[^0-9]/g, "");
};
export const alphabetsOnly = (text: string): string => {
  return text.replace(/[^a-zA-Z]/g, "");
};

// export const getDeviceInfo = () =>
//   new Promise(async (resolve, reject) => {
//     const deviceId = "NIL";
//     const deviceName = await DeviceInfo.getDeviceName();
//     const deviceOs = await DeviceInfo.getBaseOs();
//     const ipAddress = await DeviceInfo.getIpAddress();
//     const osVersion = DeviceInfo.getSystemVersion();
//     resolve({
//       appType: "MOBILE",
//       osVersion: osVersion,
//       deviceId: deviceId,
//       deviceName: deviceName,
//       deviceOS: deviceOs,
//       appVersion: "2.0",
//       clientIp: ipAddress,
//       origin: "",
//     });
//   });
const base64ToBinary = (base64: any) => {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);

  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes;
};
const createBlob = (data: any) => {
  const blob = new Blob([data], { type: "application/pdf" });
  return blob;
};

export const getFormatDate = (date: string) =>
  dayjs(date).format(DATE_FORMAT_ENUMS.LABEL);

export const saveBase64AsFile = (
  base64Data?: any,
  fileName?: any,
  mimeType: string = "base64",
  extension: string = "pdf"
) =>
  new Promise(async (res, rej) => {
    try {
      const saveFile = require("save-file");
      const folderPath = fileName;
      const base64Data1 = base64Data;

      const binaryData = base64ToBinary(base64Data1);
      const blob = createBlob(binaryData);

      const fileContent = blob;
      saveFile(folderPath + "/.pdf", fileContent).then(() => {
        console.log("File saved successfully!");
      });
    } catch (e) {
      console.error("File save failed --->", e);
      rej(e);
    }
  });

export const verifyNullForObject = (object: any) => {
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      if (object[key] === "null" || object[key] === undefined) {
        object[key] = "";
      } else if (object[key] === "string" && object[key].trim() === "") {
        object[key] = "";
      }
    }
  }
  return object;
};

export const verifyNullForArray = (data: any[]) =>
  data?.map((item) => verifyNullForObject(item));

export const convertLowerCaseToUpperCase = (data: any) =>
  String(data ? data : "").toUpperCase();

export const getRandomNumber = (length: any) =>
  Math.floor(
    Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1)
  );

export const convertAmount = (val: any) => {
  const parsedValue = !val || val === "" ? 0 : Number(val);
  const formattedValue = parsedValue.toLocaleString("en-IN");
  if (formattedValue.endsWith(".00")) {
    return formattedValue.slice(0, -3);
  }
  return formattedValue;
};

export const trimWhiteSpaceForObject = (object: any) => {
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      if (object[key] === "null" || object[key] === undefined) {
        object[key] = "";
      } else if (object[key] === "string" && object[key].trim() === "") {
        object[key] = "";
      }
    }
  }
  return object;
};

export const generateFullAddress = (address: any) =>
  address
    ? `${address?.addressLine1}, ${address?.addressLine2}, ${address?.city}, ${address?.addressLine3}, ${address?.state}-${address?.pincode}`
    : false;

export const getURLParams = (parameterName: string, url: string) => {
  let name = parameterName.replace(/[\[\]]/g, "\\$&");
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return null;
  }
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const generateTransactionIDForRazorPay = (suffix?: any) =>
  `FY${dayjs(new Date()).format("DDMMYYYYHHmmss")}${suffix}`;

export const removeDuplicatedPoliciesForAssign = (
  policies: any[],
  newPolicies: any[]
) => {
  const newPoliciesArray = newPolicies
    ? newPolicies?.map(({ policyNo }: any) => policyNo)
    : [];
  const duplicateRemovedCurrentList = reject(
    ({ policyNo }) => (newPoliciesArray.includes(policyNo) ? true : false),
    policies
  );
  return duplicateRemovedCurrentList ? duplicateRemovedCurrentList : [];
};

export const generatePermanentAddressFromEkyc = (data: any) => {
  if (data) {
    const address = String(data?.ADDRESS).split(",");
    let newData: any = {
      address1: String(address?.[0]).trim(),
      address2: String(address?.[1]).trim(),
      address3: data?.CITY,
      city: data?.CITY,
      state: data?.STATE,
      country: data?.COUNTRY,
      pincode: data?.PINCODE,
      investHorizon: "",
      addressVerified: "Y",
    };
    newData.fullAddress = generateFullAddress(newData);
    return newData;
  } else {
    return {};
  }
};

export const convertToString = (val: any) => (val ? val : "");

export const convertUnAssignedBanksToPolicyType = (banks: any[]) =>
  banks
    ? banks.map((bank) => ({
        bankAcNo: bank?.accountNo,
        bankBranch: bank?.bankBranch,
        bankIfscCode: bank?.ifscCode,
        bankName: bank?.bankName,
        accountHolderName: bank?.accountHolderName,
        bankLogo: bank?.bankLogo,
        chequeImage: bank?.chequeImage,
        bankVerified: bank?.bankVerified,
      }))
    : [];

export const swapToTop = (
  list: any[],
  targetValues: any[],
  propName: string
) => {
  list = list ? list : [];
  list.forEach(function (item, i) {
    if (targetValues.includes(item[propName])) {
      list.splice(i, 1);
      list.unshift(item);
    }
  });

  return list;
};

export const verifyFullAddressString = (address: any) =>
  String(address ? address : "")
    .replace(",", "")
    .replace("-", "")
    .trim();

export const convertToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const stripBase64Prefix = (base64: string) => base64.split(",")[1];
export const appendBase64PrefixForImage = (base64: string) =>
  `data:image/png/jpg/jpeg;base64,${base64}`;

export const createEkycPopup = (
  url: string | false = false,
  handleFeedBack: any
) => {
  if (url) {
    const popup: any = window.open(
      url,
      "popup",
      `popup=true,menubar=1,resizable=1,width=360,height=${
        window.screen.height - 60
      },left=${window.screen.width / 2 - 360 / 2},top=30`
    );
    const checkPopup = setInterval(() => {
      //  if (popup.window.location.href
      //     .includes(CLIENT_URL)) {popup.close()}
      if (!popup || !popup.closed) return;
      handleFeedBack(popup);
      clearInterval(checkPopup);
    }, 1000);
  }
};

export const getIpAddress = async () =>
  new Promise(async (resolve) => {
    try {
      let config: any = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://geolocation-db.com/json/",
        headers: {},
      };

      const { data } = await axios.request(config);

      resolve(data.IPv4);
    } catch (error) {
      resolve("0.0.0.0");
    }
  });
