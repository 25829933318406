import React from 'react';
import { Grid, IconButton } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import theme from '../../../common/theme';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { ReactComponent as Add } from '../../../common/icons/add.svg';
import './Policy.scss';
import Typography from '@mui/material/Typography';
import PolicyTab from './PolicyTab';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomButton from '../../../common/ui/CustomButton';
import { CommonModal } from '../../../common/ui/Modal/CommonModal';
import Separator from '../../../common/ui/Separator';
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/left_arrow-icon.svg';
import SvgConverter from '../../../common/SvgConverter';
import {
  fetchPolicyDocumentDownload,
  selectIsPolicyLoading,
} from './Policies.slice';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../common/ui/Spinner';
const PolicyScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const prevPath = location?.state;
  console.log('location', location);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const downloadStatementOfHolding = () => {
    dispatch(fetchPolicyDocumentDownload({ holdingStatement: 'Y' } as any));
    setOpen(false);
  };
  const policyLoading = useSelector(selectIsPolicyLoading);
  const downloadAnnualAccountStatement = () => {
    dispatch(
      fetchPolicyDocumentDownload({ annualAccountStatement: 'Y' } as any)
    );
    setOpen(false);
  };
  return (
    <>
      <Grid container>
        <Grid item xs={9} container className=' py-15'>
          <Grid item xs={1}>
            <Link to={APP_ROUTES.DASHBOARD}>
              <IconButton>
                <SvgConverter
                  Icon={ArrowLeftIcon}
                  className='go-back-icon ml-15'
                />
              </IconButton>
            </Link>
          </Grid>
          <Grid item xs={3}>
            <Typography
              className='f-16 fw-600 mt-8'
              color={theme.palette.primary.main}
            >
              Policy
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container>
            <Grid item xs={9} className='add_button'>
              <Link to={APP_ROUTES.ADDPOLICY}>
                <LabelWithIcon
                  className='mt-6 ml-15'
                  justifyContent={'flex-start'}
                  Icon={Add}
                  label={'ADD POLICY'}
                  labelProps={{
                    color: theme.palette.primary.main,
                    fontSize: 14,
                  }}
                />
              </Link>
            </Grid>
            <Grid item xs={3} className='py-10'>
              <CustomButton
                text={<MoreVertIcon />}
                variant={'text'}
                fontSize={10}
                iconClassName='ml-4'
                showIcon={false}
                onClick={() => setOpen(true)}
                color={'warning'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className='ml-50'>
          <PolicyTab prevPath={prevPath} />
        </Grid>
        <CommonModal
          onClose={handleClose}
          boxProps={{ height: 99, width: 320 }}
          open={open}
          modalClassName='modal-border'
        >
          <Grid container>
            <Grid item xs={12}>
              <CustomButton
                showIcon={false}
                text={'Statement of Holding'}
                className='f-16 fw-600 px-12 py-10 download_button'
                variant={'text'}
                onClick={downloadStatementOfHolding}
              />
              <Separator color={theme.palette.primary.main} />{' '}
              <CustomButton
                showIcon={false}
                text={'Annual Account Statement'}
                className='f-16 fw-600 px-12 py-10 download_button'
                variant={'text'}
                onClick={downloadAnnualAccountStatement}
              />
            </Grid>
          </Grid>
        </CommonModal>
      </Grid>
    </>
  );
};

export default PolicyScreen;
