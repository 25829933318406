import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import FormControlLabel from '@mui/material/FormControlLabel';
import theme from '../../../../../common/theme';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import { ReactComponent as GreenTick } from '../../../../../common/icons/green_tick.svg';
import { ReactComponent as OrangeDot } from '../../../../../common/icons/orange_dot.svg';
import { ReactComponent as Circle } from '../../../../../common/icons/empty-circle.svg';

import SvgConverter from '../../../../../common/SvgConverter';
import { TimelineDot } from '@mui/lab';
import { TrackerTransactionTimeline } from './TrackerTransactionTimeLine';

export default function TimelineScreen() {
  const timelineData = [
    {
      title: 'Policyholder e-sign',
      status: 'completed',
      eta: 'DDMMYYYY, hh:mm',
    },
    {
      title: 'Witness e-sign',
      status: 'pending',
    },
    {
      title: 'Financier e-sign',
      status: 'yet-to-start',
      eta: '2',
    },
    {
      title: 'Insurer Process',
      status: 'yet-to-start',
      eta: '2',
    },
    {
      title: 'Fund Disbursal',
      status: 'yet-to-start',
      eta: '1',
    },
  ];
  return (
    <Box className='transaction-timeline-card'>
      {/* <TrackerTransactionTimeline timelineData={timelineData} /> */}
      <Grid container>
        <Grid item xs={10}>
          <Timeline
            className='timeline'
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {timelineData.map((data, index) => (
              <TrackerTransactionTimeline
                status={data?.status}
                index={index}
                title={data?.title}
                eta={data?.eta}
              />
            ))}
          </Timeline>
        </Grid>
      </Grid>
    </Box>
  );
}
