import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import BankDetailsHeader from "./common/BankDetailsHeader";
import { BANK_ADDRESS_EMAIL_MOBILE_REMOVE_TYPES } from "../../../common/Enums";

const UnassignedBankAccountScreen = ({
  banks = [],
  handleRemoveBank,
  handleAssignPolicies,
}: any) => {
  const theme = useTheme();

  return (
    <>
      <Grid item xs={12}>
        <Typography
          className="f-16 fw-600 my-10"
          color={theme.palette.secondary.main}
        >
          Unassigned Bank Accounts
        </Typography>
      </Grid>
      <Grid container spacing={5} justifyContent={"space-between"}>
        {banks.map((data: any, index: any) => (
          <Grid item key={index} xs={12} sm={6} md={3}>
            <BankDetailsHeader
              data={data}
              index={index}
              onRemoveClick={() =>
                handleRemoveBank(
                  data.accountNo,
                  BANK_ADDRESS_EMAIL_MOBILE_REMOVE_TYPES.UNASSIGNED
                )
              }
              showAssignButton={true}
              handleAssignPolicy={() => handleAssignPolicies(data)}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default UnassignedBankAccountScreen;
