import { call, put, select, takeEvery } from "typed-redux-saga";

import {
  fetchBanks,
  fetchPoliciesForBanks,
  getBankDetailsByIfscService,
  updateForBank,
  uploadCanceledChequeService,
} from "./Bank.service";
import {
  errorLoadingPoliciesForBanks,
  loadPoliciesForBanks,
  fetchPoliciesForBanks as fetchPoliciesForBanksAction,
  fetchBanks as fetchBanksAction,
  loadBanks,
  errorLoadingBanks,
  updateBankChangesRequest,
  updateBankChangesSuccess,
  updateBankChangesError,
  selectBankChangesList,
  getBankDetailsByIfscRequest,
  getBankDetailsByIfscSuccess,
  getBankDetailsByIfscError,
  uploadCanceledChequeRequest,
  uploadCanceledChequeSuccess,
  uploadCanceledChequeError,
} from "./Bank.slice";
import { selectIsUserLoggedIn } from "../../Auth/auth.slice";
import { setAlertInfo } from "../Common/Common.slice";

function* handleFetchPoliciesForBanks() {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
      },
    };
    const { data }: any = yield* call(fetchPoliciesForBanks, requestPayload);
    const policies = data?.BankBasedPolicies;

    const unassignedBanks = data?.UnassignedBanks || [];
    const unverifiedBanks = data?.UnverifiedBanks || [];
    yield* put(
      loadPoliciesForBanks({
        policies,
        unassignedBanks,
        unverifiedBanks,
      })
    );
  } catch (e: any) {
    yield* put(errorLoadingPoliciesForBanks(e.message));
  }
}

function* handleFetchBanks({ payload }: ReturnType<typeof fetchBanksAction>) {
  try {
    console.log(payload, "Saga pyaload .. ");
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
        ...payload,
      },
    };
    const { data }: any = yield* call(fetchBanks, requestPayload);
    yield* put(loadBanks(data?.bankList || []));
  } catch (e: any) {
    console.log(e);
    yield* put(errorLoadingBanks(e.message));
  }
}

function* finalDataSubmitBank({ payload: { handleDone } }: any) {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const bankChanges: any[] = yield* select(selectBankChangesList);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
      },
      Actions: bankChanges,
    };
    const { data }: any = yield* call(updateForBank, requestPayload);
    yield* put(updateBankChangesSuccess(data || []));
    yield put(
      setAlertInfo({
        open: true,
        type: "SUCCESS",
        description: "Successfully Updated Bank policies.",
      })
    );
    if (handleDone) {
      handleDone();
    }
  } catch (e: any) {
    yield* put(updateBankChangesError(e.message));
  }
}

function* getBankDetailsByIfsc({
  payload: { handleSuccess, ...payload },
}: any) {
  try {
    const { data }: any = yield* call(getBankDetailsByIfscService, payload);
    if (data.status !== "SUCCESS") {
      throw "Failed to search bank details by IFSC code!";
    }
    const bankData = data?.response?.[0]?.DATA?.bank_details
      ? data?.response?.[0]?.DATA?.bank_details
      : false;
    if (data.status !== "SUCCESS") {
      throw "IFSC code not found!";
    }
    yield* put(getBankDetailsByIfscSuccess(bankData));
    if (handleSuccess) {
      handleSuccess(data);
    }
  } catch (e: any) {
    yield* put(getBankDetailsByIfscError(e.message));
  }
}

function* uploadCanceledCheque({
  payload: { handleSuccess, ...payload },
}: any) {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    let requestPayload = {
      eiaNo: user.eiaNo,
      payeeAccNo: payload?.payeeAccNo,
      payeeAccName: payload?.payeeAccName,
      payeeIfscCode: payload?.payeeIfscCode,
      chequeImage: payload?.chequeImage,
    };
    const { data }: any = yield* call(uploadCanceledChequeService, {
      Customer: requestPayload,
    });

    // const data = {
    //   txnId: "9a1adbbe-29ae-71e8-0204-028914700307",
    //   timestamp: "2023-05-24T18:15:21.707475100",
    //   displayMessage: "Bank Account Verified Successfully",
    //   uploadChequeStatus: "CHEQUE_VERIFIED / CHEQUE_MANUAL_VERIFICATION",
    //   OCR: {
    //     status: "SUCCESS",
    //     refNo: "20230524181504421733",
    //     address: "V-22B, VIRAVATI PALACE BHOJA MARKET,SECTOR-27 201301",
    //     ifsc: "PUNB0070710",
    //     accountNumber: "1113155000015020",
    //     bankName: "SKYLARK",
    //     chequeNumber: "31",
    //     accountType: "Savings",
    //     micrCode: "",
    //     contact: "911202534352",
    //     branch: "NOIDA",
    //     name: "GAURAV AGGARWAL,SUNAYANA AGARWAL",
    //     pincode: "",
    //     country: ["IN", "IND", "INDIA"],
    //     city: ["NOIDA"],
    //     district: ["GAUTAM BUDDHA NAGAR"],
    //     state: [["UTTAR PRADESH", "UP"]],
    //     dob: "",
    //     gender: "",
    //     guardianName: "",
    //     issueDate: "",
    //     expiryDate: "",
    //   },
    //   PennyDrop: {
    //     paymt_status: "S",
    //     epsClientCode: "8650",
    //     uniqueRefNo: "8650_00004269",
    //     beneName: "GAURAV AGGARWAL,SUNAYANA AGARWAL",
    //     beneAcctNo: "GAURAV AGARWAL",
    //     beneIFSC: "PUNB0070770",
    //     pymtAmount: "1.00",
    //     paymt_date: "24-May-2023",
    //     paymt_err_code: "IM000",
    //     paymt_err_desc: "SUCCESS",
    //     upi_ref_no: "314418332161",
    //     addl_tran_ref: "Addl. Tran. Ref.",
    //     Name_Match: "",
    //     Enable_Match: "",
    //     Request_Name_Match_Perc: "0",
    //     Response_Name_Match_Perc: "0",
    //   },
    //   NameMatch: {
    //     status: "SUCCESS",
    //     refNo: "20230524181521054186",
    //     similarity: "0.9230769230769231",
    //     similarityPercentage: 92.31,
    //   },
    //   errorCode: "0",
    //   errorDescription: "",
    // };

    yield* put(uploadCanceledChequeSuccess(data));
    if (handleSuccess) {
      handleSuccess(data);
    }
  } catch (e: any) {
    console.log(e);
    yield* put(uploadCanceledChequeError(e.message));
  }
}

function* watchBanks() {
  yield* takeEvery(
    fetchPoliciesForBanksAction.type,
    handleFetchPoliciesForBanks
  );
  yield* takeEvery(fetchBanksAction.type, handleFetchBanks);
  yield* takeEvery(updateBankChangesRequest.type, finalDataSubmitBank);
  yield* takeEvery(getBankDetailsByIfscRequest.type, getBankDetailsByIfsc);
  yield* takeEvery(uploadCanceledChequeRequest.type, uploadCanceledCheque);
}

export { watchBanks };
