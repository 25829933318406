import React, { useEffect, useMemo } from "react";
import PaymentSuccess from "./PaymentSuccess";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsUploadPaymentDataLoading,
  selectPaymentDetails,
  uploadPaymentDetailsRequest,
} from "../Payment.slice";
import { PAYMENT_GATEWAY_TYPES } from "../../../../common/Enums";
import PaymentFailed from "./PaymentFailed";

const PaymentFeedbacks = () => {
  const location = useLocation();
  const params = useParams();
  console.log(location);
  const paymentDetails = useSelector(selectPaymentDetails);
  const dispatch = useDispatch();
  const isUploadingPaymentData = useSelector(selectIsUploadPaymentDataLoading);
  const routePolicyData = location?.state?.paymentData?.policyData;
  const paymentResponse = useMemo(
    () =>
      location?.state?.paymentResponse
        ? location?.state?.paymentResponse
        : false,
    [location]
  );

  useEffect(() => {
    if (location?.state?.gateWayType === PAYMENT_GATEWAY_TYPES.RAZORPAY) {
      handleRazorPayPaymentUpload();
    }
  }, [location?.state?.gateWayType, location?.state?.status]);

  const handleRazorPayPaymentUpload = () => {
    let payload = {
      transactionId: paymentDetails?.transactionId,
      rzpOrderId: paymentResponse?.order_id,
      rzpPaymentId: paymentResponse?.razorpay_payment_id,
      rzpDigitalSign: paymentResponse?.razorpay_signature,
    };
    dispatch(uploadPaymentDetailsRequest(payload));
  };
  return (
    <div>
      {params?.paymentStatus === "success" ? (
        <PaymentSuccess
          amount={location?.state?.paymentData?.amount}
          transactionId={location?.state?.paymentData?.transactionId}
        />
      ) : (
        <PaymentFailed
          amount={location?.state?.paymentData?.amount}
          transactionId={location?.state?.paymentData?.transactionId}
        />
      )}
    </div>
  );
};

export default PaymentFeedbacks;
