import {call, put, select, takeEvery} from 'typed-redux-saga';
import {
  fetchNotifications,
  reportLoadErrorNotification,
  reportLoadNotification,
} from './Notifications.slice';
import {fetchNotificationsList} from './Notifications.service';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';

function* handleFetchNotifications() {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
      },
    };
    const {data}: any = yield* call(fetchNotificationsList, requestPayload);
    yield* put(reportLoadNotification(data?.NotificationList ?? []));
  } catch (e: any) {
    yield* put(reportLoadErrorNotification(e.message));
  }
}

function* watchNotifications() {
  yield* takeEvery(fetchNotifications.type, handleFetchNotifications);
}

export {watchNotifications};
