import React, { useState } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import theme from "../../../../common/theme";
import SvgConverter from "../../../../common/SvgConverter";
import { ReactComponent as InsurerLogo } from "../../../../common/icons/small-insurer-logo.svg";
import FormCheckBox from "../../../../common/ui/Forms/FormInput/CheckBox/FormCheckBox";
import "./UpdateMobile.scss";
import { ReactComponent as BlackRupee } from "../../../../common/icons/policy/black_rupee.svg";
import { ReactComponent as OrangeRuppee } from "../../../../common/icons/profile/orange_ruppee_icon.svg";

import Separator from "../../../../common/ui/Separator";
import { convertAmount } from "../../../../common/utils";

interface UserProps {
  policyDetails?: any;
  handleCheck?: any;
  isChecked?: any;
}
export const AssignPolicyCard: React.FC<UserProps> = ({
  policyDetails,
  handleCheck,
  isChecked,
}) => {
  return (
    <Grid item className=" mb-15 ml-10 assign-policy">
      <Grid
        container
        justifyContent={"space-between"}
        className="assign-policy"
      >
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={12}>
              <FormCheckBox
                label={
                  <Typography
                    className="f-16  fw-600"
                    color={theme.palette.primary.main}
                  >
                    {policyDetails?.planName}
                  </Typography>
                }
                checked={isChecked}
                onChange={handleCheck}
                size={16}
                checkBoxPadding={1}
              />
            </Grid>
            <Grid item>
              <Typography
                className="f-14 ml-14"
                color={theme.palette.common.black}
              >
                Life Assured
              </Typography>
              <Typography
                className="f-16 ml-14 fw-600"
                color={theme.palette.common.black}
              >
                {policyDetails?.customerName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <img
            src={policyDetails?.companyLogo}
            className="mt-8"
            alt="Company Logo"
            width={"100%"}
            style={{ objectFit: "contain" }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"space-between"}
        className="assign-policy ml-14"
      >
        <Grid item sm={6}>
          <Grid item>
            <Typography className="f-14" color={theme.palette.common.black}>
              Sum Assured
            </Typography>
          </Grid>
          <Grid item>
            <Grid item container alignItems={"flex-end"} spacing={0.5}>
              <Grid item className="d-flex mb-4">
                <SvgConverter Icon={OrangeRuppee} />
              </Grid>
              <Grid item>
                <Typography
                  fontSize={16}
                  fontWeight={600}
                  lineHeight={"18px"}
                  color={theme.palette.common.black}
                >
                  {convertAmount(policyDetails?.sumAssured)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6}>
          <Grid item>
            <Typography className="f-14" color={theme.palette.common.black}>
              Premium
            </Typography>
          </Grid>
          <Grid item>
            <Grid item container alignItems={"flex-end"} spacing={0.5}>
              <Grid item className="d-flex mb-4">
                <SvgConverter Icon={OrangeRuppee} />
              </Grid>
              <Grid item>
                <Typography
                  fontSize={16}
                  fontWeight={600}
                  lineHeight={"18px"}
                  color={theme.palette.common.black}
                >
                  {convertAmount(policyDetails?.premiumAmount)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="my-10 ml-10">
        <Separator color={theme.palette.grey[400]} className="W-50" />
      </Grid>
      <Grid item className="ml-12">
        <Typography className="f-14" color={theme.palette.common.black}>
          Current Number
        </Typography>
        <Typography className="f-14 fw-600" color={theme.palette.common.black}>
          {policyDetails?.mobileNo}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AssignPolicyCard;
