import React from 'react';
import { Grid } from '@mui/material';
import TrackTransactionLayout from './TrackerTansactionLayout/TrackerTransactionLayout';
import TrackTransactionFooter from './TrackerTansactionLayout/TrackerTransactionFooter';
import CustomButton from '../../../../common/ui/CustomButton';
import { ReactComponent as Cancel } from '../../../../common/icons/update-mobile/cancel_orange.svg';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import Typography from '@mui/material/Typography';
import theme from '../../../../common/theme';
import { Link } from 'react-router-dom';
import TimelineScreen from './TrackerTansactionLayout/TimeLineScreen';
const TrackTransactionScreen = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={7}>
          <Grid container justifyContent='space-between' className='py-10 '>
            <Typography
              className=' fw-600 '
              color={theme.palette.primary.main}
              fontSize={16}
            >
              Need Money
            </Typography>
            <Link to={APP_ROUTES.DASHBOARD}>
              <CustomButton
                className=' fw-600'
                text={'Done'}
                variant={'text'}
                color={'primary'}
                showIcon={false}
                startIcon={Cancel}
                type='submit'
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4}>
          <TrackTransactionLayout />
        </Grid>
        <Grid item xs={5}>
          <TimelineScreen />
          <TrackTransactionFooter />
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </>
  );
};

export default TrackTransactionScreen;
