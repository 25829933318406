import { axiosInstance } from '../../../common/axiosInstance';
import ROUTES from '../../../common/routes';

const { policy, policyDocument, listInsurer, addPolicy, viewPolicy } = ROUTES;
const axios = axiosInstance;

const fetchPolicies = (data: any) => axios.request({ ...policy, data });

const fetchPolicyDocument = (data: any) =>
  axios.request({ ...policyDocument, data });

const fetchAllInsurers = () => axios.request({ ...listInsurer });

const AddPolicies = (data: any) => axios.request({ ...addPolicy, data });

const ViewPolicies = (data: any) => axios.request({ ...viewPolicy, data });

export {
  fetchPolicies,
  fetchPolicyDocument,
  fetchAllInsurers,
  AddPolicies,
  ViewPolicies,
};
