import React from 'react';
import { ReactComponent as MobileIcon } from './../../../common/icons/mobile-icon.svg';
import { ReactComponent as PanCardIcon } from './../../../common/icons/pan_card_icon.svg';
import { ReactComponent as LabelCalendarIcon } from '../../../common/icons/login_calendar_label-icon.svg';
import { ReactComponent as WitnessPerson } from '../../../common/icons/LoanModule/witness-icon.svg';
import { ReactComponent as WitnessEmail } from '../../../common/icons/LoanModule/witness_email_icon.svg';
import { ReactComponent as cancel } from '../../../common/icons/cancel-icon.svg';
import { ReactComponent as correct } from '../../../common/icons/LoanModule/right-arrow-icon.svg';
import { Grid, Typography, useTheme } from '@mui/material';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import { APP_ROUTES, AUTH_ROUTES } from '../../../common/constants/Routes';
import DatePickerModal from '../../../common/ui/DatePickerModal';
import CustomButton from '../../../common/ui/CustomButton';
import { Link, useNavigate } from 'react-router-dom';
import '../LoanModule/LoanModule.scss';
import { useForm } from 'react-hook-form';
import data from '../../../common/constants/FormConstants.json';
const AddWitnessDetails = () => {
  const theme = useTheme();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const navigate = useNavigate();
  const handleDateChange = (date: any) => {
    setValue('dob', date, { shouldValidate: true });
  };
  const onSubmit = (data: any) => {
    console.log('data', data);
    // /    e.preventDefault();
    // dispatch(
    //   fetchLoginRequest({ loginId: "test-id", dateOfBirth: "02-02-2023" })
    // );

    navigate('/submit-loan-application');
    console.log(data);
  };
  const dobValue = watch('dob');
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container justifyContent={'center'}>
        <Grid item justifyContent={'center'} className='need-money'>
          <Grid item container className={'w-100'}>
            <Grid item className={'mb-30'}>
              <Typography
                component={'span'}
                className={'f-16 fw-600'}
                color={theme.palette.common.black}
              >
                Step 1/2
              </Typography>
              <Typography
                className={'f-16 fw-600'}
                color={theme.palette.primary.main}
              >
                Add Witness Details
              </Typography>
            </Grid>
            <Grid item className='mb-5 need-money'>
              <Grid
                container
                spacing={2}
                justifyContent={'space-between'}
                className='mb-14'
              >
                <Grid item>
                  <Link to={APP_ROUTES.RECOMMENDED_SELECTED}>
                    <LabelWithIcon
                      className='f-14'
                      justifyContent={'flex-start'}
                      Icon={cancel}
                      label={'CANCEL'}
                      labelProps={{
                        fontSize: 14,
                        color: 'primary',
                      }}
                      iconProps={{ width: 18, height: 18 }}
                    />
                  </Link>
                </Grid>
                <Grid item>
                  <CustomButton
                    className='f-14 fw-600 '
                    text={'NEXT'}
                    variant={'text'}
                    color={'primary'}
                    showIcon={false}
                    startIcon={correct}
                    type='submit'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className='mb-15 w-100'>
              <LabelWithIcon
                Icon={WitnessPerson}
                label={'Name of Witness'}
                justifyContent={'flex-start'}
                className={'mb-3'}
              />
              <FormInput
                type='text'
                placeholder='Type here..'
                inputProps={{
                  className: 'text-center',
                  ...register('witness', {
                    required: {
                      value: true,
                      message: data.COMMON,
                    },
                  }),
                }}
                errors={errors}
              />
            </Grid>
            <Grid item className='mb-15 w-100'>
              <LabelWithIcon
                Icon={PanCardIcon}
                label={"Witness's PAN Number"}
                justifyContent={'flex-start'}
                className={'mb-3'}
              />
              <FormInput
                type='text'
                placeholder='ABCDE0100X'
                inputProps={{
                  className: 'text-center',
                  ...register('money', {
                    required: {
                      value: true,
                      message: data.PANCARD,
                    },
                  }),
                }}
                errors={errors}
              />
            </Grid>
            <Grid item className='mb-15 w-100'>
              <LabelWithIcon
                Icon={MobileIcon}
                label={'Mobile Number of Witness'}
                justifyContent={'flex-start'}
                className={'mb-3'}
              />
              <Grid container flexWrap={'nowrap'} spacing={1}>
                <Grid item sm={3}>
                  <FormInput
                    inputProps={{ style: { textAlign: 'center' } }}
                    type='number'
                    placeholder='+91'
                    hideCountButton={true}
                  />
                </Grid>
                <Grid item flex={1}>
                  <FormInput
                    type='number'
                    placeholder='1234567890'
                    hideCountButton={true}
                    inputProps={{
                      className: 'text-center',
                      ...register('number', {
                        required: {
                          value: true,
                          message: data.MOBILENUMBER,
                        },
                      }),
                    }}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className='mb-15 w-100'>
              <LabelWithIcon
                Icon={WitnessEmail}
                label={"Witness's email"}
                justifyContent={'flex-start'}
                className={'mb-3'}
              />
              <FormInput
                type='text'
                placeholder='email@website.com'
                inputProps={{
                  className: 'text-center',
                  ...register('email', {
                    required: {
                      value: true,
                      message: data.EMAIL,
                    },
                  }),
                }}
                errors={errors}
              />
            </Grid>

            <Grid container item xs={12} flexDirection={'column'}>
              <Grid item className='my-3'>
                <LabelWithIcon
                  Icon={LabelCalendarIcon}
                  label={"Witness's Date Of Birth"}
                  iconProps={{ height: 33 }}
                  justifyContent={'flex-start'}
                />
              </Grid>
              <Grid
                item
                container
                flexDirection={'row'}
                spacing={2}
                alignItems={'center'}
                justifyContent={'space-between'}
                flexWrap={'nowrap'}
              >
                <Grid item sm={3}>
                  <FormInput
                    inputProps={{
                      style: { textAlign: 'center' },
                      value: dobValue ? dobValue.format('DD') : '',
                    }}
                    placeholder={'DD'}
                  />
                </Grid>
                <Grid item sm={3}>
                  <FormInput
                    inputProps={{
                      style: { textAlign: 'center' },
                      value: dobValue ? dobValue.format('MM') : '',
                    }}
                    placeholder={'MM'}
                  />
                </Grid>
                <Grid item sm={4}>
                  <FormInput
                    inputProps={{
                      style: { textAlign: 'center' },
                      value: dobValue ? dobValue.format('YYYY') : '',
                    }}
                    placeholder={'YYYY'}
                  />
                </Grid>
                <Grid item>
                  <DatePickerModal
                    iconType={'primary'}
                    onChange={handleDateChange}
                  />
                </Grid>
              </Grid>{' '}
              <FormInput
                hidden={true}
                inputProps={{
                  ...register('dob', {
                    required: {
                      value: true,
                      message: data.DOB,
                    },
                  }),
                }}
                errors={errors}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddWitnessDetails;
