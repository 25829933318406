import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { CommonModal } from "../../../common/ui/Modal/CommonModal";
import Typography from "@mui/material/Typography";
import theme from "../../../common/theme";
import { Grid } from "@mui/material";
import FormInput from "../../../common/ui/Forms/FormInput/FormInput";
import CustomButton from "../../../common/ui/CustomButton";
import { ReactComponent as Verify } from "../../../common/icons/update-mobile/verify_orange.svg";
import { ReactComponent as Cancel } from "../../../common/icons/update-mobile/cancel_orange.svg";
import config from "../../../common/config";

const EmailEntryModal = ({ open, setOpen, onSubmit, loading }: any) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({ defaultValues: { email: "" } });

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  return (
    <CommonModal
      onClose={() => setOpen(false)}
      open={open}
      modalClassName="px-20 py-20"
      boxProps={{ width: 400 }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography className="f-16 fw-6" color={theme.palette.primary.main}>
          Please enter Email Address
        </Typography>

        <Grid container flexWrap={"nowrap"} spacing={1} className="py-10">
          <Grid item flex={1}>
            <FormInput
              placeholder="email@website.com"
              name="email"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Email is required",
                },
                pattern: {
                  value: config.emailRegex,
                  message: "Please enter valid email",
                },
              }}
              errors={errors}
            />
          </Grid>
        </Grid>
        <Grid container textAlign={"center"} className="mt-10">
          <Grid item xs={6}>
            <CustomButton
              text={"verify"}
              variant={"text"}
              color={"primary"}
              showIcon={false}
              startIcon={Verify}
              type="submit"
              loading={loading}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomButton
              text={"CANCEL"}
              variant={"text"}
              color={"primary"}
              showIcon={false}
              startIcon={Cancel}
              onClick={() => setOpen(false)}
            />
          </Grid>
        </Grid>
      </form>
    </CommonModal>
  );
};

export default EmailEntryModal;
