import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import VerifiedText from "../VerifiedText";
import UnverifiedText from "../UnverifiedText";
import CustomButton from "../../../../common/ui/CustomButton";
import OverflowTip from "../../../../common/ui/OverflowTip";

const MobilePolicyHeader = ({
  index,
  mobileNo,
  verified,
  primary,
  onRemove,
  showAssignPolicyButton = false,
  handleAssignPolicy,
  onVerify,
}: any) => {
  const theme = useTheme();
  return (
    <Grid item className="mobile-container">
      <Grid
        container
        justifyContent="space-between"
        wrap="nowrap"
        className="mb-10 mobile-container"
      >
        <Grid item flex={1}>
          <Grid flexWrap={"nowrap"} container spacing={0.5} className="ml-n20">
            <Grid item className="ml-n20s">
              <Typography color="black" fontSize={20} fontWeight={600}>
                {index + 1}.
              </Typography>
            </Grid>
            <Grid item className="mt-3" flex={1}>
              <Grid container alignItems={"center"}>
                <Grid item className="mr-5">
                  <Typography color="primary" fontSize={18} fontWeight={600}>
                    {mobileNo}
                    {/* <OverflowTip>{mobileNo} asd asdasdasdad</OverflowTip> */}
                  </Typography>
                </Grid>
                <Grid item>
                  {String(verified).toUpperCase() === "Y" ? (
                    <VerifiedText />
                  ) : (
                    <UnverifiedText />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <CustomButton
            text={"remove"}
            variant="text"
            showIcon={false}
            color="primary"
            onClick={onRemove}
            className="f-14 fw-400 mt-5"
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={"space-between"} className="mb-10">
        {showAssignPolicyButton ? (
          <Grid item>
            <CustomButton
              text={"Assign policies"}
              variant="text"
              showIcon={false}
              color="primary"
              onClick={handleAssignPolicy}
              className="f-14 fw-400"
            />
          </Grid>
        ) : (
          <></>
        )}

        <Grid item>
          {String(primary).toUpperCase() === "Y" ? (
            <Typography color={theme.palette.common.black} fontSize={12}>
              (Primary Mobile Number)
            </Typography>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MobilePolicyHeader;
