import React from "react";
import SvgConverter from "../SvgConverter";
import { ReactComponent as LifeIcon } from "./../icons/profile/Life_icon.svg";
import { ReactComponent as AssetIcon } from "./../icons/profile/asset_icon.svg";
import { ReactComponent as HealthIcon } from "./../icons/profile/health_icon.svg";
import { POLICY_TYPE_CODES } from "../Enums";

const ICONS: any = {
  [POLICY_TYPE_CODES.HEALTH]: HealthIcon,
  [POLICY_TYPE_CODES.LIFE]: LifeIcon,
  [POLICY_TYPE_CODES.ASSET]: AssetIcon,
};

const GeneratePolicyIcon = ({ policyTypeCode }: any) => {
  return (
    <SvgConverter
      Icon={ICONS[String(policyTypeCode).toUpperCase()]}
      width={20}
      height={21}
    />
  );
};

export default GeneratePolicyIcon;
