import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import { ReactComponent as checked } from "../../../../common/icons/profile/checked.svg";
import { ReactComponent as ExeDangerIcon } from "../../../../common/icons/Red_Alert_icon.svg";
import AddressSection from "../AddressSection";
import SvgConverter from "../../../../common/SvgConverter";
import CustomButton from "../../../../common/ui/CustomButton";
const PermanentAddress = ({
  addressDetails,
  onChange,
  changeLoading = false,
}: any) => {
  const theme = useTheme();

  return (
    <Grid container>
      <Grid item className="address">
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Typography
              color={theme.palette.primary.main}
              fontSize={16}
              fontWeight={600}
            >
              Permanent Address
            </Typography>
            {addressDetails?.city ? (
              <Typography
                // component={'span'}
                color={theme.palette.grey[400]}
                fontSize={12}
                className="mt-n4"
              >
                Assigned to all policies
              </Typography>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item>
            <CustomButton
              text={"CHANGE"}
              variant="text"
              color="primary"
              fontSize={14}
              showIcon={false}
              fontWeight={400}
              onClick={onChange}
              loading={changeLoading}
            />
          </Grid>
        </Grid>
        {addressDetails?.city ? (
          <Grid item className="manage-permanent-address p-20 mb-10">
            <AddressSection data={addressDetails} />
            {String(addressDetails.addressVerified).toUpperCase() === "Y" ? (
              <Grid
                item
                container
                spacing={0.5}
                flexWrap={"nowrap"}
                alignItems={"center"}
              >
                <Grid item>
                  <SvgConverter
                    Icon={checked}
                    width={"11px"}
                    height={"8px"}
                    className="mb-4"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    className="f-12"
                    color={theme.palette.success.main}
                  >
                    Verified
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid
                item
                container
                spacing={0.5}
                flexWrap={"nowrap"}
                alignItems={"center"}
              >
                <Grid item>
                  <SvgConverter Icon={ExeDangerIcon} width={10} height={8} />
                </Grid>
                <Grid item>
                  <Typography className="f-12" color={theme.palette.error.main}>
                    Not Verified
                  </Typography>
                </Grid>
              </Grid>
            )}

            {/* <Typography color={theme.palette.common.black} fontSize={12}>
            [PAN Registered]
          </Typography>
          <Typography color={theme.palette.common.black} fontSize={12}>
            [Aadhar Registered]
          </Typography> */}
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

export default PermanentAddress;
