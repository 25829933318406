import React from "react";
import { Grid } from "@mui/material";
// import { ReactComponent as CancelledCheck } from "../../../common/icons/update-mobile/cancelled_cheque_icon.svg";
import {
  assetPolicies,
  combinePoliciesAccount,
  getBankInfoByAcNo,
  healthPolicies,
  lifePolicies,
} from "./Bank.slice";
import BankDetailsHeader from "./common/BankDetailsHeader";
import PolicyCard from "../Common/PolicyCard";

const BankPolicies = ({
  policiesObj,
  totalPolicies,
  unAssignedBanks,
  handleChangeBank,
  handleRemoveBank,
}: any) => {
  return (
    <Grid
      container
      className="Manage-bank"
      spacing={5}
      justifyContent={"space-between"}
    >
      {Object.keys(policiesObj).map((acNo: any, index) => {
        const bankInfo = getBankInfoByAcNo(acNo)(
          combinePoliciesAccount(totalPolicies, unAssignedBanks)
        );
        return (
          <Grid item xs={12} sm={6} md={3} key={acNo}>
            <Grid item className="Manage-bank">
              <BankDetailsHeader
                data={bankInfo}
                index={index}
                onRemoveClick={() => handleRemoveBank(acNo)}
              />
              <Grid
                item
                className="mobile-Policy-screen policy-list-container p-20"
              >
                {lifePolicies(policiesObj[acNo]).map((policy: any) => (
                  <PolicyCard
                    key={policy?.policyNo}
                    policyDetails={policy}
                    changeButtonText={"change bank"}
                    onChangeClick={handleChangeBank(acNo, policy)}
                  />
                ))}
                {healthPolicies(policiesObj[acNo]).map((policy: any) => (
                  <PolicyCard
                    key={policy?.policyNo}
                    policyDetails={policy}
                    changeButtonText={"change bank"}
                    onChangeClick={handleChangeBank(acNo, policy)}
                  />
                ))}
                {assetPolicies(policiesObj[acNo]).map((policy: any) => (
                  <PolicyCard
                    key={policy?.policyNo}
                    policyDetails={policy}
                    changeButtonText={"change bank"}
                    onChangeClick={handleChangeBank(acNo, policy)}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default BankPolicies;
