import { Grid } from '@mui/material';
import React from 'react';
import { ReactComponent as Download } from '../../../../../common/icons/policy/download.svg';
import { ReactComponent as Arrow } from '../../../../../common/icons/right_arrow-icon.svg';
import '../../Policy.scss';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import theme from '../../../../../common/theme';
import SvgConverter from '../../../../../common/SvgConverter';
import Separator from '../../../../../common/ui/Separator';
import CustomButton from '../../../../../common/ui/CustomButton';
import { CommonModal } from '../../../../../common/ui/Modal/CommonModal';
import PolicyServices from '../../PolicyServices/PolicyServices';
import { APP_ROUTES } from '../../../../../common/constants/Routes';
import { fetchPolicyDocumentDownload } from '../../Policies.slice';
import { useDispatch } from 'react-redux';
import DownloadStatement from '../../DownloadStatement/DownloadStatement';
import PremiumReceipt from '../../DownloadStatement/PremiumReceipt';
import BonusStatement from '../../DownloadStatement/BonusStatement';
import FundStatement from '../../DownloadStatement/FundSatement';

interface UserProps {
  footerType?: string;
  items?: any;
}
export const PolicyFooter: React.FC<UserProps> = ({ footerType, items }) => {
  const [open, setOpen] = React.useState(false);
  const [viewModal, setViewModal] = React.useState(false);
  const [premiumModal, setPremiumModal] = React.useState(false);
  const [bonusModal, setBonusModal] = React.useState(false);
  const [FundModal, setFundModal] = React.useState(false);

  const dispatch = useDispatch();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleViewClose = () => setViewModal(false);
  const handlePremiumClose = () => setPremiumModal(false);
  const handleBonusClose = () => setBonusModal(false);
  const handleFundClose = () => setFundModal(false);

  const handlePremiumBack = () => {
    setPremiumModal(false);
    setViewModal(true);
  };
  const handleBonusBack = () => {
    setBonusModal(false);
    setViewModal(true);
  };
  const handleFundBack = () => {
    setFundModal(false);
    setViewModal(true);
  };
  const downloadPolicyDocument = () => {
    dispatch(
      fetchPolicyDocumentDownload({
        policyDocument: 'Y',
        policyNumber: items?.policyNo,
        alternateCode: items?.alternateCode,
        insurerCode: items?.companyCode,
      } as any)
    );
  };

  return (
    <Grid container>
      <Grid item className='' xs={10}>
        <Typography
          className='f-14 fw-6 py-15  px-7'
          color={theme.palette.primary.main}
        >
          VIEW POLICY DETAILS
        </Typography>
      </Grid>
      <Grid item xs={1} className='py-12'>
        <Link to={APP_ROUTES.POLICY_DETAILS} state={items}>
          <SvgConverter Icon={Arrow} className='right-arrow ml-13' />{' '}
        </Link>
      </Grid>
      <Separator color={theme.palette.primary.main} />
      <Grid item xs={12} display={'flex'}>
        <CustomButton
          showIcon={false}
          text={'DOWNLOAD POLICY DOCUMENT'}
          className='f-14 fw-400'
          variant={'text'}
          onClick={downloadPolicyDocument}
        />
        <Grid item xs={1} className='py-12 ml-15'>
          <SvgConverter Icon={Download} />
        </Grid>
      </Grid>
      <Separator color={theme.palette.primary.main} />
      <Grid item xs={12} display={'flex'}>
        <CustomButton
          showIcon={false}
          text={'VIEW/DOWNLOAD STATEMENTS'}
          className='f-14 fw-400'
          variant={'text'}
          onClick={() => setViewModal(true)}
        />
        <Grid item xs={1} className='py-12 ml-15'>
          <SvgConverter Icon={Download} />
        </Grid>
      </Grid>
      {footerType === 'active' ? (
        <>
          <Separator color={theme.palette.primary.main} />
          <Grid container>
            <Grid item xs={9}>
              <Typography
                className='f-14 fw-6 py-10 px-8'
                color={theme.palette.primary.main}
              >
                POLICY SERVICES
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <CustomButton
                text={<SvgConverter Icon={Arrow} className='right-arrow' />}
                variant={'text'}
                color={'primary'}
                showIcon={false}
                onClick={() => setOpen(true)}
                className='py-8 ml-15'
              />
            </Grid>
          </Grid>

          <CommonModal
            onClose={handleClose}
            boxProps={{ height: 411, width: 320 }}
            open={open}
            modalClassName='modal-border'
          >
            <PolicyServices />
          </CommonModal>
        </>
      ) : (
        <>{/* <Separator color={theme.palette.primary.main} /> */}</>
      )}
      <CommonModal
        onClose={handleViewClose}
        boxProps={{ width: 320 }}
        open={viewModal}
        modalClassName='modal-border'
      >
        <DownloadStatement
          PremiumClick={() => setPremiumModal(true)}
          BonusClick={() => setBonusModal(true)}
          FundClick={() => setFundModal(true)}
        />
      </CommonModal>
      <CommonModal
        onClose={handlePremiumClose}
        boxProps={{ width: 320 }}
        open={premiumModal}
        modalClassName='modal-border'
      >
        <PremiumReceipt PremiumBack={handlePremiumBack} />
      </CommonModal>
      <CommonModal
        onClose={handlePremiumClose}
        boxProps={{ width: 320 }}
        open={bonusModal}
        modalClassName='modal-border'
      >
        <BonusStatement BonusBack={handleBonusBack} />
      </CommonModal>
      <CommonModal
        onClose={handlePremiumClose}
        boxProps={{ width: 320 }}
        open={FundModal}
        modalClassName='modal-border'
      >
        <FundStatement FundBack={handleFundBack} />
      </CommonModal>
    </Grid>
  );
};

export default PolicyFooter;
