import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import '../../Policy.scss';
import Typography from '@mui/material/Typography';
import { Link, useLocation } from 'react-router-dom';
import theme from '../../../../../common/theme';
import SvgConverter from '../../../../../common/SvgConverter';
import Separator from '../../../../../common/ui/Separator';
import CustomButton from '../../../../../common/ui/CustomButton';
import { APP_ROUTES } from '../../../../../common/constants/Routes';
import { ReactComponent as cancel } from '../../../../../common/icons/cancel-icon.svg';
import LabelWithIcon from '../../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRuppee } from '../../../../../common/icons/small-orange-rupee.svg';
import HDFC from '../../../../../assets/images/HDFC.png';
import { ReactComponent as Premium } from '../../../../../common/icons/dashboard/pay_premiums-icon.svg';
import { ReactComponent as GreenTick } from '../../../../../common/icons/small-greentick.svg';
import DoughnutWithPercentage from '../../../../../common/ui/Charts/DoughnutWithPercentage';
import { fetchViewPolicyDetails, viewPolicyList } from '../../Policies.slice';
import { useDispatch, useSelector } from 'react-redux';
interface UserProps {
  footerType?: string;
}
export const PolicyDetails: React.FC<UserProps> = ({ footerType }) => {
  const location = useLocation();
  const policyDetails = location.state;

  const policyNum = policyDetails?.policyNo;
  const altCode = policyDetails?.alternateCode;
  const insCode = policyDetails?.companyCode;
  const VerifiedFlag = policyDetails?.verifiedFlag;
  const dispatch = useDispatch();
  const ViewPolicyData = useSelector(viewPolicyList);
  const PolicyDataList = ViewPolicyData.policyDetails;
  const dayjs = require('dayjs');
  const dateValue = PolicyDataList && PolicyDataList?.policyIssuanceDate;
  const formattedDate = dayjs(dateValue).format('DD-MM-YYYY');
  const formattedPremiumDue = dayjs(
    PolicyDataList && PolicyDataList?.premiumDueBy
  ).format('DD-MM-YYYY');
  useEffect(() => {
    dispatch(
      fetchViewPolicyDetails({
        policyNum,
        altCode,
        insCode,
        VerifiedFlag,
      } as any)
    );
  }, []);
  return (
    <Grid container className='policy-details' justifyContent={'center'}>
      <Grid item xs={12}>
        <Grid container justifyContent={'space-between'} className='py-20'>
          <Grid item>
            <Typography
              className='f-16 fw-600 ml-20'
              color={theme.palette.primary.main}
            >
              Policy Details
            </Typography>
          </Grid>
          <Grid item>
            <Link to={APP_ROUTES.POLICY}>
              <CustomButton
                className='f-14  '
                text={'close'}
                variant={'text'}
                color={'primary'}
                endIcon={cancel}
                type='submit'
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Separator />
      </Grid>
      <Grid item xs={12} className='py-20'>
        <Grid item xs={11} container className='ml-20 border-box'>
          <Grid item xs={3} className='ml-10 mt-15'>
            <img src={PolicyDataList?.insurerLogo} width={'60px'} />
            <LabelWithIcon
              className=' mt-20 mr-10'
              svgClassName='mb-2 '
              justifyContent={'flex-start'}
              Icon={GreenTick}
              label={'Verified'}
              labelProps={{
                color: '#63a103',
                fontSize: 12,
                fontWeight: 600,
              }}
            />
          </Grid>
          <Grid item xs={6} className='mt-15 ml-10'>
            <Typography
              className='f-18 fw-600 '
              color={theme.palette.primary.main}
              fontSize={14}
            >
              {PolicyDataList?.productName}
            </Typography>{' '}
            <Typography
              color={theme.palette.primary.main}
              fontSize={14}
              className='mb-20'
            >
              Policy #:{' '}
              {PolicyDataList?.policyNo.length === 0
                ? '-'
                : PolicyDataList?.policyNo}
            </Typography>
            <Typography
              className='f-14 mt-4 '
              color={theme.palette.primary.main}
            >
              Life Assured
            </Typography>{' '}
            <Typography
              className='f-14 fw-600 '
              color={theme.palette.common.black}
              fontSize={16}
            >
              {PolicyDataList?.lifeAssured}
            </Typography>{' '}
            <Typography color={theme.palette.primary.main} fontSize={14}>
              Sum Assured
            </Typography>
            <Grid item container alignItems={'flex-end'} className='mb-15'>
              <LabelWithIcon
                svgClassName=''
                justifyContent={'flex-start'}
                Icon={OrangeRuppee}
                label={
                  PolicyDataList?.sumAssured.length === 0
                    ? '-'
                    : PolicyDataList?.sumAssured
                }
                labelProps={{
                  color: theme.palette.common.black,
                  fontSize: 16,
                  fontWeight: 600,
                }}
              />
            </Grid>
          </Grid>
          <Separator color={theme.palette.primary.main} />
          <Grid item container xs={12} className='py-10'>
            {' '}
            <Grid item xs={4}>
              <Typography
                className='f-14 ml-10'
                color={theme.palette.common.black}
              >
                Policy Status:
              </Typography>
            </Grid>
            <Grid item xs={5} justifyContent={'start'}>
              <Typography
                className='f-16 fw-600 '
                color={theme.palette.primary.main}
              >
                {PolicyDataList?.policyStatusPolicy?.length === 0
                  ? '-'
                  : PolicyDataList?.policyStatusPolicy}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className='ml-20'>
        <Grid item container xs={11}>
          {' '}
          <Grid item xs={6}>
            <Typography
              className='f-14 fw-600 '
              color={theme.palette.primary.main}
              fontSize={14}
            >
              Policy Insurance Date
            </Typography>
            <Typography color={theme.palette.common.black} fontSize={18}>
              {formattedDate === 'Invalid Date' ? '-' : formattedDate}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              className='f-14 fw-600 '
              color={theme.palette.primary.main}
              fontSize={14}
            >
              Policy Term
            </Typography>
            <Typography color={theme.palette.common.black} fontSize={18}>
              {PolicyDataList?.paymentTerm.length === 0
                ? '-'
                : PolicyDataList?.paymentTerm}{' '}
              years
            </Typography>
          </Grid>
          <Grid container item xs={12} className='mt-10'>
            <Grid item xs={12}>
              <Typography
                className='f-14 fw-600 '
                color={theme.palette.primary.main}
                fontSize={14}
              >
                Freelook Period
              </Typography>
            </Grid>

            <Grid item xs={4} className='py-10'>
              <DoughnutWithPercentage
                freelookPeriod={
                  PolicyDataList?.freelookPeriod
                    ? PolicyDataList?.freelookPeriod
                    : '-'
                }
              />
            </Grid>
            <Separator color={theme.palette.primary.main} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className='ml-20 py-20'>
        <Grid item container xs={11} className='border-box  py-5 px-5'>
          <Grid container item xs={5}>
            <Grid item xs={2}>
              <SvgConverter Icon={Premium} className='ml-2 mt-5' />
            </Grid>
            <Grid item xs={6}>
              <Typography className='f-12' color={theme.palette.primary.main}>
                Premium
              </Typography>
              <LabelWithIcon
                svgClassName='mb-2'
                justifyContent={'flex-start'}
                Icon={OrangeRuppee}
                label={
                  PolicyDataList?.premium.length === 0
                    ? '-'
                    : PolicyDataList?.premium
                }
                labelProps={{
                  color: theme.palette.common.black,
                  fontSize: 14,
                  fontWeight: 600,
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography
              className='f-14 mt-10'
              color={theme.palette.primary.main}
            >
              Due by{' '}
              {formattedPremiumDue === 'Invalid Date'
                ? '-'
                : formattedPremiumDue}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className='ml-20'>
        <Grid item container xs={11}>
          {' '}
          <Grid item xs={6}>
            <Typography
              className='f-14 fw-600 '
              color={theme.palette.primary.main}
              fontSize={14}
            >
              Premium Frequency
            </Typography>
            <Typography color={theme.palette.common.black} fontSize={18}>
              {PolicyDataList?.premiumFrequency.length === 0
                ? '-'
                : PolicyDataList?.premiumFrequency}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              className='f-14 fw-600 '
              color={theme.palette.primary.main}
              fontSize={14}
            >
              Payment Term
            </Typography>
            <Typography color={theme.palette.common.black} fontSize={18}>
              {PolicyDataList?.paymentTerm.length === 0
                ? '-'
                : PolicyDataList?.paymentTerm}{' '}
              years
            </Typography>
          </Grid>
          <Grid item xs={12} className='mt-10 mb-30'>
            <Typography
              className='f-14 fw-600 '
              color={theme.palette.primary.main}
              fontSize={14}
            >
              Mode
            </Typography>{' '}
            <Typography color={theme.palette.common.black} fontSize={18}>
              {PolicyDataList?.mode?.length === 0 ? '-' : PolicyDataList?.mode}
            </Typography>
          </Grid>
          <Grid item xs={12} className='py-30'>
            <Separator color={theme.palette.primary.main} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PolicyDetails;
