import React from "react";
import { CommonModal } from "./CommonModal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SvgConverter from "../../SvgConverter";
import { ReactComponent as ErrorIcon } from "../../icons/Red_Alert_icon.svg";
import { ReactComponent as TickIcon } from "../../icons/Big-Green-Tick.svg";
import { Typography } from "@mui/material";
import CustomButton from "../CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAlertInfo,
  setAlertInfo,
} from "../../../features/App/Common/Common.slice";
const ApiModal = () => {
  const dispatch = useDispatch();
  const alertInfo = useSelector(selectAlertInfo);
  const handleClickOnOk = () => () => {
    dispatch(setAlertInfo({ open: false } as any));
  };
  return (
    <CommonModal modalClassName="api-modal-container" open={alertInfo.open}>
      <Box>
        <Grid container alignItems={"center"} justifyContent={"center"}>
          <Grid item marginBottom={1}>
            <SvgConverter
              Icon={alertInfo?.type === "SUCCESS" ? TickIcon : ErrorIcon}
            />
          </Grid>
        </Grid>
        <Typography textAlign={"center"} className={"f-14 mb-15"}>
          {alertInfo.description}
        </Typography>
        <Grid container alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <CustomButton
              text={"OK"}
              showIcon={false}
              variant={"outlined"}
              color={"success"}
              onClick={handleClickOnOk()}
            />
          </Grid>
        </Grid>
      </Box>
    </CommonModal>
  );
};

export default ApiModal;
