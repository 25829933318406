import { call, put, select, takeEvery } from 'typed-redux-saga';

import {
  addNewPolicy,
  addNewPolicyError,
  addNewPolicySuccess,
  errorLoadingActivePolicy,
  errorLoadingArchivePolicy,
  errorLoadingPolicyDocumentDownload,
  errorViewPolicyDetails,
  fetchActivePolicy,
  fetchArchivePolicy,
  fetchInsurers,
  fetchPolicyDocumentDownload,
  fetchViewPolicyDetails,
  loadActivePolicy,
  loadArchivePolicy,
  loadInsurers,
  loadPolicyDocumentDownload,
  loadViewPolicyDetails,
} from './Policies.slice';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';
import {
  AddPolicies,
  ViewPolicies,
  fetchAllInsurers,
  fetchPolicies,
  fetchPolicyDocument,
} from './Policies.service';
import { setAlertInfo } from '../Common/Common.slice';
import { saveBase64AsFile } from '../../../common/utils';

export enum PolicyStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE',
}

function* handleFetchActivePolicy() {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
        policyStatus: PolicyStatus.ACTIVE,
      },
    };
    const { data }: any = yield* call(fetchPolicies, requestPayload);
    yield* put(loadActivePolicy(data));
  } catch (e: any) {
    yield* put(errorLoadingActivePolicy(e.message));
  }
}

function* handleFetchArchivePolicy() {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
        policyStatus: PolicyStatus.ARCHIVE,
      },
    };
    const { data }: any = yield* call(fetchPolicies, requestPayload);
    yield* put(loadArchivePolicy(data));
  } catch (e: any) {
    yield* put(errorLoadingArchivePolicy(e.message));
  }
}

function* handleFetchInsurers() {
  try {
    const { data }: any = yield* call(fetchAllInsurers);
    yield* put(loadInsurers(data.MasterData));
  } catch (e: any) {
    yield* put(errorLoadingArchivePolicy(e.message));
  }
}

function* handleFetchStatementOfHolding({ payload }: any) {
  try {
    // yield* put(
    //   setAlertInfo({
    //     open: true,
    //     progressing: 'PROGRESS',
    //     title: 'Downloading Document...',
    //     description: 'Please wait while document is downloading.',
    //   })
    // );
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
      },
      Document: {
        holdingStatement: '',
        annualAccountStatement: '',
        policyDocument: '',
        policyNumber: '',
        insurerCode: '',
        alternateCode: '',
        ...payload,
      },
    };
    console.log('requestPayload', requestPayload);
    const { data }: any = yield* call(fetchPolicyDocument, requestPayload);
    const fileName = yield* call(
      saveBase64AsFile,
      data.docImageBase64,
      data.docName
    );
    const alertInfo = {
      type: 'SUCCESS',
      title: 'Download Success!',
      description: `Document has downloaded with file name as ${fileName} In downloads directory.`,
    };
    // yield* put(setShowAlert(alertInfo));
    yield* put(loadPolicyDocumentDownload(data));
  } catch (e: any) {
    yield* put(errorLoadingPolicyDocumentDownload(e.message));
  }
}

function* handleAddPolicy({ payload }: any) {
  try {
    const { data }: any = yield* call(AddPolicies, payload);
    yield* put(addNewPolicySuccess(data));
    // yield* put(
    //   setAlertInfo({
    //     type: 'SUCCESS',
    //     title: 'success!',
    //     description: data?.displayMessage,
    //   })
    // );
    // payload?.Customer?.navigation.navigate(UserScreens.policy.routeName);
    yield call(handleFetchActivePolicy);
    yield call(handleFetchArchivePolicy);
  } catch (e: any) {
    yield* put(addNewPolicyError(e.message));
  }
}
function* handleFetchViewPolicy({ payload }: any) {
  console.log('View policy payload', payload);
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
        policyNo: payload?.policyNum,
        insurerCode: payload?.insCode,
        alternateCode: payload?.altCode,
        verifiedFlag: payload?.VerifiedFlag,
      },
    };
    const { data }: any = yield* call(ViewPolicies, requestPayload);
    console.log('data of view policy', data);
    yield* put(loadViewPolicyDetails(data));
  } catch (e: any) {
    yield* put(errorViewPolicyDetails(e.message));
  }
}

function* watchPolicies() {
  yield* takeEvery(fetchActivePolicy.type, handleFetchActivePolicy);
  yield* takeEvery(fetchArchivePolicy.type, handleFetchArchivePolicy);
  yield* takeEvery(
    fetchPolicyDocumentDownload.type,
    handleFetchStatementOfHolding
  );
  yield* takeEvery(fetchInsurers.type, handleFetchInsurers);
  yield* takeEvery(addNewPolicy.type, handleAddPolicy);
  yield* takeEvery(fetchViewPolicyDetails.type, handleFetchViewPolicy);
}

export { watchPolicies };
