import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import '../Layout.scss';
import { ReactComponent as CloseIcon } from '../../common/icons/close.svg';
import SvgConverter from '../../common/SvgConverter';
import { MENU } from '../../common/constants/Menu';
import { Link } from 'react-router-dom';
import Separator from '../../common/ui/Separator';
import { useDispatch } from 'react-redux';
import { userSignOut } from '../../features/Auth/auth.slice';

const LeftMenuList = ({ setOpen }: { setOpen: Function }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(userSignOut());
  };

  return (
    <div className='notification-container'>
      <Grid
        container
        justifyContent={'space-between'}
        className={'notification-drawer-header'}
        alignItems={'center'}
      >
        <Grid item>
          <Grid item>
            <IconButton onClick={() => setOpen(false)}>
              <SvgConverter Icon={CloseIcon} />
            </IconButton>
          </Grid>
          <Grid
            container
            justifyContent={'space-between'}
            alignItems={'center'}
            spacing={2}
          >
            <Grid item>
              <List className='left-menu-container'>
                {MENU.map(
                  (
                    { path, title, submenus }: any,
                    idx: React.Key | null | undefined
                  ) => (
                    <React.Fragment key={idx}>
                      <Link to={path}>
                        <ListItemText
                          className='menu-item-container'
                          primary={
                            <Typography
                              color={theme.palette.primary.main}
                              fontWeight={600}
                              fontSize={18}
                            >
                              {title}
                            </Typography>
                          }
                        />
                      </Link>
                      {submenus && (
                        <List>
                          {submenus.map(
                            (
                              { path: submenuPath, title: submenuTitle }: any,
                              submenuIdx: React.Key | null | undefined
                            ) => (
                              <Link to={submenuPath} key={submenuIdx}>
                                <ListItem button>
                                  <ListItemText
                                    primary={
                                      <Typography
                                        color={theme.palette.primary.main}
                                        fontWeight={600}
                                        fontSize={18}
                                      >
                                        {submenuTitle}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                              </Link>
                            )
                          )}
                        </List>
                      )}
                    </React.Fragment>
                  )
                )}
                <div className='mb-16'>
                  <Separator color={theme.palette.primary.main} />
                </div>
                <Link to={'#'}>
                  <ListItemText
                    className='menu-item-container'
                    primary={
                      <Typography
                        color={theme.palette.primary.main}
                        fontWeight={600}
                        fontSize={18}
                      >
                        Support
                      </Typography>
                    }
                  />
                </Link>
                <Link to={'#'}>
                  <ListItemText
                    className='menu-item-container'
                    primary={
                      <Typography
                        color={theme.palette.primary.main}
                        fontWeight={600}
                        fontSize={18}
                      >
                        Legal
                      </Typography>
                    }
                  />
                </Link>
                <Link to={'#'} onClick={handleLogout}>
                  <ListItemText
                    className='menu-item-container'
                    primary={
                      <Typography
                        color={theme.palette.primary.main}
                        fontWeight={600}
                        fontSize={18}
                      >
                        Logout
                      </Typography>
                    }
                  />
                </Link>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default LeftMenuList;
