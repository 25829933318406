import React from 'react';
import { Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import SvgConverter from '../../../../common/SvgConverter';
import { ReactComponent as RupeeIcon } from '../../../../common/icons/dashboard/rupee-icon.svg';

const PolicyCardCost = ({
  title = 'Title',
  value = 0,
  policyCounts = 0,
}: {
  title?: string | undefined;
  value?: number | string | undefined | 0;
  policyCounts?: number | 0;
}) => {
  const theme = useTheme();
  return (
    <Box className={'dashboard-policy-cost-container'}>
      <Typography
        fontWeight={600}
        className='pb-1'
        fontSize={16}
        color={theme.palette.primary.main}
      >
        {title}
      </Typography>
      <Box
        className={'header-border'}
        borderBottom={`2px solid ${theme.palette.secondary.main}`}
      />
      <Grid item>
        <Grid
          container
          flexWrap={'nowrap'}
          className='mt-0'
          alignItems={'flex-end'}
          flexDirection={'row'}
          spacing={0.5}
        >
          <Grid
            item
            container
            flexWrap={'nowrap'}
            alignItems={'flex-end'}
            flexDirection={'row'}
            sm={6}
          >
            <Grid item className='h-100'>
              <SvgConverter
                Icon={RupeeIcon}
                className={'d-flex align-items-end mb-4'}
              />
            </Grid>
            <Grid item>
              <Typography
                fontWeight={300}
                fontSize={28}
                lineHeight={1}
                className={'ml-6'}
              >
                {value}
              </Typography>
            </Grid>
          </Grid>
          <Grid item sm={6}>
            <Typography fontSize={14} className={'ml-10'}>
              from{' '}
              <span
                className='fw-600'
                style={{ color: theme.palette.primary.main }}
              >
                {policyCounts}
              </span>{' '}
              <span style={{ color: theme.palette.primary.main }}>
                {policyCounts > 1 ? 'policies' : 'policy'}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

PolicyCardCost.Normal = ({
  title,
  value = 0,
  policyCounts = 0,
  hidePolicyCounts = false,
  hideLastUpdate = true,
  hideValue = false,
  titleClassName = '',
  titleFontWeight = 300,
  updatedDate,
}: {
  title?: string | undefined;
  value?: number | string | undefined | 0;
  policyCounts?: number | 0;
  hidePolicyCounts?: true | false;
  hideLastUpdate?: true | false;
  hideValue?: true | false;
  titleClassName?: string | undefined;
  titleFontWeight?: number | 300;
  updatedDate?: any;
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useTheme();
  return (
    <Box>
      <Grid className='ml-7'>
        <Typography
          fontWeight={titleFontWeight}
          fontSize={14}
          color={theme.palette.primary.main}
        >
          {title}
        </Typography>
        {hideValue === false && (
          <Grid
            item
            container
            flexWrap={'nowrap'}
            alignItems={'flex-end'}
            flexDirection={'row'}
          >
            <Grid item className='h-100'>
              <SvgConverter
                Icon={RupeeIcon}
                className={'d-flex align-items-end mb-4'}
              />
            </Grid>
            <Grid item>
              <Typography
                fontWeight={300}
                fontSize={28}
                lineHeight={1}
                className={`ml-6 ${titleClassName}`}
              >
                {value}
              </Typography>
            </Grid>
          </Grid>
        )}

        {hidePolicyCounts === false && (
          <Grid item>
            <Typography fontSize={14}>
              from{' '}
              <span
                className='fw-600'
                style={{ color: theme.palette.primary.main }}
              >
                {policyCounts}
              </span>{' '}
              <span style={{ color: theme.palette.primary.main }}>
                {policyCounts > 1 ? 'policies' : 'policy'}
              </span>
            </Typography>
          </Grid>
        )}

        {hideLastUpdate === false && (
          <Grid item>
            <Typography fontSize={10} color={theme.palette.grey[400]}>
              [Last Updated on {updatedDate}]
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PolicyCardCost;
