import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import Separator from '../../../common/ui/Separator';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material';
import '../SignUp/PersonalProfileScreen.css';
import UserProfileCard from '../../../common/ui/UserProfileCard';
import CustomButton from '../../../common/ui/CustomButton';
import FileUploader from '../../../common/ui/Forms/FormInput/FileUploader/FileUploader';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { Link } from 'react-router-dom';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as NotInformativeIcon } from '../../../common/icons/profile/i_icon.svg';
import { text } from 'stream/consumers';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserProfile,
  uploadOtherPhotoRequest,
  uploadProfilePhotoRequest,
  uploadSignaturePhotoRequest,
} from '../../App/Profile/Profile.slice';
import ImagePickerModal from '../../../common/ui/Modal/ImagePickerModal';
import { UploadType } from '../../../common/ui/Selectbox/Upload.saga';

interface PersonalDetailsProps {
  dob?: string | number;
  gender?: string;
  fatherName?: string | number;
  pan?: string | number;
  aadhar?: string | number;
  passport?: string | number;
  mobileNumber?: string | number;
  mobile2?: string | number;
  email?: string | number;
  assignedPolicy?: string | number;
  addSignature?: string | number;
  // items?: any;
  mobileNumberCode?: string | number;
  mobileCountryCode?: string | number;
  policyMobileNo: any;
  policyEmail: any;
  Images: any;
  authorizedRepresentativeMobileNo: string | number;
  authorizedRepresentative: string | number;
}
const PersonalDetails = ({
  dob,
  gender,
  fatherName,
  pan,
  aadhar,
  passport,
  mobileNumber,
  mobile2,
  email,
  assignedPolicy,
  addSignature,
  // items,
  mobileNumberCode,
  policyMobileNo,
  mobileCountryCode,
  policyEmail,
  Images,
  authorizedRepresentativeMobileNo,
  authorizedRepresentative,
}: PersonalDetailsProps) => {
  const [show, setShow] = React.useState(false);
  const [imageBase64, setImageBase64] = React.useState<string | null>(null);
  const [profileUri, setProfileUri] = React.useState<string | null>(null);
  const [signBase64, setSignImage64] = React.useState<string | null>(null);
  const [signUri, setSignUri] = React.useState<string | null>(null);
  const [uploadType, setUploadType] = React.useState<UploadType | null>(null);
  const [otherBase64, setOtherBase64] = React.useState<string | null>(null);
  const [otherUri, setOtherUri] = React.useState<string | null>(null);

  const theme = useTheme();
  const [image, setImage] = useState('');
  function handleFilesChange(newFiles: any) {
    var file = newFiles[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    var base64 = '';
    reader.onloadend = function () {
      if (reader.result) {
        base64 = (reader.result as string).replace(
          /^data:image\/\w+;base64,/,
          ''
        );
        setImage(base64);
      }
    };
  }

  const dispatch = useDispatch();
  const userProfile = useSelector(selectUserProfile);
  const [open, setOpen] = useState(false);
  const handleImage = () => {
    setOpen(true);
  };
  // const processImage = async (data: any) => {
  //   try {
  //     const imgType = uploadType;
  //     const file = data?.assets?.[0]?.file;
  //     const fileName = data?.assets?.[0]?.fileName;
  //     const maxWidth = data?.assets[0]?.width ?? 100;
  //     const maxHeight = data?.assets[0]?.height ?? 100;

  //     if (file && fileName) {
  //       const resizedFile = await resizeImage(file, maxWidth, maxHeight);

  //       if (imgType === UploadType.PROFILE) {
  //         setImageBase64(resizedFile.base64);
  //         setProfileUri(resizedFile.base64);
  //         dispatch(
  //           uploadProfilePhotoRequest({
  //             imgType,
  //             imageBase64: resizedFile.base64,
  //             fileName,
  //           } as any)
  //         );
  //       } else if (imgType === UploadType.SIGNATURE) {
  //         dispatch(
  //           uploadSignaturePhotoRequest({
  //             imgType,
  //             imageBase64: resizedFile.base64,
  //             fileName,
  //           } as any)
  //         );
  //         setSignImage64(resizedFile.base64);
  //         setSignUri(data?.assets?.[0]?.uri || '');
  //       } else if (imgType === UploadType.OTHER) {
  //         dispatch(
  //           uploadOtherPhotoRequest({
  //             imgType,
  //             imageBase64: resizedFile.base64,
  //             fileName,
  //           } as any)
  //         );
  //         setOtherBase64(resizedFile.base64);
  //         setOtherUri(data?.assets?.[0]?.uri || '');
  //       }
  //       setShow(false);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid item className='w-100'>
          <Separator color={theme.palette.primary.main} />
        </Grid>
        <Grid item className=' mt-10 mb-10'>
          <UserProfileCard
            profileUri={
              userProfile?.Customer?.profileImg
                ? 'data:image;base64,' + userProfile?.Customer?.profileImg
                : 'profileUri'
            }
            customerName={userProfile?.Customer?.customerName}
            eiaNum={userProfile?.Customer?.eiaNo}
            profileReadiness={userProfile?.Customer?.profileReadiness}
            lastUpdateDate={userProfile?.Customer?.lastUpdateDt}
          />
        </Grid>
        <Grid item className='w-100'>
          <Separator color={theme.palette.primary.main} />
        </Grid>

        <Grid item>
          <Grid item>
            <Typography className='f-14 fw-600' color={'primary'}>
              Authorized Representative
            </Typography>
          </Grid>
          <Grid item className='mt-10 ml-n3'>
            <Link to={APP_ROUTES.AUTHORISED_REPRESENTATIVE}>
              <CustomButton
                text={'tap to add ar'}
                variant='text'
                color={'primary'}
                showIcon={false}
              />
            </Link>
          </Grid>
        </Grid>

        <Grid item className='w-100'>
          <Separator color={theme.palette.primary.main} />
        </Grid>
        <Grid item container className='mt-20  mb-20'>
          <Grid item xs={2} className='mb-20 mt-10'>
            <Typography className='f-14 fw-600' color={'primary'}>
              Father's Name
            </Typography>
            <Typography className='f-18' color={theme.palette.common.black}>
              {fatherName}
            </Typography>
          </Grid>
          <Grid item xs={2} className='mb-20 mt-10'>
            <Typography className='f-14 fw-600' color={'primary'}>
              Date Of Birth
            </Typography>
            <Typography className='f-18' color={theme.palette.common.black}>
              {dob}
            </Typography>
          </Grid>
          <Grid item xs={1} className='mb-20 mt-10'>
            <Typography className='f-14 fw-600' color={'primary'}>
              Gender
            </Typography>
            <Typography className='f-18' color={theme.palette.common.black}>
              {gender}
            </Typography>
          </Grid>
          <Grid item xs={2} className='mb-20 mt-10'>
            <Typography className='f-14 fw-600' color={'primary'}>
              PAN
            </Typography>
            <Typography className='f-18' color={theme.palette.common.black}>
              {pan}
            </Typography>
          </Grid>
          <Grid item xs={2} className='mb-20 mt-10'>
            <Typography className='f-14 fw-600' color={'primary'}>
              Aadhar
            </Typography>
            <Typography className='f-18' color={theme.palette.common.black}>
              {aadhar}
            </Typography>
          </Grid>
          <Grid item xs={2} className='mb-20 mt-10'>
            <Typography className='f-14 fw-600' color={'primary'}>
              Passport
            </Typography>
            <Typography className='f-18' color={theme.palette.common.black}>
              {passport}
            </Typography>
          </Grid>
          <Grid item className='w-100'>
            <Separator color={theme.palette.primary.main} />
          </Grid>
        </Grid>
        <Grid item container className='mt-20 mb-20'>
          {mobileNumber && (
            <Grid item xs={2} className=' mb-14'>
              <Typography className='f-14 fw-600' color={'primary'}>
                Primary Mobile
              </Typography>
              <Grid container spacing={1} className='mt-1 ml-2'>
                <Typography className='f-18' color={theme.palette.common.black}>
                  {mobileNumberCode}
                </Typography>
                <Typography className='f-18' color={theme.palette.common.black}>
                  {mobileNumber}
                </Typography>
              </Grid>

              <Link to={APP_ROUTES.UPDATE_MOBILE}>
                <Typography className='f-12' color={'primary'}>
                  {'SEE ASSIGNED POLICIES'}
                </Typography>
              </Link>
            </Grid>
          )}
          {policyMobileNo &&
            policyMobileNo.map((item: any, index: any) => (
              <Grid item xs={2} className='mb-14'>
                <Typography
                  className='f-14 fw-600'
                  color={'primary'}
                  key={index}
                >
                  Mobile Number {index + 2}
                </Typography>
                <Grid container spacing={1} className='mt-2 ml-2'>
                  <Typography
                    className='f-18'
                    color={theme.palette.common.black}
                  >
                    {item.mobileCountryCode}
                  </Typography>
                  <Typography
                    className='f-18'
                    color={theme.palette.common.black}
                  >
                    {item.mobileNo}
                  </Typography>
                </Grid>

                <Link to={APP_ROUTES.UPDATE_MOBILE}>
                  <Typography className='f-12' color={'primary'}>
                    {'SEE ASSIGNED POLICIES'}
                  </Typography>
                </Link>
              </Grid>
            ))}
        </Grid>
        <Grid container>
          {email && (
            <Grid item xs={2} className='mb-14'>
              <Typography className='f-14 fw-600' color={'primary'}>
                Primary email
              </Typography>
              <Typography className='f-18' color={theme.palette.common.black}>
                {email}
              </Typography>
              <Typography className='f-12' color={'primary'}>
                <a href='#'>{'SEE ASSIGNED POLICIES'}</a>
              </Typography>
            </Grid>
          )}
          {policyEmail &&
            policyEmail.map((item: any, index: any) => (
              <Grid item xs={2} className='mb-14'>
                <Typography className='f-14 fw-600' color={'primary'}>
                  email{index + 2}
                </Typography>
                <Typography className='f-18' color={theme.palette.common.black}>
                  {item.email}
                </Typography>
                <Typography className='f-12' color={'primary'}>
                  <a href='#'>{'ASSIGNED POLICIES'}</a>
                </Typography>
              </Grid>
            ))}

          <Grid item className='w-100 mt-20 mb-10'>
            <Separator color={theme.palette.primary.main} />
          </Grid>
        </Grid>
        <Grid item className='mt-20 mb-20'>
          <Typography className='f-14 fw-600' color={'primary'}>
            Signature
          </Typography>

          {Images &&
            Images.map((item: any) => (
              <>
                {item.imgData && item.imgType === 'S' && (
                  <img
                    src={`data:image/png;base64,${item.imgData}`}
                    width={100}
                  />
                )}
              </>
            ))}
          <Typography className='f-16' color={'primary'}>
            {/* <FileUploader
              accepts={['image/png']}
              onFilesChange={handleFilesChange}
            > */}
            <CustomButton
              text={'TAP TO ADD SIGNATURE'}
              variant={'text'}
              fontSize={16}
              // iconClassName='ml-4'
              showIcon={false}
              fontWeight={300}
              onClick={handleImage}
            />
            <ImagePickerModal
              open={open}
              setOpen={setOpen}
              // handleImage={processImage}
            />
            {/* </FileUploader> */}
          </Typography>
        </Grid>
        <Grid item className='w-100 '>
          <Separator color={theme.palette.primary.main} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PersonalDetails;
