import { Grid } from '@mui/material';
import React from 'react';
import '../../Policy.scss';
import ArchiveLifeCard from '../ArchiveContent/ArchiveLifeCard';

interface UserProps {
  headerType?: string;
  contentType?: string;
  premiumDue?: string;
  filteredLife?: any;
}
export const ArchiveLife: React.FC<UserProps> = ({ filteredLife }) => {
  return (
    <Grid container justifyContent={'center'}>
      {filteredLife.map((data: any, index: any) => (
        <Grid item xs={4} key={index}>
          <ArchiveLifeCard
            contentType={'archive'}
            items={data}
            verificationStatus='Y'
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ArchiveLife;
