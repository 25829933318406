import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React from 'react';
import theme from '../../../../../common/theme';
import '../../Policy.scss';
import LabelWithIcon from '../../../../../common/ui/LabelWithIcon';
import { ReactComponent as BlackRupee } from '../../../../../common/icons/policy/black_rupee.svg';
import SvgConverter from '../../../../../common/SvgConverter';
import HDFC from '../../../../../assets/images/HDFC.png';
import Separator from '../../../../../common/ui/Separator';
import { ReactComponent as Premium } from '../../../../../common/icons/dashboard/pay_premiums-icon.svg';
import PolicyFooter from '../PolicyFooter/PolicyFooter';
import CustomButton from '../../../../../common/ui/CustomButton';
import { ReactComponent as Circle } from '../../../../../common/icons/dashboard/circle_arrow-icon.svg';
import AssetsHeader from '../PolicyCard/AssetsHeader';
import PremiumDue from '../PolicyCard/PremiumDue';
import PayNow from '../PolicyCard/PayNow';
interface UserProps {
  contentType?: string;
  items?: any;
  verificationStatus?: string;
}
const ActiveAssetsCard: React.FC<UserProps> = ({
  contentType,
  items,
  verificationStatus,
}) => {
  return (
    <Box className='policy-card'>
      <Grid container>
        <AssetsHeader items={items} contentType={contentType} />
        <Separator color={theme.palette.primary.main} />
        <PremiumDue items={items} contentType={contentType} />

        {items.paymentFlag === 'Y' ||
          (items.verifiedFlag === 'Y' && (
            <>
              <PayNow items={items} contentType={contentType} />
            </>
          ))}
        <Separator color={theme.palette.primary.main} />
        <PolicyFooter footerType={contentType} items={items} />
      </Grid>
    </Box>
  );
};

export default ActiveAssetsCard;
