import React from 'react';
import { Grid } from '@mui/material';
import '../Nominee.scss';
import Typography from '@mui/material/Typography';
import HDFC from '../../../../assets/images/HDFC.png';
import { Link } from 'react-router-dom';
import Separator from '../../../../common/ui/Separator';
import theme from '../../../../common/theme';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRupee } from '../../../../common/icons/orange_rupee.svg';
import { ReactComponent as checked } from '../../../../common/icons/profile/checked.svg';
import SvgConverter from '../../../../common/SvgConverter';
import { useSelector } from 'react-redux';
import { selectNomineeList } from '../Nominee.slice';
import { ReactComponent as alert } from '../../../../common/icons/Nominee-orange-alert-icon.svg';

const NomineeCardList = ({ nominees, overallPercentage, isVerified }: any) => {
  const NomineesList = useSelector(selectNomineeList);

  return (
    <>
      <Grid item className='nominee-form p-20'>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Grid item container spacing={0.5} flexWrap={'nowrap'}>
              <Typography
                fontSize={14}
                fontWeight={600}
                color={theme.palette.primary.main}
                className='mb-3'
              >
                Nominee
              </Typography>
              {isVerified? (
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <SvgConverter
                      Icon={checked}
                      width={'11px'}
                      height={'8px'}
                      className='mt-4'
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      className='f-14 mt-n4'
                      color={theme.palette.common.black}
                    >
                      Updated
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              ):(
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <SvgConverter
                      Icon={alert}
                      width={'4px'}
                      height={'13px'}
                      className='mb-5'
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      className='f-14 mt-n4'
                      color={theme.palette.common.black}
                    >
                      Under process
                    </Typography>
                  </Grid>
                </Grid>
              </Grid> 
              
              )}
            </Grid>
          </Grid>

          <Grid item className='ml-n30'>
            <Typography
              fontSize={16}
              fontWeight={600}
              color={theme.palette.primary.main}
              className='mb-5 mt-n3'
            >
              {overallPercentage}%
            </Typography>
          </Grid>
        </Grid>
        {nominees?.map((item: any, index: any) => (
          <Grid
            container
            justifyContent={'space-between'}
            key={index}
            className=''
          >
            <Grid item>
              <Grid item>
                <Typography
                  fontSize={18}
                  fontWeight={600}
                  color={theme.palette.common.black}
                >
                  {item?.nomineeFirstName}
                </Typography>
                <Typography
                  fontSize={14}
                  color={theme.palette.common.black}
                  className=''
                >
                  {item?.nomineeAge}
                </Typography>
                <Typography fontSize={14} color={theme.palette.common.black}>
                  {item?.relationshipName}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className='pr-10'>
              <Typography
                fontSize={18}
                fontWeight={600}
                color={theme.palette.common.black}
                className='mb-5'
              >
                {item?.nomineePercentage}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default NomineeCardList;
