import * as React from "react";
import Typography from "@mui/material/Typography";
import CustomButton from "../../../common/ui/CustomButton";
import theme from "../../../common/theme";
import { ReactComponent as Verify } from "../../../common/icons/update-mobile/verify_orange.svg";
import { ReactComponent as Cancel } from "../../../common/icons/update-mobile/cancel_orange.svg";
import { ReactComponent as Edit } from "../../../common/icons/edit.svg";
import { Grid } from "@mui/material";
import { CommonModal } from "../../../common/ui/Modal/CommonModal";
import Spinner from "../../../common/ui/Spinner";
interface UserProps {
  open?: any;
  setOpen?: any;
  contentType?: "EMAIL" | "MOBILE" | "ADDRESS" | "BANK";
  loading?: any;
  onSubmit?: any;
  onCancel?: any;
}

const DESCRIPTIONS = {
  EMAIL: "You have made changes in the Email ID assignments.",
  MOBILE: "You have made changes in the Mobile number assignments.",
  ADDRESS: "You have made changes in the Address assignments.",
  BANK: "You have made changes in the Bank assignments.",
};

export const DoneModal: React.FC<UserProps> = ({
  open,
  setOpen,
  contentType = "ADDRESS",
  loading,
  onSubmit,
  onCancel,
}) => {
  return (
    <CommonModal
      onClose={() => setOpen(false)}
      open={open}
      modalClassName="px-20 py-20"
      boxProps={{ width: 400 }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            className="f-16 fw-600 mb-10"
            color={theme.palette.primary.main}
          >
            {DESCRIPTIONS[contentType]}
          </Typography>
          <Typography
            className="f-16 fw-600"
            color={theme.palette.primary.main}
          >
            The changes will be forwarded to the insurance company and the
            official bank details will be updated.
          </Typography>
          <Grid
            container
            textAlign={"center"}
            justifyContent={"center"}
            className="mt-10"
          >
            {loading ? (
              <Grid item>
                <Spinner />
              </Grid>
            ) : (
              <>
                <Grid item xs={12} className="py-10">
                  <CustomButton
                    text={"submit changes"}
                    variant={"text"}
                    color={"primary"}
                    showIcon={false}
                    startIcon={Verify}
                    className="fw-600"
                    onClick={onSubmit}
                  />
                </Grid>
                <Grid item xs={12} className="py-8">
                  <CustomButton
                    text={"edit more"}
                    variant={"text"}
                    color={"primary"}
                    showIcon={false}
                    startIcon={Edit}
                    onClick={() => setOpen(false)}
                  />
                </Grid>
                <Grid item xs={12} className="py-8">
                  <CustomButton
                    text={"cancel & exit"}
                    variant={"text"}
                    color={"primary"}
                    showIcon={false}
                    startIcon={Cancel}
                    onClick={onCancel}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </CommonModal>
  );
};
