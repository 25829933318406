import { all } from "typed-redux-saga";
import { watchAuth } from "../features/Auth/auth.saga";
import { watchPolicies } from "../features/App/PolicyScreen/Policies.saga";
import { watchProfile } from "../features/App/Profile/Profile.saga";
import { watchMobileNumbers } from "../features/App/MobileNumber/MobileNumber.saga";
import { watchEmails } from "../features/App/Email/Emails.saga";
import { watchAddress } from "../features/App/AddressModule/Address.saga";
import { watchBanks } from "../features/App/BankModule/Bank.saga";
import { watchCommon } from "../features/App/Common/Common.saga";
import { watchNominee } from "../features/App/Nominee/Nominee.saga";
import { watchNotifications } from "../features/App/Notifiactions/Notifications.saga";
import { watchDashboard } from "../features/App/DashboardScreen/Dashboard.saga";
import { watchPayments } from "../features/App/Payment/Payment.saga";

function* rootSaga() {
  yield* all([
    watchAuth(),
    watchPolicies(),
    watchProfile(),
    watchMobileNumbers(),
    watchEmails(),
    watchAddress(),
    watchBanks(),
    watchCommon(),
    watchNominee(),
    watchNotifications(),
    watchDashboard(),
    watchPayments(),
  ]);
}

export default rootSaga;
