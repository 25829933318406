import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import SvgConverter from '../SvgConverter';

interface iconPropsInterface {
  width?: number | undefined;
  height?: number | undefined;
}

interface labelPropsInterface {
  color?: string | undefined;
  fontSize?: number | undefined;
  fontWeight?: number | undefined;
}

interface PropsInterface {
  Icon: any;
  label?: string;
  spacing?: number;
  iconProps?: iconPropsInterface | undefined;
  labelProps?: labelPropsInterface | undefined;
  flexDirection?: 'column' | 'row';
  justifyContent?: 'flex-start' | 'center';
  className?: string | undefined;
  svgClassName?: string | undefined;
}

const LabelWithIcon = ({
  Icon,
  label,
  spacing = 1,
  iconProps,
  labelProps,
  flexDirection = 'row',
  justifyContent = 'center',
  className,
  svgClassName,
}: PropsInterface) => {
  const theme = useTheme();

  return (
    <Grid
      container
      spacing={spacing}
      flexDirection={flexDirection}
      alignItems={'center'}
      justifyContent={justifyContent}
      className={`${className}`}
    >
      <Grid item>
        <Grid
          container
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ width: iconProps?.width, height: iconProps?.height }}
        >
          <Grid item>
            <SvgConverter Icon={Icon} className={`${svgClassName}`} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography
          color={labelProps?.color || theme.palette.common.black}
          fontSize={labelProps?.fontSize || 14}
          fontWeight={labelProps?.fontWeight}
        >
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LabelWithIcon;
