import { Grid, Typography } from '@mui/material';
import React from 'react';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as alert } from '../../../common/icons/profile/alert.svg';

const AddressNotKnown = () => {
  return (
    <Grid item>
      <Grid item className='f-12 mt-2 ml-2'  flexWrap={'nowrap'}>
        <SvgConverter Icon={alert} />
      </Grid>
    </Grid>
  );
};

export default AddressNotKnown;
