import React, { useState, useEffect } from 'react';
import { Grid, useTheme } from '@mui/material';
import './profile.css';
import FamilyDetailCard from './FamilyDetailsCard';
import { useDispatch, useSelector } from 'react-redux';
import {
  emailGenerateOtpLoading,
  emailVerifyOtpLoading,
  fetchUserFamilyListAction,
  mobileGenerateOtpLoading,
  mobileVerifyOtpLoading,
  saveFamilyEmailVerificationDetails,
  saveFamilyVerificationDetails,
  selectFamilyListIsLoading,
  selectUserFamilyList,
  selectUserFamilyListError,
  verifyGenerateOtpForEmailRequest,
  verifyGenerateOtpForMobileRequest,
  verifyOtpEmailRequest,
  verifyOtpMobileNoRequest,
} from './Profile.slice';
import Spinner from '../../../common/ui/Spinner';
import config from '../../../common/config';
import OtpEntryModal from '../Common/OtpEntryModal';
import EmailEntryModal from '../Common/EmailEntryModal';
import MobileEntryModal from '../Common/MobileEntryModal';
export const FamilyScreen = () => {
  const theme = useTheme();
  const familyDetails = [
    {
      relationship: 'mother',
      Name: 'First Name Last Name',
      isNominee: true,
      isAuthorisedPerson: true,
      iseIA: true,
      IsMobileNumber: true,
      isEmail: true,
      IsAddress: true,
    },
    {
      relationship: 'son',
      Name: 'First Name Last Name',
      isNominee: true,
      isAuthorisedPerson: false,
      iseIA: true,
      IsMobileNumber: true,
      isEmail: true,
      IsAddress: true,
    },
    {
      relationship: 'daughter',
      Name: 'First Name Last Name',
      isNominee: true,
      isAuthorisedPerson: false,
      iseIA: true,
      IsMobileNumber: true,
      isEmail: true,
      IsAddress: true,
    },
    {
      relationship: 'Father',
      Name: 'First Name Last Name',
      isNominee: false,
      isAuthorisedPerson: false,
      iseIA: false,
      IsMobileNumber: false,
      isEmail: false,
      IsAddress: false,
    },
    {
      relationship: 'Mother',
      Name: 'First Name Last Name',
      isNominee: false,
      isAuthorisedPerson: false,
      iseIA: false,
      IsMobileNumber: false,
      isEmail: false,
      IsAddress: false,
    },
  ];

  const FamilyMembers = [
    {
      relationName: 'Spouse',
      eiaNoFamily: '224323432',
      firstName: 'ABC',
      middleName: 'AAAA',
      lastName: 'LastName',
      imageBase64: '',
      email: 'abc@gmail.com',
      mobCountryCode: '91',
      mobNumber: '43294932',
      mobNoVerified: 'Y',
      DOB: '10/02/1999',
      address1: 'AAA',
      address2: 'AAA',
      address3: 'AAA',
      city: 'Chennai',
      state: 'TamilNadu',
      country: 'India',
      pin: '600084',
      authorizedPerson: '',
    },
    {
      relationName: 'Daughter',
      eiaNoFamily: ' ',
      firstName: 'ABC',
      middleName: 'AAAA',
      lastName: 'LastName',
      imageBase64: '',
      email: 'abc@gmail.com',
      mobCountryCode: '91',
      mobNumber: '43294932',
      mobNoVerified: 'N',
      DOB: '10/02/1999',
      address1: 'AAA',
      address2: 'AAA',
      address3: 'AAA',
      city: 'Chennai',
      state: 'TamilNadu',
      country: 'India',
      pin: '600084',
      authorizedPerson: '',
    },
  ];
  const dispatch = useDispatch();
  const isProfileLoading = useSelector(selectFamilyListIsLoading);
  const familyList = useSelector(selectUserFamilyList);
  const error = useSelector(selectUserFamilyListError);
  const isLoadingEmail = useSelector(emailGenerateOtpLoading);
  const isVerifyEmailLoading = useSelector(emailVerifyOtpLoading);
  const isLoadingMobile = useSelector(mobileGenerateOtpLoading);
  const isVerifyMobileLoading = useSelector(mobileVerifyOtpLoading);

  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showAddEmailModal, setShowAddEmailModal] = React.useState(false);
  const [showAddAddressModal, setShowAddAddressModal] = React.useState(false);
  const [showOtpModal, setShowOtpModal] = React.useState(false);
  const [showLabelText, setshowLabelText] = React.useState('');
  const [mobile, setMobile] = React.useState({});
  const [emailId, setEmailId] = useState<any>({});
  const [mobileData, setMobileData] = useState<any>({});
  const [emailData, setEmailData] = useState<any>({});
  const [newEmailData, setNewEmailData] = useState<any>({});
  const [verifyMobileEmailDuration, setVerifyMobileEmailDuration] =
    React.useState(0);
  useEffect(() => {
    dispatch(fetchUserFamilyListAction());
  }, [dispatch]);

  const onAddMobileNo = (data: any) => {
    setShowAddModal(true);
    setshowLabelText('Mobile');
    setMobileData(data);
  };
  const onAddEmail = (data: any) => {
    setShowAddEmailModal(true);
    setshowLabelText('Email');
    setEmailData(data);
  };
  const onAddNewAddress = (data: any) => {
    setShowAddAddressModal(false);
    setshowLabelText('Address');
  };
  const closeAllModals = () => {
    setShowAddModal(false);
    setShowAddEmailModal(false);
    setShowAddAddressModal(false);
    setShowOtpModal(false);
  };

  const verifyMobileNo = (data: any) => {
    setMobileData(data);
    if (data) {
      const mobileNo = data?.mobNumber;
      dispatch(verifyGenerateOtpForMobileRequest({ mobileNo } as any));
      setMobile(mobileNo);
      setShowOtpModal(true);
      setVerifyMobileEmailDuration(config.otpDuration);
    }
  };
  const verifyEmail = (data: any) => {
    setEmailData(data);
    if (data) {
      const email = data?.email;
      dispatch(verifyGenerateOtpForEmailRequest({ email } as any));
      setEmailId(email);
      // setMobile({});
      setShowOtpModal(true);
      setVerifyMobileEmailDuration(config.otpDuration);
    }
  };
  const handleSubmitMobile = (datas: any) => {
    setShowOtpModal(false);
    if (datas) {
      // setEmailData({});
      setMobile(datas?.mobileNo);
      let data: any = {
        mobileNo: datas?.mobileNo,
        // email: '',
      };
      data.handleSuccess = () => {
        setShowAddModal(false);
        setShowOtpModal(true);
        setVerifyMobileEmailDuration(config.otpDuration);
      };
      dispatch(verifyGenerateOtpForMobileRequest(data));
    }
  };
  const handleSubmitEmail = (datas: any) => {
    setShowOtpModal(false);
    if (datas) {
      //  console.log('email');
      //  setMobileData({});
      setEmailId(datas?.email);
      let data: any = {
        email: datas?.email,
        // mobileNo: '',
      };
      data.handleSuccess = () => {
        setShowAddEmailModal(false);
        setShowOtpModal(true);
        setVerifyMobileEmailDuration(config.otpDuration);
        // setMobile('');
      };
      dispatch(verifyGenerateOtpForEmailRequest(data));
    }
  };
  const handleMobileOtpVerify = (otpCode: any) => {
    if (otpCode) {
      if (mobile) {
        let data: any = {
          otpCode,
          mobileNo: mobile,
          seqId: mobileData?.seqId,
          mblNominee: mobileData?.nominee,
        };
        data.handleSuccess = () => {
          setShowOtpModal(false);
          dispatch(saveFamilyVerificationDetails(data));
        };
        dispatch(verifyOtpMobileNoRequest(data));
      }
    }
  };
  const handleEmailOtpVerify = (otpCode: any) => {
    if (otpCode) {
      console.log('otpppppp', otpCode);
      if (emailId) {
        console.log('emailid', emailId);
        let data: any = {
          otpCode,
          email: emailId,
          seqId: emailData?.seqId,
          emailNominee: emailData?.nominee,
        };
        data.handleSuccess = () => {
          setShowOtpModal(false);
          dispatch(saveFamilyEmailVerificationDetails(data));
        };
        dispatch(verifyOtpEmailRequest(data));
      }
    }
  };
  return (
    <>
      {isProfileLoading ? (
        <Grid item>
          <Spinner />
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {/* {familyDetails.map((data, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <FamilyDetailCard data={data} />
        </Grid>
      ))} */}
          {familyList.map((data, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <FamilyDetailCard
                data={data}
                addNewMobile={() => onAddMobileNo(data)}
                addNewEmail={() => onAddEmail(data)}
                onVerifyEmail={() => verifyEmail(data)}
                onVerifyMobile={() => verifyMobileNo(data)}
              />
            </Grid>
          ))}
        </Grid>
      )}

      <EmailEntryModal
        open={showAddEmailModal}
        setOpen={setShowAddEmailModal}
        onSubmit={handleSubmitEmail}
        loading={isLoadingEmail}
        value={emailId.email}
        handleCancel={closeAllModals}
      />
      <MobileEntryModal
        open={showAddModal}
        setOpen={setShowAddModal}
        onSubmit={handleSubmitMobile}
        handleCancel={closeAllModals}
        textLabel={'Mobile'}
        loading={isLoadingMobile}
      />
      <OtpEntryModal
        open={showOtpModal}
        setOpen={setShowOtpModal}
        loading={isVerifyEmailLoading}
        isEmail={true}
        onSubmit={handleEmailOtpVerify}
        handleCancel={closeAllModals}

        // isEmail={mobile ? mobile?.mobileNo : emailId ? emailId?.email : ''}
      />
      <OtpEntryModal
        open={showOtpModal}
        setOpen={setShowOtpModal}
        loading={isVerifyMobileLoading}
        isEmail={false}
        onSubmit={handleMobileOtpVerify}
        handleCancel={closeAllModals}
      />
    </>
  );
};
