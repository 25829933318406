import * as React from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import CustomButton from "../../../common/ui/CustomButton";
import theme from "../../../common/theme";
import "./Modal.scss";
import { Grid } from "@mui/material";
import { ReactComponent as Verify } from "../../../common/icons/update-mobile/verify_orange.svg";
import { ReactComponent as Cancel } from "../../../common/icons/update-mobile/cancel_orange.svg";
import { ReactComponent as Add_Mobile } from "../../../common/icons/update-mobile/Add_Mobile.svg";
import Typography from "@mui/material/Typography";
import CustomRadioGroup from "../Forms/FormInput/RadioButton/RadioGroup";
import Separator from "../Separator";
import { FC, ReactNode, useState } from "react";
interface boxPropsInterface {
  width?: number | undefined;
  height?: number | undefined;
}
interface ModalProps {
  boxProps?: boxPropsInterface | undefined;
  onClose?: () => void;
  open?: any;
  children?: React.ReactNode;
  modalClassName?: string | undefined;
}
export const CommonModal: FC<ModalProps> = ({
  boxProps,
  open,
  children,
  onClose,
  modalClassName,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        className={`common-style ${modalClassName}`}
        sx={{
          width: boxProps?.width,
          height: boxProps?.height,
          borderRadius: 3,
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};
