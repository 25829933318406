import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import theme from '../../../../../common/theme';
import '../../Policy.scss';
import Separator from '../../../../../common/ui/Separator';
import PolicyFooter from '../PolicyFooter/PolicyFooter';
import AssetsHeader from '../PolicyCard/AssetsHeader';
import PremiumDue from '../PolicyCard/PremiumDue';
interface UserProps {
  contentType?: string;
  items?: any;
  verificationStatus?: string;
}
const ArchiveAssetsCard: React.FC<UserProps> = ({
  contentType,
  items,
  verificationStatus,
}) => {
  return (
    <Box className='policy-card'>
      <Grid container>
        <AssetsHeader items={items} contentType={contentType} />
        <Separator color={theme.palette.primary.main} />
        <PremiumDue
          items={items}
          contentType={contentType}
          PolicyType={'assets'}
        />

        <Separator color={theme.palette.primary.main} />
        <PolicyFooter footerType={contentType} />
      </Grid>
    </Box>
  );
};

export default ArchiveAssetsCard;
