import { createSlice } from "@reduxjs/toolkit";
import { LoadingState } from "../../../common";
import { RootState } from "../../../common/store";

interface PaymentState {
  paymentDetails: {
    status: LoadingState;
    data: any;
    error: any;
  };
  uploadPaymentData: {
    status: LoadingState;
    data: any;
    error: any;
  };
}

const initialState: PaymentState = {
  paymentDetails: {
    status: "idle",
    data: undefined,
    error: undefined,
  },
  uploadPaymentData: {
    status: "idle",
    data: undefined,
    error: undefined,
  },
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    fetchPaymentDetailsRequest: (state, _action) => {
      state.paymentDetails.status = "loading";
      state.paymentDetails.data = undefined;
      state.paymentDetails.error = undefined;
    },
    paymentDetailsSuccess: (state, { payload }) => {
      state.paymentDetails.status = "done";
      state.paymentDetails.data = payload;
      state.paymentDetails.error = undefined;
    },
    paymentDetailsError: (state, { payload }) => {
      state.paymentDetails.status = "error";
      state.paymentDetails.data = undefined;
      state.paymentDetails.error = payload;
    },
    resetPaymentDetailsRequest: (state, _action) => {
      state.paymentDetails.status = "idle";
      state.paymentDetails.data = undefined;
      state.paymentDetails.error = undefined;
    },
    resetPaymentDetails: (state) => {
      state.paymentDetails.status = "idle";
      state.paymentDetails.data = undefined;
      state.paymentDetails.error = undefined;
    },

    uploadPaymentDetailsRequest: (state, _action) => {
      state.uploadPaymentData.status = "loading";
      state.uploadPaymentData.data = undefined;
      state.uploadPaymentData.error = undefined;
    },
    uploadPaymentDetailsSuccess: (state, { payload }) => {
      state.uploadPaymentData.status = "done";
      state.uploadPaymentData.data = payload;
      state.uploadPaymentData.error = undefined;
    },
    uploadPaymentDetailsError: (state, { payload }) => {
      state.uploadPaymentData.status = "error";
      state.uploadPaymentData.data = undefined;
      state.uploadPaymentData.error = payload;
    },
    resetUploadPaymentDetails: (state) => {
      state.uploadPaymentData.status = "idle";
      state.uploadPaymentData.data = undefined;
      state.uploadPaymentData.error = undefined;
    },
  },
});

export const {
  fetchPaymentDetailsRequest,
  paymentDetailsSuccess,
  paymentDetailsError,
  resetPaymentDetails,
  uploadPaymentDetailsRequest,
  uploadPaymentDetailsSuccess,
  uploadPaymentDetailsError,
  resetUploadPaymentDetails,
  resetPaymentDetailsRequest,
} = paymentSlice.actions;
export default paymentSlice.reducer;

export const selectIsPaymentDetailsLoading = (state: RootState) =>
  state.payment.paymentDetails.status === "loading";

export const selectIsPaymentDetailsFailed = (state: RootState) =>
  state.payment.paymentDetails.status === "error";

export const selectPaymentDetails = (state: RootState) =>
  state.payment.paymentDetails.data;

export const selectIsUploadPaymentDataLoading = (state: RootState) =>
  state.payment.uploadPaymentData.status === "loading";

export const selectUploadPaymentData = (state: RootState) =>
  state.payment.uploadPaymentData.data;
