import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid/Grid";

import {
  changeEmail,
  fetchPoliciesForEmails,
  generateOtpForEmailRequest,
  isGenerateOtpForEmailLoading,
  isUpdateEmailLoading,
  removeEmail,
  selectEmailChangesList,
  selectIsEmailVerifyLoading,
  selectIsPolicyEmailsLoading,
  selectPoliciesForEmails,
  setEmailChangesList,
  updateEmailChangesRequest,
  verifyEmailRequest,
} from "./Emails.slice";
import { useDispatch, useSelector } from "react-redux";
import { Typography, useTheme } from "@mui/material";
import CustomButton from "../../../common/ui/CustomButton";
import { ReactComponent as rightArrow } from "../../../common/icons/right_arrow-icon.svg";
import { ReactComponent as EmailIcon } from "../../../common/icons/email-small.svg";
import ManageEmailHeader from "./ManageEmailHeader";
import { setAlertInfo } from "../Common/Common.slice";
import config from "../../../common/config";
import OtpEntryModal from "../Common/OtpEntryModal";
import EmailEntryModal from "../Common/EmailEntryModal";
import { useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../common/constants/Routes";
import { SELECT_POLICY_TYPES_FOR_MODULES } from "../../../common/Enums";
import {
  fetchOtpRequest,
  selectIsOtpRequestLoading,
  selectIsUserLoggedIn,
} from "../../Auth/auth.slice";
import { DoneModal } from "../Common/DoneModal";
import Spinner from "../../../common/ui/Spinner";
import ChangeEmailModal from "./Modals/ChangeEmailModal";
import RemoveEmailModal from "./Modals/RemoveEmailModal";
import ConfirmationModal from "../../../common/ui/Modal/ConfirmationModal";

const ManageEmailScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDoneModal, setShowDoneModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showRemoveNumberModal, setShowRemoveNumberModal] = useState(false);
  const [showChangeNumberModal, setShowChangeNumberModal] = useState(false);
  const [selectedPolicyDetails, setSelectedPolicyDetails] = useState({});
  const [selectedEmail, setSelectedEmail] = useState({});
  const [verifyEmailDuration, setVerifyEmailDuration] = useState(0);
  const [newEmailData, setNewEmailData] = useState<any>({});
  const [isInstantVerifyRunning, setIsInstantVerifyRunning] = useState(false);
  const [removeUnAssignedEmail, setRemoveUnAssignedEmail] = useState(false);
  const [isPreSelectedPolicyType, setIsPreSelectedPolicyType] =
    useState<any>(false);

  const policiesForEmail = useSelector(selectPoliciesForEmails);
  const isGenerateOtpIsLoading = useSelector(isGenerateOtpForEmailLoading);
  const isResendOtpLoading = useSelector(selectIsOtpRequestLoading);
  const isVerifyEmailLoading = useSelector(selectIsEmailVerifyLoading);
  const emailChangesList = useSelector(selectEmailChangesList);
  const isUpdateEmailIsLoading = useSelector(isUpdateEmailLoading);
  const loggedUser = useSelector(selectIsUserLoggedIn);

  const toggleAddNumberModal = (
    isPoliciesPreSelected: boolean,
    isChange: boolean
  ) => {
    if (isPoliciesPreSelected === true) {
      setIsPreSelectedPolicyType(isChange ? "CHANGE" : "REMOVE");
    } else {
      setIsPreSelectedPolicyType(false);
    }
    setShowAddModal(!showAddModal);
    setShowRemoveNumberModal(false);
    setShowChangeNumberModal(false);
  };

  const toggleDoneModal = () => {
    if (emailChangesList?.length > 0) {
      setShowDoneModal(!showDoneModal);
    } else {
      navigate(-1);
    }
    return true;
  };

  const toggleRemoveNumberModal = (email: string) => {
    setSelectedEmail(email);
    setShowRemoveNumberModal(!showRemoveNumberModal);
  };
  const toggleChangeEmailModal = (data: any) => {
    setSelectedPolicyDetails(data);
    setShowChangeNumberModal(!showChangeNumberModal);
  };

  const toggleOtpModal = () => {
    setShowAddModal(false);
    setShowOtpModal(!showOtpModal);
  };

  const closeAllModals = () => {
    setShowAddModal(false);
    setShowDoneModal(false);
    setShowOtpModal(false);
    setShowRemoveNumberModal(false);
    setShowChangeNumberModal(false);
  };

  const isPoliciesForEmailsLoading = useSelector(selectIsPolicyEmailsLoading);

  useEffect(() => {
    if (emailChangesList?.length === 0) dispatch(fetchPoliciesForEmails());
  }, [dispatch, emailChangesList]);

  const handleOtpVerify = (otpCode: any) => {
    let data: any = { otpCode, email: newEmailData?.email };
    data.handleSuccess = () => {
      if (isPreSelectedPolicyType === "CHANGE") {
        setShowOtpModal(false);
        setIsPreSelectedPolicyType(false);
        dispatch(
          changeEmail({
            selectedPolicy: selectedPolicyDetails,
            targetEmail: newEmailData?.email,
          })
        );
      } else if (isPreSelectedPolicyType === "REMOVE") {
        setShowOtpModal(false);
        dispatch(
          removeEmail({
            newValue: newEmailData?.email,
            oldValue: selectedEmail,
          })
        );
      } else {
        setShowAddModal(false);
        const { handleSuccess, ...newEmailDataLocal }: any = newEmailData;
        if (isInstantVerifyRunning !== true) {
          navigate(APP_ROUTES.ASSIGN_POLICY, {
            state: {
              newEmailData: newEmailDataLocal,
              MODULE_TYPE: SELECT_POLICY_TYPES_FOR_MODULES.EMAIL,
            },
          });
          setIsInstantVerifyRunning(false);
        }
        setShowOtpModal(false);
      }
    };
    dispatch(verifyEmailRequest(data));
  };

  const handleEmailChangesSubmit = () => {
    const handleSuccess: any = () => {
      setShowDoneModal(false);
      navigate(-1);
    };
    dispatch(updateEmailChangesRequest({ handleSuccess } as any));
  };

  const handleNewEmailSubmit = (data: any) => {
    if (policiesForEmail?.unAssignedEmails?.length <= 5) {
      setNewEmailData(data);
      data.handleSuccess = () => {
        setShowAddModal(false);
        setShowOtpModal(true);
        setVerifyEmailDuration(config.otpDuration);
      };
      dispatch(generateOtpForEmailRequest(data));
    } else {
      setShowAddModal(false);
      dispatch(
        setAlertInfo({
          open: true,
          type: "FAILED",
          description: "Only 5 unassigned Emails are allowed.",
        })
      );
    }
  };

  const handleResendOtp = () => {
    const handleSuccess = () => {
      setVerifyEmailDuration(config.otpDuration);
    };
    let data: any = {
      eiaNo: loggedUser?.eiaNo,
      value: newEmailData?.email,
      otpPurpose: "UPDATE_EMAIL",
      flag: "EMAIL",
    };
    dispatch(
      fetchOtpRequest({
        Customer: data,
        handleSuccess,
      })
    );
  };

  const handleCancel = () => {
    navigate(-1);
    dispatch(setEmailChangesList([]));
  };

  const handleRemoveForUnAssignedMobiles = () => {
    const handleDone = () => {
      setRemoveUnAssignedEmail(false);
    };
    let payload = {
      oldValue: removeUnAssignedEmail,
      isUnAssignedEmail: true,
      handleDone,
    };
    dispatch(removeEmail(payload));
  };

  const handleInstantVerify = (email: string) => {
    setIsInstantVerifyRunning(true);
    closeAllModals();
    setNewEmailData({ email });
    setShowOtpModal(true);
    dispatch(generateOtpForEmailRequest({ email } as any));
    setVerifyEmailDuration(config.otpDuration);
  };

  const handleAssignPolicy = (data: any) => {
    navigate(APP_ROUTES.ASSIGN_POLICY, {
      state: {
        newEmailData: data,
        MODULE_TYPE: SELECT_POLICY_TYPES_FOR_MODULES.EMAIL,
        isOld: true,
      },
    });
  };

  return (
    <Grid item className="ml-50 mr-50 mb-50">
      <Grid item xs={12} justifyContent="center">
        <Grid container justifyContent="space-between">
          <Typography
            className="f-16  fw-600"
            color={theme.palette.primary.main}
          >
            Manage Email
          </Typography>

          <CustomButton
            text={"DONE"}
            variant={"text"}
            color={"primary"}
            showIcon={true}
            fontSize={14}
            onClick={toggleDoneModal}
            endIcon={rightArrow}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-10">
        <CustomButton
          text={"Add a Email"}
          variant={"text"}
          color={"primary"}
          showIcon={false}
          onClick={() => setShowAddModal(true)}
          startIcon={EmailIcon}
        />
      </Grid>
      {isPoliciesForEmailsLoading ? (
        <Grid container justifyContent={"center"}>
          <Grid item>
            <Spinner />
          </Grid>
        </Grid>
      ) : (
        <ManageEmailHeader
          toggleRemoveNumberModal={toggleRemoveNumberModal}
          toggleChangeEmailModal={toggleChangeEmailModal}
          handleAssignPolicy={handleAssignPolicy}
          handleRemoveForUnAssignedEmails={(email: any) =>
            setRemoveUnAssignedEmail(email)
          }
        />
      )}

      <EmailEntryModal
        open={showAddModal}
        setOpen={setShowAddModal}
        onSubmit={handleNewEmailSubmit}
        loading={isGenerateOtpIsLoading}
      />
      <OtpEntryModal
        isEmail={true}
        value={newEmailData.email}
        open={showOtpModal}
        setOpen={setShowOtpModal}
        onSubmit={handleOtpVerify}
        loading={isVerifyEmailLoading}
        onFinishDuration={() => setVerifyEmailDuration(0)}
        duration={verifyEmailDuration}
        resendLoading={isResendOtpLoading}
        handleResendOtp={handleResendOtp}
      />
      <DoneModal
        open={showDoneModal}
        setOpen={setShowDoneModal}
        onSubmit={handleEmailChangesSubmit}
        loading={isUpdateEmailIsLoading}
        onCancel={handleCancel}
        contentType={"EMAIL"}
      />
      <ChangeEmailModal
        open={showChangeNumberModal}
        setOpen={setShowChangeNumberModal}
        selectedPolicyDetails={selectedPolicyDetails}
        toggleAddNumberModal={toggleAddNumberModal}
      />
      <RemoveEmailModal
        open={showRemoveNumberModal}
        setOpen={setShowRemoveNumberModal}
        selectedEmail={selectedEmail}
        toggleAddNumberModal={toggleAddNumberModal}
      />
      <ConfirmationModal
        show={Boolean(removeUnAssignedEmail)}
        setShow={setRemoveUnAssignedEmail}
        description={`Are you sure to remove ${removeUnAssignedEmail} from unassigned mobiles?`}
        confirmText={"Remove"}
        cancelText={"Cancel"}
        onConfirm={handleRemoveForUnAssignedMobiles}
        onCancel={() => setRemoveUnAssignedEmail(false)}
      />
    </Grid>
  );
};
export default ManageEmailScreen;
