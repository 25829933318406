import React, { useState, useMemo, useEffect } from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import SvgConverter from "../../../common/SvgConverter";
import HdfcIcon from "../../../assets/images/HDFC.png";
import Camspay from "../../../common/icons/dashboard/camspay.png";
import RazorpayIcon from "../../../common/icons/dashboard/razorpay.png";

import Separator from "../../../common/ui/Separator";
import { ReactComponent as RupeeIcon } from "../../../common/icons/LoanModule/rupee-icon.svg";
import LabelWithIcon from "../../../common/ui/LabelWithIcon";
import { ReactComponent as correct } from "../../../common/icons/LoanModule/right-arrow-icon.svg";
import { ReactComponent as rightArrow } from "../../../common/icons/right_arrow-icon.svg";
import RadioButton from "../LoanModule/RadioButtonDetails";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ReactComponent as cancel } from "../../../common/icons/cancel-icon.svg";
import useRazorpay from "react-razorpay";
import CustomButton from "../../../common/ui/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserProfile,
  selectIsLoading,
  selectUserProfile,
} from "../Profile/Profile.slice";
import {
  fetchPaymentDetailsRequest,
  selectIsPaymentDetailsFailed,
  selectIsPaymentDetailsLoading,
  selectPaymentDetails,
} from "./Payment.slice";
import { selectIsUserLoggedIn } from "../../Auth/auth.slice";
import { useLocation, useNavigate } from "react-router-dom";
import {
  convertAmount,
  generateTransactionIDForRazorPay,
} from "../../../common/utils";
import {
  CAMSPAY_FEEDBACKS_URL,
  PAYMENT_GATEWAY_TYPES,
  PAYMENT_STATUS,
} from "../../../common/Enums";
import { EncryptCamsPayRequest } from "../../../common/CamsPayEncDec";
import dayjs from "dayjs";
import Spinner from "../../../common/ui/Spinner";
import { APP_ROUTES } from "../../../common/constants/Routes";

const PaymentScreen = () => {
  const Razorpay = useRazorpay();
  const theme = useTheme();
  const [selectedGateway, setSelectedGateway] = useState<any>(false);
  const dispatch = useDispatch();
  const isProfileDataLoading = useSelector(selectIsLoading);
  const isPaymentDetailsLoading = useSelector(selectIsPaymentDetailsLoading);
  const paymentDetails = useSelector(selectPaymentDetails);
  const userProfile = useSelector(selectUserProfile);
  const userLoggedData = useSelector(selectIsUserLoggedIn);
  const isPaymentDetailsError = useSelector(selectIsPaymentDetailsFailed);

  const location = useLocation();
  const navigate = useNavigate();

  const policyData = useMemo(
    () => (location?.state?.data ? location?.state?.data : {}),
    [location?.state?.data]
  );

  const isICICI = useMemo(
    () => Boolean(policyData?.companyCode == 10),
    [policyData]
  );

  useEffect(() => {
    if (!userProfile?.Customer?.eiaNo) {
      dispatch(fetchUserProfile());
    }
  }, [dispatch]);

  useEffect(() => {
    if (userProfile?.Customer?.customerName && isICICI) {
      fetchPaymentDetails();
    }
  }, [userProfile, isICICI]);

  const fetchPaymentDetails = async () => {
    const payload = {
      transactionId: generateTransactionIDForRazorPay(userLoggedData?.eiaNo),
      eiaNo: userProfile?.Customer?.eiaNo,
      policyNo: policyData?.policyNo,
      customerName: String(userProfile?.Customer?.customerName)
        .trim()
        .replace(" ", ""),
      emailId: userProfile?.Customer?.email,
      mobileNo: userProfile?.Customer?.mobileNo,
      insuranceCompCode: policyData?.companyCode,
    };
    dispatch(fetchPaymentDetailsRequest(payload));
  };

  const handlePay = () => {
    const paymentData = {
      description: "Pay policy premium Amount",
      image: null,
      currency: "INR",
      transactionId: paymentDetails?.transactionId,
      amount: Number(paymentDetails?.premiumAmount)
        ? Number(paymentDetails?.premiumAmount)
        : 0,
      prefill: {
        email: userProfile?.Customer?.email,
        contact: userProfile?.Customer?.mobileNo,
        name: userProfile?.Customer?.customerName,
      },
      policyData,
    };
    switch (selectedGateway as any) {
      case PAYMENT_GATEWAY_TYPES.RAZORPAY:
        initiateRazorPay(paymentData);
        break;

      case PAYMENT_GATEWAY_TYPES.CAMSPAY:
        initiateCamsPay(paymentData);
        break;

      default:
        alert("Invalid Payment Gateway!");
        break;
    }
  };

  useEffect(() => {
    if (isICICI) {
      setSelectedGateway(PAYMENT_GATEWAY_TYPES.RAZORPAY);
    } else {
      setSelectedGateway(PAYMENT_GATEWAY_TYPES.CAMSPAY);
    }
  }, [isICICI]);

  useEffect(() => {
    clearPreviousPaymentData();
  }, []);

  const clearPreviousPaymentData = () => {};

  const initiateRazorPay = async (paymentData: any) => {
    try {
      console.log(paymentData);

      const options = {
        key: process.env.REACT_APP_RAZOR_PAY_API_KEY, // Enter the Key ID generated from the Dashboard
        currency: paymentData.currency,
        amount: Math.ceil(
          (paymentData?.amount > 1 ? paymentData?.amount : 1) * 100
        ),
        name: "BIMA CENTRAL",
        description: paymentData?.description,
        image: paymentData?.image
          ? paymentData?.image
          : "https://i.imgur.com/3g7nmJC.png",
        order_id: paymentDetails.rzpOrderId,
        handler: function (paymentResponse: any) {
          const payload = {
            gateWayType: PAYMENT_GATEWAY_TYPES.RAZORPAY,
            paymentResponse: {
              ...paymentResponse,
              transactionId: paymentData?.transactionId,
              order_id: paymentDetails.rzpOrderId,
            },
            status: PAYMENT_STATUS.SUCCESS,
            paymentData,
          };
          navigate(APP_ROUTES.PAYMENT_SUCCESS, { state: payload });
        },
        prefill: {
          email: paymentData?.prefill.email,
          contact: "+91" + paymentData?.prefill.contact,
          name: paymentData?.prefill.name,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },

        theme: { color: "#F37254" },
      };
      const rzp1 = new Razorpay(options as any);
      rzp1.on("payment.failed", function (errorResponse: any) {
        const payload = {
          gateWayType: PAYMENT_GATEWAY_TYPES.RAZORPAY,
          paymentResponse: {
            ...errorResponse,
            transactionId: paymentData?.transactionId,
            order_id: paymentDetails.rzpOrderId,
          },
          status: PAYMENT_STATUS.ERROR,
          paymentData,
        };
        navigate(APP_ROUTES.PAYMENT_FAILED, {
          state: { ...payload, ...paymentData },
        });
      });

      rzp1.open();
    } catch (error) {
      console.log(error);
    }
  };

  const initiateCamsPay = async (paymentData: any) => {
    try {
      const data = {
        custid: "senthil@gmail.com",
        trxnid: Math.floor(100000 + Math.random() * 90000000000000),
        amount: "50.00",
        accno: "xxxxxxxxxxxxx",
        remarks: "test transaction",
        ifsc: "xxxxxxxxxxx",
        currency: "INR",
        successurl: CAMSPAY_FEEDBACKS_URL.SUCCESS,
        failureurl: CAMSPAY_FEEDBACKS_URL.FAILED,
        reqdt: dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss"),
        devicetype: "WEB",
        schemes: {
          tt: "P",
          scount: "1",
          sdtl: [
            {
              ac: "P",
              sc: "dht",
              amt: "1.00",
            },
          ],
        },
        // --- ETC ---
        applicationname: "React Native",
      };
      const encryptedData: any = await EncryptCamsPayRequest(data);
      autoCamsForm(encryptedData);
    } catch (error) {
      console.log(error);
    }
  };

  function autoCamsForm(data: any) {
    var form = document.createElement("form");
    var input1 = document.createElement("input");
    var input2 = document.createElement("input");
    var input3 = document.createElement("input");

    form.method = "POST";
    form.action = "https://uat.camspay.com/api/v1/pgtrxn";

    input1.value = data.id;
    input1.name = "id";
    form.appendChild(input1);

    input2.value = data.req;
    input2.name = "req";
    form.appendChild(input2);

    input3.value = data.checksum;
    input3.name = "checksum";
    form.appendChild(input3);

    document.body.appendChild(form);

    form.submit();
  }

  return (
    <Grid container justifyContent={"center"}>
      <Grid item className="cal-payment-screen">
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>
            <Typography className="f-16 fw-600 mt-n10" color={"primary"}>
              Premium Payment
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={0.5}>
              <Grid item className="mt-n2">
                <Typography className="f-14" color={"primary"}>
                  Close
                </Typography>
              </Grid>
              <Grid item>
                <SvgConverter Icon={cancel} width={23} height={23} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="my-10">
          <Separator color={theme.palette.grey[300]} />
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          className="mb-15"
          wrap={"nowrap"}
        >
          <Grid item>
            <Grid item>
              <Typography
                className="f-14 fw-600"
                color={theme.palette.primary.main}
              >
                {policyData.companyName}
              </Typography>

              <Typography className="f-12" color={theme.palette.common.black}>
                Policy# {policyData?.policyNo}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="f-12" color={theme.palette.primary.main}>
                Life Assured
              </Typography>
              <Typography className="f-14" color={theme.palette.common.black}>
                {policyData.customerName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="f-12" color={theme.palette.primary.main}>
                Sum Assured
              </Typography>
              <Grid container alignItems={"center"} spacing={0.5}>
                <Grid item>
                  <SvgConverter Icon={RupeeIcon} />
                </Grid>
                <Grid item>
                  <Typography
                    className="f-14 fw-600"
                    color={theme.palette.common.black}
                  >
                    {convertAmount(policyData.sumAssured)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="mt-10">
            <img
              src={policyData?.companyLogo}
              alt="logo"
              width={"51"}
              height={"32"}
            />
          </Grid>
        </Grid>
        <Separator color={theme.palette.grey[300]} />
        <Grid item className="mt-10 ">
          <Typography className="f-14" color={theme.palette.primary.main}>
            Renewal Premium
          </Typography>
          {isProfileDataLoading || isPaymentDetailsLoading ? (
            <Spinner size={20} />
          ) : (
            <Grid container spacing={0.5} alignItems={"center"}>
              <Grid item>
                <SvgConverter Icon={RupeeIcon} width={"7"} height={"12"} />
              </Grid>
              <Grid item>
                <Typography
                  className="f-28 fw-600"
                  color={theme.palette.common.black}
                >
                  {convertAmount(paymentDetails?.premiumAmount)}
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid item className="mt-10">
            <Separator color={theme.palette.grey[300]} />
          </Grid>

          {/* <Grid container className="payment-options mt-8">
            <Grid item className="mb-10">
              <Typography className="f-12" color={theme.palette.primary.main}>
                Select a Payment Gateway
              </Typography>
            </Grid>
            <FormControl>
              <RadioGroup value={selectedValue} onChange={handleChange}>
                <FormControlLabel
                  value="camspay"
                  control={<Radio size="small" />}
                  label={
                    <img src={Camspay} alt="logo" width={"123"} height={"25"} />
                  }
                />
                <FormControlLabel
                  value="razorpay"
                  control={<Radio size="small" />}
                  label={
                    <img
                      src={RazorpayIcon}
                      alt="logo"
                      width={"143"}
                      height={"31"}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item className="mt-10">
            <Separator color={theme.palette.grey[300]} />
          </Grid> */}
          <Grid container justifyContent={"space-between"} className="mt-10">
            <Grid item>
              <Typography className="f-14" color={theme.palette.primary.main}>
                CANCEL
              </Typography>
            </Grid>
            <Grid item>
              <CustomButton
                text={"PAY"}
                variant={"text"}
                color={"primary"}
                showIcon={false}
                fontSize={14}
                onClick={handlePay}
                endIcon={rightArrow}
                loading={isProfileDataLoading || isPaymentDetailsLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="mt-10">
          <Separator color={theme.palette.grey[300]} />
        </Grid>
        <Grid item className="mt-15">
          <Typography className="f-12" color={theme.palette.primary.main}>
            Terms & Conditions
          </Typography>
          <Typography className="f-12" color={theme.palette.grey.A700}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
            euismod bibendum laoreet. Proin gravida dolor sit amet lacus
            accumsan et viverra justo commodo. Proin sodales pulvinar sic
            tempor. Sociis natoque penatibus et magnis dis parturient montes,
            nascetur ridiculus mus.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentScreen;
