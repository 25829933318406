import React, { useEffect, useMemo } from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import CustomRadioGroup from "../../../../common/ui/Forms/FormInput/RadioButton/RadioGroup";
import { CommonModal } from "../../../../common/ui/Modal/CommonModal";
import CustomButton from "../../../../common/ui/CustomButton";
import Separator from "../../../../common/ui/Separator";
import { ReactComponent as AddMobileIcon } from "../../../../common/icons/update-mobile/Add_Mobile.svg";
import { ReactComponent as Cancel } from "../../../../common/icons/update-mobile/cancel_orange.svg";
import { ReactComponent as Verify } from "../../../../common/icons/update-mobile/verify_orange.svg";
import {
  getMobileNoList,
  removeMobileNumber,
  selectPoliciesForMobileNumbers,
} from "../MobileNumber.slice";
import { useDispatch, useSelector } from "react-redux";

const RemoveNumberModal = ({
  open,
  setOpen,
  selectedMobileNo,
  toggleAddNumberModal,
}: any) => {
  const theme = useTheme();
  const [selectedOption, setSelectedOption] = React.useState<any>(false);
  const mobilePolicies = useSelector(selectPoliciesForMobileNumbers);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!open) setSelectedOption(false);
  }, [open]);

  const mobileNumbers = useMemo(
    () =>
      getMobileNoList({ mobileNo: selectedMobileNo }, mobilePolicies).map(
        (val) => ({
          value: val,
          label: val,
        })
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      mobilePolicies.mobileBasedPolicies,
      mobilePolicies.unAssignedMobiles,
      selectedMobileNo,
    ]
  );

  const handleSubmit = () => {
    dispatch(
      removeMobileNumber({
        newValue: selectedOption,
        oldValue: selectedMobileNo,
      })
    );
    setOpen(false);
  };

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
  };

  return (
    <CommonModal
      onClose={() => setOpen(false)}
      open={open}
      modalClassName="px-20 py-20"
      boxProps={{ width: 354 }}
    >
      <Grid item>
        <Grid item>
          <Typography
            className="f-16 fw-600"
            color={theme.palette.primary.main}
          >
            To remove the phone number, please select a phone number to assign
            the policies.
          </Typography>

          <Grid container>
            <Grid item xs={12} className="my-25">
              <CustomRadioGroup
                name="RadioGroup"
                options={mobileNumbers}
                value={selectedOption}
                onChange={handleOptionChange}
                size="small"
              />
            </Grid>
            <Grid item xs={12} className="py-2">
              <Typography
                className="f-16 fw-600"
                color={theme.palette.primary.main}
              >
                OR
              </Typography>
            </Grid>
          </Grid>
          <Grid item className="py-10">
            <CustomButton
              text={"ADD MOBILE NUMBER"}
              variant={"text"}
              color={"primary"}
              showIcon={false}
              onClick={() => toggleAddNumberModal(true, false)}
              fontSize={14}
              startIcon={AddMobileIcon}
            />
          </Grid>

          <Grid item className="py-5">
            <Separator color={theme.palette.primary.main} borderWidth={2} />
          </Grid>
          <Grid container textAlign={"center"}>
            <Grid item xs={12} className="py-10 ">
              <CustomButton
                text={"remove number"}
                variant={"text"}
                color={"primary"}
                showIcon={false}
                onClick={handleSubmit}
                fontSize={16}
                fontWeight={600}
                startIcon={Verify}
              />
            </Grid>
            <Grid item xs={12} className="py-10">
              <CustomButton
                text={"Cancel"}
                variant={"text"}
                color={"primary"}
                showIcon={false}
                startIcon={Cancel}
                onClick={() => setOpen(false)}
                fontSize={16}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default RemoveNumberModal;
