import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import CustomButton from "../../../common/ui/CustomButton";
import { ReactComponent as rightArrow } from "../../../common/icons/right_arrow-icon.svg";
import ManageAddressHeader from "./ManageAddressHeader";
import "./Address.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAddress,
  fetchPoliciesForAddress,
  getAddressEkycUrlRequest,
  removeAddress,
  saveAddressEkycRequest,
  selectAddressChangesList,
  selectIsPolicyAddressLoading,
  selectIsUpdateAddressLoading,
  selectPoliciesForAddress,
  setAddressChangesList,
  updateAddressChangesRequest,
} from "./Address.slice";
import { find, propEq } from "ramda";
import { APP_ROUTES } from "../../../common/constants/Routes";
import { useNavigate } from "react-router-dom";
import { SELECT_POLICY_TYPES_FOR_MODULES } from "../../../common/Enums";
import Spinner from "../../../common/ui/Spinner";
import ChangeAddressModal from "./AddressModals/ChangeAddressModal";
import { DoneModal } from "../Common/DoneModal";
import ConfirmationModal from "../../../common/ui/Modal/ConfirmationModal";
import RemoveAddressModal from "./AddressModals/RemoveAddressModal";
import AddressEntryModal from "./AddressModals/AddressEntryModal";
import { createEkycPopup } from "../../../common/utils";

const ManageAddressScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showAddModal, setShowAddModal] = useState(false);
  const [isEkycPopupOpen, setIsEkycPopupOpen] = useState(false);
  const [showDoneModal, setShowDoneModal] = useState(false);
  const [showRemoveNumberModal, setShowRemoveNumberModal] = useState(false);
  const [showChangeNumberModal, setShowChangeNumberModal] = useState(false);
  const [selectedPolicyDetails, setSelectedPolicyDetails] = useState({});
  const [removeUnAssignedAddress, setRemoveUnAssignedAddress] =
    useState<any>(false);
  const [isPreSelectedPolicyType, setIsPreSelectedPolicyType] =
    useState<any>(false);

  const addressChangesList = useSelector(selectAddressChangesList);
  const { addressBasedPolicies, unassignedAddresses }: any = useSelector(
    selectPoliciesForAddress
  );

  useEffect(() => {
    if (addressChangesList?.length === 0) {
      dispatch(fetchPoliciesForAddress());
    }
  }, [dispatch, addressChangesList]);

  const handleChangeAddress = (
    selectedAddress: string,
    isNewAddress?: boolean
  ) => {
    let newAddress: any = {};

    if (isNewAddress) {
      newAddress = selectedAddress;
    } else {
      newAddress = find(propEq(selectedAddress, "fullAddress"))([
        ...addressBasedPolicies,
        ...unassignedAddresses,
      ]);
    }

    dispatch(
      changeAddress({
        selectedPolicy: selectedPolicyDetails,
        newAddress,
      })
    );
    setShowChangeNumberModal(false);
  };

  const handleRemoveAddress = (selectedAddress: any, isNewAddress: boolean) => {
    let newAddress: any = {};
    if (isNewAddress) {
      newAddress = selectedAddress;
    } else {
      newAddress = find(propEq(selectedAddress, "fullAddress"))([
        ...addressBasedPolicies,
        ...unassignedAddresses,
      ]);
    }
    dispatch(
      removeAddress({
        oldAddress: selectedPolicyDetails,
        newAddress,
      })
    );
    setShowRemoveNumberModal(false);
  };

  const toggleAddNumberModal = (
    isPoliciesPreSelected: boolean,
    isChange: boolean
  ) => {
    if (isPoliciesPreSelected === true) {
      setIsPreSelectedPolicyType(isChange ? "CHANGE" : "REMOVE");
    } else {
      setIsPreSelectedPolicyType(false);
    }
    setShowAddModal(!showAddModal);
    setShowRemoveNumberModal(false);
    setShowChangeNumberModal(false);
  };
  const toggleDoneModal = () => {
    setShowDoneModal(!showDoneModal);
  };

  const toggleRemoveNumberModal = (address: any) => {
    setSelectedPolicyDetails(address);
    setShowRemoveNumberModal(!showRemoveNumberModal);
  };

  const handleOnChangeAddressClick = (data: any) => {
    setSelectedPolicyDetails(data ? data : {});
    setShowChangeNumberModal(!showChangeNumberModal);
  };

  const handleNewAddressSubmit = (data: any) => {
    setShowAddModal(false);
    if (isPreSelectedPolicyType === "CHANGE") {
      handleChangeAddress(data, true);
      setIsPreSelectedPolicyType(false);
    } else if (isPreSelectedPolicyType === "REMOVE") {
      handleRemoveAddress(data, true);
      setIsPreSelectedPolicyType(false);
    } else {
      navigate(APP_ROUTES.ASSIGN_POLICY, {
        state: {
          newAddress: data,
          MODULE_TYPE: SELECT_POLICY_TYPES_FOR_MODULES.ADDRESS,
        },
      });
    }
  };

  const handleAddressChangesSubmit = () => {
    const handleDone: any = () => {
      setShowDoneModal(false);
      navigate(-1);
    };
    dispatch(updateAddressChangesRequest({ handleDone } as any));
  };

  const closeAllModals = () => {
    setShowAddModal(false);
    setShowDoneModal(false);
    setShowRemoveNumberModal(false);
    setShowChangeNumberModal(false);
  };

  const isPoliciesForAddressLoading = useSelector(selectIsPolicyAddressLoading);

  const handleAssignPolicies = (address: any) => {
    navigate(APP_ROUTES.ASSIGN_POLICY, {
      state: {
        newAddress: address,
        isOld: true,
        MODULE_TYPE: SELECT_POLICY_TYPES_FOR_MODULES.ADDRESS,
      },
    });
  };

  const handleRemoveForUnAssignedAddress = () => {
    const handleDone = () => {
      setRemoveUnAssignedAddress(false);
    };
    let payload = {
      oldAddress: removeUnAssignedAddress,
      isUnAssignedAddress: true,
      handleDone,
    };
    dispatch(removeAddress(payload));
  };

  const handleDonePress = () => {
    if (addressChangesList?.length > 0) {
      toggleDoneModal();
    } else {
      navigate(-1);
    }
    return true;
  };

  const handleCancelChanges = () => {
    navigate(-1);
    dispatch(setAddressChangesList([]));
  };

  const isUpdateAddressLoading = useSelector(selectIsUpdateAddressLoading);

  const handleEkycFeedBack = (data: any) => {
    setIsEkycPopupOpen(false);
    dispatch(saveAddressEkycRequest());
  };

  const handleChangePermanentAddress = () => {
    const handleSuccess = (data: any) => {
      createEkycPopup(data?.url, handleEkycFeedBack);
      setIsEkycPopupOpen(true);
    };
    dispatch(getAddressEkycUrlRequest({ handleSuccess }));
  };

  return (
    <Box marginBottom={5}>
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item>
          <Typography className="f-16 fw-600 mt-n10" color={"primary"}>
            Manage Address
          </Typography>
        </Grid>
        <Grid item className="mb-10">
          <CustomButton
            text={"DONE"}
            variant={"text"}
            color={"primary"}
            showIcon={true}
            fontSize={14}
            onClick={handleDonePress}
            endIcon={rightArrow}
          />
        </Grid>
      </Grid>
      {isPoliciesForAddressLoading ? (
        <Grid container justifyContent={"center"}>
          <Grid item>
            <Spinner />
          </Grid>
        </Grid>
      ) : (
        <ManageAddressHeader
          handleOnChangeAddressClick={handleOnChangeAddressClick}
          handleAssignPolicies={handleAssignPolicies}
          setRemoveUnAssignedAddress={setRemoveUnAssignedAddress}
          toggleRemoveNumberModal={toggleRemoveNumberModal}
          toggleAddNumberModal={toggleAddNumberModal}
          handleChangePermanentAddress={handleChangePermanentAddress}
          isEkycPopupOpen={isEkycPopupOpen}
        />
      )}
      <DoneModal
        open={showDoneModal}
        setOpen={setShowDoneModal}
        onSubmit={handleAddressChangesSubmit}
        loading={isUpdateAddressLoading}
        onCancel={handleCancelChanges}
      />
      <ChangeAddressModal
        open={showChangeNumberModal}
        setOpen={setShowChangeNumberModal}
        selectedPolicyDetails={selectedPolicyDetails}
        onSubmit={handleChangeAddress}
        toggleAddNumberModal={toggleAddNumberModal}
      />
      <RemoveAddressModal
        open={showRemoveNumberModal}
        setOpen={setShowRemoveNumberModal}
        selectedPolicyDetails={selectedPolicyDetails}
        onSubmit={handleRemoveAddress}
        toggleAddNumberModal={toggleAddNumberModal}
      />
      <AddressEntryModal
        show={showAddModal}
        setShow={setShowAddModal}
        onSubmit={handleNewAddressSubmit}
        handleCancel={closeAllModals}
      />
      <ConfirmationModal
        show={Boolean(removeUnAssignedAddress)}
        setShow={setRemoveUnAssignedAddress}
        description={`Are you sure to remove ${removeUnAssignedAddress?.fullAddress} from unassigned Addresses?`}
        confirmText={"Remove"}
        cancelText={"Cancel"}
        onConfirm={handleRemoveForUnAssignedAddress}
        onCancel={() => setRemoveUnAssignedAddress(false)}
      />
    </Box>
  );
};

export default ManageAddressScreen;
