import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import { ReactComponent as rightArrow } from "../../../common/icons/right_arrow-icon.svg";
import CustomButton from "../../../common/ui/CustomButton";
import { ReactComponent as AddMobileIcon } from "../../../common/icons/update-mobile/Add_Mobile.svg";
import Separator from "../../../common/ui/Separator";

const MobileHeader = ({ toggleAddNumberModal, toggleDoneModal }: any) => {
  const theme = useTheme();
  return (
    <Grid item>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        className="mb-10"
      >
        <Grid item>
          <Typography className="f-16 fw-600 " color={"primary"}>
            Manage Mobile Number
          </Typography>
        </Grid>
        <Grid item className="mb-10">
          <CustomButton
            text={"DONE"}
            variant={"text"}
            color={"primary"}
            showIcon={true}
            onClick={toggleDoneModal}
            fontSize={14}
            endIcon={rightArrow}
          />
        </Grid>
      </Grid>
      <Grid item className=" mt-10 mb-25">
        <CustomButton
          text={"ADD MOBILE NUMBER"}
          variant={"text"}
          color={"primary"}
          showIcon={false}
          onClick={toggleAddNumberModal}
          fontSize={14}
          startIcon={AddMobileIcon}
        />

        <Grid item className="mt-7">
          <Separator color={theme.palette.primary.main} borderWidth={2} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MobileHeader;
