import { FormHelperText, Grid, useTheme } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import theme from '../../../theme';
import './index.scss';
import { Controller, useForm } from 'react-hook-form';

interface FormInputInterface {
  size?: 'small' | 'medium';
  color?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string | undefined;
  placeholder?: string | undefined;
  type?: string | undefined;
  className?: string | undefined;
  labelClassName?: string | undefined;
  spacing?: number | undefined;
  inputProps?: any;
  value?: any;
  backgroundColor?: string | undefined;
  hideCountButton?: boolean | undefined;
  invertBorder?: boolean | undefined;
  errors?: any;
  errorTextClassName?: string | undefined;
  hidden?: boolean | undefined;
  classes?: any;
  control?: any;
  name?: string | undefined;
  rules?: any;
  readOnly?: boolean | undefined;
  onChange?: any;
}
const FormInput = ({
  disabled = false,
  fullWidth = false,
  label = '',
  placeholder = ' ',
  size = 'small',
  type = 'text',
  className,
  labelClassName,
  spacing,
  inputProps = {},
  backgroundColor,
  hideCountButton = false,
  invertBorder = false,
  errors = {},
  errorTextClassName = '',
  hidden = false,
  onChange = {},
  classes = {},
  control = false,
  name = 'test',
  rules = {},
  readOnly = false,
}: FormInputInterface) => {
  const theme = useTheme();
  const { control: childControl } = useForm();
  return (
    <Grid
      className={`form-input-container ${hidden && 'hidden'}`}
      container
      flexDirection={'column'}
      spacing={spacing}
    >
      <Grid item>
        <InputLabel variant='standard' className={`${labelClassName}`}>
          {label}
        </InputLabel>
      </Grid>
      <Grid item>
        <Controller
          name={name}
          control={control ? control : childControl}
          rules={rules}
          render={({ field: { value, onChange, ...field } }) => {
            // console.log(field);
            return (
              <OutlinedInput
                className={`form-input w-100 ${className} ${
                  hideCountButton ? 'hide-count-button' : ''
                } ${invertBorder ? 'invert-border' : 'normal-border'}`}
                fullWidth={fullWidth}
                size={size}
                placeholder={placeholder}
                type={type}
                disabled={disabled}
                onChange={onChange}
                inputProps={{ ...field, ...inputProps }}
                value={value}
                sx={{
                  backgroundColor:
                    backgroundColor || theme.palette.common.white,
                }}
                error={true}
                readOnly={readOnly}
              />
            );
          }}
        />

        {errors?.[name]?.message && (
          <FormHelperText
            className={`error-text ${errorTextClassName}`}
            error={false}
            sx={{ color: theme.palette.error.dark }}
          >
            {errors?.[name]?.message}
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  );
};

export default FormInput;
