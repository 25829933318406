import React from "react";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as rightArrow } from "../../../common/icons/right_arrow-icon.svg";
import CustomButton from "../../../common/ui/CustomButton";
import { ReactComponent as BankIcon } from "../../../common/icons/update-mobile/Bank_icon.svg";

const BankHeader = ({ onAddBankClick, onClickDone }: any) => {
  return (
    <Grid item className="mb-20">
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        className=""
      >
        <Grid item>
          <Typography className="f-16 fw-600 mt-n10" color={"primary"}>
            Manage Bank Details
          </Typography>
        </Grid>
        <Grid item className="mb-10">
          <CustomButton
            text={"DONE"}
            variant={"text"}
            color={"primary"}
            showIcon={true}
            onClick={onClickDone}
            fontSize={14}
            endIcon={rightArrow}
          />
        </Grid>
      </Grid>
      <Grid item className="mb-15">
        <CustomButton
          text={"ADD A BANK ACCOUNT"}
          variant={"text"}
          color={"primary"}
          showIcon={false}
          onClick={onAddBankClick}
          fontSize={14}
          startIcon={BankIcon}
        />
      </Grid>
    </Grid>
  );
};

export default BankHeader;
