import { reject } from "ramda";
import { call, put, select, takeEvery } from "typed-redux-saga";

import {
  fetchAddressList,
  fetchPoliciesForAddress,
  finalSubmitAddress,
} from "./Address.service";
import {
  errorLoadingPoliciesForAddress,
  loadPoliciesForAddress,
  fetchPoliciesForAddress as fetchPoliciesForAddressAction,
  fetchAddress as fetchAddressAction,
  loadAddress,
  errorLoadingAddress,
  selectAddressChangesList,
  updateAddressChangesRequest,
  updateAddressChangesSuccess,
  updateAddressChangesError,
  getAddressEkycUrlRequest,
  getAddressEkycUrlSuccess,
  getAddressEkycUrlError,
  saveAddressEkycRequest,
  saveAddressEkycSuccess,
  saveAddressEkycError,
  selectGetAddressEkycUrl,
} from "./Address.slice";

import {
  generatePermanentAddressFromEkyc,
  verifyNullForArray,
  verifyNullForObject,
} from "../../../common/utils";
import { setAlertInfo } from "../Common/Common.slice";
import { KYC_TYPES } from "../../../common/Enums";
import { selectIsUserLoggedIn } from "../../Auth/auth.slice";
import { initiateEkycRequest, saveEkycRequest } from "../../Auth/auth.service";

function* handleFetchPoliciesForAddress() {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
      },
    };
    const { data }: any = yield* call(fetchPoliciesForAddress, requestPayload);
    yield* put(
      loadPoliciesForAddress({
        permanentAddress: data?.PermanentAddresses
          ? verifyNullForObject(data?.PermanentAddresses)
          : {},
        addressBasedPolicies: data?.AddressBasedPolicies
          ? reject(
              ({ city }) => String(city).trim().length === 0,
              verifyNullForArray(data?.AddressBasedPolicies)
            )
          : [],
        unassignedAddresses: data?.UnassignedAddresses
          ? verifyNullForArray(data?.UnassignedAddresses)
          : [],
      })
    );
  } catch (e: any) {
    yield* put(errorLoadingPoliciesForAddress(e.message));
  }
}

function* handleFetchAddress({
  payload,
}: ReturnType<typeof fetchAddressAction>) {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
        address1: payload?.address1,
        address2: payload?.address2,
        address3: payload?.address3,
        city: payload?.city,
        state: payload?.state,
        country: payload?.country,
        pincode: payload?.pincode,
      },
    };
    const { data }: any = yield* call(fetchAddressList, requestPayload);
    yield* put(loadAddress(data?.listAddress || []));
  } catch (e: any) {
    yield* put(errorLoadingAddress(e.message));
  }
}

function* finalDataSubmitAddress({ payload: { handleDone } }: any) {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const addressChanges: any[] = yield* select(selectAddressChangesList);
    const requestPayload = {
      Customer: {
        eiaNo: user.eiaNo,
      },
      Actions: addressChanges,
    };
    const { data }: any = yield* call(finalSubmitAddress, requestPayload);
    yield* put(updateAddressChangesSuccess(data || []));
    yield put(
      setAlertInfo({
        open: true,
        type: "SUCCESS",
        description: "Successfully Updated Address policies.",
      })
    );
    if (handleDone) {
      handleDone();
    }
  } catch (e: any) {
    yield* put(updateAddressChangesError(e.message));
  }
}

function* initiateEkycWatcher({ payload: { handleSuccess } }: any) {
  try {
    const user: any = yield* select(selectIsUserLoggedIn);
    const { data }: any = yield* call(initiateEkycRequest, {
      Customer: { eiaNo: user.eiaNo, flag: KYC_TYPES.ADDRESS_CHANGE },
    });
    yield* put(getAddressEkycUrlSuccess(data));
    if (handleSuccess) {
      handleSuccess(data);
    }
  } catch (e: any) {
    yield* put(getAddressEkycUrlError(e.message));
  }
}

function* saveEkycWatcher() {
  try {
    const kycData: any = yield* select(selectGetAddressEkycUrl);
    const user: any = yield* select(selectIsUserLoggedIn);
    if (kycData.id) {
      const { data }: any = yield* call(saveEkycRequest, {
        kycId: kycData.id,
        eiaNo: user.eiaNo,
      });
      const permanentAddress = generatePermanentAddressFromEkyc(data);
      if (!permanentAddress?.city) {
        throw "Failed";
      }
      yield* put(
        saveAddressEkycSuccess({
          permanentAddress,
        })
      );
      yield put(
        setAlertInfo({
          open: true,
          type: "SUCCESS",
          description: "Successfully Updated permanent Address.",
        })
      );
    }
  } catch (e: any) {
    yield* put(saveAddressEkycError(e.message));
    yield put(
      setAlertInfo({
        open: true,
        type: "FAILED",
        description: "Failed to Updated permanent Address.",
      })
    );
  }
}

function* watchAddress() {
  yield* takeEvery(
    fetchPoliciesForAddressAction.type,
    handleFetchPoliciesForAddress
  );
  yield* takeEvery(fetchAddressAction.type, handleFetchAddress);
  yield* takeEvery(updateAddressChangesRequest.type, finalDataSubmitAddress);
  yield* takeEvery(getAddressEkycUrlRequest.type, initiateEkycWatcher);
  yield* takeEvery(saveAddressEkycRequest.type, saveEkycWatcher);
}

export { watchAddress };
