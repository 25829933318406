import { axiosInstance } from "../../../common/axiosInstance";
import { EmailRoutes } from "../../../common/routes";

const axios = axiosInstance;

const fetchPoliciesForEmail = (data: any) =>
  axios.request({ ...EmailRoutes.listAllPoliciesForEmail, data });

const generateOtpEmail = (data: any) =>
  axios.request({ ...EmailRoutes.generateOtpForEmail, data });

const verifyOtpEmail = (data: any) =>
  axios.request({ ...EmailRoutes.verifyOtpForEmail, data });

const updateForEmail = (data: any) =>
  axios.request({ ...EmailRoutes.updateEmail, data });

export {
  fetchPoliciesForEmail,
  generateOtpEmail,
  verifyOtpEmail,
  updateForEmail,
};
