import { Box, Avatar, Badge, useTheme } from '@mui/material';
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';

import '../SignUp/PersonalProfileScreen.css';

import PersonalDetails from './PersonalDetails';
import PersonalBankDetails from './PersonalBankDetails';
import PersonalBankDocuments from './PersonalBankDocuments';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUserProfile,
  selectIsLoading,
  selectUserProfile,
} from '../../App/Profile/Profile.slice';
import PersonalAddress from './PersonalAddress';
import Spinner from '../../../common/ui/Spinner';

function PersonalProfileScreen({ otherUri }: { otherUri: any }) {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   console.log('PersonalProfileScreen');
  //   //dispatch(fetchUserProfile());
  // }, []);
  // useEffect(() => {
  //   console.log('PersonalProfileScreen==');
  // }, []);
  useEffect(() => {
    dispatch(fetchUserProfile());
  }, []);
  const userProfile = useSelector(selectUserProfile);
  const isLoading = useSelector(selectIsLoading);

  // const Customer = {
  //   eiaNo: '9999926296533',
  //   profileImg: '',
  //   customerName: 'test customer',
  //   lastUpdateDt: '25/12/2001',
  //   profileReadiness: '70%',
  //   dob: '25/12/2001',
  //   gender: 'M',
  //   fatherName: 'test father',
  //   pan: 'CAMST4003T',
  //   aadhar: '1234 1234 1234',
  //   passport: 'A123456',
  //   mobileNo: '9999999999',
  //   email: 'xxx@testmail.com',
  //   permanentAddress: 'No 6,Raja Street,Chennai,TamilNadu,India-600001',
  // };
  const bankDetails = [
    {
      bank: 'Bank 1',
      accountHolderName: 'Accound Holder Name',
      account: 'Acc# 1234567890123',
      bankName: 'BankName',
      branchName: 'Bank Branch',
      ifscCode: 'IFSC Code',
    },
    {
      bank: 'Bank 2',
      accountHolderName: 'Accound Holder Name',
      account: 'Acc# 1234567890123',
      bankName: 'BankName',
      branchName: 'Bank Branch',
      ifscCode: 'IFSC Code',
    },
  ];
  return (
    <Box>
      {isLoading ? (
        <Grid item>
          <Spinner />
        </Grid>
      ) : (
        <Grid container spacing={1} justifyContent={'space-between'}>
          <Grid item xs={12}>
            <PersonalDetails
              // items={Customer}
              dob={userProfile?.Customer?.dob}
              fatherName={userProfile?.Customer?.fatherName}
              aadhar={userProfile?.Customer?.aadhar}
              gender={userProfile?.Customer?.gender}
              pan={userProfile?.Customer?.pan}
              passport={userProfile?.Customer?.passport}
              mobileNumber={userProfile?.Customer?.mobileNo}
              // mobileNumberCode={userProfile?.Customer?.mobileCountryCode}
              policyMobileNo={userProfile?.PolicyMobile.filter(
                (policyMobile) =>
                  policyMobile.mobileNo !== userProfile?.Customer?.mobileNo
              )}
              policyEmail={userProfile?.PolicyEmail.filter(
                (policyEmail) =>
                  policyEmail.email !== userProfile?.Customer?.email
              )}
              email={userProfile?.Customer?.email}
              Images={userProfile?.Images}
              authorizedRepresentativeMobileNo={
                userProfile?.Customer?.authorizedRepresentativeMobileNo
                  ? userProfile?.Customer?.authorizedRepresentativeMobileNo
                  : '-'
              }
              authorizedRepresentative={
                userProfile?.Customer?.authorizedRepresentative
                  ? userProfile?.Customer?.authorizedRepresentative
                  : '-'
              }
            />
          </Grid>
          <Grid item xs={12}>
            <PersonalAddress
              // PermanentAddress={userProfile?.Customer?.PermanentAddress}
              // PolicyAddress={userProfile?.PolicyAddress || []}
              // CorrespondenceAddress={userProfile?.Customer.CorrespondenceAddress}
              PermanentAddress={userProfile?.Customer?.PermanentAddress}
              PolicyAddress={userProfile?.PolicyAddress}
              CorrespondenceAddress={
                userProfile?.Customer?.CorrespondenceAddress
              }
            />
          </Grid>
          <Grid item xs={12}>
            <PersonalBankDetails profileResponse={userProfile?.Bank} />
          </Grid>
          <Grid item xs={12}>
            <PersonalBankDocuments
              profileIds={userProfile?.Images}
              otherUri={otherUri}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default PersonalProfileScreen;
