import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DashboardCardLayout from './common/DashboardCardLayout';
import PolicyCardHeader from './common/PolicyCardHeader';
import { useTheme } from '@mui/system';
import { Grid } from '@mui/material';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as RupeeIcon } from '../../../common/icons/dashboard/rupee-icon.svg';

interface UserProps {
  assetsDetails?: any;
}
export const AssetsScreen: React.FC<UserProps> = ({ assetsDetails }) => {
  console.log('assetsDetails', assetsDetails);
  const theme = useTheme();
  return (
    <DashboardCardLayout>
      <div className='mb-30'>
        <PolicyCardHeader type={'assets'} />
      </div>
      {assetsDetails &&
        assetsDetails.length > 0 &&
        assetsDetails.map((assetsData: any) => (
          <Grid
            container
            flexDirection={'row'}
            spacing={3}
            justifyContent={'space-between'}
          >
            <Grid item className='mb-20'>
              <div className='w-280px'>
                <div className='dashboard-policy-cost-container'>
                  <Typography
                    fontWeight={600}
                    className='pb-1'
                    fontSize={14}
                    color={theme.palette.primary.main}
                  >
                    {assetsData?.type}{' '}
                    <span className='fw-400'>{assetsData?.registrationNo}</span>
                  </Typography>
                  <Box
                    className={'header-border mb-3'}
                    borderBottom={`2px solid ${theme.palette.secondary.main}`}
                  />
                  <Typography
                    fontWeight={600}
                    className='pb-1'
                    fontSize={14}
                    color={theme.palette.primary.main}
                  >
                    IDV
                  </Typography>
                </div>
                <Grid
                  container
                  flexWrap={'nowrap'}
                  className='mt-0'
                  alignItems={'flex-end'}
                  flexDirection={'row'}
                  spacing={0.5}
                >
                  <Grid
                    item
                    container
                    flexWrap={'nowrap'}
                    alignItems={'flex-end'}
                    flexDirection={'row'}
                    sm={6}
                  >
                    <Grid item className='h-100'>
                      <SvgConverter
                        Icon={RupeeIcon}
                        className={'d-flex align-items-end mb-4'}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        fontWeight={300}
                        fontSize={28}
                        lineHeight={1}
                        className={'ml-6'}
                      >
                        {assetsData?.IDV}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography
                      fontSize={14}
                      className={'ml-5'}
                      color={'primary'}
                    >
                      @{assetsData?.depreciation}% depreciation
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  fontWeight={400}
                  fontSize={14}
                  lineHeight={1}
                  className={'ml-16'}
                >
                  plus{' '}
                  <Typography
                    component={'span'}
                    fontSize={14}
                    color={'primary'}
                  >
                    {assetsData?.terms}
                  </Typography>
                </Typography>
                <div className='mt-16'>
                  <Typography
                    fontSize={28}
                    fontWeight={300}
                    color={'secondary'}
                  >
                    {assetsData?.countCashlessGarages}
                  </Typography>
                  <Typography fontSize={14} color={'primary'}>
                    cashless garages in {assetsData?.areaCashlessGarages} area
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        ))}
    </DashboardCardLayout>
  );
};

export default AssetsScreen;
