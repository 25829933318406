import React from 'react';
import Typography from '@mui/material/Typography';
import Separator from '../../../common/ui/Separator';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material';
import '../SignUp/PersonalProfileScreen.css';
import { ReactComponent as bankid } from './../../../common/icons/normal_u827.svg';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import SvgConverter from '../../../common/SvgConverter';
import BankDetailsCard from './BankAccountDetails';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { Link } from 'react-router-dom';
import { ReactComponent as ChequeIcon } from '../../../common/icons/update-mobile/cancelled_cheque_icon.svg';
import HdfcIcon from '../../../common/icons/update-mobile/hdfc_bank_icon.png';
import { useSelector } from 'react-redux';
import { selectUserProfile } from '../../App/Profile/Profile.slice';

interface bankDetailsInterface {
  profileResponse?: any;
}
const PersonalBankDetails = ({ profileResponse }: bankDetailsInterface) => {
  const theme = useTheme();
  const userProfile = useSelector(selectUserProfile);

  return (
    <Grid container>
      <Grid item xs={12} spacing={0.5}>
        <Grid container>
          {profileResponse &&
            profileResponse.map((item: any, index: any) => (
              <Grid item xs={6} key={index}>
                <Grid container>
                  <Grid item xs={5}>
                    <Typography className='f-16 fw-600 ' color={'primary'}>
                      {item.bankName}
                    </Typography>
                    <Typography
                      className='f-16'
                      color={theme.palette.common.black}
                    >
                      {item.accountHolderName}
                    </Typography>
                    <Typography
                      className='f-16'
                      color={theme.palette.common.black}
                    >
                      {item.accountNo}
                    </Typography>
                    <Typography
                      className='f-16'
                      color={theme.palette.common.black}
                    >
                      {item.bankName}
                    </Typography>
                    <Typography
                      className='f-16'
                      color={theme.palette.common.black}
                    >
                      {item.bankBranch}
                    </Typography>
                    <Typography
                      className='f-16'
                      color={theme.palette.common.black}
                    >
                      {item.ifscCode}
                    </Typography>

                    <Link to={APP_ROUTES.BANK}>
                      <Typography
                        className='f-12 mt-25 mb-10'
                        color={'primary'}
                        flexWrap={'nowrap'}
                      >
                        <a href='#'>{'SEE ASSIGNED POLICIES'}</a>
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={6} className='mt-20'>
                    <Grid container flexDirection={'column'}>
                      <Grid item className=''>
                        <img
                          src={item.bankLogo}
                          alt='logo'
                          width={'73'}
                          height={'77'}
                        />
                      </Grid>
                      <Grid item className='ml-n18'>
                        <SvgConverter Icon={item.chqImg} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid item xs={12} className='mt-14'>
        <Separator color={theme.palette.primary.main} />
      </Grid>
    </Grid>
  );
};

export default PersonalBankDetails;
