import { Box, Grid, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import Separator from '../../../common/ui/Separator';
import AssetsScreen from './AssetsScreen';
import PoliciesByCalendar from './PoliciesByCalendar';
import HealthScreen from './HealthScreen';
import HelpComponent from './HelpComponent';
import LifeScreen from './LifeScreen';
import './Dashboard.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  DashboardList,
  dashboardListRequest,
  selectIsDashboardLoading,
} from './Dashboard.slice';
import { verifyNullForObject } from '../../../common/utils';

const DashboardScreen = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const dashboardListDatas = useSelector(DashboardList);
  const dashboardLoading = useSelector(selectIsDashboardLoading);
  let calendarList =
    dashboardListDatas && dashboardListDatas?.PolicyRenewalCalenderDetails;
  console.log('dashboardListDatas', dashboardListDatas);
  useEffect(() => {
    dispatch(dashboardListRequest());
  }, []);

  return (
    <Box className='ml-14'>
      <Grid container justifyContent={'left'} spacing={12}>
        <Grid item lg={8}>
          <LifeScreen lifeDetails={dashboardListDatas?.life} />
          <div className='mb-20'>
            <Separator color={theme.palette.primary.main} />
          </div>
          <HealthScreen healthDetails={dashboardListDatas?.health} />
          <div className='mb-20'>
            <Separator color={theme.palette.primary.main} />
          </div>
          <AssetsScreen assetsDetails={dashboardListDatas?.assets} />
        </Grid>
        <Grid item lg={4}>
          <PoliciesByCalendar
            calendarList={calendarList}
            calendarLoading={dashboardLoading}
          />
          <div className='my-20'>
            <Separator color={theme.palette.primary.main} />
          </div>
          <HelpComponent />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardScreen;
