import React from 'react';
import Typography from '@mui/material/Typography';
import Separator from '../../../common/ui/Separator';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material';
import '../SignUp/PersonalProfileScreen.css';
import AddressDetails from '../../App/Profile/AddressDetails';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { Link } from 'react-router-dom';
// interface addressCardinterface {
//   PermanentAddress?: string;
//   CorrespondenceAddress?: string;
//   Address3?: string;
//   assignedpolicy?: string;
//   PolicyAddress?:string | number;
// }

const PersonalAddress = ({
  PolicyAddress,
  PermanentAddress,
  CorrespondenceAddress,
  assignedpolicy,
}: {
  PolicyAddress: any;
  PermanentAddress: any;
  CorrespondenceAddress: any;
  assignedpolicy?: string;
}) => {
  const theme = useTheme();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={0.5} className='py-20'>
          {PermanentAddress && (
            <Grid item xs={4} className='py-0'>
              <Typography className='f-14 fw-600 mb-14' color={'primary'}>
                {'Permanent Address [As per ID]'}
              </Typography>
              <Grid item className='mb-4'>
                <AddressDetails
                  address={PermanentAddress ? PermanentAddress : null}
                />
              </Grid>
              {PermanentAddress?.address1 ||
              PermanentAddress?.address2 ||
              PermanentAddress?.address3 ||
              PermanentAddress?.city ||
              PermanentAddress?.state ||
              PermanentAddress?.country ||
              PermanentAddress?.pincode ? (
                <Link to={APP_ROUTES.MANAGE_ADDRESS}>
                  <Typography className='f-12 my-10' color={'primary'}>
                    <a href='#'>{' SEE ASSIGNED POLICIES'}</a>
                  </Typography>
                </Link>
              ) : null}
            </Grid>
          )}
          {/* <Grid item xs={4}>
            <Typography className='f-14 fw-600 mb-14' color={'primary'}>
              {CorrespondenceAddress}
            </Typography>
            <Grid item className='mb-4'>
              <AddressDetails
                houseNo={'House No.'}
                street={'Street No./Name'}
                locality={'Locality Name,'}
                area={' Area Name'}
                city={'Village/Town/City'}
                district={'District'}
                state={'State'}
                pinCode={'PIN Code'}
              />
            </Grid>
            <Link to={APP_ROUTES.ADDRESS}>
              <Typography className='f-12  m-0' color={'primary'}>
                <a href='#'>{assignedpolicy}</a>
              </Typography>
            </Link>
          </Grid> */}
          {PolicyAddress &&
            PolicyAddress.map((address: any) => (
              <Grid item xs={4}>
                <Typography className='f-14 fw-600 mb-14' color={'primary'}>
                  {address.city ? `${address.city} Address` : null}
                </Typography>
                <Grid item className='mb-4'>
                  <AddressDetails address={address ? address : null} />
                </Grid>
                {address?.address1 ||
                address?.address2 ||
                address?.address3 ||
                address?.city ||
                address?.state ||
                address?.country ||
                address?.pincode ? (
                  <Link to={APP_ROUTES.MANAGE_ADDRESS}>
                    <Typography className='f-12 my-10' color={'primary'}>
                      <a href='#'>{' SEE ASSIGNED POLICIES'}</a>
                    </Typography>
                  </Link>
                ) : null}
              </Grid>
            ))}
          <Grid item className='mt-14 w-100'>
            <Separator color={theme.palette.primary.main} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PersonalAddress;
