import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import '../Policy.scss';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import theme from '../../../../common/theme';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import { ReactComponent as Add } from '../../../../common/icons/add.svg';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import CustomSelectBox from '../../../../common/ui/Selectbox/CustomSelect';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewPolicy,
  fetchInsurers,
  selectInsurerOptions,
} from '../Policies.slice';
import CustomButton from '../../../../common/ui/CustomButton';
import { useForm } from 'react-hook-form';
import { selectLoginRequestInfo } from '../../../Auth/auth.slice';

interface UserProps {
  policyNo?: any;
  setPolicyNo?: any;
  insurercode?: any;
  setInsurerCode?: any;
}
export const AddPolicyForm: React.FC<UserProps> = ({
  policyNo,
  setPolicyNo,
  insurercode,
  setInsurerCode,
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const insurerOptions = useSelector(selectInsurerOptions);
  const loginInfo = useSelector(selectLoginRequestInfo);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const insurerOptionsList =
    insurerOptions &&
    insurerOptions.map((item: any) => ({
      Description: item.label,
      Category: item.value,
      Code: item.alternateCode,
    }));
  const handleSelectChange = (value: string) => {
    // setSelectedOption(value);
  };
  const dispatch = useDispatch();
  const onSubmit = (data: any) => {
    dispatch(
      addNewPolicy({
        Customer: {
          eiaNo: loginInfo?.Customer?.eiaNo,
          insurerCode: selectedOption,
          policyNo: data?.policyNo,
        },
      } as any)
    );
    setPolicyNo(data?.policyNo);
    setInsurerCode(selectedOption);
  };
  useEffect(() => {
    dispatch(fetchInsurers());
  }, []);
  return (
    <Box className='add-policy'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} className='p-5'>
            <Typography
              className='f-14 fw-600 display-inline'
              color={theme.palette.primary.main}
            >
              Enter Policy Number
            </Typography>

            <FormInput
              type='text'
              placeholder='ABCDE0100X'
              color={theme.palette.common.black}
              name='policyNo'
              hideCountButton={true}
              errors={errors}
              inputProps={{
                ...register('policyNo', {
                  required: {
                    value: false,
                    message: 'This field is mandatory!',
                  },
                }),
              }}
            />
          </Grid>
          <Grid item xs={12} className='p-5 '>
            <Typography
              className='f-14 fw-600 display-inline'
              color={theme.palette.primary.main}
            >
              Select Insurer
            </Typography>
            <CustomSelectBox
              options={insurerOptionsList}
              value={selectedOption}
              onChange={(value) => handleSelectChange}
              setSelectedOption={setSelectedOption}
              ClassName='w-100'
              dropdownWidth='20px'
            />
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          className='px-10'
          flexDirection={'row-reverse'}
        >
          <Grid item>
            <CustomButton
              text={'ADD POLICY'}
              variant={'text'}
              type={'submit'}
              color={'primary'}
              className={'f-14 fw-600 mt-12 '}
              startIcon={Add}
              showIcon={false}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AddPolicyForm;
