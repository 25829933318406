import React, { useEffect } from 'react';
import { TabList } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Tab, useTheme } from '@mui/material';
import './profile.css';
import { useState } from 'react';
import { FamilyScreen } from './FamilyScreen';
import PersonalProfileScreen from '../../Auth/SignUp/PersonalProfileScreen';
import Grid from '@mui/material/Grid/Grid';
import { ReactComponent as plusIcon } from '../../../common/icons/plus-icon.svg';
import CustomButton from '../../../common/ui/CustomButton';
import CustomPopOver from '../../../common/ui/CustomPopOver';
import ModalDetailsCard from './AddFamilyMember/AddFamilyMemberContent';
import Separator from '../../../common/ui/Separator';
import theme from '../../../common/theme';
import { fetchUserProfile } from './Profile.slice';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';

const ProfileScreen = () => {
  const [value, setValue] = useState('Personal');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(fetchUserProfile());
  // }, []);
  const [otherUri, setOtherUri] = React.useState<string | null>(null);

  return (
    <Box>
      <TabContext value={value}>
        <Box className='ml-20'>
          <TabList
            onChange={handleChange}
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
            textColor='inherit'
          >
            <Tab
              label='Personal'
              value='Personal'
              sx={{
                fontWeight: value === 'Personal' ? 800 : 600,
                color: value === 'Personal' ? 'black' : 'black',
                textTransform: 'none',
              }}
              className='mt-20 mr-5'
            />

            <Grid item xs={1} className='separator'>
              <Separator
                orientation='vertical'
                color={theme.palette.secondary.main}
                borderWidth={3}
                className='h-100'
              />
            </Grid>

            <Tab
              label='Family & others'
              value='Family'
              sx={{
                fontWeight: value === 'Family' ? 800 : 600,
                color: value === 'Family' ? 'black' : 'black',
                textTransform: 'none',
              }}
              className='mt-20 mr-5'
            />
          </TabList>
        </Box>
        <TabPanel value='Personal'>
          <PersonalProfileScreen otherUri={otherUri} />
        </TabPanel>
        <TabPanel value='Family'>
          <FamilyScreen />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

const Header = () => {
  const navigate = useNavigate();

  const [Open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [anchorEle, setAnchorEle] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEle(event.currentTarget);
  };
  const handleClosed = () => {
    setAnchorEle(null);
  };

  const open = Boolean(anchorEle);
  
  const theme = useTheme();
  return (
    <Grid container justifyContent={'flex-end'}>
      <Grid item>
        <CustomButton
          text={'ADD A FAMILY MEMBER'}
          color={'primary'}
          startIcon={plusIcon}
          variant={'text'}
          showIcon={false}
          onClick={handleClick}
          fontSize={12}
        />

        <ModalDetailsCard
          open={open}
          setAnchorEle={setAnchorEle}
          handleClosed={handleClosed}
        
        />
      </Grid>
    </Grid>
  );
};

ProfileScreen.Header = Header;

export default ProfileScreen;
