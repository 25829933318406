import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import theme from '../../../../../common/theme';
import '../../Policy.scss';
import LabelWithIcon from '../../../../../common/ui/LabelWithIcon';
import HDFC from '../../../../../assets/images/HDFC.png';
import { ReactComponent as OrangeRuppee } from '../../../../../common/icons/small-orange-rupee.svg';
import VerificationStatus from './VerificationStatus';
interface UserProps {
  contentType?: string;
  items?: any;
}
const LifeHeader: React.FC<UserProps> = ({ contentType, items }) => {
  return (
    <Grid container item xs={12} className='px-10 py-15' spacing={2}>
      <Grid item xs={3}>
        <img src={items.companyLogo} className='bank-image'  />

        <VerificationStatus status={items.verifiedFlag} />
      </Grid>
      <Grid item xs={8}>
        <Typography
          className='f-16 fw-600 mb-0'
          color={theme.palette.primary.main}
        >
          {items.planName.length === 0 ? '-' : items.planName}
        </Typography>
        <Typography className='f-14 ' color={theme.palette.primary.main}>
          Life Assured
        </Typography>
        <Typography
          className='f-16 fw-600'
          color={
            contentType === 'active'
              ? theme.palette.common.black
              : theme.palette.grey.A700
          }
        >
          {items.customerName.length === 0 ? '-' : items.customerName}
        </Typography>
        <Typography className='f-14 mb-2' color={theme.palette.primary.main}>
          Sum Assured
        </Typography>
        <LabelWithIcon
          className=' mr-10'
          svgClassName=''
          justifyContent={'flex-start'}
          Icon={OrangeRuppee}
          label={items?.sumAssured.length === 0 ? '0' : items?.sumAssured}
          labelProps={{
            color: theme.palette.common.black,
            fontSize: 16,
            fontWeight: 600,
          }}
        />
        <Typography className='f-14 mb-2' color={theme.palette.primary.main}>
          Maturity Value
        </Typography>
        <LabelWithIcon
          className=' mr-10'
          svgClassName=''
          justifyContent={'flex-start'}
          Icon={OrangeRuppee}
          label={items?.maturityValue.length === 0 ? '0' : items?.maturityValue}
          labelProps={{
            color: theme.palette.common.black,
            fontSize: 16,
            fontWeight: 600,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default LifeHeader;
