import React from "react";
import { Grid, useTheme } from "@mui/material";
import "../payment.scss";
import Typography from "@mui/material/Typography";
import { ReactComponent as OrangeRupee } from "../../../../common/icons/orange_rupee.svg";
import { ReactComponent as GreenTick } from "../../../../common/icons/Big-Green-Tick.svg";
import { ReactComponent as Cancel } from "../../../../common/icons/update-mobile/cancel_orange.svg";
import { ReactComponent as Circle } from "../../../../common/icons/background-circle.svg";
import CustomButton from "../../../../common/ui/CustomButton";
import LabelWithIcon from "../../../../common/ui/LabelWithIcon";
import Separator from "../../../../common/ui/Separator";
import SvgConverter from "../../../../common/SvgConverter";
import { convertAmount } from "../../../../common/utils";
import { Link } from "react-router-dom";

const PaymentSuccess = ({ amount, transactionId }: any) => {
  const theme = useTheme();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={3} width={279}>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <Grid container justifyContent={"space-between"}>
              <Typography
                fontSize={16}
                fontWeight={600}
                color={theme.palette.primary.main}
              >
                Premium Payment
              </Typography>
              <CustomButton
                className=" fw-600"
                text={"cancel"}
                variant={"text"}
                color={"primary"}
                showIcon={false}
                startIcon={Cancel}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} className="payment-card-success mt-30">
            <Grid container justifyContent={"center"}>
              <Grid item>
                <SvgConverter Icon={GreenTick} className="green-tick" />
                <SvgConverter Icon={Circle} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          textAlign={"center"}
          className="mt-10"
        >
          <Grid item>
            <LabelWithIcon
              svgClassName="mb-2 "
              justifyContent={"center"}
              Icon={OrangeRupee}
              label={convertAmount(amount)}
              labelProps={{
                color: theme.palette.common.black,
                fontSize: 20,
                fontWeight: 600,
              }}
            />
            <Typography fontSize={16} fontWeight={600} color={" #63a103"}>
              Premium Payment Successfull
            </Typography>
            <Typography
              fontSize={12}
              color={theme.palette.common.black}
              className="mt-15"
            >
              Transaction ID{" "}
            </Typography>{" "}
            <Typography
              fontSize={12}
              fontWeight={600}
              color={theme.palette.common.black}
              className="mb-20"
            >
              {transactionId}
            </Typography>
            <Grid item className="mb-10">
              <Separator color={" #d7d7d7"} />
            </Grid>
            <CustomButton
              className=" fw-600"
              text={"Download Payment Acknowledgment Receipt"}
              variant={"text"}
              color={"primary"}
            />{" "}
            <Grid item className="mt-10 mb-20">
              <Separator color={" #d7d7d7"} />
            </Grid>
            <Typography
              fontSize={16}
              color={theme.palette.primary.main}
              lineHeight={1}
              className={"mb-20"}
            >
              The{" "}
              <Typography component={"span"} fontWeight={"bold"} lineHeight={1}>
                premium receipt
              </Typography>
              will be generated in{" "}
              <Typography component={"span"} fontWeight={"bold"} lineHeight={1}>
                xx hours
              </Typography>{" "}
              and will be available on Bima Central, as well as, shared through
              email.
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent={"right"} textAlign={"right"}>
          <Grid item justifyContent={"right"} textAlign={"right"}>
            <Link to={"/"} replace>
              <CustomButton
                className=" fw-600"
                text={"Click to go back to previous page"}
                variant={"text"}
                color={"primary"}
                showIcon={false}
              />
            </Link>
          </Grid>
        </Grid>
        <Grid container justifyContent={"left"} textAlign={"left"}>
          <Grid item>
            <Typography fontSize={12} color={theme.palette.primary.main}>
              Terms & conditions
            </Typography>
            <Typography fontSize={12} fontWeight={600} color={"#7f7f7f"}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              euismod bibendum laoreet. Proin gravida dolor sit amet lacus
              accumsan et viverra justo commodo. Proin sodales pulvinar sic
              tempor. Sociis natoque penatibus et magnis dis parturient montes,
              nascetur ridiculus mus.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentSuccess;
