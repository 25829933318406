import React, { useState } from 'react';
import {
  Grid,
  Radio,
  FormControlLabel,
  Typography,
  useTheme,
} from '@mui/material';

interface Option {
  label: string;
  value: string;
  className?: string;
}

interface Props {
  options: Option[];
  className?: string; 
}

const RadioButton: React.FC<Props> = ({ options, className }) => {
  const theme = useTheme();
  const [selectedValue, setSelectedValue] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Grid
      container
      flexDirection={'column'}
      spacing={1}
      className={`p-12 ml-16 ${className}`}
    >
      {options.map((option) => (
        <Grid item key={option.value} className='f-12'>
          <FormControlLabel
            control={
              <Radio
                size='small'
                color={'primary'}
                checked={selectedValue === option.value}
                onChange={handleChange}
                value={option.value}
                style={{ color: '#797979' }}
              />
            }
            label={
              <Typography
                className='f-12 fw-600'
                color={theme.palette.common.black}
              >
                {option.label}
              </Typography>
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default RadioButton;
