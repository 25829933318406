import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import Separator from "../../../common/ui/Separator";
import MobilePolicyHeader from "../MobileNumber/common/MobilePolicyHeader";

const UnassignedEmail = ({
  emails,
  handleAssignPolicy,
  handleRemoveForUnAssignedEmails,
}: any) => {
  const theme = useTheme();

  return (
    <>
      <Grid item xs={12}>
        <Typography className="f-16  mt-10" color={theme.palette.error.main}>
          Unassigned EmailS
        </Typography>
      </Grid>
      <Grid item className="my-10">
        <Separator color={theme.palette.primary.main} borderWidth={2} />
      </Grid>
      <Grid container justifyContent={"space-between"} spacing={5}>
        {emails.map((data: any, index: number) => (
          <MobilePolicyHeader
            index={index}
            mobileNo={data?.email}
            verified={data["emailVerified"]}
            primary={data["primaryFlag"]}
            onRemove={() => handleRemoveForUnAssignedEmails(data?.email)}
            handleAssignPolicy={() =>
              handleAssignPolicy({
                email: data?.email,
                emailVerified: data["emailVerified"],
              })
            }
            showAssignPolicyButton={true}
            // onVerify={() => handleInstantVerify(email)}
          />
        ))}
      </Grid>
    </>
  );
};

export default UnassignedEmail;
