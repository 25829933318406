import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../common/store";

interface alertInfo {
  open: boolean;
  type?: "SUCCESS" | "FAILED" | "INFO";
  description?: string;
  loading?: boolean;
}
interface CommonState {
  alertInfo: alertInfo;
}

const initialState: CommonState = {
  alertInfo: { open: false },
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setAlertInfo: (state, { payload }: { payload: alertInfo }) => {
      state.alertInfo = { ...state.alertInfo, ...payload };
    },
  },
});

export const { setAlertInfo } = commonSlice.actions;
export default commonSlice.reducer;

export const selectAlertInfo = (state: RootState) => state.common.alertInfo;
