import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import React from 'react';
import SvgConverter from '../../../../common/SvgConverter';
import HdfcIcon from '../../../../assets/images/HDFC.png';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import { ReactComponent as TickSuccessIcon } from '../../../../common/icons/dashboard/green_tick_icon.svg';
import { ReactComponent as OrangeRuppee } from '../../../../common/icons/dashboard/orange_ruppee_icon.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../common/icons/dashboard/calendar_right_arrow.svg';
import { ReactComponent as CircleIcon } from '../../../../common/icons/dashboard/calendar_icon_circle.svg';

import Separator from '../../../../common/ui/Separator';
import { ReactComponent as RupeeOrangeIcon } from '../../../../common/icons/dashboard/calendar_orange_ruppee_icon.svg';
import dayjs from 'dayjs';
import CustomButton from '../../../../common/ui/CustomButton';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';

const PolicyCalendarCard = ({
  data,
  isLastItem,
}: {
  data?: any;
  isLastItem: boolean;
}) => {
  const Icons: any = {
    life: HdfcIcon,
  };

  const theme = useTheme();
  const currentDate = new Date();
  const formatedCurrentDate = dayjs(currentDate);
  const premiumDate = dayjs(data.premiumDueDate);
  const diffInDays = formatedCurrentDate.isAfter(premiumDate, 'day');
  const monthAbbreviation = premiumDate.format('MMM').toUpperCase();
  const day = premiumDate.date();

  console.log('day', day);
  const dateVerification = (date: any, day: any, month: any) => {
    if (date.length === 0) {
      return '-';
    } else {
      if (month) premiumDate.format('MMM').toUpperCase();
      if (day) premiumDate.date();
    }
  };
  return (
    <Grid
      item
      container
      borderBottom={
        isLastItem === false ? `1px solid ${theme.palette.grey[400]}` : 'none'
      }
    >
      <Grid item xs={2}>
        <Grid item xs={12} display={'flex'} justifyContent={'end'}>
          {data.isDue ? (
            <Typography
              color={' rgba(127, 127, 127, 0.99)'}
              fontSize={28}
              fontWeight={300}
            >
              {dateVerification(data.premiumDueDate, true, false)}
            </Typography>
          ) : (
            <>
              <Grid item className=''>
                <Typography
                  color={theme.palette.secondary.main}
                  fontSize={28}
                  fontWeight={300}
                >
                  {dateVerification(data.premiumDueDate, true, false)}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12} display={'flex'} justifyContent={'end'}>
          {data.paymentFlag === 'Y' ? (
            <Typography
              component={'span'}
              color={theme.palette.primary.main}
              whiteSpace={'nowrap'}
              fontSize={14}
              fontWeight={600}
              className='mb-5'
            >
              {dateVerification(data.premiumDueDate, false, true)}
            </Typography>
          ) : (
            <Typography
              component={'span'}
              whiteSpace={'nowrap'}
              color={' rgba(127, 127, 127, 0.99)'}
              fontSize={14}
              fontWeight={600}
              className='mb-5'
            >
              {dateVerification(data.premiumDueDate, false, true)}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} display={'flex'} justifyContent={'end'}>
          <img
            src={data.insurerLogo}
            width={42}
            height={42}
            className='claendar-image'
          />
        </Grid>
      </Grid>
      <Grid item xs={0.5} className='py-5 mt-5'>
        <Separator
          orientation={'vertical'}
          color={theme.palette.grey[400]}
          className='h-75'
        />
      </Grid>
      <Grid item xs={9.5} className=' py-10'>
        <Grid item xs={12} className='px-15'>
          <Typography
            color={'rgba(127, 127, 127, 0.99)'}
            fontSize={16}
            fontWeight={600}
            lineHeight={'14px'}
            className='py-5'
          >
            {data.productName}
          </Typography>
        </Grid>
        <Grid item xs={12} className='px-15'>
          <Typography
            color={theme.palette.common.black}
            fontSize={14}
            lineHeight={'14px'}
            className='py-5'
          >
            Annual Premium
          </Typography>
          <LabelWithIcon
            justifyContent={'flex-start'}
            Icon={RupeeOrangeIcon}
            label={data.premiumAmount}
            labelProps={{
              color: theme.palette.common.black,
              fontSize: 16,
              fontWeight: 600,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {data.paymentFlag === 'Y' ? (
            <>
              <Typography
                color={theme.palette.secondary.main}
                fontSize={14}
                className='px-15'
              >
                Due on {data.premiumDueDate}
              </Typography>
              <Grid item className='mb-10 mt-10 ml-15'>
                <Separator color={theme.palette.grey[400]} />
              </Grid>
            </>
          ) : (
            <>
              <Grid item className=''>
                <Typography
                  color={theme.palette.grey[400]}
                  fontSize={14}
                  className='px-15'
                >
                  Due on {data.premiumDueDate}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {data.paymentFlag === 'Y' ? (
            <>
              <Grid container className='mt-5' justifyContent={'space-between'}>
                <Grid item>
                  {/* <Grid
                  container
                  alignItems='center'
                  spacing={0.5}
                  className='finance-my-premium p-5'
                >
                  <Grid item>
                    <SvgConverter Icon={OrangeRuppee} width={10} height={16} />
                  </Grid>
                  <Grid item>
                    <Typography
                      color={theme.palette.primary.main}
                      fontSize={12}
                      fontWeight={600}
                    >
                      FINANCE MY PREMIUM
                    </Typography>
                  </Grid>
                </Grid> */}
                  {/* current dis feature is not implemented so it is commented */}
                </Grid>
                <Grid item className=''>
                  <Link to={APP_ROUTES.PREMIUM_PAYMENT}>
                    <Grid container alignItems='center' spacing={0.5}>
                      <Grid item>
                        <Typography
                          color={theme.palette.primary.main}
                          fontSize={14}
                          fontWeight={600}
                        >
                          PAY NOW
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          alignItems='center'
                          justifyContent='center'
                          spacing={1}
                        >
                          <Grid item>
                            <SvgConverter
                              Icon={CircleIcon}
                              width={15}
                              height={15}
                            />
                          </Grid>
                          <Grid item style={{ position: 'relative' }}>
                            <div
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '-138%',
                                transform: 'translate(-50%, -50%)',
                              }}
                            >
                              <SvgConverter
                                className='rotate-180'
                                Icon={ArrowRightIcon}
                                width={15}
                                height={15}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Link to={APP_ROUTES.PREMIUM_PAYMENT}>
                <Grid container spacing={1}>
                  <Grid item xs={12} className='paid-date mt-20 ml-20'>
                    <LabelWithIcon
                      justifyContent={'flex-start'}
                      Icon={TickSuccessIcon}
                      label={'Paid on -'}
                      labelProps={{
                        color: theme.palette.success.main,
                        fontSize: 14,
                      }}
                      className='mb-5'
                    />
                  </Grid>
                </Grid>
              </Link>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PolicyCalendarCard;
