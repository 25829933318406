import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import Separator from "../../../common/ui/Separator";
import PolicyCard from "../Common/PolicyCard";

const UnassignedEmailPolicies = ({ policies, onChangeEmail }: any) => {
  const theme = useTheme();
  return (
    <>
      <Grid item xs={12}>
        <Typography className="f-16  mt-10" color={theme.palette.error.main}>
          Unassigned Policies
        </Typography>
      </Grid>
      <Grid item className="mt-10 mb-20">
        <Separator color={theme.palette.primary.main} borderWidth={2} />
      </Grid>
      <Grid item className="mobile-container policy-list-container">
        {policies.map((policy: any) => (
          <PolicyCard
            changeButtonText={"assign email"}
            policyDetails={policy}
            key={policy?.policyNo}
            onChangeClick={() => onChangeEmail(policy)}
          />
        ))}
      </Grid>
    </>
  );
};

export default UnassignedEmailPolicies;
