import React, { useEffect } from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import CustomRadioGroup from "../../../../common/ui/Forms/FormInput/RadioButton/RadioGroup";
import { CommonModal } from "../../../../common/ui/Modal/CommonModal";
import { ReactComponent as BankIcon } from "../../../../common/icons/update-mobile/Bank_icon.svg";
import CustomButton from "../../../../common/ui/CustomButton";
import Separator from "../../../../common/ui/Separator";
import { ReactComponent as Cancel } from "../../../../common/icons/update-mobile/cancel_orange.svg";
import { ReactComponent as Verify } from "../../../../common/icons/update-mobile/verify_orange.svg";
import { useSelector } from "react-redux";
import { getUniqueBanks } from "../Bank.slice";

const ChangeBankModalDetails = ({
  open,
  setOpen,
  onSubmit,
  addBankAccount,
  selectedAccount,
  selectedPolicy,
}: any) => {
  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
  };
  const [selectedOption, setSelectedOption] = React.useState<any>(false);

  const bankList = useSelector(getUniqueBanks);

  const otherBanks = bankList.filter(
    ({ bankAcNo }) => bankAcNo !== selectedAccount
  );

  useEffect(() => {
    setSelectedOption(false);
  }, [open]);

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
  };
  return (
    <CommonModal
      onClose={() => setOpen(false)}
      open={open}
      modalClassName="px-20 py-20"
      boxProps={{ width: 354 }}
    >
      <Grid item>
        <Grid item className="mb-10">
          <Typography
            className="f-16 fw-600"
            color={theme.palette.primary.main}
          >
            Select a bank account to assign.
          </Typography>

          <Grid container>
            <Grid
              item
              xs={12}
              className="my-30 custom-scroll-bar"
              height={"40vh"}
            >
              <CustomRadioGroup
                name="RadioGroup"
                options={otherBanks.map((data: any, index: number) => ({
                  value: data.bankAcNo,
                  label: (
                    <div className={index > 0 ? "mt-30" : ""}>
                      <Typography
                        className="f-16 fw-600"
                        color={theme.palette.primary.main}
                      >
                        {data.bankName}
                      </Typography>
                      <div>
                        {data.accountHolderName && `${data.accountHolderName},`}{" "}
                        Acc# {data.bankAcNo} {data.bankName}, {data.bankBranch},
                        {data.bankIfscCode}
                      </div>
                    </div>
                  ),
                }))}
                value={selectedOption}
                onChange={handleOptionChange}
                size="small"
              />
            </Grid>
            <Grid item xs={12} className="py-2">
              <Typography
                className="f-16 fw-600"
                color={theme.palette.primary.main}
              >
                OR
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <CustomButton
              text={"ADD A BANK ACCOUNT"}
              variant={"text"}
              color={"primary"}
              showIcon={false}
              onClick={addBankAccount}
              startIcon={BankIcon}
              className="py-15"
            />
          </Grid>

          <Grid item className="py-5">
            <Separator color={theme.palette.primary.main} />
          </Grid>
          <Grid container textAlign={"center"}>
            <Grid item xs={12} className="py-10 ">
              <CustomButton
                text={"CHANGE BANK"}
                variant={"text"}
                color={"primary"}
                showIcon={false}
                startIcon={Verify}
                onClick={() => onSubmit(selectedOption)}
                type="submit"
                fontWeight={600}
                fontSize={16}
              />
            </Grid>
            <Grid item xs={12} className="py-10">
              <CustomButton
                text={"Cancel"}
                variant={"text"}
                color={"primary"}
                showIcon={false}
                startIcon={Cancel}
                onClick={handleClose}
                fontSize={16}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default ChangeBankModalDetails;
