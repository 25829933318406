import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import TotalFundAndLossCoverDetails from './TotalFundAndLossCoverDetails';
import LoanPayoutDetails from './LoanPayoutDetails';
import '../LoanModule/LoanModule.scss';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as RoundIcon } from '../../../common/icons/LoanModule/round_icon.svg';
import { ReactComponent as PlusIcon } from '../../../common/icons/LoanModule/Plus-icon.svg';
import Separator from '../../../common/ui/Separator';
import { ReactComponent as rightArrow } from '../../../common/icons/right_arrow-icon.svg';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import ProductDetails from './ProductDetails';
import LinkWithArrow from './LinkWithArrow';

const MaxedOutCombination = () => {
  const theme = useTheme();

  return (
    <>
      <Grid className='need-money'>
        <Grid item>
          <Typography className='f-14 fw-600' color={'primary'}>
            Maxed Out Combinations
          </Typography>
        </Grid>
        <Grid item className='mb-5'>
          <Typography
            component={'span'}
            className='f-14'
            color={theme.palette.error.main}
          >
            (Not Recomended)
          </Typography>
        </Grid>
        <Grid item className='need-money mb-20'>
          <Separator color={theme.palette.primary.main} />
        </Grid>
        <Grid item className='my-options'>
          <SvgConverter Icon={RoundIcon} className='round-icon' />
          <Typography
            component={'span'}
            className='icon-number f-16'
            color={theme.palette.common.black}
          >
            1
          </Typography>
          <Grid
            container
            spacing={1}
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Grid item flexDirection={'column'} xs={5} className='ml-5'>
              <ProductDetails
                product={'Income Product'}
                selfName={'[Self Name]'}
                rupeeOne={'XXXXXXXX'}
                rupeeTwo={'XXXXXXXX'}
                IconProps={{ Icon: RupeeIcon }}
              />
            </Grid>
            <Grid item className='ml-30' alignItems={'center'}>
              <Separator
                orientation={'vertical'}
                color={theme.palette.grey[300]}
                borderWidth={1}
              />
            </Grid>
            <Grid item flexDirection={'column'} xs={5}>
              <LoanPayoutDetails
                payDetail={'Surrender Value'}
                rupee={'XXXXXXXX'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justifyContent={'center'} className='mt-10'>
          <SvgConverter Icon={PlusIcon} />
        </Grid>
        <Grid item className='my-options mt-20'>
          <SvgConverter Icon={RoundIcon} className='round-icon' />
          <Typography
            component={'span'}
            className='icon-number f-16'
            color={theme.palette.common.black}
          >
            2
          </Typography>
          <Grid
            container
            spacing={1}
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Grid item flexDirection={'column'} xs={5} className='ml-5'>
              <ProductDetails
                product={'Savings Product'}
                selfName={'[Self Name]'}
                rupeeOne={'XXXXXXXX'}
                rupeeTwo={'XXXXXXXX'}
                IconProps={{ Icon: RupeeIcon }}
              />
            </Grid>
            <Grid item className='ml-30' alignItems={'center'}>
              <Separator
                orientation={'vertical'}
                color={theme.palette.grey[300]}
                borderWidth={1}
              />
            </Grid>
            <Grid item flexDirection={'column'} xs={5}>
              <LoanPayoutDetails
                payDetail={'Surrender Value'}
                rupee={'XXXXXXXX'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justifyContent={'center'} className='mt-10'>
          <SvgConverter Icon={PlusIcon} />
        </Grid>
        <Grid item className='my-options mt-20'>
          <SvgConverter Icon={RoundIcon} className='round-icon' />
          <Typography
            component={'span'}
            className='icon-number f-16'
            color={theme.palette.common.black}
          >
            3
          </Typography>
          <Grid
            container
            spacing={1}
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Grid item flexDirection={'column'} xs={5} className='ml-5'>
              <ProductDetails
                product={'Savings Product'}
                selfName={'[Self Name]'}
                rupeeOne={'XXXXXXXX'}
                rupeeTwo={'XXXXXXXX'}
                IconProps={{ Icon: RupeeIcon }}
              />
            </Grid>
            <Grid item className='ml-30' alignItems={'center'}>
              <Separator
                orientation={'vertical'}
                color={theme.palette.grey[300]}
                borderWidth={1}
              />
            </Grid>
            <Grid item flexDirection={'column'} xs={5}>
              <LoanPayoutDetails
                payDetail={'Surrender Value'}
                rupee={'XXXXXXXX'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className='my-10'>
          <TotalFundAndLossCoverDetails
            TotalNo={'(1+2+3)'}
            rupee1={'XXXXXXXX'}
            rupee2={'XXXXXXXX'}
            Icon={RupeeIcon}
            labelProps={{ color: 'error' }}
          />
        </Grid>
        <LinkWithArrow to={''} text={'PROCEED'} Icon={rightArrow} />
      </Grid>
    </>
  );
};

export default MaxedOutCombination;
