import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import '../LoanModule/LoanModule.scss';
import SelectFinancierDetails from './SelectFinancierDetails';
import HdfcIcon from '../../../assets/images/HDFC.png';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import Separator from '../../../common/ui/Separator';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { ReactComponent as cancel } from '../../../common/icons/cancel-icon.svg';
import { ReactComponent as correct } from '../../../common/icons/LoanModule/right-arrow-icon.svg';
import { ReactComponent as rightArrow } from '../../../common/icons/right_arrow-icon.svg';
import ProductDetails from './ProductDetails';
import LinkWithArrow from './LinkWithArrow';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { Link } from 'react-router-dom';

const SubmitLoanApplication = () => {
  const theme = useTheme();
  return (
    <Grid container justifyContent={'center'}>
      <Grid item className='need-money'>
        <Grid item className={'mb-10'}>
          <Typography
            component={'span'}
            className={'f-16 fw-600'}
            color={theme.palette.common.black}
          >
            Step 1/2
          </Typography>
          <Typography
            className={'f-16 fw-600'}
            color={theme.palette.primary.main}
          >
            Submit Loan Application
          </Typography>
        </Grid>
        <Grid item className='mb-5'>
          <Grid
            container
            spacing={2}
            justifyContent={'space-between'}
            className='mb-14'
          >
            <Grid item>
              <Link to={APP_ROUTES.ADD_WITNESS_DETAILS}>
                <LabelWithIcon
                  className='f-14 fw-600'
                  justifyContent={'flex-start'}
                  Icon={correct}
                  label={'BACK'}
                  labelProps={{
                    fontSize: 14,
                    color: 'primary',
                    fontWeight: 600,
                  }}
                  iconProps={{ width: 26, height: 18 }}
                  svgClassName='submit-loan'
                />
              </Link>
            </Grid>
            <Grid item>
              <Link to={APP_ROUTES.RECOMMENDED_SELECTED}>
                <LabelWithIcon
                  className='f-14'
                  justifyContent={'flex-start'}
                  Icon={cancel}
                  label={'CANCEL'}
                  labelProps={{ fontSize: 14, color: 'primary' }}
                  iconProps={{ width: 18, height: 18 }}
                />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography className='f-14' color={theme.palette.common.black}>
            Policy Details
          </Typography>
          <Separator color={theme.palette.grey[300]} />
        </Grid>
        <Grid item>
          <Grid
            container
            flexWrap={'nowrap'}
            alignItems={'center'}
            justifyContent={'space-between'}
            spacing={1}
            className='p-10 '
          >
            <Grid item>
              <img src={HdfcIcon} alt='logo' width={'42'} height={'27'} />
            </Grid>
            <Grid item>
              <ProductDetails
                product={'Savings Product'}
                selfName={'[Self Name]'}
                rupeeOne={'XXXXXXXX'}
                rupeeTwo={'XXXXXXXX'}
                IconProps={{ Icon: RupeeIcon }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography className='f-14' color={theme.palette.common.black}>
            Financier
          </Typography>
          <Separator color={theme.palette.grey[300]} />
        </Grid>
        <Grid item>
          <Grid
            container
            flexWrap={'nowrap'}
            alignItems={'center'}
            justifyContent={'space-between'}
            spacing={1}
            className='p-10 '
          >
            <Grid item>
              <img src={HdfcIcon} alt='logo' width={'42'} height={'27'} />
            </Grid>
            <Grid item>
              <SelectFinancierDetails
                product={'Financier Name'}
                lifeAssured={'Loan Amount'}
                charges={'Charges:'}
                rupeeOne={'XXXXXXXX'}
                rupeeTwo={'XX'}
                rupeeThree={'XX'}
                repaymentOptions={'Repayment Option Selected'}
                decideLater={'Decide Later'}
                IconProps={{ Icon: RupeeIcon }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography className='f-14' color={theme.palette.common.black}>
            Witness Details
          </Typography>
          <Separator color={theme.palette.grey[300]} />
        </Grid>
        <Grid item>
          <Grid item className='my-2'>
            <Typography
              className='f-14 fw-600'
              color={theme.palette.primary.main}
            >
              Witness Name
            </Typography>
          </Grid>
          <Grid container spacing={0.5} justifyContent={'space-between'}>
            <Grid item xs={5} flexWrap={'nowrap'}>
              <Typography className='f-12' color={theme.palette.primary.main}>
                PAN# ABCDE0123X
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className='f-12 ' color={theme.palette.primary.main}>
                9876543210
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={0.5} justifyContent={'space-between'}>
            <Grid item xs={5}>
              <Typography
                className='f-12'
                color={theme.palette.primary.main}
                flexWrap={'nowrap'}
              >
                DoB: DD MM YYYY
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className='f-12 ' color={theme.palette.primary.main}>
                email@website.com
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <LinkWithArrow
          to={APP_ROUTES.TRACK_TRANSACTION}
          text={'E-SIGN THE APPLICATION'}
          Icon={rightArrow}
        />
      </Grid>
    </Grid>
  );
};

export default SubmitLoanApplication;
