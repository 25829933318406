import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import SvgConverter from '../../../common/SvgConverter';
import HdfcIcon from '../../../assets/images/HDFC.png';
import '../LoanModule/LoanModule.scss';
import { ReactComponent as RoundBlackicon } from '../../../common/icons/LoanModule/round_black_icon.svg';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import SelectedRecommendProductDetails from './SelectedRecommendProductDetails';
import FormInputWithRupee from './FormInputWithRupee';
import { ReactComponent as PlusIcon } from '../../../common/icons/LoanModule/Plus-icon.svg';
import TotalFundAndLossCoverDetails from './TotalFundAndLossCoverDetails';
import { ReactComponent as rightArrow } from '../../../common/icons/right_arrow-icon.svg';
import Separator from '../../../common/ui/Separator';
import MaturityAndFundValue from './MaturityAndFundValue';
import FinancierNameAndLoanAmountContent from './FinancierNameAndLoanAmountContent';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/LoanModule/left_arrow_icon.svg';
import LinkWithArrow from './LinkWithArrow';
import NeedMoneyHeader from './NeedMoneyHeader';

const RecommendedCombinationSelected = () => {
  const theme = useTheme();

  return (
    <>
      <Grid item className='mb-10'>
        <NeedMoneyHeader />
      </Grid>
      <Grid container justifyContent={'center'}>
        <Grid item>
          <Grid item sm={10}>
            <Typography
              className='f-16 fw-600'
              color={theme.palette.primary.main}
              flexWrap={'wrap'}
            >
              Recommended Combination selected
            </Typography>
          </Grid>
          <Grid item className='mb-14'>
            <Link to={APP_ROUTES.MY_OPTIONS}>
              <LabelWithIcon
                className='f-14'
                justifyContent={'flex-start'}
                Icon={ArrowLeftIcon}
                label={'GO BACK'}
                labelProps={{ fontSize: 14 }}
                iconProps={{ width: 14, height: 14 }}
                svgClassName='go-back-icon mb-2'
              />
            </Link>
          </Grid>
          <Grid item className='mb-20'>
            <Separator color={theme.palette.grey[300]} />
          </Grid>
          <Grid item className='recommend-select'>
            <SelectedRecommendProductDetails
              Icon={RoundBlackicon}
              logoSrc={HdfcIcon}
              product={'Income Product'}
              selfName={'[Self Name]'}
            />
            <Typography
              component={'span'}
              className='recommend-icon-number one f-16'
              color={theme.palette.common.black}
            >
              1
            </Typography>
            <Grid item bgcolor={'#f2f2f2'} className='p-10'>
              <Grid container spacing={0.5}>
                <Grid item>
                  <Typography
                    className='f-14'
                    color={theme.palette.common.black}
                  >
                    Payout of
                  </Typography>
                </Grid>
                <Grid item>
                  <SvgConverter Icon={RupeeIcon} />
                </Grid>
                <Grid item>
                  <Typography
                    className='f-16 fw-600'
                    color={theme.palette.primary.main}
                  >
                    XXXXXXXX
                  </Typography>
                </Grid>
              </Grid>
              <Typography className='f-14'>
                to be recieved on DDMMYYYY
              </Typography>
            </Grid>
          </Grid>
          <Grid item container justifyContent={'center'} className='my-10'>
            <SvgConverter Icon={PlusIcon} />
          </Grid>
          <Grid item className='recommend-select'>
            <Grid item>
              <SelectedRecommendProductDetails
                Icon={RoundBlackicon}
                logoSrc={HdfcIcon}
                product={'Savings Product'}
                selfName={'[Self Name]'}
              />
            </Grid>
            <Typography
              component={'span'}
              className='recommend-icon-number two f-16'
              color={theme.palette.common.black}
            >
              2
            </Typography>
            <Grid item bgcolor={'#f2f2f2'} className='p-10'>
              <MaturityAndFundValue />
              <Grid item>
                <Grid container spacing={1} className='mt-4'>
                  <Grid item>
                    <Typography
                      className='f-14'
                      color={theme.palette.common.black}
                    >
                      Partial Withdrawal of
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      className='f-12 mt-2'
                      color={theme.palette.common.black}
                      justifyContent={'center'}
                    >
                      [xx % of fund value]
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className='mt-4'>
                <FormInputWithRupee />
              </Grid>
              <Typography className='f-14'>to be initiated</Typography>
            </Grid>
          </Grid>
          <Grid item container justifyContent={'center'} className='my-10'>
            <SvgConverter Icon={PlusIcon} />
          </Grid>
          <Grid item>
            <FinancierNameAndLoanAmountContent />
          </Grid>

          <Grid item className='my-10'>
            <TotalFundAndLossCoverDetails
              TotalNo={'(1+2+3)'}
              rupee1={'XXXXXXXX'}
              rupee2={'Nil'}
            />
          </Grid>
          <LinkWithArrow
            to={APP_ROUTES.ADD_WITNESS_DETAILS}
            text={'PROCEED'}
            Icon={rightArrow}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default RecommendedCombinationSelected;
