import { Grid } from '@mui/material';
import React from 'react';
import '../../Policy.scss';
import ArchiveHealthCard from '../ArchiveContent/ArchiveHealthCard';

interface UserProps {
  headerType?: string;
  contentType?: string;
  premiumDue?: string;
  filteredHealth?: any;
}
export const ArchiveHealth: React.FC<UserProps> = ({ filteredHealth }) => {
  return (
    <Grid container justifyContent={'center'}>
      {filteredHealth.map((data: any, index: any) => (
        <Grid item key={index} xs={4}>
          <ArchiveHealthCard contentType={'archive'} items={data} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ArchiveHealth;
