import { Box, Grid } from "@mui/material";
import React, { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectPoliciesForEmails } from "./Emails.slice";
import { filter, findIndex, groupBy, prop, propEq, reject } from "ramda";
import MobilePolicyHeader from "../MobileNumber/common/MobilePolicyHeader";
import { PolicyAlternateCodes } from "../../../common/types/PolicyTypes";
import PolicyCard from "../Common/PolicyCard";
import UnassignedEmail from "./UnassignedEmail";
import UnassignedEmailPolicies from "./UnassignedEmailPolicies";

const ManageEmailHeader = ({
  toggleRemoveNumberModal,
  toggleChangeEmailModal,
  handleAssignPolicy,
  handleRemoveForUnAssignedEmails,
}: any) => {
  const [count, setCount] = useState(0);
  const groupPoliciesByNumber = groupBy(prop<any>("email"));
  const { emailBasedPolicies, unAssignedEmails } = useSelector(
    selectPoliciesForEmails
  );
  const { assignedPoliciesObj, unAssignedPolicies }: any = useMemo(() => {
    let data: any = { assignedPoliciesObj: {}, unAssignedPolicies: [] };
    if (emailBasedPolicies?.length > 0) {
      let assignedPoliciesObjLocal = groupPoliciesByNumber(
        emailBasedPolicies ? emailBasedPolicies : []
      );
      const unAssignedPoliciesLocal = assignedPoliciesObjLocal.null;
      delete assignedPoliciesObjLocal.null;
      data = {
        assignedPoliciesObj: assignedPoliciesObjLocal,
        unAssignedPolicies: unAssignedPoliciesLocal
          ? unAssignedPoliciesLocal
          : [],
      };
    }
    return data;
  }, [emailBasedPolicies, groupPoliciesByNumber]);

  const lifePolicies = filter(
    propEq(PolicyAlternateCodes.LIFE, "alternateCode")
  );
  const healthPolicies: any = filter(
    propEq(PolicyAlternateCodes.HEALTH, "alternateCode")
  );
  const assetPolicies: any = filter(
    propEq(PolicyAlternateCodes.ASSET, "alternateCode")
  );

  useEffect(() => {
    setCount(count + 1);
  }, []);

  const unAssignedEmailsLocal = useMemo(() => {
    if (unAssignedEmails && emailBasedPolicies) {
      return reject(
        ({ email }: any) =>
          findIndex(propEq("email", email))(emailBasedPolicies) >= 0,
        unAssignedEmails
      );
    } else {
      return [];
    }
  }, [unAssignedEmails, emailBasedPolicies]);

  return (
    <>
      <Grid container spacing={3} className="mt-0 ">
        {Object.keys(assignedPoliciesObj).map(
          (email: string, index: number) => (
            <Grid item key={email} xs={12} sm={6} md={3}>
              <MobilePolicyHeader
                index={index}
                mobileNo={email}
                verified={assignedPoliciesObj[email][0]["emailVerified"]}
                primary={assignedPoliciesObj[email][0]["primaryFlag"]}
                onRemove={() => toggleRemoveNumberModal(email)}
                // handleAssignPolicy={handleAssignPolicy}
                showAssignPolicyButton={false}
                // onVerify={() => handleInstantVerify(email)}
              />
              <Grid
                item
                className="mobile-Policy-screen policy-list-container p-20"
              >
                {lifePolicies(assignedPoliciesObj[email]).map((policy: any) => (
                  <PolicyCard
                    policyDetails={policy}
                    key={policy?.policyNo}
                    changeButtonText={"CHANGE EMAIL"}
                    onChangeClick={() => toggleChangeEmailModal(policy)}
                  />
                ))}
                {healthPolicies(assignedPoliciesObj[email]).map(
                  (policy: any) => (
                    <PolicyCard
                      policyDetails={policy}
                      key={policy?.policyNo}
                      changeButtonText={"CHANGE EMAIL"}
                      onChangeClick={() => toggleChangeEmailModal(policy)}
                    />
                  )
                )}
                {assetPolicies(assignedPoliciesObj[email]).map(
                  (policy: any) => (
                    <PolicyCard
                      policyDetails={policy}
                      key={policy?.policyNo}
                      changeButtonText={"CHANGE EMAIL"}
                      onChangeClick={() => toggleChangeEmailModal(policy)}
                    />
                  )
                )}
              </Grid>
            </Grid>
          )
        )}
      </Grid>
      {unAssignedEmailsLocal?.length > 0 && (
        <div className="mt-15">
          <UnassignedEmail
            emails={unAssignedEmailsLocal}
            handleAssignPolicy={handleAssignPolicy}
            handleRemoveForUnAssignedEmails={handleRemoveForUnAssignedEmails}
          />
        </div>
      )}

      {unAssignedPolicies?.length > 0 && (
        <div className="mt-15">
          <UnassignedEmailPolicies
            policies={unAssignedPolicies}
            onChangeEmail={toggleChangeEmailModal}
          />
        </div>
      )}
    </>
  );
};

export default ManageEmailHeader;
