import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);
const defaults = ChartJS.defaults;
// console.log('defaults', defaults);
defaults.plugins.legend.display = true;
defaults.plugins.legend.labels.boxWidth = 14;
defaults.plugins.legend.labels.boxHeight = 14;
defaults.plugins.legend.labels.useBorderRadius = true;
defaults.plugins.legend.labels.borderRadius = 14;

interface UserProps {
  riskprofile?: any;
}
export const DoughnutChart: React.FC<UserProps> = ({ riskprofile }) => {
  const lowData = riskprofile?.low.length === 0 ? 0 : riskprofile?.low;
  const mediumData = riskprofile?.medium.length === 0 ? 0 : riskprofile?.medium;
  const highData = riskprofile?.high.length === 0 ? 0 : riskprofile?.high;
  const chartData = {
    labels: ['Low', 'Medium', 'High'],
    datasets: [
      {
        labels: ['Low', 'Medium', 'High'],
        data: [lowData, mediumData, highData],
        backgroundColor: ['#95f204', '#f59a23', '#d9001b'],
        borderWidth: 1,
        cutout: '83%',
        radius: '100%',
      },
    ],
    options: {
      legend: {
        position: 'left',
        display: 'none',
      },
    },
  };
  return <Doughnut data={chartData} options={{ responsive: false }} />;
};

export default DoughnutChart;
