import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import theme from '../../../../../common/theme';
import '../../Policy.scss';
import LabelWithIcon from '../../../../../common/ui/LabelWithIcon';
import HDFC from '../../../../../assets/images/HDFC.png';
import { ReactComponent as OrangeButton } from '../../../../../common/icons/policy/orange-button.svg';
import { ReactComponent as OrangeRuppee } from '../../../../../common/icons/small-orange-rupee.svg';
import VerificationStatus from './VerificationStatus';
interface UserProps {
  contentType?: string;
  items?: any;
}
const HealthHeader: React.FC<UserProps> = ({ contentType, items }) => {
  return (
    <Grid container>
      <Grid container className='px-10 py-15' spacing={2}>
        <Grid item xs={3}>
          <img src={items.companyLogo} width={'60px'} />
          <VerificationStatus status={items.verifiedFlag} />
        </Grid>
        <Grid item xs={9}>
          <Typography
            className='f-14 fw-600 mb-5 '
            color={theme.palette.primary.main}
          >
            {items.planName}
          </Typography>
          <>
            {' '}
            <Typography className='f-14 ' color={theme.palette.primary.main}>
              Product Type
            </Typography>
            <Typography
              className='f-16 fw-600'
              color={theme.palette.common.black}
            >
              {items.customerName.length === 0 ? '-' : items.customerName}
            </Typography>
            <Typography
              className='f-14 fw-600'
              color={
                contentType === 'active'
                  ? theme.palette.common.black
                  : theme.palette.grey.A700
              }
            >
              {items?.PlanName}
            </Typography>
            <LabelWithIcon
              svgClassName=''
              justifyContent={'flex-start'}
              Icon={OrangeButton}
              label={items.policyStatus}
              labelProps={{
                color: theme.palette.primary.main,
                fontSize: 16,
              }}
            />
            <Typography className='f-14 ' color={theme.palette.primary.main}>
              Total Sum Assured
            </Typography>
            <LabelWithIcon
              className='px-6 mr-10'
              svgClassName=''
              justifyContent={'flex-start'}
              Icon={OrangeRuppee}
              label={items?.sumAssured.length === 0 ? '-' : items.sumAssured}
              labelProps={{
                color: theme.palette.grey.A700,
                fontSize: 16,
                fontWeight: 600,
              }}
            />
          </>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HealthHeader;
