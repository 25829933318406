import React, { useState, useEffect } from 'react';
import { Grid, useTheme } from '@mui/material';
import { ReactComponent as cancel } from '../../../../common/icons/cancel-icon.svg';
import { ReactComponent as correct } from '../../../../common/icons/tick-icon.svg';
import './AddFamilyMember.scss';
import Typography from '@mui/material/Typography';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import DatePickerModal from '../../../../common/ui/DatePickerModal';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useLocation, useParams } from 'react-router-dom';
import Separator from '../../../../common/ui/Separator';
// import ProfileUploader from '../ProfileUploader';
import CustomButton from '../../../../common/ui/CustomButton';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import data from '../../../../common/constants/FormConstants.json';
// import ProfileUploader from '../ProfileUploader';
import { useDispatch, useSelector } from 'react-redux';
import {
  addFamilyMemberRequest,
  fetchUserProfile,
  selectUserProfile,
} from '../Profile.slice';
import { selectIsUserLoggedIn } from '../../../Auth/auth.slice';
import FormCheckBox from '../../../../common/ui/Forms/FormInput/CheckBox/FormCheckBox';
import AddFamilyAddress from './AddFamilyAddress';
const AddFamilyMember = () => {
  const location = useLocation();
  const Relationdata = location.state ? location.state.data : null;
  console.log('relationData', Relationdata);
  const theme = useTheme();
  const linkparams = useParams();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    watch,
  } = useForm();
  const handleDateChange = (date: any) => {
    const formattedDate = date?.format('DD/MM/YYYY');

    if (formattedDate) {
      const [day, month, year] = formattedDate.split('/');

      setValue('date', day, { shouldValidate: true });
      setValue('month', month, { shouldValidate: true });
      setValue('year', year, { shouldValidate: true });
    } else {
      setValue('date', '', { shouldValidate: true });
      setValue('month', '', { shouldValidate: true });
      setValue('year', '', { shouldValidate: true });
    }

    setValue('dob', formattedDate, { shouldValidate: true });
  };

  const [isChecked, setIsChecked] = useState(false);
  const permanentAddress = watch('permanentAddress');

  const relationCode = Relationdata?.relationCode;
  console.log('relationcode', relationCode);
  const userInfo = useSelector(selectIsUserLoggedIn);
  const navigation = useNavigate();
  const onSubmit = (data: any) => {
    if (!isValid) {
      return false;
    }
    const payload = {
      FamilyMember: {
        relationCode: relationCode,
        eiaNoFamily: userInfo?.eiaNo,
        firstName: data.firstName,
        middleName: data.middleName ? data.middleName : '',
        lastName: data.lastName ? data.lastName : '',
        //  imageBase64: imageBase64 ? imageBase64 : '',
        email: data.email,
        mobCountryCode: data.countryCode,
        mobNumber: data.mobileNumber,
        mobNoVerified: 'N',
        dob: data?.dob,
        address1: data.address1,
        address2: data.address2,
        address3: data.address3,
        city: data.city,
        state: data.state,
        country: 'India',
        pin: data.pin,
        authorizedPerson: '',
        addressSameAsLifeAssured: data.permanentAddress === true ? 'Y' : 'N',
      },
      navigation,
    };
    console.log('data', data);
    dispatch(addFamilyMemberRequest(payload as any));
  };

  const dobValue = watch('dob');
  const mobileNumberRegex = /^\d{10}$/;
  useEffect(() => {
    dispatch(fetchUserProfile());
  }, []);
  const userProfile = useSelector(selectUserProfile);
  const permanentEiaAddress: any =
    userProfile?.Customer?.PermanentAddress || {};
  console.log('permanentEiaAddress', permanentEiaAddress);

  const handleChange = () => {
    setValue('permanentAddress', !permanentAddress);

    if (!permanentAddress) {
      setValue('address1', permanentEiaAddress?.address1 || '');
      setValue('address2', permanentEiaAddress?.address2 || '');
      setValue('address3', permanentEiaAddress?.address3 || '');
      setValue('city', permanentEiaAddress?.city || '');
      setValue('country', permanentEiaAddress?.country || '');
      setValue('pin', permanentEiaAddress?.pincode || '');
      setValue('state', permanentEiaAddress?.state || '');
    } else {
      setValue('address1', '');
      setValue('address2', '');
      setValue('address3', '');
      setValue('city', '');
      setValue('country', '');
      setValue('pin', '');
      setValue('state', '');
    }

  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <Typography className='f-16  fw-600 mb-10' color={'primary'}>
            Add Family Member-{Relationdata?.relationship}
          </Typography>
        </Grid>
        <Grid item className='mb-5'>
          <Grid
            container
            spacing={2}
            justifyContent={'space-between'}
            className='mb-14'
          >
            <Grid item>
              <Link to={APP_ROUTES.PROFILE}>
                <CustomButton
                  className='f-14'
                  text={'CANCEL'}
                  variant={'text'}
                  color={'primary'}
                  showIcon={false}
                  startIcon={cancel}
                  type='submit'
                />
              </Link>
            </Grid>
            <Grid item>
              <CustomButton
                className='f-14 fw-600 '
                text={'ADD MEMBER'}
                variant={'text'}
                color={'primary'}
                showIcon={false}
                startIcon={correct}
                type='submit'
              />
            </Grid>
          </Grid>
        </Grid>
        <Separator color={theme.palette.primary.main} />
      </Grid>
      <Grid container className='h-100 mt-15' justifyContent={'center'}>
        <Grid item className='add-family'>
          <Grid item>
            <Typography className='f-14' color={'primary'}>
              Name
            </Typography>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <FormInput
                type='text'
                placeholder='First Name'
                // value={firstName}
                name='firstName'
                inputProps={{
                  className: 'text-center',
                  ...register('firstName', {
                    required: {
                      value: true,
                      message: data.FIRSTNAME,
                    },
                  }),
                }}
                errors={errors}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormInput
                type='text'
                placeholder='Middle Name'
                name='middleName'
                inputProps={{
                  className: 'text-center',
                  ...register('middleName', {
                    required: {
                      value: true,
                      message: data.MIDDLENAME,
                    },
                  }),
                }}
                errors={errors}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} className='mt-2 mb-14'>
            <Grid item xs={6} sm={6}>
              <FormInput
                type='text'
                placeholder='Last Name'
                name='lastName'
                inputProps={{
                  className: 'text-center',
                  ...register('lastName', {
                    required: {
                      value: true,
                      message: data.LASTNAME,
                    },
                  }),
                }}
                errors={errors}
              />
            </Grid>
            <Grid item xs={6} sm={6} display={'flex'}>
              <Grid display={'flex'}>
                {/* <ProfileUploader /> */}
                <Typography
                  className='ml-12 f-14'
                  flexWrap={'wrap'}
                  color={'primary'}
                >
                  Upload Pic (optional)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography className='f-14' color={'primary'}>
              Email
            </Typography>
          </Grid>
          <Grid item className='mb-10'>
            <FormInput
              type='email'
              placeholder='ABCDE0100X'
              name='email'
              inputProps={{
                className: 'text-center',
                ...register('email', {
                  required: {
                    value: true,
                    message: data.EMAIL,
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item>
            <Typography className='f-14' color={'primary'}>
              Mobile Number
            </Typography>
          </Grid>
          <Grid container flexWrap={'nowrap'} spacing={1} className='mb-10'>
            <Grid item xs={3} sm={3}>
              <FormInput
                inputProps={{
                  className: 'px-5',
                  ...register('countryCode'),
                }}
                type='number'
                placeholder='+91'
                hideCountButton={true}
              />
            </Grid>
            <Grid item flex={1}>
              <FormInput
                type='number'
                placeholder='1234567890'
                hideCountButton={true}
                name='mobileNumber'
                inputProps={{
                  ...register('mobileNumber', {
                    required: data.MOBILENUMBER,
                    maxLength: {
                      value: 10,
                      message: data.MOBILEDIGIT,
                    },
                    pattern: {
                      value: mobileNumberRegex,
                      message: 'Invalid mobile number',
                    },
                  }),
                }}
                errors={errors}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography className='f-14' color={'primary'}>
              Date Of Birth
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            flexDirection={'column'}
            className='mb-5'
          >
            <Grid
              item
              container
              flexDirection={'row'}
              spacing={2}
              alignItems={'center'}
              justifyContent={'space-between'}
              flexWrap={'nowrap'}
            >
              <Grid item sm={3}>
                <FormInput
                  placeholder={'DD'}
                  name='date'
                  inputProps={{
                    className: 'text-center',
                    value: dobValue,
                  }}
                />
              </Grid>
              <Grid item sm={3}>
                <FormInput
                  placeholder={'MM'}
                  name='month'
                  inputProps={{
                    className: 'text-center',
                    value: dobValue,
                  }}
                />
              </Grid>
              <Grid item sm={4}>
                <FormInput
                  placeholder={'YYYY'}
                  name='year'
                  inputProps={{
                    className: 'text-center',
                    value: dobValue,
                  }}
                />{' '}
              </Grid>
              <Grid item>
                <DatePickerModal
                  iconType={'primary'}
                  onChange={handleDateChange}
                />
              </Grid>
            </Grid>{' '}
            <FormInput
              hidden={true}
              inputProps={{
                ...register('dob', {
                  required: {
                    value: true,
                    message: 'This field is mandatory!',
                  },
                }),
              }}
              errors={errors}
            />
          </Grid>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Typography className='f-14' color={'primary'}>
                Address
              </Typography>
            </Grid>
            <Grid item className='mt-n2 '>
              <FormCheckBox
                label={
                  <Typography
                    className='f-14'
                    color={theme.palette.common.black}
                  >
                    same as life Assured
                  </Typography>
                }
                checked={permanentAddress}
                onClick={handleChange}
                // onClick={handleChange}
                size={16}
                checkBoxPadding={1}
                {...register('permanentAddress')}

              />
            </Grid>
          </Grid>
          {permanentAddress ? (
            <Typography className='f-12' color={theme.palette.common.black}>
              same as life Assured
            </Typography>
          ) : (
            <AddFamilyAddress
              setValue={setValue}
              register={register}
              errors={errors}
              permanentAddress={permanentAddress}
              permanentEiaAddress={permanentEiaAddress}
              {...watch()}
            />
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default AddFamilyMember;
