import { createSlice } from "@reduxjs/toolkit";
import { LoadingState } from "../../common";
import { RootState } from "../../common/store";
import { AuthUser } from "../../common/utils";

interface AuthState {
  token?: string | null;
  user: AuthUser | null;
  authUserInfo: any;
  loginRequest: {
    status: LoadingState;
    requestInfo: any;
    error: any;
  };
  otpRequest: {
    status: LoadingState;
    responseInfo: any;
    error: any;
  };
  otpVerify: {
    status: LoadingState;
    responseInfo: any;
    error: any;
  };
  setMpin: {
    status: LoadingState;
    responseInfo: any;
    error: any;
  };
  verifyMpin: {
    status: LoadingState;
    responseInfo: any;
    error: any;
  };
  signUp: {
    status: LoadingState;
    responseInfo: any;
    error: any;
    payload: any;
  };
  initiateEkyc: {
    status: LoadingState;
    responseInfo: any;
    error: any;
  };
  saveEkyc: {
    status: LoadingState;
    responseInfo: any;
    error: any;
  };
}

const initialState: AuthState = {
  token: null,
  user: null,
  authUserInfo: null,
  loginRequest: {
    status: "idle",
    requestInfo: undefined,
    error: undefined,
  },
  otpRequest: {
    status: "idle",
    responseInfo: undefined,
    error: undefined,
  },
  otpVerify: {
    status: "idle",
    responseInfo: undefined,
    error: undefined,
  },
  setMpin: {
    status: "idle",
    responseInfo: undefined,
    error: undefined,
  },
  verifyMpin: {
    status: "idle",
    responseInfo: undefined,
    error: undefined,
  },
  signUp: {
    status: "idle",
    responseInfo: undefined,
    error: undefined,
    payload: undefined,
  },
  initiateEkyc: {
    status: "idle",
    responseInfo: undefined,
    error: undefined,
  },
  saveEkyc: {
    status: "idle",
    responseInfo: undefined,
    error: undefined,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    fetchLoginRequest: (state, _action) => {
      state.loginRequest.status = "loading";
      state.loginRequest.requestInfo = undefined;
      state.loginRequest.error = undefined;
    },
    reportLoginRequestSuccess: (state, { payload }) => {
      state.loginRequest.status = "done";
      state.loginRequest.requestInfo = payload;
      state.loginRequest.error = undefined;
    },
    reportLoginRequestError: (state, { payload }) => {
      state.loginRequest.status = "error";
      state.loginRequest.requestInfo = undefined;
      state.loginRequest.error = payload;
    },

    fetchOtpRequest: (state, _action) => {
      state.token = "token";
      state.otpRequest.status = "loading";
      state.otpRequest.responseInfo = undefined;
      state.otpRequest.error = undefined;
    },
    reportOtpLoginSuccess: (state, { payload }) => {
      state.otpRequest.status = "done";
      state.otpRequest.responseInfo = payload;
      state.otpRequest.error = undefined;
    },
    reportOtpLoginError: (state, { payload }) => {
      state.otpRequest.status = "error";
      state.otpRequest.responseInfo = undefined;
      state.otpRequest.error = payload;
    },

    fetchOtpVerify: (state, _action) => {
      state.otpVerify.status = "loading";
      state.otpVerify.responseInfo = undefined;
      state.otpVerify.error = undefined;
    },
    reportOtpVerifySuccess: (state, { payload }) => {
      state.otpVerify.status = "done";
      state.otpVerify.responseInfo = payload;
      state.otpVerify.error = undefined;
    },
    reportOtpVerifyError: (state, { payload }) => {
      state.otpVerify.status = "error";
      state.otpVerify.responseInfo = undefined;
      state.otpVerify.error = payload;
    },

    setMpinRequest: (state, _action) => {
      state.setMpin.status = "loading";
      state.setMpin.responseInfo = undefined;
      state.setMpin.error = undefined;
    },
    setMpinSuccess: (state, { payload }) => {
      state.setMpin.status = "done";
      state.setMpin.responseInfo = payload;
      state.setMpin.error = undefined;
    },
    setMpinError: (state, { payload }) => {
      state.setMpin.status = "error";
      state.setMpin.responseInfo = undefined;
      state.setMpin.error = payload;
    },

    verifyMpinRequest: (state, _action) => {
      state.verifyMpin.status = "loading";
      state.verifyMpin.responseInfo = undefined;
      state.verifyMpin.error = undefined;
    },
    verifyMpinSuccess: (state, { payload }) => {
      state.verifyMpin.status = "done";
      state.verifyMpin.responseInfo = payload;
      state.verifyMpin.error = undefined;
    },
    verifyMpinError: (state, { payload }) => {
      state.verifyMpin.status = "error";
      state.verifyMpin.responseInfo = undefined;
      state.verifyMpin.error = payload;
    },

    signUpRequest: (state, { payload }) => {
      state.signUp.status = "loading";
      state.signUp.responseInfo = undefined;
      state.signUp.error = undefined;
      state.signUp.payload = payload;
    },
    signUpSuccess: (state, { payload }) => {
      state.signUp.status = "done";
      state.signUp.responseInfo = payload;
      state.signUp.error = undefined;
    },
    signUpError: (state, { payload }) => {
      state.signUp.status = "error";
      state.signUp.responseInfo = undefined;
      state.signUp.error = payload;
    },

    initiateEkycRequest: (state) => {
      state.initiateEkyc.status = "loading";
      state.initiateEkyc.responseInfo = undefined;
      state.initiateEkyc.error = undefined;
    },
    initiateEkycSuccess: (state, { payload }) => {
      state.initiateEkyc.status = "done";
      state.initiateEkyc.responseInfo = payload;
      state.initiateEkyc.error = undefined;
    },
    initiateEkycError: (state, { payload }) => {
      state.initiateEkyc.status = "error";
      state.initiateEkyc.responseInfo = undefined;
      state.initiateEkyc.error = payload;
    },

    saveEkycRequest: (state) => {
      state.saveEkyc.status = "loading";
      state.saveEkyc.responseInfo = undefined;
      state.saveEkyc.error = undefined;
    },
    saveEkycSuccess: (state, { payload }) => {
      state.saveEkyc.status = "done";
      state.saveEkyc.responseInfo = payload;
      state.saveEkyc.error = undefined;
    },
    saveEkycError: (state, { payload }) => {
      state.saveEkyc.status = "error";
      state.saveEkyc.responseInfo = undefined;
      state.saveEkyc.error = payload;
    },

    setUser: (state, action) => {
      state.user = action.payload;
    },
    userSignOut: (state) => {
      state.user = null;
      sessionStorage.clear();
    },
  },
});

export const {
  setUser,
  userSignOut,
  fetchLoginRequest,
  reportLoginRequestError,
  reportLoginRequestSuccess,
  reportOtpLoginSuccess,
  reportOtpLoginError,
  fetchOtpRequest,
  fetchOtpVerify,
  reportOtpVerifySuccess,
  reportOtpVerifyError,
  setMpinRequest,
  setMpinSuccess,
  setMpinError,
  verifyMpinRequest,
  verifyMpinSuccess,
  verifyMpinError,
  signUpRequest,
  signUpSuccess,
  signUpError,
  initiateEkycRequest,
  initiateEkycSuccess,
  initiateEkycError,
  saveEkycRequest,
  saveEkycSuccess,
  saveEkycError,
} = authSlice.actions;
export default authSlice.reducer;

export const selectIsLoginRequestLoading = (state: RootState) =>
  state.auth.loginRequest.status === "loading";

export const selectLoginRequestInfo = (state: RootState) =>
  state.auth.loginRequest.requestInfo;

export const selectLoginRequestInfoError = (state: RootState) =>
  state.auth.loginRequest.error;

export const selectIsOtpRequestLoading = (state: RootState) =>
  state.auth.otpRequest.status === "loading";

export const selectOtpResponseInfo = (state: RootState) =>
  state.auth.otpRequest.responseInfo;

export const selectOtpLoginError = (state: RootState) =>
  state.auth.otpRequest.error;

export const selectIsUserLoggedIn = (state: RootState) => state.auth.user;

export const selectIsOtpValidationLoading = (state: RootState) =>
  state.auth.otpVerify.status === "loading";

export const selectIsSetMpinLoading = (state: RootState) =>
  state.auth.setMpin.status === "loading";

export const selectIsVerifyMpinLoading = (state: RootState) =>
  state.auth.verifyMpin.status === "loading";

export const selectIsSignUpLoading = (state: RootState) =>
  state.auth.signUp.status === "loading";
export const selectIsSignUpData = (state: RootState) =>
  state.auth.signUp.responseInfo;
export const selectSignUpPayload = (state: RootState) =>
  state.auth.signUp?.payload?.Customer;

export const selectIsInitiateEkycLoading = (state: RootState) =>
  state.auth.initiateEkyc.status === "loading";
export const selectInitiateEkyc = (state: RootState) =>
  state.auth.initiateEkyc.responseInfo;

export const selectIsSaveEkycLoading = (state: RootState) =>
  state.auth.saveEkyc.status === "loading";
export const selectSaveEkycResponse = (state: RootState) =>
  state.auth.saveEkyc.responseInfo;
export const selectLoginToken = (state: RootState) => state.auth.token;
