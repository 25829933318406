import React, { useState } from 'react';
import { IconButton, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import SvgConverter from '../../../common/SvgConverter';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { ReactComponent as questionmark } from '../../../common/icons/profile/question-mark.svg';
import { ReactComponent as verticalDivider } from '../../../common/icons/profile/vertical-divider.svg';
import { ReactComponent as alert } from '../../../common/icons/profile/alert.svg';
import { ReactComponent as checked } from '../../../common/icons/profile/checked.svg';
import VerifiedMobilenumber from './VerifiedMobilenumber';
import NotVerifiedMobilenumber from './NotKnownMobilenumber';
import VerifiedEmail from './VerifiedEmail';
import NotVerifiedEmail from './NotKnownEmail';
import AddressDetails from './AddressDetails';
import Addressverified from './Addressverified';
import VerifiedNominee from './VerifiedNominee';
import AddressNotVerified from './AddressNotVerified';
import AddressNotKnown from './AddressNotKnown';
import VerifiedAuthorisedPerson from './verifiedAuthorisedPerson';
import Separator from '../../../common/ui/Separator';
import ProfileUploader from './Profileuploader';
import VerifiedText from './VerifiedText';
import ImagePickerModal from '../../../common/ui/Modal/ImagePickerModal';
import CustomButton from '../../../common/ui/CustomButton';

const FamilyDetailCard = ({
  data,
  addNewMobile,
  addNewEmail,
  onVerifyEmail,
  onVerifyMobile,
}: {
  data?: any;
  addNewMobile?: () => void;
  addNewEmail?: () => void;
  onVerifyMobile?: () => void;
  onVerifyEmail?: () => void;
}) => {
  const theme = useTheme();
  const convertedMobile = data.mobNoVerified.toUpperCase();
  const convertedEmail = data.emailVerified.toUpperCase();
  const convertedAddress = data.addressVerified.toUpperCase();
  const [modalOpen, setModalOpen] = useState(false);
  const handleModal = () => {
    setModalOpen(true);
  };
  return (
    <Grid container flexDirection={'column'} className='mb-4 '>
      <div className='container-family'>
        <Grid item>
          <Typography className='f-16 fw-600 mb-4' color={'primary'}>
            {data.relationName}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <ProfileUploader />
          </Grid>
          <Grid item justifyContent={'flex-start'}>
            <Grid item>
              <Typography className='f-18' color={theme.palette.common.black}>
                {data.firstName} {data.middleName} {data.lastName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className='f-14' color={'primary'}>
                [eIA ID :
                {data.eiaNoFamily ? (
                  data.eiaNoFamily
                ) : (
                  <Typography
                    component={'span'}
                    className='f-12'
                    color={theme.palette.error.main}
                  >
                    Not Known
                  </Typography>
                )}
                ]
              </Typography>
            </Grid>
            <Grid item>{data.isNominee === 'Y' && <VerifiedNominee />}</Grid>
            <Grid item>
              {data.authorizedPerson === 'Y' && <VerifiedAuthorisedPerson />}
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container spacing={0.6}>
            <Grid item>
              <Typography
                className='f-14 fw-600'
                color={theme.palette.primary.main}
              >
                Mobile number
              </Typography>
            </Grid>
            {data.mobNumber && (
              <Grid item className='mt-2'>
                <VerifiedText
                  isVerified={convertedMobile === 'Y'}
                  label={convertedMobile === 'Y' ? 'verified' : 'not verified'}
                />
              </Grid>
            )}
            {!data.mobNumber && <NotVerifiedMobilenumber />}

            {data.mobNumber && convertedMobile !== 'Y' && (
              <>
                <Grid item>
                  <Separator orientation='vertical' />
                </Grid>
                <Grid item flexDirection={'row'}>
                  {/* <Typography className='f-14 ' color={'primary'}>
                    <a href='#'>TAP HERE TO VERIFY </a>
                  </Typography> */}
                  <CustomButton
                    text={'tap here to verify'}
                    variant='text'
                    showIcon={false}
                    color='primary'
                    className='f-14'
                    onClick={onVerifyMobile}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid>
            {data.mobNumber && (
              <Grid item className='mb-4'>
                <Typography className='f-18' color={theme.palette.common.black}>
                  {data.mobNumber}
                </Typography>
              </Grid>
            )}
            {!data.mobNumber && (
              <Grid item>
                <Grid container spacing={1} flexWrap={'nowrap'}>
                  <Grid item>
                    <Typography className='f-18'>Not Available</Typography>
                  </Grid>
                  <Grid item flexDirection={'row'} alignItems={'center'}>
                    {/* <Typography className='f-14 ' color={'primary'}>
                      <a href='#'>TAP HERE TO ADD </a>
                    </Typography> */}
                    <CustomButton
                      text={'tap here to add'}
                      variant='text'
                      showIcon={false}
                      color='primary'
                      onClick={addNewMobile}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>

          <>
            {/* <Grid item>
                  <Typography className='f-18'>Not Available</Typography>
                </Grid> */}
          </>
        </Grid>
        <Grid item>
          <Grid container spacing={0.4}>
            <Grid item>
              <Typography
                className='f-14 fw-600'
                color={theme.palette.primary.main}
              >
                email
              </Typography>
            </Grid>
            {data.email && (
              <Grid item className='mt-2'>
                <VerifiedText
                  isVerified={convertedEmail === 'Y'}
                  label={convertedEmail === 'Y' ? 'verified' : 'not verified'}
                />
              </Grid>
            )}
            {!data.email && <NotVerifiedEmail />}

            {data.email && convertedEmail !== 'Y' && (
              <>
                <Grid item>
                  <Separator orientation='vertical' />
                </Grid>
                <Grid item flexDirection={'row'}>
                  {/* <Typography className='f-14 ' color={'primary'}>
                    <a href='#'>TAP HERE TO VERIFY </a>
                  </Typography> */}
                  <CustomButton
                    text={'tap here to verify'}
                    variant='text'
                    showIcon={false}
                    color='primary'
                    className='f-14 mt-n2'
                    onClick={onVerifyEmail}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid>
            {data.email && (
              <Grid item className='mb-4'>
                <Typography className='f-18' color={theme.palette.common.black}>
                  {data.email}
                </Typography>
              </Grid>
            )}
            {!data.email && (
              <Grid item>
                <Grid container spacing={1} flexWrap={'nowrap'}>
                  <Grid item>
                    <Typography className='f-18'>Not Available</Typography>
                  </Grid>
                  <Grid item flexDirection={'row'} alignItems={'center'}>
                    {/* <Typography className='f-14 ' color={'primary'}>
                      <a href='#'>TAP HERE TO ADD </a>
                    </Typography> */}
                    <CustomButton
                      text={'tap here to add'}
                      variant='text'
                      showIcon={false}
                      color='primary'
                      onClick={addNewEmail}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>

          <>
            {/* <Grid item>
                  <Typography className='f-18'>Not Available</Typography>
                </Grid> */}
          </>
        </Grid>
        <Grid item>
          <Grid container spacing={0.3} flexWrap={'nowrap'}>
            <Grid item>
              <Typography
                className='f-14 fw-600'
                color={theme.palette.primary.main}
              >
                address
              </Typography>
            </Grid>
            {data.address1 && (
              <Grid item className='mt-2'>
                <VerifiedText
                  isVerified={convertedAddress === 'Y'}
                  label={convertedAddress === 'Y' ? 'verified' : 'not verified'}
                />
              </Grid>
            )}
            {!data.address1 && <AddressNotKnown />}

            {data.address1 && !convertedAddress && (
              <>
                <Grid item>
                  <Separator orientation='vertical' />
                </Grid>
                <Grid item flexDirection={'row'}>
                  <Typography className='f-14 ' color={'primary'}>
                    <a href='#'>TAP HERE TO VERIFY </a>
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid>
            {!data.address1 && (
              <Grid item>
                <Grid container spacing={1} flexWrap={'nowrap'}>
                  <Grid item>
                    <Typography className='f-18'>Not Available</Typography>
                  </Grid>
                  <Grid item flexDirection={'row'} alignItems={'center'}>
                    <Typography className='f-14 ' color={'primary'}>
                      <a href='#'>TAP HERE TO ADD </a>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {data.address1 && (
          <Grid item>
            {/* <AddressNotVerified /> */}
            <Typography className='f-18' color='textPrimary'>
              {data.address1}
            </Typography>
            <Typography className='f-18' color='textPrimary'>
              {data.address2}
            </Typography>
            <Typography className='f-18' color='textPrimary'>
              {data.city}
            </Typography>
            <Typography className='f-18' color='textPrimary'>
              {data.state}
            </Typography>
            <Typography className='f-18' color='textPrimary'>
              {data.country}
            </Typography>
            <Typography className='f-18' color='textPrimary'>
              {data.pincode}
            </Typography>
          </Grid>
        )}
      </div>
    </Grid>
  );
};

export default FamilyDetailCard;
