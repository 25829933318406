import './Login.scss';
import { Grid, Typography, useTheme } from '@mui/material';
import CustomButton from '../../../common/ui/CustomButton';
import { LoginForm } from './LoginForm';
import { Link } from 'react-router-dom';
import { AUTH_ROUTES } from '../../../common/constants/Routes';
import Logo from '../../../assets/logo.png';
import Separator from '../../../common/ui/Separator';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as BemaCentralLogo } from '../../../assets/images/BemaCentralLogo.svg';

export const Login = () => {
  const theme = useTheme();
  return (
    <>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        className={'h-100'}
      >
        <Grid
          item
          lg={3}
          className='form mt-30'
          container
          spacing={4}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          textAlign={'center'}
        >
          <Grid item className='mt-33'>
            <SvgConverter
              Icon={BemaCentralLogo}
              width={174}
              height={108}
              className='mt-35 mb-10'
            />
          </Grid>
          <Grid item>
            <LoginForm />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        alignItems={'center'}
        className={'w-100 mt-35 login-footer'}
        flexDirection={'column'}
        spacing={0}
        height={216}
      >
        <Grid item className='mt-35'>
          <Typography
            color={theme.palette.common.white}
            fontSize={16}
            className='mt-33'
          >
            New User?
          </Typography>
        </Grid>
        <Grid item>
          <Link to={AUTH_ROUTES.SIGNUP}>
            <CustomButton
              text={'SIGN UP'}
              variant={'text'}
              size={'large'}
              iconClassName={'right-arrow'}
              color={'secondary'}
              className={'f-20'}
            />
          </Link>
        </Grid>
        <Grid item>
          <Typography fontSize={12} className='mt-50 footer-text'>
            Powered by CAMS Insurance Repository
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
