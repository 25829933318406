import React, { ReactElement } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import SvgConverter from '../../../common/SvgConverter';
// import { ReactComponent as RupeeIcon } from '../../../../common/icons/LoanModule/rupee-icon.svg';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
interface iconInterface {
  Icon?: any;
  width?: number | undefined;
  height?: number | undefined;
  className?: string;
}
interface ProductProps {
  product?: string;
  selfName?: string;
  charges?: string;
  rupeeOne?: string | number;
  rupeeTwo?: string | number;
  IconProps?: iconInterface | undefined;
}

const ProductDetails = ({
  product,

  selfName,

  rupeeOne,
  rupeeTwo,
  IconProps,
}: ProductProps) => {
  const theme = useTheme();
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Typography className='f-14 fw-600' color={'primary'}>
          {product}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className='f-12'>Life Assured</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className='f-12 fw-600'>{selfName}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className='f-12'>Maturity Value</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={0.5}>
          <Grid item>
            <SvgConverter
              Icon={IconProps?.Icon}
              width={IconProps?.width || 5}
              height={IconProps?.height || 9}
              className={IconProps?.className || 'mb-5'}
            />
          </Grid>
          <Grid item>
            <Typography className='f-12' color={theme.palette.common.black}>
              {rupeeOne}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className='mt-n8 w-100'>
        <Typography className='f-12'>Fund Value</Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={0.5}>
          <Grid item>
            <SvgConverter
              Icon={IconProps?.Icon}
              width={IconProps?.width || 5}
              height={IconProps?.height || 9}
              className={IconProps?.className || 'mb-5'}
            />
          </Grid>
          <Grid item>
            <Typography className='f-12' color={theme.palette.common.black}>
              {rupeeTwo}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductDetails;
