import { Grid, Typography } from '@mui/material';
import React from 'react';
import '../../Policy.scss';
import theme from '../../../../../common/theme';
import ActiveAssetsCard from '../ActiveContent/ActiveAssetsCard';

interface UserProps {
  headerType?: string;
  contentType?: string;
  premiumDue?: string;
  filteredAssets?: any;
}
export const ActiveAssets: React.FC<UserProps> = ({
  filteredAssets,
  headerType,
  contentType,
  premiumDue,
}) => {
  return (
    <Grid container justifyContent={'center'}>
      {filteredAssets.map((data: any, index: any) => (
        <Grid item xs={4} key={index}>
          <Typography
            className='f-12 fw-600 py-4'
            color={theme.palette.primary.main}
          >
            {data?.vehicleModel}- {data?.vehicleNo}
          </Typography>
          <ActiveAssetsCard
            contentType={contentType}
            items={data}
            verificationStatus={'N'}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ActiveAssets;
