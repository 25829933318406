import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import theme from '../../../../../common/theme';
import '../../Policy.scss';
import SvgConverter from '../../../../../common/SvgConverter';
import { ReactComponent as GreenTick } from '../../../../../common/icons/small-greentick.svg';
import { ReactComponent as InProgress } from '../../../../../common/icons/small-inprogress.svg';
interface UserProps {
  status?: any;
}
const VerificationStatus: React.FC<UserProps> = ({ status }) => {
  return (
    <Grid container>
      {status === 'Y' ? (
        <Grid container justifyContent={'center'} className='mt-20'>
          <SvgConverter
            Icon={GreenTick}
            className={'d-flex align-items-end mb-4'}
          />
          <Typography fontSize={14} color={'#63a103'}>
            Verified
          </Typography>
        </Grid>
      ) : (
        <Grid container justifyContent={'center'} className='mt-20 px-10'>
          <SvgConverter
            Icon={InProgress}
            className={'d-flex align-items-end mb-4'}
          />
          <Typography fontSize={12} color={theme.palette.secondary.main}>
            Verification inprogress
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default VerificationStatus;
