import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import theme from '../../../../../common/theme';
import '../../Policy.scss';
import LabelWithIcon from '../../../../../common/ui/LabelWithIcon';
import SvgConverter from '../../../../../common/SvgConverter';
import { ReactComponent as Premium } from '../../../../../common/icons/dashboard/pay_premiums-icon.svg';
import { ReactComponent as OrangeRuppee } from '../../../../../common/icons/small-orange-rupee.svg';
import Separator from '../../../../../common/ui/Separator';
import CustomButton from '../../../../../common/ui/CustomButton';
import { ReactComponent as Circle } from '../../../../../common/icons/dashboard/circle_arrow-icon.svg';
interface UserProps {
  contentType?: string;
  items?: any;
  PolicyType?: string;
}
const PremiumDue: React.FC<UserProps> = ({
  contentType,
  items,
  PolicyType,
}) => {
  const dayjs = require('dayjs');
  const dateValue = items && items?.premuimDueDate;
  const formattedDate = dayjs(dateValue).format('DD-MM-YYYY');
  console.log('items', items);
  return (
    <>
      <Grid container className='p-5'>
        <Grid item xs={4} className='py-10'>
          <Typography
            className='f-14  px-5 nowrap-text'
            color={theme.palette.primary.main}
          >
            Fund Value
          </Typography>
          <LabelWithIcon
            className='px-6'
            svgClassName=''
            justifyContent={'flex-start'}
            Icon={OrangeRuppee}
            label={items.fundValue.length === 0 ? '0' : items.fundValue}
            labelProps={{
              color: theme.palette.common.black,
              fontSize: 16,
              fontWeight: 600,
            }}
          />
        </Grid>
        <Grid item xs={1} className='px-5 py-10  ml-5 fw-800 separator'>
          <Separator
            orientation='vertical'
            color={theme.palette.secondary.main}
            borderWidth={2}
          />
        </Grid>
        <Grid item xs={5} className='py-10'>
          <Typography
            className='f-14 px-8 nowrap-text'
            color={theme.palette.primary.main}
          >
            Surrender Value
          </Typography>
          <LabelWithIcon
            className='px-6 '
            justifyContent={'flex-start'}
            svgClassName=''
            Icon={OrangeRuppee}
            label={
              items.surrenderValue.length === 0 ? '0' : items.surrenderValue
            }
            labelProps={{
              color: theme.palette.common.black,
              fontSize: 16,
              fontWeight: 600,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            className='f-12 px-10 py-5 mb-5 '
            color={theme.palette.grey[400]}
          >
            [Last Updated on DDMMYYYY]
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          className='px-10'
          flexDirection={'row-reverse'}
        >
          <Separator />
          <Grid item>
            <CustomButton
              text={'Refresh now'}
              variant={'text'}
              color={'primary'}
              fontWeight={600}
              fontSize={14}
              iconClassName='ml-4'
              endIcon={Circle}
              className='py-10'
            />
          </Grid>
        </Grid>
      </Grid>
      <Separator color={theme.palette.primary.main} />
      {items.verifiedFlag === 'N' && (
        <Grid container className='p-10 premium-container'>
          <Grid item xs={1}>
            <SvgConverter Icon={Premium} className='ml-2 mt-5' />
          </Grid>
          <Grid item xs={4} className='ml-5'>
            <Typography className='f-14' color={theme.palette.primary.main}>
              Premium
            </Typography>
            <LabelWithIcon
              svgClassName='mb-2'
              justifyContent={'flex-start'}
              Icon={OrangeRuppee}
              label={items?.premiumAmount.length ? '-' : items?.premiumAmount}
              labelProps={{
                color: theme.palette.common.black,
                fontSize: 16,
                fontWeight: 600,
              }}
            />
          </Grid>

          <Grid item xs={6} className='mt-8'>
            {contentType === 'archive' && PolicyType === 'life' ? (
              <>
                <Typography
                  className='f-14 '
                  color={theme.palette.secondary.main}
                >
                  Term ended
                  {formattedDate === 'Invalid Date' ? '-' : formattedDate}
                </Typography>
              </>
            ) : contentType === 'archive' &&
              (PolicyType === 'health' || PolicyType === 'assets') ? (
              <Typography
                className='f-14 '
                color={theme.palette.secondary.main}
              >
                Policy term over on{' '}
                {formattedDate === 'Invalid Date' ? '-' : formattedDate}
              </Typography>
            ) : (
              <Typography className='f-14 ' color={theme.palette.primary.main}>
                Due by {formattedDate === 'Invalid Date' ? '-' : formattedDate}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PremiumDue;
