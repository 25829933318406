// import * as React from 'react';
// import { styled, useTheme } from '@mui/material/styles';
// import Box from '@mui/material/Box';

// import LinearProgress, {
//   linearProgressClasses,
// } from '@mui/material/LinearProgress';

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 10,
//   borderRadius: 16,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     borderRadius: 16,
//     backgroundColor:
//       theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 16,
//   },
// }));

// // Inspired by the former Facebook spinners.

// const LinearBar = ({
//   height = 7,
//   color = 'primary',
//   progressVal,
// }: {
//   height?: number | undefined;
//   color?: 'primary' | 'secondary';
//   progressVal?: any;
// }) => {
//   const currentProgress = 100 - progressVal;
//   console.log('progressVal', progressVal);
//   return (
//     <Box sx={{ flexGrow: 1 }}>
//       <BorderLinearProgress
//         variant='determinate'
//         value={progressVal}
//         sx={{ height }}
//         color={color}
//       />
//     </Box>
//   );
// };

// export default LinearBar;
import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import Separator from '../Separator';
import theme from '../../theme';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 16,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    borderRadius: 16,
    backgroundColor: 'gray',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 16,
    backgroundColor: theme.palette.primary.main,
  },
}));

// Inspired by the former Facebook spinners.

const LinearBar = React.forwardRef(
  (
    {
      height = 7,
      color = 'primary',
      progressVal,
      total,
    }: {
      height?: number | undefined;
      color?: 'primary' | 'secondary';
      progressVal?: any;
      total?: any;
    },
    ref?: any
  ) => {
    const clampProgress = Math.max(0, Math.min(1, progressVal));
    console.log('clampProgress', clampProgress);
    return (
      <Box sx={{ flexGrow: 1 }} ref={ref}>
        <BorderLinearProgress
          variant='determinate'
          value={clampProgress * 100}
          sx={{ height }}
          color={color}
        />
      </Box>
    );
  }
);

export default LinearBar;
