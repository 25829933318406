import { Box, Grid } from '@mui/material';
import React from 'react';
import '../Policy.scss';
import Typography from '@mui/material/Typography';
import theme from '../../../../common/theme';
import { ReactComponent as Life } from '../../../../common/icons/group-certified-small.svg';
import { ReactComponent as Health } from '../../../../common/icons/small-health-icon.svg';
import { ReactComponent as Assets } from '../../../../common/icons/small-assets-icon.svg';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import HDFC from '../../../../assets/images/HDFC.png';
import { useSelector } from 'react-redux';
import { addPolicyList } from '../Policies.slice';

interface UserProps {
  policyNo?: any;
  setPolicyNo?: any;
  insurercode?: any;
  setInsurerCode?: any;
}
export const AddPolicyList: React.FC<UserProps> = ({
  policyNo,
  setPolicyNo,
  insurercode,
  setInsurerCode,
}) => {
  return (
    <>
      <Grid container>
        <Grid item>
          <Typography
            className='f-16 fw-600 mb-5 '
            color={theme.palette.common.black}
          >
            Policy List
          </Typography>
        </Grid>
        <Grid container spacing={10}>
          <Grid item xs={4}>
            <Box className='add-policy '>
              <Grid item>
                <Grid item xs={12} className='py-5 mb-10'>
                  <LabelWithIcon
                    className=' f-16'
                    svgClassName='mb-2'
                    justifyContent={'flex-start'}
                    Icon={Life}
                    label={'Life'}
                    labelProps={{
                      color: theme.palette.primary.main,
                      fontSize: 14,
                    }}
                  />
                </Grid>

                {insurercode === 'L' && (
                  <Grid container className='background-container'>
                    {/* <Grid item xs={1} className='mt-10 '>
                      <span className='ml-10'>{index + 1}</span>
                    </Grid> */}
                    <Grid item xs={11}>
                      <Grid container>
                        <Grid item xs={2} className='mt-10'>
                          <img src={HDFC} width={42} height={42} />
                        </Grid>
                        <Grid item xs={9} className='mt-5 ml-10'>
                          <Typography
                            className='f-14'
                            color={theme.palette.common.black}
                          >
                            Policy Number
                          </Typography>
                          <Typography
                            className='f-16 fw-600'
                            color={theme.palette.common.black}
                          >
                            {policyNo}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className='add-policy '>
              <Grid item>
                <Grid item xs={12} className='py-5 mb-10'>
                  <LabelWithIcon
                    className=' f-16'
                    svgClassName='mb-2'
                    justifyContent={'flex-start'}
                    Icon={Health}
                    label={'Health'}
                    labelProps={{
                      color: theme.palette.primary.main,
                      fontSize: 14,
                    }}
                  />
                </Grid>

                {insurercode === 'H' && (
                  <Grid container className='background-container'>
                    {/* <Grid item xs={1} className='mt-10 '>
                      <span className='ml-10'>{index + 1}</span>
                    </Grid> */}
                    <Grid item xs={11}>
                      <Grid container>
                        <Grid item xs={2} className='mt-10'>
                          <img src={HDFC} width={42} height={42} />
                        </Grid>
                        <Grid item xs={9} className='mt-5 ml-10'>
                          <Typography
                            className='f-14'
                            color={theme.palette.common.black}
                          >
                            Policy Number
                          </Typography>
                          <Typography
                            className='f-16 fw-600'
                            color={theme.palette.common.black}
                          >
                            {policyNo}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className='add-policy '>
              <Grid item>
                <Grid item xs={12} className='py-5 mb-10'>
                  <LabelWithIcon
                    className=' f-16'
                    svgClassName='mb-2'
                    justifyContent={'flex-start'}
                    Icon={Assets}
                    label={'Assets'}
                    labelProps={{
                      color: theme.palette.primary.main,
                      fontSize: 14,
                    }}
                  />
                </Grid>
                {insurercode === 'G' && (
                  <Grid container className='background-container'>
                    {/* <Grid item xs={1} className='mt-10 '>
                      <span className='ml-10'>{index + 1}</span>
                    </Grid> */}
                    <Grid item xs={11}>
                      <Grid container>
                        <Grid item xs={2} className='mt-10'>
                          <img src={HDFC} width={42} height={42} />
                        </Grid>
                        <Grid item xs={9} className='mt-5 ml-10'>
                          <Typography
                            className='f-14'
                            color={theme.palette.common.black}
                          >
                            Policy Number
                          </Typography>
                          <Typography
                            className='f-16 fw-600'
                            color={theme.palette.common.black}
                          >
                            {policyNo}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPolicyList;
