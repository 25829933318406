import { createSlice } from "@reduxjs/toolkit";
import { Bank } from "../Profile/types/Profile.model";
import {
  filter,
  find,
  groupBy,
  pipe,
  prop,
  propEq,
  reject,
  uniqBy,
} from "ramda";
import { LoadingState } from "../../../common";
import {
  convertToString,
  convertUnAssignedBanksToPolicyType,
  removeDuplicatedPoliciesForAssign,
  swapToTop,
} from "../../../common/utils";
import {
  BANK_ADDRESS_EMAIL_MOBILE_REMOVE_TYPES,
  MOBILE_EMAIL_CHANGE_ACTIONS,
} from "../../../common/Enums";
import { PolicyAlternateCodes } from "../../../common/types/PolicyTypes";
import { RootState } from "../../../common/store";

interface BankState {
  policies: any[];
  unassignedBanks: any[];
  unverifiedBanks: any[];
  isLoading: LoadingState;
  errors: any | null;
  banks: any[];
  isBankLoading: LoadingState;
  errorBanks: any;
  generateOtpForBank: { status: LoadingState; data: any; error: any };
  verifyBank: { status: LoadingState; data: any; error: any };
  updateBankChanges: { status: LoadingState; data: any; error: any };
  bankDetailByIfsc: { status: LoadingState; data: any; error: any };
  uploadCanceledCheque: {
    status: LoadingState;
    data: any;
    error: any;
    payload: any;
  };
  bankChangesList: any[];
}

const initialState: BankState = {
  isLoading: "idle",
  policies: [],
  unassignedBanks: [],
  unverifiedBanks: [],
  errors: null,
  isBankLoading: "idle",
  banks: [],
  errorBanks: null,
  generateOtpForBank: {
    status: "idle",
    data: undefined,
    error: undefined,
  },
  verifyBank: {
    status: "idle",
    data: undefined,
    error: undefined,
  },
  updateBankChanges: {
    status: "idle",
    data: undefined,
    error: undefined,
  },
  bankDetailByIfsc: {
    status: "idle",
    data: undefined,
    error: undefined,
  },
  uploadCanceledCheque: {
    status: "idle",
    data: undefined,
    error: undefined,
    payload: false,
  },
  bankChangesList: [],
};

const banksSlice = createSlice({
  name: "banks",
  initialState,
  reducers: {
    fetchPoliciesForBanks: (state) => ({
      ...state,
      isLoading: "loading",
      errors: null,
      policies: [],
      unverifiedBanks: [],
      unassignedBanks: [],
    }),
    loadPoliciesForBanks: (state, action) => ({
      ...state,
      isLoading: "done",
      errors: null,
      policies: action.payload.policies,
      unverifiedBanks: action.payload.unverifiedBanks,
      unassignedBanks: action.payload.unassignedBanks,
    }),
    errorLoadingPoliciesForBanks: (state, action) => ({
      ...state,
      isLoading: "done",
      errors: action.payload,
      policies: [],
      unverifiedBanks: [],
      unassignedBanks: [],
    }),
    fetchBanks: (state, _action) => ({
      ...state,
      isBankLoading: "loading",
      banks: [],
      errorBanks: null,
    }),
    loadBanks: (state, action) => ({
      ...state,
      isBankLoading: "done",
      banks: action.payload,
      errorBanks: null,
    }),
    errorLoadingBanks: (state, action) => ({
      ...state,
      isBankLoading: "done",
      banks: [],
      errorBanks: action.payload,
    }),
    generateOtpForBankRequest: (state) => {
      state.generateOtpForBank.status = "loading";
      state.generateOtpForBank.data = undefined;
      state.generateOtpForBank.error = undefined;
    },
    generateOtpForBankSuccess: (state, { payload }) => {
      state.generateOtpForBank.status = "done";
      state.generateOtpForBank.data = payload;
      state.generateOtpForBank.error = undefined;
    },
    generateOtpForBankError: (state, { payload }) => {
      state.generateOtpForBank.status = "error";
      state.generateOtpForBank.data = undefined;
      state.generateOtpForBank.error = payload;
    },
    verifyBankRequest: (state) => {
      state.verifyBank.status = "loading";
      state.verifyBank.data = undefined;
      state.verifyBank.error = undefined;
    },
    verifyBankSuccess: (state, { payload }) => {
      state.verifyBank.status = "done";
      state.verifyBank.data = payload;
      state.verifyBank.error = undefined;
    },
    verifyBankError: (state, { payload }) => {
      state.verifyBank.status = "error";
      state.verifyBank.data = undefined;
      state.verifyBank.error = payload;
    },
    updateBankChangesRequest: (state) => {
      state.updateBankChanges.status = "loading";
      state.updateBankChanges.data = undefined;
      state.updateBankChanges.error = undefined;
    },
    updateBankChangesSuccess: (state, { payload }) => {
      state.updateBankChanges.status = "done";
      state.updateBankChanges.data = payload;
      state.updateBankChanges.error = undefined;
      state.bankChangesList = [];
    },
    updateBankChangesError: (state, { payload }) => {
      state.updateBankChanges.status = "error";
      state.updateBankChanges.data = undefined;
      state.updateBankChanges.error = payload;
    },

    getBankDetailsByIfscRequest: (state) => {
      state.bankDetailByIfsc.status = "loading";
      state.bankDetailByIfsc.data = undefined;
      state.bankDetailByIfsc.error = undefined;
    },
    getBankDetailsByIfscSuccess: (state, { payload }) => {
      state.bankDetailByIfsc.status = "done";
      state.bankDetailByIfsc.data = payload;
      state.bankDetailByIfsc.error = undefined;
    },
    getBankDetailsByIfscError: (state, { payload }) => {
      state.bankDetailByIfsc.status = "error";
      state.bankDetailByIfsc.data = undefined;
      state.bankDetailByIfsc.error = payload;
    },

    uploadCanceledChequeRequest: (state, { payload }) => {
      state.uploadCanceledCheque.status = "loading";
      state.uploadCanceledCheque.data = undefined;
      state.uploadCanceledCheque.error = undefined;
      state.uploadCanceledCheque.payload = payload;
    },
    uploadCanceledChequeSuccess: (state, { payload }) => {
      state.uploadCanceledCheque.status = "done";
      state.uploadCanceledCheque.data = payload;
      state.uploadCanceledCheque.error = undefined;
    },
    uploadCanceledChequeError: (state, { payload }) => {
      state.uploadCanceledCheque.status = "error";
      state.uploadCanceledCheque.data = undefined;
      state.uploadCanceledCheque.error = payload;
      state.uploadCanceledCheque.payload = false;
    },
    assignBank: (state, action) => {
      const { selectedPolicies, newOCRDetails, handleDone, isUnaAssigned } =
        action.payload;
      const newBankDetails = handleAddNewBankUsingOCR(newOCRDetails?.OCR);

      if (newOCRDetails) {
        if (selectedPolicies.length > 0) {
          let policies = selectedPolicies.map((row: any) => ({
            ...row,
            ...newBankDetails,
          }));
          const duplicateRemovedCurrentList = removeDuplicatedPoliciesForAssign(
            state.policies,
            selectedPolicies
          );

          state.policies = [...policies, ...duplicateRemovedCurrentList];
          state.policies = swapToTop(
            state.policies,
            [newOCRDetails?.OCR?.accountNumber],
            "bankAcNo"
          );
          state.bankChangesList = [
            ...state.bankChangesList,
            generateBankChangesForAPI(
              selectedPolicies,
              newBankDetails,
              null,
              MOBILE_EMAIL_CHANGE_ACTIONS.ADD,
              newOCRDetails,
              state.uploadCanceledCheque?.payload?.chequeImage
            ),
          ];
          state.unassignedBanks = removeBankFromUnverifiedAndUnassigned(
            state.unassignedBanks,
            newOCRDetails?.OCR?.accountNumber
          );
          state.unverifiedBanks = removeBankFromUnverifiedAndUnassigned(
            state.unverifiedBanks,
            newOCRDetails?.OCR?.accountNumber
          );
        }
      }
      state.uploadCanceledCheque.data = undefined;
      state.uploadCanceledCheque.payload = false;

      if (handleDone) {
        handleDone();
      }
    },

    changeBank: (state, action) => {
      const { selectedPolicy, targetBank } = action.payload;
      const policies = changePolicyBank(
        state.policies,
        selectedPolicy,
        targetBank,
        state.unassignedBanks
      );
      const targetBankDetails = getBankInfoByAcNo(targetBank)(
        combinePoliciesAccount(state.policies, state.unassignedBanks)
      );
      const isEmailHavePolicy = policies.some(
        (policy) => policy.bankAcNo === selectedPolicy.bankAcNo
      );

      if (!isEmailHavePolicy && selectedPolicy?.mobileNo != "null") {
        state.unassignedBanks = [
          ...state.unassignedBanks,
          convertPolicyBankToUnassignedBank(selectedPolicy),
        ];
      }

      state.policies = policies;

      state.bankChangesList = [
        ...state.bankChangesList,
        generateBankChangesForAPI(
          [selectedPolicy],
          targetBankDetails,
          selectedPolicy,
          MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE
        ),
      ];
      const { banks } = removeBankFromUnassigned(
        state.unassignedBanks,
        targetBank
      );
      state.unassignedBanks = banks;
    },
    removeBank: (state, action) => {
      const { targetBankAccountNo, oldBankAccNo, type }: any = action.payload;
      let apiChange: any = {};
      if (type === BANK_ADDRESS_EMAIL_MOBILE_REMOVE_TYPES.UNASSIGNED) {
        const { banks, targetBankDetails } = removeBankFromUnassigned(
          state.unassignedBanks,
          targetBankAccountNo
        );
        state.unassignedBanks = banks;
        apiChange = generateBankChangesForAPI(
          [],
          null,
          targetBankDetails,
          MOBILE_EMAIL_CHANGE_ACTIONS.REMOVE
        );
      } else if (type === BANK_ADDRESS_EMAIL_MOBILE_REMOVE_TYPES.UNVERIFIED) {
        const { banks, targetBankDetails } = removeBankFromUnassigned(
          state.unverifiedBanks,
          targetBankAccountNo
        );
        state.unverifiedBanks = banks;
        apiChange = generateBankChangesForAPI(
          [],
          null,
          targetBankDetails,
          MOBILE_EMAIL_CHANGE_ACTIONS.REMOVE
        );
      } else {
        const newBankDetails = getBankInfoByAcNo(targetBankAccountNo)(
          combinePoliciesAccount(state.policies, state.unassignedBanks)
        );
        const oldBankDetails = getBankInfoByAcNo(oldBankAccNo)(
          combinePoliciesAccount(state.policies, state.unassignedBanks)
        );

        const affectedPolicies = filter(
          ({ bankAcNo }) => bankAcNo == oldBankAccNo,
          state.policies
        );
        const policies = assignPolicyToOtherBank(
          combinePoliciesAccount(state.policies, state.unassignedBanks),
          targetBankAccountNo,
          oldBankAccNo
        );
        state.policies = policies;
        apiChange = generateBankChangesForAPI(
          affectedPolicies,
          newBankDetails,
          oldBankDetails,
          MOBILE_EMAIL_CHANGE_ACTIONS.REMOVE
        );
        const { banks } = removeBankFromUnassigned(
          state.unassignedBanks,
          targetBankAccountNo
        );
        state.unassignedBanks = banks;
      }
      if (apiChange?.action) {
        state.bankChangesList = [...state.bankChangesList, apiChange];
      }
    },
    setBankChangesList: (state, { payload }) => {
      state.bankChangesList = payload ? payload : [];
    },
    skipBank: (state, { payload }) => {
      let apiChange: any = [];
      const {
        isVerified,
        bankDetailsByIfsc,
        uploadChequePayload,
        bankOCRDetails,
        newAccountData,
        handleDone,
      } = payload;
      if (isVerified) {
        const unassignedBankDetails = convertOcrToVerifiedBank(
          bankOCRDetails?.OCR,
          uploadChequePayload?.chequeImage
        );
        state.unassignedBanks = [
          unassignedBankDetails,
          ...state.unassignedBanks,
        ];
        apiChange = generateBankChangesForAPI(
          [],
          convertUnassignedUnVerifiedToAssignedBankDetails(
            unassignedBankDetails
          ),
          null,
          MOBILE_EMAIL_CHANGE_ACTIONS.ADD,
          bankOCRDetails,
          uploadChequePayload?.chequeImage
        );
        state.unverifiedBanks = removeBankFromUnverifiedAndUnassigned(
          state.unverifiedBanks,
          unassignedBankDetails?.accountNo
        );
      } else {
        const unVerifiedBankDetails = convertIfscToBankFormat(
          bankDetailsByIfsc,
          newAccountData
        );
        state.unverifiedBanks = [
          unVerifiedBankDetails,
          ...state.unverifiedBanks,
        ];
        apiChange = generateBankChangesForAPI(
          [],
          convertUnassignedUnVerifiedToAssignedBankDetails(
            unVerifiedBankDetails
          ),
          null,
          MOBILE_EMAIL_CHANGE_ACTIONS.ADD
        );
        state.unassignedBanks = removeBankFromUnverifiedAndUnassigned(
          state.unassignedBanks,
          unVerifiedBankDetails?.accountNo
        );
      }
      if (apiChange?.action) {
        state.bankChangesList = [...state.bankChangesList, apiChange];
      }
      // resetting old data
      state.uploadCanceledCheque.payload = undefined;
      state.uploadCanceledCheque.data = undefined;
      state.bankDetailByIfsc.data = undefined;
      if (handleDone) {
        handleDone();
      }
    },
    assignPoliciesForUnassignedBank: (state, { payload }) => {
      const { selectedPolicies, unAssignedBankDetails, handleDone } = payload;
      const newBankDetails = convertUnassignedUnVerifiedToAssignedBankDetails(
        unAssignedBankDetails
      );

      let policies = selectedPolicies.map((row: any) => ({
        ...row,
        ...newBankDetails,
      }));
      const duplicateRemovedCurrentList = removeDuplicatedPoliciesForAssign(
        state.policies,
        selectedPolicies
      );

      state.policies = [...policies, ...duplicateRemovedCurrentList];
      state.policies = swapToTop(
        state.policies,
        [newBankDetails?.bankAcNo],
        "bankAcNo"
      );

      state.unassignedBanks = reject(
        ({ accountNo }) => accountNo === unAssignedBankDetails?.accountNo,
        state.unassignedBanks
      );

      let apiChange = generateBankChangesForAPI(
        selectedPolicies,
        newBankDetails,
        null,
        MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE
      );

      if (apiChange?.action) {
        state.bankChangesList = [...state.bankChangesList, apiChange];
      }

      if (handleDone) {
        handleDone();
      }
    },
    handleInstantBankFlow: (state, { payload }) => {
      const { selectedBankAcNo, selectedPolicyNo, isChange } = payload;

      const newBankDetails = state.uploadCanceledCheque.data;
      const chequeImage = state.uploadCanceledCheque?.payload?.chequeImage;
      const newBankDetailsFormatted = handleAddNewBankUsingOCR(
        newBankDetails?.OCR ? newBankDetails?.OCR : {}
      );

      if (isChange) {
        const selectedPolicy = find(propEq("policyNo", selectedPolicyNo))(
          state.policies
        );
        const policies = changePolicyBank(
          state.policies,
          selectedPolicy,
          null,
          state.unassignedBanks,
          newBankDetailsFormatted
        );
        state.policies = policies;
        state.bankChangesList = [
          ...state.bankChangesList,
          generateBankChangesForAPI(
            [selectedPolicy],
            newBankDetailsFormatted,
            selectedPolicy,
            MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE,
            newBankDetails,
            chequeImage
          ),
        ];
      } else {
        const oldBankDetails = getBankInfoByAcNo(selectedBankAcNo)(
          combinePoliciesAccount(state.policies, state.unassignedBanks)
        );
        const selectedPolicies = filter(
          ({ bankAcNo }) => bankAcNo === selectedBankAcNo,
          state.policies
        );
        let policies = selectedPolicies.map((row: any) => ({
          ...row,
          ...newBankDetails,
        }));
        const duplicateRemovedCurrentList = removeDuplicatedPoliciesForAssign(
          state.policies,
          selectedPolicies
        );

        state.policies = [...policies, ...duplicateRemovedCurrentList];
        state.policies = swapToTop(
          state.policies,
          [newBankDetails?.OCR?.accountNumber],
          "bankAcNo"
        );
        state.bankChangesList = [
          ...state.bankChangesList,
          generateBankChangesForAPI(
            selectedPolicies,
            newBankDetailsFormatted,
            oldBankDetails,
            MOBILE_EMAIL_CHANGE_ACTIONS.REMOVE,
            newBankDetails,
            chequeImage
          ),
        ];
      }
      state.uploadCanceledCheque.data = undefined;
      state.uploadCanceledCheque.payload = false;
    },
    clearBankEntries: (state) => {
      state.uploadCanceledCheque.payload = undefined;
      state.uploadCanceledCheque.data = undefined;
      state.bankDetailByIfsc.data = undefined;
    },
  },
});

export const combinePoliciesAccount = (
  polices: any[],
  unassignedBanks: any[]
) => {
  let combinedData = [
    ...(polices ? polices : []),
    ...convertUnAssignedBanksToPolicyType(unassignedBanks),
  ];

  return combinedData;
};

const removeBankFromUnassigned = (banks: any, targetBankAccNo: any) => ({
  targetBankDetails: find(propEq("bankAcNo", targetBankAccNo))(
    convertUnAssignedBanksToPolicyType(banks)
  ),
  banks: reject(({ accountNo }) => accountNo === targetBankAccNo, banks),
});

const generateBankChangesForAPI = (
  policies: any[],
  newBankDetails: any,
  oldBankDetails: any,
  action: string,
  newOCRDetails: any = {},
  chequeImage: string | undefined = undefined
) => {
  const policiesForPayload: any[] = policies.map(
    ({ companyCode, policyNo, alternateCode }: any) => ({
      insurerCode: companyCode,
      policyNo,
      alternateCode,
    })
  );

  let newValues = {
    newPayeeBankName: "",
    newPayeeBranchName: "",
    newPayeeActype: "",
    newPayeeAcNo: "",
    newPayeeSwiftCode: "",
    newPayeeBankAddressF1: "",
    newPayeeBankAddressF2: "",
    newPayeeBankVerified: "Y",
    newPayeeIfscCode: "",
  };

  if (newBankDetails) {
    newValues.newPayeeBankName = convertToString(newBankDetails?.bankName);
    newValues.newPayeeBranchName = convertToString(newBankDetails?.bankBranch);
    newValues.newPayeeAcNo = convertToString(newBankDetails?.bankAcNo);
    newValues.newPayeeActype = convertToString(newBankDetails?.actype);
    newValues.newPayeeSwiftCode = convertToString(newBankDetails?.swiftCode);
    newValues.newPayeeBankAddressF1 = convertToString(
      newBankDetails?.addressF1
    );
    newValues.newPayeeBankAddressF2 = convertToString(
      newBankDetails?.addressF2
    );
    newValues.newPayeeBankVerified = convertToString(
      newBankDetails?.bankVerified
    );
    newValues.newPayeeIfscCode = convertToString(newBankDetails?.bankIfscCode);
  }

  let oldValues = {
    oldPayeeBankName: "",
    oldPayeeBranchName: "",
    oldPayeeActype: "",
    oldPayeeAcNo: "",
    oldPayeeSwiftCode: "",
    oldPayeeBankAddressF1: "",
    oldPayeeBankAddressF2: "",
    oldPayeeIfscCode: "",
  };

  if (oldBankDetails || oldBankDetails != "null") {
    oldValues.oldPayeeBankName = convertToString(oldBankDetails?.bankName);
    oldValues.oldPayeeBranchName = convertToString(oldBankDetails?.bankBranch);
    oldValues.oldPayeeAcNo = convertToString(oldBankDetails?.bankAcNo);
    oldValues.oldPayeeActype = convertToString(oldBankDetails?.actype);
    oldValues.oldPayeeSwiftCode = convertToString(oldBankDetails?.swiftCode);
    oldValues.oldPayeeBankAddressF1 = convertToString(
      oldBankDetails?.addressF1
    );
    oldValues.oldPayeeBankAddressF2 = convertToString(
      oldBankDetails?.addressF2
    );
    // oldValues.oldPayeeBankVerified = convertToString(
    //   newBankDetails?.bankVerified,
    // );
    oldValues.oldPayeeIfscCode = convertToString(oldBankDetails?.bankIfscCode);
  }

  return {
    ...newValues,
    ...oldValues,
    action: oldBankDetails == "null" ? MOBILE_EMAIL_CHANGE_ACTIONS.ADD : action, // For unsigned policies have to send as add action type
    Policies: policiesForPayload,
    primaryFlag: "",
    bankLogo: "",
    chequeImage: chequeImage ? chequeImage : "",
    ...newOCRDetails,
  };
};

export const {
  fetchBanks,
  loadBanks,
  errorLoadingBanks,
  fetchPoliciesForBanks,
  loadPoliciesForBanks,
  errorLoadingPoliciesForBanks,
  generateOtpForBankRequest,
  generateOtpForBankSuccess,
  generateOtpForBankError,
  verifyBankRequest,
  verifyBankSuccess,
  verifyBankError,
  updateBankChangesRequest,
  updateBankChangesSuccess,
  updateBankChangesError,
  changeBank,
  removeBank,
  getBankDetailsByIfscRequest,
  getBankDetailsByIfscSuccess,
  getBankDetailsByIfscError,
  uploadCanceledChequeRequest,
  uploadCanceledChequeSuccess,
  uploadCanceledChequeError,
  setBankChangesList,
  assignBank,
  skipBank,
  clearBankEntries,
  assignPoliciesForUnassignedBank,
  handleInstantBankFlow,
} = banksSlice.actions;

const removeBankFromUnverifiedAndUnassigned = (
  bankList: any[],
  bankAccNo: string
) => reject(({ accountNo }) => accountNo == bankAccNo, bankList);

const changePolicyBank = (
  policies: any[],
  selectedPolicy: any,
  targetBankAccNo: string | null,
  unAssignedBanks: any[],
  customTargetBankInfo?: any
) => {
  let selectedPolicyIndex = policies.findIndex(
    ({ bankAcNo, policyNo }: any) =>
      bankAcNo === selectedPolicy.bankAcNo &&
      policyNo === selectedPolicy.policyNo
  );

  const selectedBankInfo = customTargetBankInfo
    ? customTargetBankInfo
    : getBankInfoByAcNo(targetBankAccNo)(
        combinePoliciesAccount(policies, unAssignedBanks)
      );

  if (selectedPolicyIndex >= 0) {
    policies[selectedPolicyIndex].bankAcNo = selectedBankInfo.bankAcNo;
    policies[selectedPolicyIndex].bankName = selectedBankInfo.bankName;
    policies[selectedPolicyIndex].bankBranch = selectedBankInfo.bankBranch;
    policies[selectedPolicyIndex].bankIfscCode = selectedBankInfo.bankIfscCode;
    policies[selectedPolicyIndex].chequeImage = selectedBankInfo.chequeImage;
    policies[selectedPolicyIndex].bankLogo = selectedBankInfo.bankLogo;
  }
  return policies;
};

const mapAssignPolicy =
  (newValue: string, oldValue: string, policies: any[]) => (policy: any) => {
    const selectedBankInfo = getBankInfoByAcNo(newValue)(policies);
    if (policy.bankAcNo === oldValue) {
      policy.bankAcNo = newValue;
      policy.bankName = selectedBankInfo.bankName;
      policy.bankBranch = selectedBankInfo.bankBranch;
      policy.bankIfscCode = selectedBankInfo.bankIfscCode;
      policy.chequeImage = selectedBankInfo.chequeImage;
      policy.bankLogo = selectedBankInfo.bankLogo;
    }
    return policy;
  };

const assignPolicyToOtherBank = (
  policies: Bank[],
  newValue: string,
  oldValue: string
) => policies.map(mapAssignPolicy(newValue, oldValue, policies));

const groupPoliciesByBankAccountNumber = groupBy(prop<any>("bankAcNo"));

export const lifePolicies = filter(
  propEq(PolicyAlternateCodes.LIFE, "alternateCode")
);

export const healthPolicies: any = filter(
  propEq(PolicyAlternateCodes.HEALTH, "alternateCode")
);

export const assetPolicies: any = filter(
  propEq(PolicyAlternateCodes.ASSET, "alternateCode")
);

export const getBankInfoByAcNo = (accountNo: any) => (policies: any) => {
  const account = policies.find(({ bankAcNo }: any) => bankAcNo === accountNo);
  return mapBankInfo(account);
};

export const getBankInfoByAcNoCustomerName =
  (accountNo: any) => (policies: any) => {
    const bankPolicies = policies.length ? policies : [];
    const account = bankPolicies.find(
      ({ bankAcNo }: any) => bankAcNo === accountNo
    );
    return mapBankInfo(account);
  };

export const mapBankInfo = (account: any) => ({
  bankAcNo: account?.bankAcNo,
  bankBranch: account?.bankBranch,
  bankIfscCode: account?.bankIfscCode,
  bankName: account?.bankName,
  chequeImage: account?.chequeImage,
  bankLogo: account?.bankLogo,
  accountHolderName: account?.customerName,
  bankVerified: account?.bankVerified,
});

export const getUniqueBanks = (state: RootState) =>
  pipe(uniqBy(prop("bankAcNo")))(
    [
      ...state.banks.policies,
      ...convertUnAssignedBanksToPolicyType(state.banks.unassignedBanks),
    ]
      .filter(({ bankAcNo }) => bankAcNo)
      .map((bank) => ({
        bankAcNo: bank?.bankAcNo,
        bankBranch: bank?.bankBranch,
        bankIfscCode: bank?.bankIfscCode,
        bankName: bank?.bankName,
        accountHolderName: bank?.customerName,
      }))
  );

export const handleAddNewBankUsingOCR = (
  newOCR: any,
  chequeImage?: string
) => ({
  bankAcNo: newOCR?.accountNumber,
  bankBranch: newOCR?.branch,
  bankIfscCode: newOCR?.ifsc,
  bankName: newOCR?.bankName,
  accountHolderName: newOCR?.name,
  chequeImage: chequeImage ? chequeImage : "",
});

const convertUnassignedUnVerifiedToAssignedBankDetails = (bank: any) => ({
  bankAcNo: bank?.accountNo,
  bankBranch: bank?.bankBranch,
  bankIfscCode: bank?.ifscCode,
  bankName: bank?.bankName,
  accountHolderName: bank?.accountHolderName,
  bankVerified: bank?.bankVerified,
  bankLogo: bank?.bankLogo,
  chequeImage: bank?.chequeImage,
});

const convertPolicyBankToUnassignedBank = (bank: any) => ({
  accountNo: bank?.bankAcNo,
  bankBranch: bank?.bankBranch,
  ifscCode: bank?.bankIfscCode,
  bankName: bank?.bankName,
  accountHolderName: bank?.accountHolderName,
  bankVerified: bank?.bankVerified,
  bankLogo: bank?.bankLogo,
  chequeImage: bank?.chequeImage,
});

const convertIfscToBankFormat = (ifscDetails: any, newAccountData: any) => ({
  bankName: convertToString(ifscDetails?.BANK),
  accountHolderName: convertToString(newAccountData?.accountHolderName),
  accountNo: convertToString(newAccountData?.accountNumber),
  bankBranch: convertToString(ifscDetails?.BRANCH),
  ifscCode: convertToString(ifscDetails?.IFSC),
  bankLogo: convertToString(ifscDetails?.LOGO),
  chequeImage: "",
  bankVerified: "N",
});

export const convertOcrToVerifiedBank = (newOCR: any, chequeImage: string) => ({
  bankName: convertToString(newOCR?.bankName),
  accountHolderName: convertToString(newOCR?.name),
  accountNo: convertToString(newOCR?.accountNumber),
  bankBranch: convertToString(newOCR?.branch),
  ifscCode: convertToString(newOCR?.ifsc),
  bankLogo: convertToString(newOCR?.bankLogo),
  chequeImage: convertToString(chequeImage),
  bankVerified: "Y",
});

export const selectGroupedPoliciesByBanks = (state: RootState) =>
  groupPoliciesByBankAccountNumber(
    state.banks.policies.filter(({ bankAcNo }) => bankAcNo)
  );

export const selectTotalPolices = (state: RootState) => state.banks.policies;

export const selectUnassignedBanks = (state: RootState) =>
  state.banks.unassignedBanks;

export const selectUnVerifiedBanks = (state: RootState) =>
  state.banks.unverifiedBanks;

export const selectUnassignedPolicies = (state: RootState) =>
  state.banks.policies.filter(
    ({ bankAcNo }) => !bankAcNo || String(bankAcNo).trim().length === 0
  );

export const selectIsPolicyBanksLoading = (state: RootState) =>
  state.banks.isLoading === "loading";

export const selectIsPolicyBanksStatus = (state: RootState) =>
  state.banks.isLoading;

export const selectPoliciesForBanks = (state: RootState) =>
  state.banks.policies;

export const selectErrorLoadingPolicies = (state: RootState) =>
  state.banks.errors;

export const selectIsBanksLoading = (state: RootState) =>
  state.banks.isLoading === "loading";

export const selectBanks = (state: RootState) => state.banks.banks;

export const selectErrorLoadingBanks = (state: RootState) =>
  state.banks.errorBanks;

export const selectBankChangesList = (state: RootState) =>
  state.banks.bankChangesList;

export const selectGetBankDetailsLoading = (state: RootState) =>
  state.banks.bankDetailByIfsc.status === "loading";

export const selectGetBankDetails = (state: RootState) =>
  state.banks.bankDetailByIfsc.data;

export const selectUploadCanceledChequeLoading = (state: RootState) =>
  state.banks.uploadCanceledCheque.status === "loading";
export const selectUploadCanceledChequeDetails = (state: RootState) =>
  state.banks.uploadCanceledCheque.data;
export const selectUploadCanceledChequePayloadDetails = (state: RootState) =>
  state.banks.uploadCanceledCheque.payload;

export const selectUpdateBankChangesLoading = (state: RootState) =>
  state.banks.updateBankChanges.status === "loading";

export const selectUpdateBankChangesDetails = (state: RootState) =>
  state.banks.updateBankChanges.data;

export default banksSlice.reducer;
