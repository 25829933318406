import Grid from '@mui/material/Grid';
import React from 'react'
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as checked } from '../../../common/icons/profile/checked.svg';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';

const VerifiedAuthorisedPerson = () => {
    const theme = useTheme();
  return (
    <Grid item container spacing={0.5} flexWrap={'nowrap'}>
      <Grid item>
        <SvgConverter
          Icon={checked}
          width={'11px'}
          height={'8px'}
          className='mb-4'
        />
      </Grid>
      <Grid item>
        <Typography className='f-14' color={theme.palette.common.black}>
          Authorised Person
        </Typography>
      </Grid>
    </Grid>
  );
}

export default VerifiedAuthorisedPerson