import React from 'react';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import theme from '../../../../../common/theme';
import TransactionCard from './TransactionCard';

const TrackTransactionLayout = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className='f-13 fw-600 ' color={theme.palette.common.black}>
          Track Transactions
        </Typography>
        <Typography className='f-13 mb-15' color={theme.palette.common.black}>
          Session of DDMMYYYY
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TransactionCard step={'1'} />
        <TransactionCard step={'2'} />
        <TransactionCard step={'3'} />
      </Grid>
    </Grid>
  );
};

export default TrackTransactionLayout;
