const DEV = {
  baseURL: 'http://192.168.3.211:40003/riCustomerManagement/v1/',
  authURL: 'http://192.168.3.211:30002',
};

const UAT = {
  baseURL: 'https://uatcamsrep.bimacentral.in/reImagine/v1/',
  authURL: 'https://uatcamsrep.bimacentral.in',
};

const config = {
  // ...DEV,
  ...UAT,
  // camsPayUrl: 'http://192.168.9.64:4000',
  camsPayUrl: 'https://pgpayment-uat.camsrepository.com',
  camsEkycUrl: 'https://camsrepekycuat.camsonline.com',
  camsEkycBearerToken: 'uat_gRrb0h6J4aXfaLRBle794hVxe53G5BApwBzAjtFdzoU=',
  camsPayUserName: 'CAMSRep',
  camsPayPassword: 'ieHWtgIvZCWrMyU3pKYAc9YndWm7t9t5qV7lAJmqIqI=',
  keyBase64: 'eCo6sjihoAQxtTaE+qSV1VlEDlhILN5R7fREd1BNaWI=',
  ivBase64: '5S3a+G4uChnTD+8DcgFHUQ==',
  razerPayApiKey: 'rzp_test_StZYEA3UBFMWw5',
  camsIdKey: 'EncCoMMONdEC',
  camsIdIv: 'ComMonaesvectors',
  camsReqKey: 'Ld1FjdXen8fMV0tk',
  camsReqIv: 'camspayaesvector',
  camsDecKey: 'Ld1FjdXen8fMV0tk',
  camsChecksumKey:
    '9caa6a4e13d8c05c99a5e8612c90f2ecc2d87f9717d0624f4ad8bfd4e94f657c',
  camsMerchantId: '884233',
  camsSupplierId: '661429',
  // --------------- ETC --------------------------------------
  timeStampFormat: 'YYYY-MM-DDTHH:mm:ss.SSS',
  otpCount: 6,
  otpDuration: 30,
  mPinCount: 4,
  emailRegex:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PanRegex: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
  mobileRegex: /^[0-9]{10}$/,
  eiaRegex: /^[0-9]{13}$/,
  mode: 'dev',
  vahanaCloudDomain: 'https://vahanahub-uat.vahanacloud.com',
  vahanacloudCred: {
    loginId: 'ramasubramaniyam.e@camsonline.com',
    orgid: 'CAMS-B7ERBOTJL0',
    appid: 'POLICYGENIE-9912XNWB10',
    clientsecret: 'bbba4bd5-5b46-49a5-bfd6-33169ccc5355',
    servicename: 'IFSC_DATA',
  },
};

export default config;
