import InboxIcon from "@mui/icons-material/MoveToInbox";
import { APP_ROUTES } from "./Routes";
export const MENU = [
  {
    path: APP_ROUTES.POLICY,
    title: "Policies",
    Icon: InboxIcon,
  },
  {
    path: APP_ROUTES.PROFILE,
    title: "Profile",
    Icon: InboxIcon,
  },

  {
    path: APP_ROUTES.LEGAL,
    title: "Manage",
    Icon: InboxIcon,
    submenus: [
      {
        path: APP_ROUTES.UPDATE_MOBILE,
        title: "Mobile Numbers",
      },
      {
        path: APP_ROUTES.EMAIL,
        title: "emails",
      },
      {
        path: APP_ROUTES.MANAGE_ADDRESS,
        title: "Address",
      },
      {
        path: APP_ROUTES.BANK,
        title: "Bank Details ",
      },
      {
        path: APP_ROUTES.NOMINEE,
        title: "Nominees ",
      },
    ],
  },
];
