import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React from "react";
import theme from "../../../../../common/theme";
import "../../Policy.scss";
import LabelWithIcon from "../../../../../common/ui/LabelWithIcon";
import { ReactComponent as BlackRupee } from "../../../../../common/icons/policy/black_rupee.svg";
import SvgConverter from "../../../../../common/SvgConverter";
import HDFC from "../../../../../assets/images/HDFC.png";
import Separator from "../../../../../common/ui/Separator";
import { ReactComponent as Premium } from "../../../../../common/icons/dashboard/pay_premiums-icon.svg";
import PolicyFooter from "../PolicyFooter/PolicyFooter";
import CustomButton from "../../../../../common/ui/CustomButton";
import { ReactComponent as Circle } from "../../../../../common/icons/dashboard/circle_arrow-icon.svg";
import { ReactComponent as OrangeRuppee } from "../../../../../common/icons/small-orange-rupee.svg";
import { ReactComponent as BigOrangeRuppee } from "../../../../../common/icons/Big-orange-rupee.svg";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../../../common/constants/Routes";
interface UserProps {
  contentType?: string;
  items?: any;
}
const PayNow: React.FC<UserProps> = ({ contentType, items }) => {
  const dayjs = require("dayjs");
  const dateValue = items && items?.premuimDueDate;
  const formattedDate = dayjs(dateValue).format("DD-MM-YYYY");
  return (
    <>
      <Grid container className="py-5 p-5 px-5">
        <Grid item xs={1}>
          <SvgConverter Icon={Premium} className="ml-3 mt-5" />
        </Grid>
        <Grid item xs={5}>
          <Typography className="f-14 px-6" color={theme.palette.primary.main}>
            Premium
          </Typography>
          <LabelWithIcon
            className="px-6"
            svgClassName="mb-2"
            justifyContent={"flex-start"}
            Icon={BlackRupee}
            label={items.premiumAmount.length ? "-" : items.premiumAmount}
            labelProps={{
              color: theme.palette.common.black,
              fontSize: 14,
              fontWeight: 600,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className="f-14  mt-4" color={theme.palette.primary.main}>
            Due by {formattedDate}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          className="py-10 px-10"
          justifyContent={"space-between"}
        >
          <Grid item xs={12}>
            <Separator color={theme.palette.primary.main} />
          </Grid>
          <Grid container item xs={5} className="finance-border mt-10">
            <Grid item xs={1}>
              <SvgConverter Icon={BigOrangeRuppee} className="ml-3 mt-5" />
            </Grid>
            <Grid item xs={10}>
              <Typography
                className="f-12 px-6 fw-600"
                color={theme.palette.primary.main}
              >
                FINANCE MY PREMIUM
              </Typography>
            </Grid>
          </Grid>
          <Grid item className="mt-17">
            <Link to={APP_ROUTES.PREMIUM_PAYMENT} state={{ data: items }}>
              <CustomButton
                text={"PAY NOW"}
                variant={"text"}
                color={"primary"}
                fontSize={14}
                fontWeight={600}
                iconClassName="ml-4"
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PayNow;
