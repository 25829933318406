import React from 'react';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Separator from '../../common/ui/Separator';
import { Link } from 'react-router-dom';
import UserProfileCard from '../../common/ui/UserProfileCard';
import { APP_ROUTES } from '../../common/constants/Routes';
import SvgConverter from '../../common/SvgConverter';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as MenuIcon } from '../../common/icons/menu.svg';
import { ReactComponent as BellIcon } from '../../common/icons/bell.svg';
import { ReactComponent as BemaCentral } from '../../assets/images/BimaLogo.svg';
import { useSelector } from 'react-redux';
import {
  DashboardList,
  selectIsDashboardLoading,
} from '../../features/App/DashboardScreen/Dashboard.slice';
const TopHeader = ({
  setDrawerOpen = () => {},
  setNotificationDrawer = () => {},
  contentHeight = 0,
}: {
  setDrawerOpen?: Function;
  setNotificationDrawer?: Function;
  contentHeight?: number | undefined;
}) => {
  const theme = useTheme();
  const dashboardListDatas = useSelector(DashboardList);
  const customerData = dashboardListDatas?.Customer;
  const dashboardLoading = useSelector(selectIsDashboardLoading);

  return (
    <Grid container alignItems={'center'}>
      <Grid item className='mr-20 ml-10'>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={() => setDrawerOpen(true)}
          edge='start'
        >
          <SvgConverter Icon={MenuIcon} />
        </IconButton>
      </Grid>
      <Grid item flex={1}>
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Grid item>
            <Grid container alignItems={'center'}>
              <Grid item>
                {' '}
                <SvgConverter
                  Icon={BemaCentral}
                  width={155}
                  height={27}
                  className='mt-15 mb-10'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className='mr-40'>
            <Grid container alignItems={'center'}>
              <Grid item>
                <IconButton onClick={() => setNotificationDrawer(true)}>
                  <SvgConverter Icon={BellIcon} />
                </IconButton>
              </Grid>
              <Grid
                item
                sx={{ height: contentHeight - 40 }}
                className={'mx-16'}
              >
                <Separator
                  color={theme.palette.secondary.main}
                  orientation={'vertical'}
                  borderWidth={2}
                />
              </Grid>
              <Grid item>
                <div className='py-8'>
                  <Link to={APP_ROUTES.PROFILE}>
                    <UserProfileCard
                      customerName={customerData?.customerName}
                      eiaNum={customerData?.eiaNo}
                      profileReadiness={customerData?.profileReadiness}
                      lastUpdateDate='2023-06-21'
                      dashboardLoading={dashboardLoading}
                    />
                  </Link>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Separator color={theme.palette.primary.main} />
      </Grid>
    </Grid>
  );
};

export default TopHeader;
