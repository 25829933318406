export const AUTH_ROUTES = {
  LOGIN: "/",
  LOGIN_OTP_VERIFY: "/login/otp-verify",
  SIGNUP: "/signup",
  SIGNUP_OTP_VERIFY: "/signup/otp-verify",
  SIGNUP_VERIFICATION_STATUS: "/signup/verification-status",
  CASE1_STEP1: "/e-Insurance Account Information",
};

export const APP_ROUTES = {
  DASHBOARD: "/",
  POLICY: "/policy",
  ADDPOLICY: "/policy/add",
  ASSIGN_POLICY: "/policy/assign",
  POLICY_DETAILS: "/policy/details",
  PROFILE: "/profile",
  DASHBOARDSCREEN: "/dashboard",
  ADD_FAMILY_SCREEN: "/addfamilyscreen/:title",
  UPDATE_MOBILE: "/update-mobile",
  NEED_MONEY: "/needmoney",
  MY_OPTIONS: "/myoptions",
  ADD_WITNESS_DETAILS: "/add-witness-details",
  RECOMMENDED_SELECTED: "/recommended-combination-selected",
  SUBMIT_LOAN_APPLICATION: "/submit-loan-application",
  TRACK_TRANSACTION: "/track-transaction",
  PREMIUM_PAYMENT: "/premium-payment",
  MANAGE_ADDRESS: "/manage-address",
  SELECT_POLICIES_TO_ASSIGN: "/select-policies-to-assign",
  CHANGE_ADDRESS: "/change-address",
  EMAIL: "/email",
  BANK: "/bank",
  NOMINEE: "/nominee",
  EDITNOMINEE: "/nominee/edit",
  PAYMENTPAGE: "/payment",
  PRIVACYPOLICY: "/privacy/policy",
  DISCLAIMER: "/disclaimer",
  DATAPROTECTIONPOLICY: "/data-protection-policy",
  SECURITYTIPS: "/security-tips",
  LEGAL: "/legal",
  PAYMENT_SUCCESS: "/payment-feedback/success",
  PAYMENT_FAILED: "/payment-feedback/failed",
  AUTHORISED_REPRESENTATIVE: "/authorised-representat",
};
