import React, { useState } from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import UnAssignedPoliciesForBank from "./common/UnAssignedPoliciesForBank";
import { ReactComponent as LifeIcon } from "../../../common/icons/profile/Life_icon.svg";
import { ReactComponent as AssetIcon } from "../../../common/icons/profile/asset_icon.svg";
import { ReactComponent as HealthIcon } from "../../../common/icons/profile/health_icon.svg";
import SvgConverter from "../../../common/SvgConverter";
import Separator from "../../../common/ui/Separator";
import CustomButton from "../../../common/ui/CustomButton";
import { CommonModal } from "../../../common/ui/Modal/CommonModal";
import ChangeBankModalDetails from "./BankModals/ChangeBankModalDetails";
import { assetPolicies, healthPolicies, lifePolicies } from "./Bank.slice";
const UnassignedPoliciesScreen = ({ policies = [], handleChangeBank }: any) => {
  const theme = useTheme();

  const policiesForLife = lifePolicies(policies);
  const policiesForHealth = healthPolicies(policies);
  const policiesForAsset = assetPolicies(policies);

  return (
    <>
      <Grid item className="Manage-bank">
        <Grid item xs={12} className="my-10">
          <Grid item>
            <Typography
              className="f-16 fw-600 mb-10"
              color={theme.palette.secondary.main}
            >
              Unassigned Policies
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent={"space-between"}>
        <Grid item className="mb-10 ">
          <Grid container spacing={1.5} alignItems={"center"} className="mb-5">
            <Grid item>
              <SvgConverter Icon={LifeIcon} width={20} height={21} />
            </Grid>
            <Grid item>
              <Typography color={theme.palette.primary.main} fontSize={14}>
                Life
              </Typography>
            </Grid>
          </Grid>
          <Grid item className="mb-10">
            <Separator
              color={theme.palette.primary.main}
              borderWidth={2}
              className="w-10"
            />
          </Grid>
          <Grid item className="Manage-bank">
            {policiesForLife.map((policy: any) => (
              <UnAssignedPoliciesForBank
                data={policy}
                key={policy?.policyNo}
                onAssignClick={handleChangeBank(policy.bankAcNo, policy)}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item className="">
          <Grid container spacing={1.5} alignItems={"center"} className="mb-5">
            <Grid item>
              <SvgConverter Icon={HealthIcon} width={20} height={21} />
            </Grid>
            <Grid item>
              <Typography color={theme.palette.primary.main} fontSize={14}>
                Health
              </Typography>
            </Grid>
          </Grid>
          <Grid item className="mb-10">
            <Separator color={theme.palette.primary.main} borderWidth={2} />
          </Grid>
          <Grid item className="Manage-bank">
            {policiesForHealth.map((policy: any) => (
              <UnAssignedPoliciesForBank
                data={policy}
                key={policy?.policyNo}
                onAssignClick={handleChangeBank(policy.bankAcNo, policy)}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item className="">
          <Grid container spacing={1.5} alignItems={"center"} className="mb-5">
            <Grid item>
              <SvgConverter Icon={AssetIcon} width={20} height={21} />
            </Grid>
            <Grid item>
              <Typography color={theme.palette.primary.main} fontSize={14}>
                Asset
              </Typography>
            </Grid>
          </Grid>
          <Grid item className="mb-10">
            <Separator color={theme.palette.primary.main} borderWidth={2} />
          </Grid>
          <Grid item className="Manage-bank">
            {policiesForAsset.map((policy: any) => (
              <UnAssignedPoliciesForBank
                data={policy}
                key={policy?.policyNo}
                onAssignClick={handleChangeBank(policy.bankAcNo, policy)}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UnassignedPoliciesScreen;
